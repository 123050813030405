import React, { Component } from 'react';
import { push } from "connected-react-router";
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CommonUtils, CommonWidgetUtils, FeeType, LanguageUtils, LoadStatus, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil, TransferType } from "../../../../utils";

import CashModal from './CashModal';
import CashTransferDetail from "./CashTransferDetail";
import WizardComponent from '../WizardComponent';

import './CashTransfer.scss';
class CashTransfer extends Component {

    constructor(props) {
        super(props);
        this.ExternalMobileNearestControl = React.createRef();
        this.InternalMobileNearestControl = React.createRef();
        this.TransAuthExternalRef = React.createRef();
        this.TransAuthInternalRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        transferType: null,                         //Loại giao dịch
        feeType: FeeType.IN_FEE,                    //Loại phí
        selectedBenefitAccount: null,               //Tài khoản ngân hàng thụ hưởng
        transferParam: null,                        //Thông tin tiền của tiểu khoản chuyển
        transferAmount: null,                       //Số tiền chuyển
        transferAmountValid: false,                 //Kiểm tra giá trị số tiền chuyển
        description: '',                            //Nội dung
        benefitAccounts: [],                        //Danh sách các ngân hàng thụ hưởng
        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,
        authCode: '',
        authCodeValid: false,
        transactDate: null,
        initFuncData: null,                         //Data truyển lên API
        listAuthType: [],                           //Các phương thức xác thực

        transactionId: '',
        tokenId: '',
        refamt: '',
        authtype: '',
        feeamt: '',
        vatamt: '',
        feeAmount: 0,                               //Phí chuyển tiền
        realAmount: 0,                              //Số tiền thực nhận
        accountSend: {},                            // Số tiểu khoản gửi
        accountReceived: {},                        //Số tiểu khoản nhận

        filterAccount: [],
        isOpen: false,                      //Đóng mở Modal
        isRegisteredCashInAdvance: false,   //Đăng ký ứng tiền tự đỘng
        time: 0,
        warningDescMessage: '', //warning message khi có cảnh báo (lấy từ DB)

        authData: {},
        minwithdrawamt: '', // Số tiền chuyển tối thiểu
        onlinestart: '', // thời gian thực hiện yêu cầu
        onlineend: '' // thời gian hết thực hiện yêu cầu
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    clearTimer = () => {
        if (this.counter) {
            clearInterval(this.counter)
        }
    }

    timer = () => {
        this.counter = setInterval(() => {
            let { time } = this.state;
            time = time - 1;
            if (time === 0) {
                this.clearTimer();
            }
            this._setState({
                time: time
            })
        }, 1000);
    }

    setInitVal = () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1); // Ngày tiếp theo 
        let convertedDate = moment(currentDate).format('DD/MM/YYYY');
        this._setState({ transactDate: convertedDate });
    };

    //Set tài khoản ngân hàng
    onBenefitAccountNumberChange = (e) => {
        let found = this.state.benefitAccounts.find(item => item.autoid == e.target.value)
        this._setState({ selectedBenefitAccount: found });
    }

    getFeeAmountTransfer = () => {
        const { currentAccount } = this.props;
        const { transferAmount, bankBranchObj, transferType, selectedBenefitAccount } = this.state;
        let branchid = null;
        let bankid = null;
        let bankacctno = null;
        if (transferType == TransferType.EXTERNAL) {
            branchid = selectedBenefitAccount && selectedBenefitAccount.branchid;
            bankid = selectedBenefitAccount && selectedBenefitAccount.bankid;
            bankacctno = selectedBenefitAccount && selectedBenefitAccount.benefitAccount;
        }
        this._setState({ feeAmount: 0 }); // Mặc định feeAmount = 0
    }
    onBlurTransferAmount = () => {
        this.getFeeAmountTransfer();
    }

    onTransferTypeChange = (event) => {
        const isBroker = this.isBroker();
        const { accounts } = this.props;
        const { accountSend } = this.state
        if (isBroker) {
            if (TransferType.EXTERNAL == event.target.value) {
                ToastUtil.error('CM3', 'CASH_TRANSFER');
            } else {
                this._setState({ transferType: event.target.value }, () => {
                    this.refresh();
                });
            }
        } else {
            if (TransferType.INTERNAL == event.target.value) {
                let filterAccount = accounts && accounts.filter(item => item.id !== accountSend.id)
                this._setState({
                    ...this.initialState,
                    listAuthType: this.state.listAuthType,
                    bankList: this.state.bankList,
                    transferType: this.state.transferType,
                    accountSend: this.state.accountSend,
                    filterAccount: filterAccount,
                    accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null,
                    transferType: event.target.value
                }, () => {
                    this.loadAllData();
                })
            } else if (TransferType.EXTERNAL == event.target.value) {
                this._setState({ transferType: event.target.value }, () => {
                    this.refresh();
                });
            }
        }
    };

    onFeeTypeChange = (event) => {
        this._setState({ feeType: event.target.value });
    };

    onBenefitAccountChange = (account) => {
        this._setState({ selectedBenefitAccount: account });
    };

    onTransferAmountChange = (amount, valid) => {
        this._setState({ transferAmount: amount, transferAmountValid: valid });
    };

    onResetDataInForm = () => {
        this._setState({ transferAmount: null, transferAmountValid: false });
        this.fillDescriptionSample()
    };

    onDescriptionChange = (descriptionInput) => {
        this._setState({ description: descriptionInput });
    };

    onAuthCodeChange = (code, valid) => {
        this._setState({
            authCode: code,
            authCodeValid: valid
        });
    };

    refresh = () => {
        this._setState({
            ...this.initialState,
            listAuthType: this.state.listAuthType,
            bankList: this.state.bankList,
            transferType: this.state.transferType,
            accountSend: this.state.accountSend,
            accountReceived: this.state.accountReceived
        });
        this.loadAllData()
    };


    preCheckInfoInput = () => {
        const {
            description, feeType, transferType, transferAmount, transferParam, selectedBenefitAccount, accountSend, accountReceived, isRegisteredCashInAdvance
        } = this.state;

        //Check tồn tại tiểu khoản chuyển
        if (!accountReceived && transferType === TransferType.INTERNAL) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.001');
            return;
        }

        if (!selectedBenefitAccount && transferType === TransferType.EXTERNAL) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.006');
            return;
        }

        //Check số tiền chuyển
        if (!transferAmount) {
            if (transferAmount === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.003');
                return;
            }
            // ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.002');
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.003');
            return;
        }

        if (!description) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.005');
            return;
        }

        //Check data từ API thông tin tiểu khoản chuyển
        if (transferParam == null) {
            ToastUtil.errorApi('common.fail-to-can-not-make-transaction', 'common.bad-request');
            return;
        }

        //isRegisteredCashInAdvance : true => cho ứng trước
        if (transferAmount > transferParam.availabletransfer && !isRegisteredCashInAdvance) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.004');
            return;
        }

        //Fix lại cho logic thủ tục check
        // if (isRegisteredCashInAdvance) {
        //     let balanceAllow = transferParam.availabletransfer - transferParam.availableadvance
        //     if (transferAmount > balanceAllow && transferAmount <= transferParam.availabletransfer) {
        //         this.toggleConfirm()
        //         return
        //     }
        //     //console.log('check---: ', transferAmount, transferParam.availabletransfer)
        //     if (transferAmount > transferParam.availabletransfer) {
        //         ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.004');
        //         return
        //     }
        //     // else {
        //     //     ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.cash-transfer.error.004');
        //     //     return
        //     // }
        // }

        this.checkTransferInfo(true)
    }

    checkTransferInfo = (isCheckWarning) => {
        const {
            description, feeType, transferType, transferAmount, transferParam, selectedBenefitAccount, accountSend, accountReceived, isRegisteredCashInAdvance
        } = this.state;

        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        if (transferType === TransferType.INTERNAL) {
            precheckCashtransferFunct = transferService.checkInternalTransfer;
            checkTransferInfoBody = {
                "amount": Number(transferAmount),
                "toAccount": accountReceived && accountReceived.id,
            };
        } else if (transferType === TransferType.EXTERNAL) {
            precheckCashtransferFunct = transferService.checkExternalTransfer;
            let feeTypeConvert = feeType === "inFee" ? "IFEE" : "OFEE"

            checkTransferInfoBody = {
                "amount": Number(transferAmount),
                "toAccount": selectedBenefitAccount.benefitaccount,
                "feetype": feeTypeConvert,
                "bankid": selectedBenefitAccount && selectedBenefitAccount.bankid
            };
        }


        this._setState({ loadStatus: LoadStatus.LOADING });
        precheckCashtransferFunct(accountSend.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, feeamt, vatamt, challengeQuestion, authtypes, verified, warning_code, warning_desc } = result;
                    if (warning_code && warning_code != 0 && isCheckWarning == true) { //Check cảnh báo
                        this.toggleConfirm(warning_desc);
                        this._setState({ loadStatus: LoadStatus.LOADED });
                        return;
                    }
                    if (transferType === TransferType.INTERNAL) {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        let _dataInfo = this.getDataInfoCashTransfer()
                        this._setState({
                            authData: _dataInfo
                        }, () => {

                            this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, transactionId: transactionId, isOpen: false })

                            // this.props.updateConfirmCheckAuth({
                            //     typeInfo: ModalConfirmType.CASH_TRANSFER_INTERNAL,
                            //     dataInfo: _dataInfo,
                            // })
                            // this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            //     isOpenScreenConfirm: true,
                            //     isCheckAuth2: true,
                            // })
                        });
                    } else {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        let _dataInfo = this.getDataInfoCashTransfer()
                        this._setState({
                            authData: _dataInfo
                        }, () => {
                            this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false })
                            // this.props.updateConfirmCheckAuth({
                            //     typeInfo: ModalConfirmType.CASH_TRANSFER_EXTERNAL,
                            //     dataInfo: _dataInfo,
                            // })

                            // this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            //     isOpenScreenConfirm: true,
                            //     isCheckAuth2: true,
                            // })

                        });
                    }

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    loadTransferParam = () => {
        const { transferType, accountSend } = this.state;
        this._setState({ transferParam: null });
        if (accountSend) {
            inquiryService.getTransferParam(accountSend.id, transferType)
                .then((data) => {
                    this._setState({ transferParam: data.length > 0 ? data[0] : null, minwithdrawamt: Number(data[0]['minwithdrawamt']) || Number(data[0]['minwithdrawamt']) === 0 ? data[0]['minwithdrawamt'] : '', onlinestart: data[0]['onlinestart_trf_time'] || '', onlineend: data[0]['onlineend_trf_time'] || '' });
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-transfer-params');
                });
        }
    }

    loadBenefitAccounts() {
        const { transferType, accountSend } = this.state;
        const { accounts } = this.props;
        this._setState({ benefitAccounts: [], selectedBenefitAccount: null });
        if (accountSend) {
            if (transferType === TransferType.INTERNAL) {

                let filterAccount = accounts.filter(item => item.id !== accountSend.id)
                // let filterAccount1 = accounts.find(item => item.id === currentAccountId)

                this._setState({ filterAccount: this.state.filterAccount, accountSend: this.state.accountSend, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null, });
            } else if (transferType === TransferType.EXTERNAL) {
                this._setState({ benefitAccounts: [] });
                inquiryService.getTransferAccountList(accountSend.id, transferType)
                    .then((data) => {
                        this._setState({ benefitAccounts: data, selectedBenefitAccount: null });
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
                    });
            }
        }
    }

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    setStep = (step, isReloadData = false) => {
        this._setState({
            step: step
        });

        if (isReloadData) {
            this.loadInitData()
        }
    };


    loadAllData = () => {
        this.setInitVal();
        this.loadTransferParam();
        this.loadBenefitAccounts();
        this.loadRegisterCashInAdvanceInfo()
        this.fillDescriptionSample()
    }

    loadInitData = () => {
        const { accounts, currentAccountId, transType } = this.props;
        const { accountSend } = this.state;
        let filterAccount = accounts && accounts.filter(item => item.id !== currentAccountId)
        let filterAccount1 = accounts && accounts.find(item => item.id === currentAccountId)

        this._setState({ filterAccount: filterAccount, accountSend: filterAccount1, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null },
            () => {
                this.loadAllData();
            }
        );
        if (transType) {
            this._setState({ transferType: transType });
        }
    }

    callbackDocument = () => {
        document.body.classList.add("using-keyboard")
        document.body.classList.remove("using-mouse")
    }

    componentDidMount() {
        this.mounted = true
        document.addEventListener("keydown", this.callbackDocument)
        this.loadInitData()
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.callbackDocument)
        this.mounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.transType !== nextProps.transType) {
            this._setState({ transferType: nextProps.transType }, () => {
                this.onResetDataInForm();
                this.loadAllData();
                this._setState({ step: 1 });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketSEAction, socketODAction, language, currentAccountId } = this.props;
        const { transferType, bankBranchObj, selectedBenefitAccount, accountSend } = this.state;
        const { transferType: prevTransferType, bankBranchObj: prevBankBranchObj, selectedBenefitAccount: prevselectedBenefitAccount, accountSend: prevAccountSend } = prevState;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction, language: prevlanguage, currentAccountId: prevCurrentAccountId
        } = prevProps;

        if (bankBranchObj !== prevBankBranchObj || selectedBenefitAccount !== prevselectedBenefitAccount) {
            this.getFeeAmountTransfer();
        }

        if (language !== prevlanguage) {
            this.fillDescriptionSample();
        }

        if (socketCIAction[accountSend && accountSend.id] !== prevSocketCIAction[accountSend && accountSend.id] || socketSEAction[accountSend && accountSend.id] !== prevSocketSEAction[accountSend && accountSend.id] || socketODAction[accountSend && accountSend.id] !== prevSocketODAction[accountSend && accountSend.id]) {
            this.loadTransferParam();
        }

        if (accountSend !== prevAccountSend) {
            this.loadTransferParam()
            this.loadRegisterCashInAdvanceInfo();
        }
        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData();
        }
        if ((currentAccountId !== prevCurrentAccountId)) {
            this.loadInitData(currentAccountId);

        }
    }

    setAccountSendByChangeHeader = (currentAccountId) => {
        const { accounts } = this.props;
        let filterAccount = accounts && accounts.filter(item => item.id !== currentAccountId)
        let filterAccount1 = accounts && accounts.find(item => item.id === currentAccountId)
        this._setState({ filterAccount: filterAccount, accountSend: filterAccount1, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null, });
    }

    //Set tiểu khoản chuyển
    setAccountSend = (event) => {
        const { accounts } = this.props;
        const { transferType } = this.state;

        let filterAccount = accounts.filter(item => item.id !== event.target.value)
        let foundAccount = accounts.find(item => item.id === event.target.value)
        if (transferType === TransferType.INTERNAL) {
            this.props.setAccountIdScreen(event.target.value, SELECTOR_ACCOUNT_SCREEN.LAYOUT);
            this._setState({
                accountSend: foundAccount,
                filterAccount: filterAccount,
                accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null,
                transferAmount: null
            })
        } else {
            this._setState({ accountSend: foundAccount, filterAccount: filterAccount, transferAmount: null })
        }
    };

    //Set tiểu khoản nhận
    setAccountReceived = (event) => {
        const { accounts } = this.props;
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountReceived: foundAccount, transferAmount: null })
    };

    // Gọi ở đây để lấy ra trường đã đăng ký ứng trước tự động hay chưa
    loadRegisterCashInAdvanceInfo = () => {
        const { accountSend } = this.state;
        this._setState({ isRegisteredCashInAdvance: false });
        if (accountSend) {
            inquiryService.getInfoForAdvance(accountSend.id)
                .then((data) => {
                    this._setState({ isRegisteredCashInAdvance: data && data['autoadv'] === 'Y' ? true : false });
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        }
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    //Đóng mở Popup xác nhận
    toggleConfirm = (warning_desc = '') => {
        this._setState({
            isOpen: !this.state.isOpen,
            warningDescMessage: warning_desc
        });
    }

    //Điền nội dung tự động
    fillDescriptionSample = (e) => {
        let { intl, userInfo, curCustomer } = this.props
        const { transferType, accountSend, accountReceived } = this.state;
        let descriptionTransfer = ''
        const isBroker = this.isBroker();
        // if (!isBroker) {
        //     descriptionTransfer = `${LanguageUtils.getMessageByKey("account.utilities.cash-transfer.transfer1", this.props.language)}`
        // }
        // descriptionTransfer = `${LanguageUtils.getMessageByKey("account.utilities.cash-transfer." + (transferType === TransferType.INTERNAL ? "transfer-inner" : "transfer-external"), this.props.language)}`
        let custodycd = userInfo.custodycd || '';
        if (isBroker) {
            custodycd = curCustomer.custodycd || '';
        }
        if (custodycd) {
            if (transferType === TransferType.INTERNAL) {
                descriptionTransfer = intl.formatMessage({ id: "account.utilities.cash-transfer.transfer-inner" }, { custodycd: custodycd || '', accountsend: accountSend ? (accountSend.cdcontent || '') : '', accountreceive: accountReceived ? (accountReceived.cdcontent || '') : '' });
            } else if (transferType === TransferType.EXTERNAL) {
                descriptionTransfer = intl.formatMessage({ id: "account.utilities.cash-transfer.transfer-external" }, { custodycd: custodycd || '', accountsend: accountSend ? (accountSend.cdcontent || '') : '', name: userInfo.fullname || '' });
            }
        } else {
            descriptionTransfer = `${LanguageUtils.getMessageByKey("account.utilities.cash-transfer.transfer1", this.props.language)}`
        }

        let event = {
            target: {
                value: descriptionTransfer
            }
        }
        this.onDescriptionChangeInput(event)
    }

    onDescriptionChangeInput = (event) => {
        const descriptionInput = event.target.value;
        const isValid = (/\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\+|\=|\[|\{|\]|\}|\||\\|\'|\<|\,|\>|\?|\/|\"|\;|\:|\|\_/g.test(descriptionInput));
        if (!isValid && descriptionInput.length <= 200) {
            this._setState({
                descriptionInput: descriptionInput
            })
            this.onDescriptionChange(descriptionInput);
        }
    }

    transactionCb = () => {
        // ToastUtil.success('account.utilities.cash-transfer.success', 'account.utilities.cash-transfer.success-transfer');
        // this.props.setIsOpenModalHaveData("ScreenConfirm", {
        //     isOpenScreenConfirm: false,
        //     isCheckAuth2: true,
        // })
        this.onResetDataInForm()
        this._setState({ step: 3, loadStatus: LoadStatus.LOADED });
    };

    transactionInternalCb = () => {
        // ToastUtil.success('account.utilities.cash-transfer.success', 'account.utilities.cash-transfer.success-transfer');
        // this.props.setIsOpenModalHaveData("ScreenConfirm", {
        //     isOpenScreenConfirm: false,
        //     isCheckAuth2: true,
        // })
        this.onResetDataInForm()
        this._setState({ step: 3, loadStatus: LoadStatus.LOADED });
    };

    //Xử lý chuyển tiền nội bộ (case không xử lý xác thực hai lớp với chuyển tiền nội bộ)
    handleProcessInternalTranser = () => {
        const {
            accountReceived, accountSend, transferAmount, description, transactionId
        } = this.state;

        let descriptionConvert = CommonUtils.returnWithoutAccents(description)
        let transferBody = {
            "receiveAccount": accountReceived && (accountReceived.id ? accountReceived.id : accountReceived),
            "amout": transferAmount && Number(transferAmount),
            "transDescrtiption": descriptionConvert,
            "transactionId": transactionId,
        };

        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            loadStatusScreenConfirm: LoadStatus.LOADING,
        })
        transferService.processInternalTransfer(accountSend.id, transferBody)
            .then((data) => {
                ToastUtil.success('account.utilities.cash-transfer.success', 'account.utilities.cash-transfer.success-transfer');
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    loadStatusScreenConfirm: LoadStatus.LOADED,
                    isOpenScreenConfirm: false
                })
                this.onResetDataInForm()
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    loadStatusScreenConfirm: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'common.fail-to-can-not-make-transaction');
            });
    }

    genInfoWithTypeCash = () => {
        const {
            isOpen, feeAmount,
            loadStatus, accountSend, accountReceived, filterAccount,
            transferParam, transferType, selectedBenefitAccount, benefitAccounts, feeType,
            transferAmount, transferAmountValid, description,
            authStatus,
            listAuthType,
            transactionId, isRegisteredCashInAdvance

        } = this.state;
        const { accounts, userInfo, currentAccount, language, currentCustomer, role } = this.props;

        let processAuthFunc = null;
        let transferBody = null;
        let feeTypeConvert = feeType === "inFee" ? "IFEE" : "OFEE"
        let descriptionConvert = CommonUtils.returnWithoutAccents(description)

        if (transferType === TransferType.INTERNAL) {
            processAuthFunc = transferService.processInternalTransfer;
            transferBody = {
                "receiveAccount": accountReceived && (accountReceived.id ? accountReceived.id : accountReceived),
                "amout": transferAmount && Number(transferAmount),
                "transDescrtiption": descriptionConvert,
                "accountId": accountSend && accountSend.id,
                "remainAmount": transferParam && transferParam.availableTransfer
            };
            transferBody = CommonUtils.checkDataBodyNotNull(transferBody)
        } else if (transferType === TransferType.EXTERNAL) {
            processAuthFunc = transferService.processExternalTransfer;
            transferBody = {
                "benefitBank": selectedBenefitAccount && selectedBenefitAccount.benefitbankname,
                "benefitAccount": selectedBenefitAccount && selectedBenefitAccount.benefitaccount,
                "benefitName": selectedBenefitAccount && selectedBenefitAccount.benefitname,
                "benefitLisenseCode": selectedBenefitAccount && selectedBenefitAccount.benefitlisencecode,
                "branchid": selectedBenefitAccount && selectedBenefitAccount.branchid,
                "bankid": selectedBenefitAccount && selectedBenefitAccount.bankid,
                "amout": Number(transferAmount),
                "feeType": feeTypeConvert,
                "transDescrtiption": descriptionConvert,
                "remainAmount": transferParam && transferParam.availableTransfer,
                "accountId": accountSend && accountSend.id,
                "feeAmount": feeAmount,
                "branchid": (selectedBenefitAccount && selectedBenefitAccount.branchid) || '',
                "bankid": selectedBenefitAccount && selectedBenefitAccount.bankid
            };
            transferBody = CommonUtils.checkDataBodyNotNull(transferBody)
        }

        return {
            processAuthFunc,
            transferBody,
            feeTypeConvert,
            descriptionConvert,
        }
    }

    processTransfer = () => {
        this._setState({ loadStatus: LoadStatus.LOADING });
        this.TransAuthExternalRef.current.processAuth();
    };

    processInternalTransfer = () => {
        this._setState({ loadStatus: LoadStatus.LOADING });
        this.TransAuthInternalRef.current.processAuth();
    };

    getDataInfoCashTransfer = () => {
        const {
            isOpen, feeAmount,
            loadStatus, accountSend, accountReceived, filterAccount,
            transferParam, transferType, selectedBenefitAccount, benefitAccounts, feeType,
            transferAmount, transferAmountValid, description,
            authStatus,
            listAuthType,
            transactionId, isRegisteredCashInAdvance

        } = this.state;
        const { userInfo, curCustomer } = this.props;
        const isBroker = this.isBroker();
        let _info = null
        let { processAuthFunc, transferBody, feeTypeConvert, descriptionConvert } = this.genInfoWithTypeCash()
        if (transferType === TransferType.INTERNAL) {
            _info = {
                transferAmount: transferAmount,
                accountSend: accountSend,
                accountReceived: accountReceived,
                transferType: transferType,
                description: description,
                loadStatus: loadStatus,
                selectedBenefitAccount: selectedBenefitAccount,
                initFuncData: transferBody,
                feeAmount: feeAmount,
                authRef: this.TransAuthInternalRef,
                custodycd: isBroker && curCustomer ? curCustomer.custodycd : userInfo.custodycd,
                processTransfer: () => { this.processInternalTransfer() },
                processAuthFunc: processAuthFunc,
                transactionCb: () => { this.transactionInternalCb() },
                transactionFailCb: (error) => {
                    ToastUtil.errorApi(error, 'trade.auth.auth-fail');
                    this._setState({
                        loadStatus: LoadStatus.FAIL
                    });
                },
                // onSubmit: () => { this.handleProcessInternalTranser() },
                title: "common.confirm-trade",
            }
        }
        else if (transferType === TransferType.EXTERNAL) {
            _info = {
                feeType: feeType,
                transferAmount: transferAmount,
                accountSend: accountSend,
                accountReceived: accountReceived,
                transferType: transferType,
                description: description,
                selectedBenefitAccount: selectedBenefitAccount,
                initFuncData: transferBody,
                feeAmount: feeAmount,
                authRef: this.TransAuthExternalRef,
                custodycd: isBroker && curCustomer ? curCustomer.custodycd : userInfo.custodycd,
                processTransfer: () => { this.processTransfer() },
                processAuthFunc: processAuthFunc,
                refresh: () => { this.refresh() },
                transactionCb: () => { this.transactionCb() },
                transactionFailCb: (error) => {
                    ToastUtil.errorApi(error, 'trade.auth.auth-fail');
                    this._setState({
                        loadStatus: LoadStatus.FAIL
                    });
                },
                title: "common.confirm-trade",
                showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
            }
        }

        return _info || {}
    }

    render() {
        const {
            isOpen, feeAmount,
            loadStatus, accountSend, accountReceived, filterAccount,
            transferParam, transferType, selectedBenefitAccount, benefitAccounts, feeType,
            transferAmount, transferAmountValid, description, step, authData
        } = this.state;
        const { accounts, userInfo, currentAccount, language, currentCustomer, role, defaultTheme, curCustomer } = this.props;
        let { processAuthFunc, transferBody, feeTypeConvert, descriptionConvert } = this.genInfoWithTypeCash()

        const isBroker = this.isBroker();
        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="h-100">
                <div className="cash-transfer h-100">
                    <WizardComponent step={step}></WizardComponent>
                    <CashTransferDetail
                        step={step}
                        setStep={this.setStep}
                        authData={authData}
                        language={language}
                        transferType={transferType}
                        transferParam={transferParam}
                        accounts={accounts}
                        isBroker={isBroker}
                        selectedAccount={currentAccount}
                        selectedBenefitAccount={selectedBenefitAccount}
                        transferAmount={transferAmount}
                        description={description}
                        userInfo={userInfo}
                        benefitAccounts={benefitAccounts}
                        onBenefitAccountChange={this.onBenefitAccountChange}
                        onTransferTypeChange={this.onTransferTypeChange}
                        onTransferAmountChange={this.onTransferAmountChange}
                        transferAmountValid={transferAmountValid}
                        onDescriptionChange={this.onDescriptionChange}

                        role={role}
                        ExternalMobileNearestControl={this.ExternalMobileNearestControtransferAmountl}
                        InternalMobileNearestControl={this.InternalMobileNearestControl}

                        onBenefitAccountNumberChange={this.onBenefitAccountNumberChange}

                        onBlurTransferAmount={this.onBlurTransferAmount}
                        onResetDataInForm={this.onResetDataInForm}
                        getFeeAmountTransfer={this.getFeeAmountTransfer}

                        ///////////////
                        setAccountSubReceived={this.setAccountSubReceived}
                        accountSend={accountSend}
                        accountReceived={accountReceived}
                        setAccountSend={this.setAccountSend}
                        setAccountReceived={this.setAccountReceived}
                        filterAccount={filterAccount}
                        feeType={feeType}
                        onFeeTypeChange={this.onFeeTypeChange}
                        refresh={this.refresh}
                        onDescriptionChangeInput={this.onDescriptionChangeInput}
                        preCheckInfoInput={this.preCheckInfoInput}
                        defaultTheme={defaultTheme}
                        curCustomer={curCustomer}
                        loadStatusReload={this.state.loadStatusReload}
                        time={this.state.time}
                        loadTransferParam={this.loadTransferParam}
                        fillDescriptionSample={this.fillDescriptionSample}
                        minwithdrawamt={this.state.minwithdrawamt}
                        onlinestart={this.state.onlinestart}
                        onlineend={this.state.onlineend}
                        loadStatus={loadStatus}

                    />
                    {
                        isOpen &&
                        <CashModal
                            isOpen={isOpen}
                            toggle={this.toggleConfirm}
                            checkTransferInfo={this.checkTransferInfo}
                            warningDescMessage={this.state.warningDescMessage}
                        ></CashModal>
                    }
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    // let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    // let _accountInfo = _layoutPage && _layoutPage.accountInfo
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: _accounts,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        curCustomer: curCustomer,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashTransfer));

