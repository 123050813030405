import React, { Component } from 'react';

import { connect } from "react-redux";
import { Role } from 'constants/config';
import ConfirmOrderBroker from './ConfirmOrderBroker';
import ConfirmOrderOnline from './ConfirmOrderOnline';

import './ConfirmOrders.scss'
import CustomMenuTab from 'components/CustomMenuTab/CustomMenuTab';

const TAB = {
    BROKER: "BROKER",
    ALL: "ALL"
}

class ConfirmOrders extends Component {
    state = {
        tabActive: this.props.role === Role.BROKER ? TAB.BROKER : TAB.ALL
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	
		
	componentWillUnmount() {
        this.mounted = false
    }

    isBroker = () => {
        const { role } = this.props
        return role === Role.BROKER
    }

    handleSelectTab = (tab) => {
        this._setState({
            tabActive: tab
        })
    }

    render() {
        const isBroker = this.isBroker()
        const { tabActive } = this.state;

        let listTab = isBroker ? [
            {
                title: "confirm-order-online.BROKER",
                value: TAB.BROKER,
            },
            {
                title: "confirm-order-online.ALL",
                value: TAB.ALL,
            }
        ] : [
            {
                title: "confirm-order-online.ALL",
                value: TAB.ALL,
            },
        ]

        return (
            <div className="group-confirm-order">
                <div className='block block-custom-tab'>
                    <div className="header-block-custom-tab">
                        <CustomMenuTab
                            listTab={listTab}
                            addClassTitle={"headB---700-16-24 "}
                            onChangeTab={this.handleSelectTab}
                            activeTab={tabActive}
                        />
                    </div>
                    <div className={"body-block-custom-tab " + (isBroker ? "main-content broker" : "main-content")}>
                        {
                            tabActive === TAB.BROKER && (
                                <ConfirmOrderBroker />
                            )
                        }
                        {
                            tabActive === TAB.ALL && (
                                <ConfirmOrderOnline />
                            )
                        }
                    </div>
                </div>
                {/* 
                {
                    isBroker == true && (
                        <div className="tab">
                            {
                                Object.keys(TAB).map((item, index) => {
                                    return (
                                        <div
                                            key={`${index}-${item}`}
                                            className={tabActive === item ? "sub-tab txt---700-14-20 active" : "sub-tab txt---400-14-20"}
                                            onClick={() => this.handleSelectTab(item)}
                                        >
                                            <FormattedMessage id={`confirm-order-online.${item}`} />

                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                } */}

                {/* 
                <div className={isBroker ? "main-content broker" : "main-content"}>
                    {
                        tabActive === TAB.BROKER && (
                            <ConfirmOrderBroker />
                        )
                    }
                    {
                        tabActive === TAB.ALL && (
                            <ConfirmOrderOnline />
                        )
                    }
                </div> */}

            </div>

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        lang: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        language: state.app.language,
    };
};
const mapDispatchToProps = dispatch => {
    return {

    }
};
export default connect(mapStateToProps, null)(ConfirmOrders)

