import React, { Component } from 'react';
import RetryableContent from 'components/RetryableContent';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';

import { LoadStatus } from "../../../../utils";

import "./CashModal.scss"

class CashModal extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    initialState = {
        loadStatus: LoadStatus.NONE,
    };


    state = {
        ...this.initialState
    };





    render() {
        const {
            isOpen, toggle, loadStatus, checkTransferInfo, warningDescMessage
        } = this.props;
        // const { loadStatus } = this.state

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="cash-modal"
            >
                <RetryableContent status={loadStatus} disableRetry={true}>
                    <ModalBody className="confirm-end">
                        <div className='headB---700-20-30 title'>
                            <FormattedMessage id="Thông báo" />
                        </div>
                        <div className='txt---500-14-20 content'>
                            {/* <FormattedMessage id="account.utilities.cash-transfer.warningTranfer" /> */}
                            { warningDescMessage }
                        </div>
                        {/* Thao tác */}
                        <div className="row gutters-5 align-items-center h-row container-btn">
                            {/* Làm mới */}
                            <div className="col-6">
                                <button className="btn-refresh txt---500-16-24 btn"
                                    onClick={() => toggle()}
                                >
                                    <FormattedMessage id="common.btn-cancel" />
                                </button>
                            </div>
                            {/* Xác nhận */}
                            <div className="col-6">
                                <button className="btn-confirm txt---500-16-24 btn"
                                    onClick={() => checkTransferInfo()}
                                >
                                    <FormattedMessage id="common.btn-confirm" />
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </RetryableContent >
            </Modal >

        )
    };
};

export default CashModal;