import React, { Component } from 'react';
import _ from 'lodash'
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { ReactComponent as DownloadIconLight } from '../../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../../assets/icons/new/DownloadIcon_Dark.svg'
import config from '../../../../combineConfig';
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import TextToolTip from "../../../../components/TextToolTip";
import { fdsInquiryService, inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, execTypes, KeyCodeUtils, LoadStatus, mDate, Random, reportParams, reportTypes, Role, RPT_ID, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../../../utils";
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './RealizedProfitLoss.scss';

const totalDefault =
    [
        {
            column: "acctno",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
        },
        {
            column: "pnl",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "laikkh",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "phigd",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "laiut",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "tax",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "otherfee",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "phimargin",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]

let columnsDefault =
    [
        /* Số tiểu khoản */
        {
            dataField: "acctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* GT lãi/lỗ đã thực hiện */
        {
            dataField: "pnl",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Lãi không kỳ hạn */
        {
            dataField: "laikkh",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            minWidth: "100",
            width: '120',
            visibleIndex: 2,
        },
        // /* Lãi iFlex */
        // {
        //     dataField: "laiiflex",
        //     dataType: "string",
        //     allowReordering: true,  // không cho di chuyển
        //     alignment: "right",
        //     visible: true,
        //     visibleIndex: 3,
        //     minWidth: "100",
        //     width: '120',
        // },
        /* Chi phí */
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 4,
            listColumnGroup: [
                /* Phí giao dịch */
                {
                    dataField: "phigd",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 5,
                },
                /* Thuế */
                {
                    dataField: "tax",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 6,
                },
                /* Phí Margin */
                {
                    dataField: "phimargin",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 7,
                },
                /* Lãi ứng trước */
                {
                    dataField: "laiut",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 8,
                },
                /* Phí lưu ký */
                // {
                //     dataField: "philuuky",
                //     dataType: "string",
                //     allowReordering: true,  // không cho di chuyển
                //     // minWidth: "90",
                //     // width: '100',
                //     alignment: "right",
                //     visible: true,
                //     visibleIndex: 9,
                // },
                /* Phí khác */
                {
                    dataField: "otherfee",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 9,
                },
            ],
        },
    ]


/* PQL vị thế PS */
// {
//     dataField: "pqlvithe",
//     dataType: "string",
//     allowReordering: true,  // không cho di chuyển
//     minWidth: "90",
//     width: '100',
//     alignment: "right",
//     visible: true,
//     visibleIndex: 9,
// },

/* PQL TSKQ phái sinh */
// {
//     dataField: "pqltskq",
//     dataType: "string",
//     allowReordering: true,  // không cho di chuyển
//     minWidth: "90",
//     width: '100',
//     alignment: "right",
//     visible: true,
//     visibleIndex: 10,
// },

let columnsDefaultFDS =
    [
        /* Số tiểu khoản */
        {
            dataField: "acctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* GT lãi/lỗ đã thực hiện */
        {
            dataField: "pnl",
            dataType: "string",
            allowReordering: true,
            // minWidth: "90",
            // width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Lãi không kỳ hạn */
        {
            dataField: "laikkh",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 2,
        },
        /* Chi phí */
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 3,
            listColumnGroup: [
                /* Phí giao dịch */
                {
                    dataField: "phigd",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // minWidth: "90",
                    // width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 4,
                },
            ],
        },
    ]

/* PQL vị thế PS */
// {
//     dataField: "pqlvithe",
//     dataType: "string",
//     allowReordering: true,  // không cho di chuyển
//     // minWidth: "90",
//     // width: '100',
//     alignment: "right",
//     visible: true,
//     visibleIndex: 5,
// },

/* PQL TSKQ phái sinh */
// {
//     dataField: "pqltskq",
//     dataType: "string",
//     allowReordering: true,  // không cho di chuyển
//     // minWidth: "90",
//     // width: '100',
//     alignment: "right",
//     visible: true,
//     visibleIndex: 6,
// },

class RealizedProfitLoss extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        page: 1,
        numOfPage: 1,
        gotoPage: null,

        nextIndex: 0, //nextIndex > 0 có thể next trang tiếp theo
        prevIndex: 0,
        isDisabled: false,
        listAccounts: [],
        onReloadData: true,
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        //this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    genReport = (exptype) => {
        let { currentAccount, fromDate, toDate, symbol, execType, orderStatus } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL;
        execType = execType ? execType : reportParams.ALL;
        orderStatus = orderStatus ? orderStatus : reportParams.ALL;

        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];
            let accountId = currentAccount.id
            if (currentAccount.accounttype === "ALL") {
                accountId = "ALL"
            }
            rptId = RPT_ID.GET_SUMMARY_PNL;
            inpArr = [custid, accountId, fromDate, toDate, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };
    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
		
	componentWillUnmount() {
        this.mounted = false
    }
	

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        const { accounts, language } = this.props;
        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: newRow, listAccounts: found },
            () => {
                this.setReloadData()
            }
        );
        this.loadInitData();
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    // run only once when component is mounted
    loadInitData = (check) => {
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;
        let newRow = (language === 'en') ? {
            id: "All",
            entypename: "Basis",
            accounttype: "ALL"
        } :
            {
                id: "Tất cả",
                entypename: "Cơ sở",
                accounttype: "ALL"
            }
        let found = [newRow, ...accounts]

        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found },
            () => {
                this.setReloadData()
            }
        );
    };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData(this.state.page);
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };


    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    getTotals = (data, key) => {
        let total = 0;
        data.forEach(item => {
            total += item[key];
        });
        return total;
    };


    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        if (!isDerivative) {
            switch (dataField) {
                case 'acctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
                case 'pnl':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.pnl, "PRICE");
                case 'laikkh':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.laikkh, "PRICE");
                case 'laiiflex':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.laiiflex, "PRICE");
                case 'tax':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.tax, "PRICE");
                case 'phigd':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.phigd, "PRICE");
                case 'phimargin':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.phimargin, "PRICE");
                case 'laiut':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.laiut, "PRICE");
                // case 'philuuky':
                //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.philuuky, "PRICE");
                case 'otherfee':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.otherfee, "PRICE");
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'acctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
                case 'pnl':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.pnl, "PRICE");
                case 'laikkh':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.laikkh, "PRICE");
                case 'phigd':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.phigd, "PRICE");
                // case 'pqlvithe':
                //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.pqlvithe, "PRICE");
                // case 'pqltskq':
                //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.pqltskq, "PRICE");
                default:
                    return null;
            }
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'acctno':
                return "realized-profit-loss.sub-account-number";
            case 'pnl':
                return "realized-profit-loss.realized-profit-loss-gt";
            case 'laikkh':
                return "realized-profit-loss.interest-without-term";
            case 'laiiflex':
                return "realized-profit-loss.interest-iflex";
            case 'tax':
                return "realized-profit-loss.tax";
            case 'group':
                return "realized-profit-loss.cost";
            case 'phigd':
                return "realized-profit-loss.transaction-fee";
            case 'phimargin':
                return "realized-profit-loss.margin-fee";
            case 'laiut':
                return "realized-profit-loss.advance-interest";
            // case 'philuuky':
            //     return "realized-profit-loss.deposit-fee";
            // case 'pqlvithe':
            //     return "realized-profit-loss.pql-position-ps";
            // case 'pqltskq':
            //     return "realized-profit-loss.pql=derivatives-results";
            case 'otherfee':
                return "realized-profit-loss.otherfee";
            default:
                return null;
        }
    }

    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount, page } = this.state;

        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }
        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            let self = this
            let _obj = {
                custid: currentAccount.id,
                fromDate: fromDate,
                page: obj.page,
                limitRow: obj.limitRow,
                toDate: toDate,
            }
            if (currentAccount && currentAccount.accounttype === "SEC") {
                return inquiryService.getpNLSummaryByAccount(_obj.custid, _obj.fromDate, _obj.toDate).then((data) => {
                    if (data && data.d) {
                        data = data.d
                    }
                    return data
                })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'RELIZEDPROFIT_LOSS');
                        return []
                    });
            }
            if (currentAccount && currentAccount.accounttype === "FDS") {
                return fdsInquiryService.getFDSpNLSummary(_obj.custid, _obj.fromDate, _obj.toDate).then((data) => {
                    if (data && data.d) {
                        data = data.d
                    }
                    return data
                })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'RELIZEDPROFIT_LOSS');
                        return []
                    });
            }

            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                if (custid) {
                    return inquiryService.getpNLSummary(custid, _obj.fromDate, _obj.toDate).then((data) => {
                        if (data && data.d) {
                            data = data.d
                        }
                        return data
                    })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'RELIZEDPROFIT_LOSS');
                            return []
                        });
                }
            }
            return []

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    search = () => {
        this.setReloadData();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };
    render() {
        const { language, accountInfo, accounts } = this.props;
        const { currentAccount, records, listAccounts, onReloadData } = this.state;
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const isBroker = this.isBroker();
        let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        fullName = CommonUtils.getDisplayName(fullName);
        return (
            <RetryableContent className="realized-profit-loss report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="realized-profit-loss-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n txt---400-14-20 padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                {/* <div className='align-self-center label txt---400-14-20'><FormattedMessage id="realized-profit-loss.sub-account-fillter" /></div> */}
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control">
                                    {(!accounts || accounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            {isBroker && <div className="custom-form-group-n fullname">
                                <div className='align-self-center txt---400-14-20'>
                                    {fullName}
                                </div>
                            </div>}
                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-center"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-center"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {CommonUtils.renderIconTheme(<DownloadIconLight />, <DownloadIconDark />)}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-block-table p-table-10">
                        <TableExtremeCustom2
                            keyStoreUpdate="acctno" // key của row 
                            columnsDefault={isDerivative ? columnsDefaultFDS : columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="realized-profit-loss-page" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            apiLoadAll={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        customerFullName: state.user.customerFullName,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RealizedProfitLoss));
