import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { NumberInput2New } from "../../../../components/Input";
import { CashAdvanceTypes, CommonUtils, FeeTypes, stepValues, SELECTOR_ACCOUNT_SCREEN, KeyCodeUtils } from "../../../../utils";

import ModalPDFRules from './ModalPDFRules';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import LanguageUtils from '../../../../utils/LanguageUtils'
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import { Fragment } from 'react';
import { LoadStatus } from 'constants/config';

let columnsDefault =
    [
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // minWidth: "130",
            // width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "duedate",  // Ngày tiền về
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // minWidth: "130",
            // width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "amt",
            dataType: "string",
            allowReordering: true,
            // minWidth: "130",
            // width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "aamt",
            dataType: "string",
            allowReordering: true,
            // minWidth: "130",
            // width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "maxavlamt",
            dataType: "string",
            allowReordering: true,
            // minWidth: "130",
            // width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
    ]

const totalDefault =
    [
        {
            column: "amt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "aamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "maxavlamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]

class CashInAdvanceDetail extends Component {


    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
    }

    cashAdvanceTypes = CashAdvanceTypes;
    feeTypes = FeeTypes;


    state = {
        hide: false,
        advanceAllCheck: false
    }

    UNSAFE_componentWillMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this), false);
        this.mounted = false
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 576);
        if (currentHideNav !== this.state.hideNav) {
            this._setState({ hide: currentHideNav });
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        // this.trySetBenefitAccount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.trySetBenefitAccount();
        let { transferType, accountSend, accountReceived, maxreceiveamt, advanceAmount } = this.props
        const { maxreceiveamt: prevMaxreceiveamt, advanceAmount: prevAdvanceAmount } = prevProps
        if (prevProps.transferType !== transferType
            || prevProps.accountSend !== accountSend
            || prevProps.accountReceived !== accountReceived
        ) {
            // this.fillDescriptionSample();
        }

        if (advanceAmount !== prevAdvanceAmount) {
            if (advanceAmount !== maxreceiveamt && this.state.advanceAllCheck) {
                this._setState({ advanceAllCheck: false });
            }
        }
    }

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'txdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.txdate)
            case 'duedate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.duedate)
            case 'amt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.amt);
            case 'aamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.aamt);
            case 'maxavlamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.maxavlamt);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'txdate':
                return "cash-in-advance.table.date-of-sale";
            case 'duedate':
                return "cash-in-advance.table.duedate";
            case 'amt':
                return "cash-in-advance.table.money-waiting-to-come-back";
            case 'aamt':
                return "cash-in-advance.table.the-money-from-the-sale-has-been-paid-in-advance";
            case 'maxavlamt':
                return "cash-in-advance.table.amount-still-available";
            default:
                return null;
        }
    }


    onBlurTransferAmount = () => {
        this.props.onBlurTransferAmount();
    }

    onChangeSymbolTransfer = (event) => {
        const symbol = event.target.value;
        this.props.onChangeSymbolTransfer(symbol);
    }

    onChangeQttySymbolTransfer = (qtty, valid) => {
        this.props.onChangeQttySymbolTransfer(qtty, valid);
    }

    headerFormatter = (titleid) => {
        return (column, colIndex, { filterElement }) => {
            return (
                <div className="text-center action-column-header" style={{ display: "inline-grid" }}>
                    <FormattedMessage id={titleid} />
                    {filterElement}
                </div>
            );
        }
    };

    onCheckAdvanceAll = () => {
        const { advanceAllCheck } = this.state;
        const { onAdvanceAmountChange, maxreceiveamt, getAdvanceFee, accountSend } = this.props;
        this._setState({ advanceAllCheck: !advanceAllCheck }, () => {
            if (this.state.advanceAllCheck && maxreceiveamt !== 0) {
                onAdvanceAmountChange(maxreceiveamt);
                getAdvanceFee(maxreceiveamt, accountSend);
            } else {
                getAdvanceFee(0, accountSend);
                onAdvanceAmountChange(0);
            }
        })
    }

    onHandleKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.props;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (loadStatus === LoadStatus.LOADING) return;
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    render() {
        const {
            onCheckAgree, checkAgree, onToggleOpenModalRules, isOpenModalRules, onReloadData, apiLoadAll,
            preCheckInfoInput, advanceAmount, onAdvanceAmountChange, onAdvanceAmountBlur, advanceAmountValid,
            transferType, accountSend, setAccountSend, setAccountReceived, accountReceived, filterAccount,
            onTransferTypeChange, accounts, refresh, language, transferAmountValid,
            selectedSymbol, symbolTransferInfo, availableTranfer, quantityTransfer, currentCustodycd,
            cashAdvanceType, onTransferAmountChange, transferAmount, maxreceiveamt,
            pendingPayment, checkEmptyAccount, pdf_url, page, userInfo, feeAmount, advanceAllCheck, isBroker, curCustomer
        } = this.props;

        return (
            <div className="row gutters-5 h-100">
                <div className="col-12  col-lg-5">
                    <div className='block'>
                        <div className='headB---700-16-24 title'>
                            <FormattedMessage id="cash-in-advance.info-transaction" />
                        </div>
                        {/* Loại giao dịch */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.transaction-type" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group">
                                    <input className='custom-form-control' value={`${LanguageUtils.getMessageByKey('cash-in-advance.cash-advance-type.001', this.props.language)}`} readOnly />
                                </div>
                            </div>
                        </div>
                        {/* Tài khoản */}
                        {isBroker && (
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="stock-transfer.account" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <SelectorAccountCustomer
                                        curCustomer={curCustomer}
                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                        disabled={false}
                                        className="custom-form-control"
                                    />
                                </div>
                            </div>
                        )}

                        {!isBroker && (
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="stock-transfer.account" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input className='custom-form-control' value={userInfo.custodycd || ''} readOnly />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* Tiểu khoản  */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.sub-account" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group-select">
                                    <select value={accountSend ? accountSend.id : ''} onChange={setAccountSend} className="custom-form-select txt---400-14-20">
                                        {(!accounts || accounts.length === 0 || !accountSend) && (
                                            <FormattedMessage key="empty" id="header.choose-account">
                                                {(txt) => (
                                                    <option key="empty" value="" disabled>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        )}
                                        {accounts && accounts.map((account) => {
                                            return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tổng tiền có thể ứng */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.total-amount-available" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group text-secondary-1 txt---500-14-20">
                                    <NumberInput2New
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={maxreceiveamt}
                                        valid={true}
                                        onChange={null}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        readOnly={true}
                                        inputClassName="custom-form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-7 offset-lg-5">
                                <input type='checkbox' className='input-checkbox' checked={this.state.advanceAllCheck} onChange={this.onCheckAdvanceAll} />
                                <label className='label txt---400-14-20'>
                                    <FormattedMessage id="cash-in-advance.advance-all" />
                                </label>
                            </div>
                        </div>

                        {/* Số tiền ứng trước */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.amount-of-advance" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group-effect txt---400-14-20">
                                    <NumberInput2New
                                        name="transfer-amount"
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={advanceAmount}
                                        valid={advanceAmountValid}
                                        onChange={onAdvanceAmountChange}
                                        onBlur={onAdvanceAmountBlur}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        className="need-focus-eff"
                                        placeholder={CommonUtils.getPlaceHolderInput(language)}
                                        inputClassName="custom-form-control"
                                        onKeyDown={this.onHandleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Phí ứng */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.fee-amount" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group txt---400-14-20">
                                    <NumberInput2New
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={feeAmount}
                                        valid={true}
                                        onChange={null}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        readOnly={true}
                                        inputClassName="custom-form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Đồng ý xác nhận */}
                        {!checkEmptyAccount && <div className="row gutters-5 align-items-center h-row">
                            <div className="col-12">
                                <span>
                                    <input
                                        type="checkbox"
                                        onChange={onCheckAgree}
                                        checked={checkAgree}
                                        onKeyDown={this.onHandleKeyDown}
                                    ></input>
                                </span>
                                <span style={{ marginLeft: "5px" }} className="txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.common.001" />
                                </span>
                                <span className='txt---400-14-20' style={{ cursor: "pointer", borderBottom: "1px solid", margin: "0px 5px" }} onClick={onToggleOpenModalRules}>
                                    <FormattedMessage id="cash-in-advance.common.002" />
                                </span>
                                <span className="txt---400-14-20">
                                    <FormattedMessage id="cash-in-advance.common.003" />
                                </span>
                                {/* <span>{LanguageUtils.getMessageByKey("account-register.section-2.note", language)}</span> */}
                            </div>
                        </div>}
                        {!checkEmptyAccount &&
                            // <div className="row gutters-5 align-items-center h-row container-btn text-red txt---500-14-20">
                            //     <FormattedMessage id="cash-in-advance.common.004" />
                            // </div>
                            //:
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {!isBroker && (
                                    <Fragment>
                                        {/* Làm mới */}
                                        <div className="col-6">
                                            <button ref={this.refreshBtnRef} className="btn-refresh txt---500-16-24 btn" onClick={() => refresh()} onKeyDown={this.onHandleKeyDownRefresh}>
                                                <FormattedMessage id="common.btn-refresh" />
                                            </button>
                                        </div>
                                        {/* Tiếp tục */}
                                        <div className="col-6">
                                            <button className="btn-confirm txt---500-16-24 btn"
                                                onClick={() => preCheckInfoInput()}
                                                onKeyDown={this.onHandleKeyDown}
                                                ref={this.confirmBtnRef}
                                            >
                                                <FormattedMessage id="common.btn-continue" />
                                            </button>
                                        </div>
                                    </Fragment>
                                )}

                                {isBroker && (
                                    <Fragment>
                                        {/* Làm mới */}
                                        <div className="col-6">
                                            <button className="btn-refresh txt---500-16-24 btn" disabled >
                                                <FormattedMessage id="common.btn-refresh" />
                                            </button>
                                        </div>
                                        {/* Tiếp tục */}
                                        <div className="col-6">
                                            <button className="btn-confirm txt---500-16-24 btn" disabled >
                                                <FormattedMessage id="common.btn-continue" />
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        }
                    </div>
                </div>
                <div className="col-12  col-lg-7 ">
                    <div className="col-12 block-right">
                        <div className='headB---700-16-24 title p-title'>
                            <FormattedMessage id="cash-in-advance.receiving-sell" />
                        </div>
                        <div className='h-table p-table-10'>
                            <TableExtremeCustom2
                                keyStoreUpdate="txdate" // key của row 
                                columnsDefault={columnsDefault} // ds cột
                                totalDefault={totalDefault} // ds sum (nếu có)
                                renderSwitch={this.renderSwitch} // func render dữ liệu
                                returnCaption={this.returnCaption} // func render column name
                                isUseSelection={false} // true/false: hiện/ẩn chọn row
                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                addClass="cash-in-advance-all" // class cha
                                isPager={false} // hiển thị giao diện phân trang
                                //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                defaultPageSizes={500}  //Truyền 
                                apiLoadAll={apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                                isCustomTopAndBottomSticky={false}
                                showFilter={false}
                            />
                        </div>
                    </div>
                </div>
                {isOpenModalRules &&
                    <ModalPDFRules
                        titleId={"cash-in-advance.rules"}
                        isOpen={isOpenModalRules}
                        toggle={onToggleOpenModalRules}
                        onCheckAgree={onCheckAgree}
                        checked={checkAgree}
                        pdf_url={pdf_url}
                        page={page}
                    >
                    </ModalPDFRules>}
            </div >
        );
    }

}

export default CashInAdvanceDetail;

