import React, { Component } from 'react';
import _ from 'lodash'
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, accountService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { LanguageUtils, CommonUtils, LoadStatus, mDate, Random, Role, ToastUtil, confirmOrderExecTypes, SELECTOR_ACCOUNT_SCREEN, ModalConfirmType, KeyCodeUtils } from "../../../../utils";
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import $ from 'jquery';

import './ConfirmOrderOnline.scss';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';

const rowPerPage = 200;

const columnsDefault =
    [
        {
            dataField: "afacctno", // Tiểu khoản 
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "txdate", //Ngày đặt lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "txtime", //giờ đặt lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "symbol", /* Mã iFlex */
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "exectype",     /* Số dư gốc */
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        {
            dataField: "matchtype",     /* Số dư gốc */
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        {
            dataField: "orderqtty",  /* Lãi suất tất toán */
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        {
            dataField: "quoteprice",  /* Lãi suất */
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "orderid", /* Ngày đáo hạn */
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 8,
        },
        {
            dataField: "via", // Giá đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
        },
        {
            dataField: "username", // Giá đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 10,
        },
    ]

// {
//     dataField: "rootorderid", // shl gốc
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "120",
//     width: '150',
//     visible: true,
//     alignment: "center",
//     visibleIndex: 7,
// },
class ConfirmOrderOnline extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        currentAccount: {},
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: confirmOrderExecTypes[0],
        orderStatus: 'ALL',
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        page: 1,
        listAccounts: [],
        onReloadData: true,
        listOrderIdConfirm: [],
        listOrderConfirm: [],
        listOrderConfirmRaw: []
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = moment(currentDate).subtract(60, 'days').toDate();
        // this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.confirmAllBtnRef = React.createRef();
        this.confirmBtnRef = React.createRef();

        this.TransAuthExternalRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'afacctno':
                return "confirm-order-online.sub-accounts";
            case 'txdate':
                return "confirm-order-online.txdate";
            case 'txtime':
                return "confirm-order-online.txtime";
            case 'symbol':
                return "confirm-order-online.symbol";
            case 'exectype':
                return "confirm-order-online.type-order";
            case 'matchtype':
                return "confirm-order-online.order-type";
            case 'orderqtty':
                return "confirm-order-online.amount";
            case 'quoteprice':
                return "confirm-order-online.price";
            case 'orderid':
                return "confirm-order-online.orderid";
            case 'via':
                return "confirm-order-online.via";
            case 'username':
                return "confirm-order-online.username";
            // case 'rootorderid':
            //     return "confirm-order-online.root-order-id";
            default:
                return null;
        }
    }

    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const { lang, defaultTheme } = this.props
        const isFDS = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        switch (dataField) {
            case 'afacctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno);
            case 'txdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.txdate);
            case 'txdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txtime);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
            case 'exectype':
                return (e) => RenderGridCell.renderGridCellSideCode2(e.row.data.exectype, lang);
            case 'matchtype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.matchtype);
            case 'orderqtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.orderqtty);
            case 'quoteprice':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.quoteprice, "PRICE_FIXED");
            case 'orderid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.orderid);
            case 'via':
                return (e) => RenderGridCell.renderGridCellCommonByLanguage(lang, e.row.data.via, e.row.data.en_via);
            case 'username':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.username);
            // case 'rootorderid':
            //     return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rootorderid);
            default:
                return null;
        }
    }

    search = () => {
        this.setReloadData();
    }

    apiLoadAll = async (obj) => {
        const { fromDate, toDate, currentAccount, execType } = this.state;
        //console.log("reload---")
        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }

        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            if (currentAccount && currentAccount.accounttype === "SEC") {
                return inquiryService.getConfirmOrders(currentAccount.id, fromDate, toDate, execType).then((data) => {
                    if (data && data.d) {
                        data = data.d;
                    }
                    this._setState({ listOrderConfirmRaw: data });
                    return data
                }).catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-confirm-order-online');
                    return []
                });
            }

            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getConfirmOrdersAll(custid, fromDate, toDate, execType)
                    .then((data) => {
                        if (data && data.d) {
                            data = data.d;
                        }
                        this._setState({ listOrderConfirmRaw: data });
                        return data
                    }).catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-confirm-order-online');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }


    // run only once when component is mounted
    loadInitData = (check) => {
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;
        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found })
        // () => { this.setReloadData(); });
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }


    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        const { curCustomer } = this.props;
        let isBroker = this.isBroker();
        this.loadInitData();
        if (isBroker && ((curCustomer && !curCustomer.custid) || !curCustomer)) {
            this.setReloadData('NOT_CALL_API');
        }
        if (!isBroker || (isBroker && curCustomer && curCustomer.custid)) {
            this.setReloadData();
        }
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData();
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
            this.setReloadData();
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };


    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    defaultColumn = {
        headerClassName: "text-center",
    }

    onExecTypeChanged = (e) => {
        let value = e.target.value;
        this._setState({
            execType: value
        })
    };

    onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => { // selectedRowKeys: danh sách key, selectedRowsData: danh sách row
        //console.log("loc select---: ", selectedRowKeys, selectedRowsData)
        this._setState({
            listOrderIdConfirm: selectedRowKeys,
            listOrderConfirm: selectedRowsData
        }, () => {
            this.addBtnConfirm()
        });
    }

    convertData = (dataArr) => {
        const { accountInfo } = this.props;
    };


    handleConfirmAllInitClose = () => {
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this._setState({
            listOrderIdConfirm: [],
            listOrderConfirm: [],
        })
    }

    initConfirmOrderTransaction = (isConfirmAll) => {
        if (this.isBroker() === true) {
            ToastUtil.error('CM3', 'IS_BLOCK_BROKER');
            return;
        }
        var { currentAccount, listOrderIdConfirm, listOrderConfirm } = this.state;
        var orderIdStr = _.join(listOrderIdConfirm, ',');
        var confirmOrderBody = {
            orderId: orderIdStr,
        };
        if (currentAccount) {
            accountService.checkConfirmOrders(confirmOrderBody)
                .then((data) => {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    let _dataInfo = this.getDataInfo()
                    if (isConfirmAll) {
                        _dataInfo = {
                            ..._dataInfo,
                            useBtnClose: true,
                            handleClose: this.handleConfirmAllInitClose
                        }
                    }
                    this.props.updateConfirmCheckAuth({
                        typeInfo: ModalConfirmType.CONFIRM_ORDER_ONLINE,
                        dataInfo: _dataInfo,
                    })

                    this.props.setIsOpenModalHaveData("ScreenConfirm", {
                        isOpenScreenConfirm: true,
                        isCheckAuth2: true,
                    })
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-confirm-order');
                    return;
                })
        }
    };

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    getDataInfo = () => {
        var { listOrderConfirm } = this.state;
        const { language } = this.props

        let _info = null
        let { processAuthFunc, transferBody } = this.genInfo()
        _info = {
            language: language,
            listOrderConfirm: listOrderConfirm,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCb() },
            title: "confirm-order-online.confirm"
        }

        return _info || {}
    }

    genInfo = () => {
        var { listOrderIdConfirm } = this.state;
        var orderIdStr = _.join(listOrderIdConfirm, ',');

        let processAuthFunc = null;
        let transferBody = null;

        processAuthFunc = accountService.confirmOrderBase;
        transferBody = {
            orderId: orderIdStr,
        };

        return {
            processAuthFunc,
            transferBody,
        }
    }

    transactionCb = () => {
        ToastUtil.success('trade.order.confirm-order-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this._setState({
            listOrderIdConfirm: [],
            listOrderConfirm: [],
        }, () => {
            this.setReloadData();
        })

    };


    confirmOrderBase = () => {
        var { currentAccount, confirmOrderBody } = this.state;
        if (currentAccount) {
            accountService.confirmOrderBase(confirmOrderBody)
                .then((data) => {
                    this._setState({
                        listOrderIdConfirm: [],
                        listOrderConfirm: [],
                        isOpenModalConfirm: false,
                    }, () => {
                        this.setReloadData();
                    })

                    ToastUtil.success('trade.order.confirm-order-success');
                })
                .catch((error) => {
                    this._setState({
                        isOpenModalConfirm: false,
                    })
                    ToastUtil.errorApi(error, 'common.fail-to-confirm-order');
                    return;
                })
        }
    }

    isOpenModalConfirm = () => {
        const { isOpenModalConfirm } = this.state
        this._setState({
            isOpenModalConfirm: !isOpenModalConfirm
        })
    }

    addBtnConfirm = () => {
        const { listOrderIdConfirm } = this.state
        let func = this.initConfirmOrderTransaction
        let text = LanguageUtils.getMessageByKey("common.confirm-order", this.props.language)
        let length = (listOrderIdConfirm && listOrderIdConfirm.length > 0 && listOrderIdConfirm.length) || 0
        let btn = `<button class="txt---400-14-20 btn-common-table">${text} (${length})</button>`

        let _interval = setInterval(function () {
            let _getclassfilter = $('.confirm-order-online-table-tableEx .dx-datagrid-filter-row .dx-command-select')
            // let _getclassheader = $('.dx-header-row .dx-command-select')
            let _getclassheader = $('.confirm-order-online-table-tableEx .dx-command-select')
            if (_getclassfilter && _getclassfilter.length > 0) {
                _getclassfilter[0].innerHTML = btn
                // _getclassfilter.addClass("dx-command-select-cancel")
                _getclassheader.addClass("dx-command-select-confirm")
                _getclassfilter[0].onclick = func
                _interval && clearInterval(_interval)
                return null
            }
        }, 200)
    }

    confirmAll = () => {
        const { listOrderConfirmRaw } = this.state;
        if (listOrderConfirmRaw && listOrderConfirmRaw.length > 0) {
            let listOrderIdConfirm = listOrderConfirmRaw.map(item => {
                return item.orderid
            })
            this._setState({
                listOrderIdConfirm: listOrderIdConfirm,
                listOrderConfirm: listOrderConfirmRaw
            }, () => {
                this.initConfirmOrderTransaction(true);
            });
        }
    }

    handlerKeyDownSearch = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    handlerKeyDownconfirmAll = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmAllBtnRef.current || this.confirmAllBtnRef.current.disabled) return;
            this.confirmAllBtnRef.current.click();
        }
    };

    // handlerKeyDownconfirm = (event) => {
    //     const keyCode = event.which || event.keyCode;
    //     if (keyCode === KeyCodeUtils.ENTER) {
    //         event.preventDefault();
    //         if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
    //         this.confirmBtnRef.current.click();
    //     }
    // };

    render() {
        const { language, curCustomer } = this.props;
        const { currentAccount, listAccounts, onReloadData, listOrderConfirm, listOrderIdConfirm } = this.state;
        const isFDS = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const isBroker = this.isBroker();
        //console.log("ConfirmOrderOnline-this.state", this.state)
        let numberOfOrder = listOrderConfirm.length || 0

        return (
            <RetryableContent className="order-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="order-history-content">
                    {/* {
                        this.state.isOpenModalConfirm &&
                        <ModalConfirmOrderOnline
                            confirmOrderBody={this.state.confirmOrderBody}
                            numberOfOrder={numberOfOrder}
                            isOpen={this.state.isOpenModalConfirm}
                            toggle={this.isOpenModalConfirm}
                            confirmOrderBase={this.confirmOrderBase}
                        ></ModalConfirmOrderOnline>
                    } */}
                    <div className="action-container">

                        <div className='confirm-all-container'>
                            {isBroker ?
                                <button className="txt---400-14-20 btn-common-table"
                                    disabled
                                >
                                    <FormattedMessage id="confirm-order-online.confirm-all" />
                                </button>
                                :
                                <button className="txt---400-14-20 btn-common-table"
                                    onClick={() => { this.confirmAll() }}
                                    autoFocus={true}
                                    ref={this.confirmAllBtnRef}
                                    onKeyDown={this.handlerKeyDownconfirmAll}
                                >
                                    <FormattedMessage id="confirm-order-online.confirm-all" />
                                </button>
                            }

                        </div>

                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n txt---400-14-20 padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>


                            <div className="custom-form-group-n">
                                <select
                                    className="custom-form-control txt---400-14-20"
                                    defaultValue={this.state.execType}
                                    onChange={this.onExecTypeChanged}
                                >
                                    {confirmOrderExecTypes.map((execType) => {
                                        return (
                                            <FormattedMessage id={'common.exec-types.' + execType} key={execType}>
                                                {(txt) => (
                                                    <option
                                                        value={execType}
                                                    >{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        );
                                    })}
                                </select>
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDownSearch}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className='table-ex-container p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="orderid" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={isBroker ? false : true} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="confirm-order-online-table" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            apiLoadAll={this.apiLoadAll} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                            onSelectionChanged={this.onSelectionChanged}
                            isCustomTopAndBottomSticky={false}
                            addActionToSelection={this.addBtnConfirm}
                        />
                    </div>
                    {/* <div className={"action-container"}>
                        <div style={{ gap: "4px" }} className='text-near-active item-center'><FormattedMessage id="confirm-order-online.selected" />: <span className='text-green'>{numberOfOrder} <FormattedMessage id="confirm-order-online.order" /></span></div>
                        <div>
                            <button
                                ref={this.btnNext}
                                className="txt---400-14-20 btn-search"
                                onClick={this.initConfirmOrderTransaction}
                                disabled={numberOfOrder === 0}
                            >
                                <FormattedMessage id="common.confirm-order" />
                            </button>
                        </div>
                    </div> */}
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        lang: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        language: state.app.language,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfirmOrderOnline));

