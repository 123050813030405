import React, { Component } from 'react';
import { FormattedAmount } from 'components/Formating';
import { NumberInput2New } from 'components/Input';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ModalBody } from 'reactstrap';
import ReloadIconDark from '../../../../assets/icons/ReloadIcon_Dark.svg'
import ReloadIconLight from '../../../../assets/icons/ReloadIcon_Light.svg'
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CommonUtils, COUNT_DOWN_RELOAD, CommonWidgetUtils, LoadStatus, ModalConfirmType, Role, ToastUtil, TradingMoneyBIDVType, TradingMoneyBIDVTypes } from "../../../../utils";
import $ from 'jquery';
import './TradingMoneyBIDV.scss';
import DraggableModal from 'components/Modal/DraggableModal';

class TradingMoneyBIDV extends Component {

    tradingMoneyBIDVTypes = TradingMoneyBIDVTypes;


    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        tradingType: TradingMoneyBIDVType.HOLD,                         //Loại giao dịch
        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,

        transactionId: '',
        tokenId: '',
        refamt: '',
        authtype: '',
        feeamt: '',
        vatamt: '',
        accountSend: {},                            // Số tiểu khoản gửi

        infoBank: null,                         //Thông tin tài khoản hold/unhold bank
        bankholded: 0,                          //Số dư phong tỏa
        bankavailbal: 0,                        //Số dư khả dụng
        avlrelease: 0,                           //Số tiền có thể giải tỏa
        transferAmount: null,                       //Số tiền chuyển
        transferAmountValid: false,                 //Kiểm tra giá trị số tiền chuyển
        loadStatusReload: false,
        time: 0
    };

    state = {
        ...this.initialState
    };



    onTradingTypeChange = (event) => {
        this._setState({ tradingType: event.target.value }, () => {
            this.refresh();
        });
    };

    onTransferAmountChange = (amount, valid) => {
        this._setState({ transferAmount: amount, transferAmountValid: valid });
    };

    onResetDataInForm = () => {
        this._setState({ transferAmount: null, transferAmountValid: false });
    };


    refresh = () => {
        this._setState({
            ...this.initialState,
            tradingType: this.state.tradingType,
            accountSend: this.state.accountSend
        });
        this.loadAllData()
    };

    backStep = () => {
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this.props.setIsOpenTradeMoneyBIDV(true)
        this._setState({ transferAmount: this.state.transferAmount, transferAmountValid: this.state.transferAmountValid });
        $(".trading-money-bidv").css("display", "flex");
    };

    componentWillUnmount() {
        if (this.counter) {
            this.clearTimer();
        }
        this.mounted = false
    }

    timer = () => {
        this.counter = setInterval(() => {
            let { time } = this.state;
            time = time - 1;
            if (time === 0) {
                this.clearTimer();
            }
            this._setState({
                time: time
            })
        }, 1000);
    }

    clearTimer = () => {
        if (this.counter) {
            clearInterval(this.counter)
        }
    }

    onTransferAmountChange = (amount, valid) => {
        this._setState({ transferAmount: amount, transferAmountValid: valid });
    };


    preCheckInfoInput = () => {
        const {
            transferAmount, accountSend, tradingType } = this.state;

        //Check tồn tại tiểu khoản chuyển
        if (!accountSend) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'trade-money-bidv.error.001');
            return;
        }

        if (!transferAmount) {
            if (transferAmount === 0) {
                if (tradingType === TradingMoneyBIDVType.HOLD) {
                    ToastUtil.error('common.fail-to-can-not-make-transaction', 'trade-money-bidv.error.003');
                    return;
                }
                if (tradingType === TradingMoneyBIDVType.UNHOLD) {
                    ToastUtil.error('common.fail-to-can-not-make-transaction', 'trade-money-bidv.error.004');
                    return;
                }
            }
            if (tradingType === TradingMoneyBIDVType.HOLD) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'trade-money-bidv.error.005');
                return;
            }
            if (tradingType === TradingMoneyBIDVType.UNHOLD) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'trade-money-bidv.error.006');
                return;
            }
        }

        this.checkTransferInfo()
    }

    checkTransferInfo = () => {
        const {
            tradingType, transferAmount, accountSend
        } = this.state;

        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        let checkHoldBank = tradingType === TradingMoneyBIDVType.HOLD ? false : true
        precheckCashtransferFunct = transferService.checkHoldBank;
        checkTransferInfoBody = {
            "amount": transferAmount && Number(transferAmount),
            "isUnhold": checkHoldBank,
        };
        this._setState({ loadStatus: LoadStatus.LOADING });
        precheckCashtransferFunct(accountSend.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, fee, vat, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    if (tradingType === TradingMoneyBIDVType.HOLD) {
                        this._setState({ totalFee: fee + vat, loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, transactionId: transactionId }, () => {
                            let _dataInfo = this.getDataInfoCashTransfer()
                            this.props.updateConfirmCheckAuth({
                                typeInfo: ModalConfirmType.HOLD_MONEY_BIDV,
                                dataInfo: _dataInfo,
                            })
                            // this.props.setIsOpenTradeMoneyBIDV(false)
                            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                isOpenScreenConfirm: true,
                                isCheckAuth2: true,
                            })
                            $(".trading-money-bidv").css("display", "none");
                        });
                    } else {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ vat: vat, totalFee: fee + vat, loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                            let _dataInfo = this.getDataInfoCashTransfer()
                            this.props.updateConfirmCheckAuth({
                                typeInfo: ModalConfirmType.UNHOLD_MONEY_BIDV,
                                dataInfo: _dataInfo,
                            })
                            // this.props.setIsOpenTradeMoneyBIDV(false)
                            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                isOpenScreenConfirm: true,
                                isCheckAuth2: true,
                            })
                            $(".trading-money-bidv").css("display", "none");
                        });
                    }

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    loadHoldBankInfo(isReload = false) {
        const { accountSend } = this.state;

        if (accountSend) {
            this._setState({ loadStatusReload: LoadStatus.LOADING });
            inquiryService.getHoldBankInfo(accountSend && accountSend.id)
                .then((data) => {
                    this._setState({
                        infoBank: data,
                        loadStatusReload: LoadStatus.LOADED,
                        bankholded: data.bankholded,
                        bankavailbal: data.bankavailbal,
                        avlrelease: data.avlrelease,
                    });
                    if (isReload) {
                        this._setState({ loadStatusReload: LoadStatus.LOADED, time: COUNT_DOWN_RELOAD },
                            () => this.timer())
                    }
                })
                .catch((error) => {
                    this._setState({ loadStatusReload: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'trade-money-bidv.error.003');
                });
        }
    }


    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    loadAllData = () => {
        this.loadHoldBankInfo()
    }

    loadInitData = () => {
        const { accounts, currentAccountId } = this.props;
        let account = accounts.find(item => item.id === currentAccountId)
        this._setState({ accountSend: account },
            () => {
                this.loadAllData();
            }
        );
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.loadInitData()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.tradingType !== nextProps.tradingType) {
            this._setState({ tradingType: nextProps.transType }, () => {
                this.onResetDataInForm();
                this.loadAllData();
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIActionCounter, socketSEActionCounter, socketODActionCounter, currentAccountId } = this.props;
        const { accountSend } = this.state;
        const { accountSend: prevAccountSend } = prevState;
        const { socketCIActionCounter: prevSocketCIActionCounter, socketSEActionCounter: prevSocketSEActionCounter,
            socketODActionCounter: prevSocketODActionCounter, currentAccountId: prevCurrentAccountId } = prevProps;


        if (socketCIActionCounter !== prevSocketCIActionCounter || socketSEActionCounter !== prevSocketSEActionCounter || socketODActionCounter !== prevSocketODActionCounter) {
            // this.loadTransferParam();
            this.refresh()
        }
        if (accountSend !== prevAccountSend) {
            this.loadAllData()
        }

        if (currentAccountId !== prevCurrentAccountId) {
            this.loadInitData()
        }
    }

    //Set tiểu khoản chuyển
    setAccountSend = (event) => {
        const { accounts } = this.props;
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountSend: foundAccount })
        this.onResetDataInForm();
    };


    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    //Đóng mở Popup xác nhận
    toggleConfirm = () => {
        this._setState({
            isOpen: !this.state.isOpen
        });
    }

    transactionCb = () => {
        const { tradingType } = this.state
        if (tradingType === TradingMoneyBIDVType.HOLD) {
            ToastUtil.success('account.utilities.cash-transfer.success', 'trade-money-bidv.success.001');
        } else {
            ToastUtil.success('account.utilities.cash-transfer.success', 'trade-money-bidv.success.002');
        }
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this.props.setIsOpenTradeMoneyBIDV(false)
    };

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    genInfoWithTypeCash = () => {
        const { tradingType, transferAmount, accountSend } = this.state;

        let processAuthFunc = null;
        let transferBody = null;
        let checkHoldBank = tradingType === TradingMoneyBIDVType.HOLD ? false : true

        processAuthFunc = transferService.processHoldBank;
        transferBody = {
            "amount": transferAmount && Number(transferAmount),
            "isUnhold": checkHoldBank,
            "accountId": accountSend && accountSend.id,
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody
        }
    }


    getDataInfoCashTransfer = () => {
        const {
            loadStatus, accountSend, tradingType, transferAmount } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoWithTypeCash()
        //Nộp CCP
        _info = {
            accountSend: accountSend,                               //Tiểu khoản chuyển
            tradingType: tradingType,                               //Loại giao dịch
            transferAmount: transferAmount,                         //Số tiền phong toả/giải toả
            loadStatus: loadStatus,
            useBtnClose: true,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            backStep: () => { this.backStep() },
            refresh: () => { this.refresh() },
            handleClose: () => { this.close(true) },
            transactionCb: () => { this.transactionCb() },
            setLoadStatus: (status) => { this.setLoadStatus(status) },
            title: "common.confirm-trade",
        }

        return _info || {}
    }


    reloadSummaryAccount = (e) => {
        let { accountSend } = this.state;
        e.stopPropagation();
        let { time } = this.state;
        if (time === 0) {
            inquiryService.getCorebankBalance(accountSend.id)
                .then((data) => {
                    this.loadHoldBankInfo(true)
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
                });

        }
    }

    close = (checkAuth) => {
        if (checkAuth) {
            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                isOpenScreenConfirm: false,
            })
            this.props.setIsOpenTradeMoneyBIDV(false)
        } else {
            this.props.setIsOpenTradeMoneyBIDV(false)
        }
        this.refresh()
    }

    render() {
        const {
            loadStatus, accountSend, tradingType, transferAmount, transferAmountValid,
            authStatus, bankholded, bankavailbal, avlrelease
        } = this.state;
        const { language, accounts, isOpen, defaultTheme } = this.props;
        const combinedStatus = authStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus;
        const isBroker = this.isBroker();
        //console.log("loccoc 1: ", transferAmount)
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={() => this.close(false)}
                onClose={() => this.close(false)}
                className={"trading-money-bidv"}
                titleId={"trade-money-bidv.trade-money-bidv"}
            >
                <div className="body">
                    <RetryableContent status={combinedStatus} disableRetry={true}>
                        <ModalBody className="custom-body">
                            {/* Loại giao dịch */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="trade-money-bidv.transaction-type" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <select value={tradingType} onChange={this.onTradingTypeChange} className="custom-form-control txt---400-14-20">
                                            {this.tradingMoneyBIDVTypes && this.tradingMoneyBIDVTypes.map((tradingType) => {
                                                return (
                                                    <FormattedMessage key={tradingType} id={'trade-money-bidv.trade-bidv-type.' + tradingType}>
                                                        {(txt) => (
                                                            <option value={tradingType}>{txt}</option>
                                                        )}
                                                    </FormattedMessage>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* Tiểu khoản*/}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="trade-money-bidv.sub-account" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <select value={accountSend ? accountSend.id : ''} onChange={this.setAccountSend} className="custom-form-control txt---400-14-20">
                                            {(!accounts || accounts.length === 0 || !accountSend) && (
                                                <FormattedMessage key="empty" id="header.choose-account">
                                                    {(txt) => (
                                                        <option key="empty" value="" disabled>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                            {accounts && accounts.map((account) => {
                                                return (<option key={account.id} value={account.id}>{account.entypename} - {account.id}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* Số dư phong tỏa */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="trade-money-bidv.blocked-balance" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group text-red txt---500-14-20">
                                        <FormattedAmount value={bankholded} />
                                    </div>
                                </div>
                            </div>

                            {/* Số dư khả dụng */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="trade-money-bidv.available-balances" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---500-14-20">
                                        <FormattedAmount value={bankavailbal} />
                                        <div onClick={(e) => this.reloadSummaryAccount(e)} className="custom-icon-reload">
                                            <img className={"effect-rotate-360" + (this.state.loadStatusReload === LoadStatus.LOADING ? " effect-loading " : "") + (this.state.time !== 0 ? " effect-loaded" : "")} src={defaultTheme === "dark" ? ReloadIconDark : ReloadIconLight} alt="icon-reload" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Số dư có thể giải tỏa */}
                            {tradingType === TradingMoneyBIDVType.UNHOLD &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="trade-money-bidv.balance-can-be-released" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group  txt---400-14-20">
                                            <FormattedAmount value={avlrelease} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Số tiền phong tỏa */}
                            {tradingType === TradingMoneyBIDVType.HOLD &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="trade-money-bidv.blocked-amount" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group-effect txt---400-14-20">
                                            <NumberInput2New
                                                name="transfer-amount"
                                                min={null}
                                                max={999999999999}
                                                value={transferAmount}
                                                valid={transferAmountValid}
                                                onChange={this.onTransferAmountChange}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                className="need-focus-eff"
                                                placeholder={CommonUtils.getPlaceHolderInput(language)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Số tiền giải tỏa */}
                            {tradingType === TradingMoneyBIDVType.UNHOLD &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="trade-money-bidv.amount-of-relief" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group-effect txt---400-14-20">
                                            <NumberInput2New
                                                name="transfer-amount"
                                                min={null}
                                                max={999999999999}
                                                value={transferAmount}
                                                valid={transferAmountValid}
                                                onChange={this.onTransferAmountChange}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                className="need-focus-eff"
                                                placeholder={CommonUtils.getPlaceHolderInput(language)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {/* Làm mới */}
                                <div className="col-5">
                                    <button className="btn-refresh txt---500-16-24 btn"
                                        onClick={() => this.refresh()}
                                    >
                                        <FormattedMessage id="common.btn-refresh" />
                                    </button>
                                </div>
                                {/* Tiếp tục */}
                                <div className="col-7">
                                    <button className="btn-confirm txt---500-16-24 btn"
                                        onClick={() => this.preCheckInfoInput()}
                                    >
                                        <FormattedMessage id="common.btn-continue"
                                        />
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </RetryableContent >
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage
    let _accountInfo = _layoutPage && _layoutPage[_currentLayoutPageActive]["accountInfo"]
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        isOpenTradeMoneyBIDV: state.layout.isOpenTradeMoneyBIDV,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setIsOpenTradeMoneyBIDV: (isOpen) => dispatch(actions.setIsOpenTradeMoneyBIDV(isOpen))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingMoneyBIDV);