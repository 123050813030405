import React, { Component, Fragment } from 'react';
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

// import { ReactComponent as DownloadIcon } from '../../../../assets/icons/new/DownloadIcon.svg'
import { DatePicker } from "../../components/Input";
import RetryableContent from "../../components/RetryableContent";
import { inquiryService } from "../../services";
import * as actions from '../../store/actions'
import { CommonUtils, KeyCodeUtils, LanguageUtils, LoadStatus, mDate, Random, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../utils";
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './SummaryOrderMatching.scss';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';
import { FormattedPrice } from 'components/Formating';

const exChangesMatching = [
    "ALL",
    "001", //Sàn HOSE
    "002", //Sàn HNX
    "005" //Sàn UPCOM
];

const execTypes = [
    'ALL',  //Tất cả
    'NB',   //Mua
    'NS',   //Bán
]
const totalDefaultAll =
    [
        {
            column: "exectype_name",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
            alignment: "center"
        },
        {
            column: "matchqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "val_io",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "feeamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        // {
        //     column: "trfsellamt",
        //     summaryType: "sum",
        //     displayFormat: "{0}",
        //     valueFormat: ",##0",
        // },
        // {
        //     column: "taxamt",
        //     summaryType: "sum",
        //     displayFormat: "{0}",
        //     valueFormat: ",##0",
        // },
        {
            column: "taxsellamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "aright",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "ret",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]

let columnsDefaultAll =
    [
        /* Phân loại */
        {
            dataField: "exectype_name",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Khối lượng khớp */
        {
            dataField: "matchqtty",
            dataType: "string",
            allowReordering: true,
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* giá trị khớp */
        {
            dataField: "val_io",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 2,
        },
        /* Phí giao dịch */
        {
            dataField: "feeamt",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 3,
        },
        /* Phí chuyển khoản chứng khoán */
        // {
        //     dataField: "trfsellamt",
        //     dataType: "string",
        //     allowReordering: true,  // không cho di chuyển
        //     alignment: "right",
        //     visible: true,
        //     //minWidth: "100",
        //     //width: '120',
        //     visibleIndex: 4,
        // },
        // /* Thuế */
        // {
        //     dataField: "taxamt",
        //     dataType: "string",
        //     allowReordering: true,  // không cho di chuyển
        //     alignment: "right",
        //     visible: true,
        //     //minWidth: "100",
        //     //width: '120',
        //     visibleIndex: 5,
        // },
        /* Thuế TNCN */
        {
            dataField: "taxsellamt",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 5,
        },
        /* Thuế quyền */
        {
            dataField: "aright",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 6,
        },
        /* Được nhận phải trả */
        {
            dataField: "ret",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 7,
        },
    ]

const totalDefaultDetail =
    [
        {
            column: "symbol",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
            alignment: "center"
        },
        {
            column: "matchqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        // {
        //     column: "matchprice",
        //     summaryType: "sum",
        //     displayFormat: "{0}",
        //     valueFormat: ",##0",
        // }, 
        {
            column: "val_io",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "feeamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        // {
        //     column: "trfsellamt",
        //     summaryType: "sum",
        //     displayFormat: "{0}",
        //     valueFormat: ",##0",
        // },
        // {
        //     column: "taxamt",
        //     summaryType: "sum",
        //     displayFormat: "{0}",
        //     valueFormat: ",##0",
        // },
        {
            column: "taxsellamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "aright",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "ret",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]

let columnsDefaultDetail =
    [
        /* Ngày giao dịch */
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Tiểu khoản */
        {
            dataField: "cd_acctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Mua/bán */
        {
            dataField: "exectype_name",
            dataType: "string",
            allowReordering: true,
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            //minWidth: "100",
            //width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Kl khớp */
        {
            dataField: "matchqtty",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 4,
        },
        /* giá khớp */
        {
            dataField: "matchprice",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 5,
        },
        /* Giá trị khớp */
        {
            dataField: "val_io",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 6,
        },
        /* Phí giao dịch */
        {
            dataField: "feeamt",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 7,
        },
        /* Phí chuyển khoản chứng khoán */
        // {
        //     dataField: "trfsellamt",
        //     dataType: "string",
        //     allowReordering: true,  // không cho di chuyển
        //     alignment: "right",
        //     visible: true,
        //     //minWidth: "100",
        //     //width: '120',
        //     visibleIndex: 8,
        // },
        // /* Thuê */
        // {
        //     dataField: "taxamt",
        //     dataType: "string",
        //     allowReordering: true,  // không cho di chuyển
        //     alignment: "right",
        //     visible: true,
        //     //minWidth: "100",
        //     //width: '120',
        //     visibleIndex: 8,
        // },
        /* Thuế TNCN */
        {
            dataField: "taxsellamt",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 9,
        },
        /* Thuế quyền */
        {
            dataField: "aright",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 10,
        },
        /* Được nhận phải trả */
        {
            dataField: "ret",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            //minWidth: "100",
            //width: '120',
            visibleIndex: 11,
        },
    ]


class SummaryOrderMatching extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: 'ALL',
        exchange: exChangesMatching[0],
        execType: execTypes[0],
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        listAccounts: []
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        //const { accounts, currentAccountId } = this.props;
        this.loadInitData();
        let isBroker = this.isBroker();
        if (isBroker) {
            this.setReloadData("NOT_CALL_API")
        } else {
            this.setReloadData();
        }
        // let found = accounts.find(item => item.id === currentAccountId)
        // this._setState({ currentAccount: found },
        //     () => {
        //         if (isBroker) {
        //             this.setReloadData("NOT_CALL_API")
        //         } else {
        //             this.setReloadData()
        //         }
        //     }
        // );
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    renderSwitchAll = (dataField) => {
        switch (dataField) {
            case 'exectype_name':
                return (e) => RenderGridCell.renderGridCellSideCode2(e.row.data.exectype_name);
            case 'matchqtty':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchqtty, "PRICE");
            case 'val_io':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.val_io, "PRICE");
            case 'feeamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeamt, "PRICE");
            // case 'trfsellamt':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.trfsellamt, "PRICE");
            // case 'taxamt':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.taxamt, "PRICE");
            case 'taxsellamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.taxsellamt, "PRICE");
            case 'aright':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.aright, "PRICE");
            case 'ret':
                return (e) => this.renderGridCellPriceRet(e.row.data);
            default:
                return null;
        }
    }

    returnCaptionAll = (dataField) => {
        switch (dataField) {
            case 'exectype_name':
                return "summary-order-matching.table-all.exectype";
            case 'matchqtty':
                return "summary-order-matching.table-all.match-qtty";
            case 'val_io':
                return "summary-order-matching.table-all.match-value";
            case 'feeamt':
                return "summary-order-matching.table-all.fee";
            // case 'trfsellamt':
            //     return "summary-order-matching.table-all.trfsellamt";
            // case 'taxamt':
            //     return "summary-order-matching.table-all.tax";
            case 'taxsellamt':
                return "summary-order-matching.table-all.taxsellamt";
            case 'aright':
                return "summary-order-matching.table-all.aright";
            case 'ret':
                return "summary-order-matching.table-all.in-out";
            default:
                return null;
        }
    }

    renderGridCellPriceRet = (dataObj) => {
        let className = (dataObj.exectype === "NB") ? 'text-green style-text-ellipsis' : 'text-red style-text-ellipsis';
        let _data = dataObj.ret;
        return (
            <Fragment>
                <span className={className}>
                    <FormattedPrice value={_data} />
                </span>
            </Fragment>
        )
    }

    renderSwitchDetail = (dataField) => {
        switch (dataField) {
            case 'txdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txdate);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
            case 'cd_acctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.cd_acctno);
            case 'exectype_name':
                return (e) => RenderGridCell.renderGridCellSideCode2(e.row.data.exectype_name);
            case 'matchprice':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchprice, "PRICE");
            case 'matchqtty':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchqtty, "PRICE");
            case 'val_io':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.val_io, "PRICE");
            case 'feeamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeamt, "PRICE");
            // case 'trfsellamt':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.trfsellamt, "PRICE");
            // case 'taxamt':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.taxamt, "PRICE");
            case 'taxsellamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.taxsellamt, "PRICE");
            case 'aright':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.aright, "PRICE");
            case 'ret':
                return (e) => this.renderGridCellPriceRet(e.row.data);
            default:
                return null;
        }
    }

    returnCaptionDetail = (dataField) => {
        switch (dataField) {
            case 'txdate':
                return "summary-order-matching.table-detail.txdate";
            case 'symbol':
                return "summary-order-matching.table-detail.symbol";
            case 'cd_acctno':
                return "summary-order-matching.table-detail.cd_acctno";
            case 'exectype_name':
                return "summary-order-matching.table-detail.exectype";
            case 'matchqtty':
                return "summary-order-matching.table-detail.match-qtty";
            case 'matchprice':
                return "summary-order-matching.table-detail.match-price";
            case 'val_io':
                return "summary-order-matching.table-detail.match-value";
            case 'feeamt':
                return "summary-order-matching.table-detail.fee";
            // case 'trfsellamt':
            //     return "summary-order-matching.table-all.trfsellamt";
            // case 'taxamt':
            //     return "summary-order-matching.table-detail.tax";
            case 'taxsellamt':
                return "summary-order-matching.table-all.taxsellamt";
            case 'aright':
                return "summary-order-matching.table-all.aright";
            case 'ret':
                return "summary-order-matching.table-detail.in-out";
            default:
                return null;
        }
    }

    // run only once when component is mounted
    loadInitData = (check) => {
        // Lấy danh sách trạng thái để tìm kiếm
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;

        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found });
    };

    apiPagingAll = async (obj) => {
        const { fromDate, toDate, currentAccount, symbol, exchange, execType } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);

        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }
        try {
            if (currentAccount && currentAccount.accounttype === "SEC") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getMatchedOrderByTime(custid, currentAccount.id, fromDate, toDate, symbol ? symbol : "ALL", execType, exchange)
                    .then((data) => {
                        if (data && data.d) {
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-summary-matched-order-all');
                        return []
                    });
            }
            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getMatchedOrderByTimeAll(custid, fromDate, toDate, symbol ? symbol : "ALL", execType, exchange)
                    .then((data) => {
                        if (data && data.d) {
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-summary-matched-order-all');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    apiPagingDetail = async (obj) => {
        const { fromDate, toDate, currentAccount, symbol, exchange, execType } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }

        try {
            if (currentAccount && currentAccount.accounttype === "SEC") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getMatchOrderDetailbyTime(custid, currentAccount.id, fromDate, toDate, symbol ? symbol : "ALL", execType, exchange)
                    .then((data) => {
                        if (data && data.d) {
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-summary-matched-order-detail');
                        return []
                    });
            }
            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getMatchOrderDetailbyTimeAll(custid, fromDate, toDate, symbol ? symbol : "ALL", execType, exchange)
                    .then((data) => {
                        if (data && data.d) {
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-summary-matched-order-detail');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }


    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    search = () => {
        this.setReloadData();
    }

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onExchangeChanged = (event) => {
        this._setState({ exchange: event.target.value });
    };

    onExecTypeChanged = (event) => {
        this._setState({ execType: event.target.value });
    };

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    render() {
        const { accountInfo, accounts } = this.props;
        const { currentAccount, onReloadData, listAccounts } = this.state;
        const { curCustomer } = { ...accountInfo }
        const isBroker = this.isBroker();
        let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        fullName = CommonUtils.getDisplayName(fullName);
        return (
            <>
                <RetryableContent className="summary-order-matching-action report-content" status={this.state.loadStatus} disableRetry={true}>
                    <div className="headB---700-16-24 title">Tìm kiếm</div>
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                {/* <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-sub-account" /></div> */}
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            {isBroker && <div className="custom-form-group-n fullname">
                                <div className='align-self-center txt---400-14-20'>
                                    {fullName}
                                </div>
                            </div>}
                            <SymbolSuggestTrans
                                handleInputChange={this.onSymbolChanged}
                                currentSymbol={this.state.symbol}
                                symbolToDisplay={this.state.symbol}
                                suggestId="summary-order-matching-symbol "
                                isRedirect={false}
                                isDisplayError={false}
                                placeholder={LanguageUtils.getMessageByKey("summary-order-matching.search.all-symbol", this.props.language)}
                            />
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="summary-order-matching.search.exchange" /></div>
                                <select
                                    value={this.state.exchange}
                                    onChange={this.onExchangeChanged}
                                    className="custom-form-control txt---400-14-20">
                                    {exChangesMatching.map((exchange) => {
                                        return (
                                            <FormattedMessage id={'summary-order-matching.search.exchange-values.' + exchange} key={exchange}>
                                                {(txt) => (
                                                    <option
                                                        value={exchange}
                                                    >{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        );
                                    })}

                                </select>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="summary-order-matching.search.order" /></div>
                                <select
                                    value={this.state.execType}
                                    onChange={this.onExecTypeChanged}
                                    className="custom-form-control txt---400-14-20">
                                    {execTypes.map((execType) => {
                                        return (
                                            <FormattedMessage id={'summary-order-matching.search.order-values.' + execType} key={execType}>
                                                {(txt) => (
                                                    <option
                                                        value={execType}
                                                    >{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        );
                                    })}

                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>
                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                    </div>
                </RetryableContent>

                <RetryableContent className="summary-order-matching-all report-content" status={this.state.loadStatus} disableRetry={true}>
                    <div className="summary-order-matching-all-content">
                        <div className="headB---700-16-24 title">Tổng hợp</div>
                        <div className="h-block-table p-table-10">
                            <TableExtremeCustom2
                                keyStoreUpdate="acctno" // key của row 
                                columnsDefault={columnsDefaultAll} // ds cột
                                totalDefault={totalDefaultAll} // ds sum (nếu có)
                                renderSwitch={this.renderSwitchAll} // func render dữ liệu
                                returnCaption={this.returnCaptionAll} // func render column name
                                isUseSelection={false} // true/false: hiện/ẩn chọn row
                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                addClass="summary-order-matching-all-page" // class cha
                                isPager={false} // hiển thị giao diện phân trang
                                apiLoadAll={this.apiPagingAll} // sử dụng Api phân trang: không trả ra totalCount
                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                defaultPageSizes={100}  //Truyền 
                                showFilter={false}
                            />
                        </div>
                    </div>
                </RetryableContent >

                <RetryableContent className="summary-order-matching-detail report-content" status={this.state.loadStatus} disableRetry={true}>
                    <div className="summary-order-matching-detail-content">
                        <div className="headB---700-16-24 title">Chi tiết</div>
                        <div className="h-block-table p-table-10">
                            <TableExtremeCustom2
                                keyStoreUpdate="acctno" // key của row 
                                columnsDefault={columnsDefaultDetail} // ds cột
                                totalDefault={totalDefaultDetail} // ds sum (nếu có)
                                renderSwitch={this.renderSwitchDetail} // func render dữ liệu
                                returnCaption={this.returnCaptionDetail} // func render column name
                                isUseSelection={false} // true/false: hiện/ẩn chọn row
                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                addClass="summary-order-matching-detail-page" // class cha
                                isPager={false} // hiển thị giao diện phân trang
                                apiLoadAll={this.apiPagingDetail} // sử dụng Api phân trang: không trả ra totalCount
                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                defaultPageSizes={100}  //Truyền 
                                showFilter={true}
                                legacyMode={true}
                            />
                        </div>
                    </div>
                </RetryableContent >
            </>
        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        customerFullName: state.user.customerFullName,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SummaryOrderMatching));
