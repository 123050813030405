import React, { Component } from 'react';
import { push } from "connected-react-router";
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CommonUtils, CommonWidgetUtils, FeeType, LanguageUtils, LoadStatus, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil, TransferType, KeyCodeUtils, stepValues, ModalConfirmType } from "../../../../utils";
import { ReactComponent as ReloadIconDark } from '../../../../assets/icons/ReloadIcon_Dark.svg'
import { ReactComponent as ReloadIconLight } from '../../../../assets/icons/ReloadIcon_Light.svg'
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import { NumberInput2New } from "../../../../components/Input";

import WizardComponent from '../WizardComponent';
import TranAuthScreenConfirm from 'containers/Header/TranAuthScreenConfirm/TranAuthScreenConfirm';

import './Unhold.scss';
import _ from 'lodash';
class Unhold extends Component {

    constructor(props) {
        super(props);
        this.UnholdNearestControl = React.createRef();
        this.TransAuthUnholdRef = React.createRef();
        this.confirmBtnRef = React.createRef();
        this.successBtnRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện

        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,
        authCode: '',
        authCodeValid: false,
        transactDate: null,
        initFuncData: null,                         //Data truyển lên API
        listAuthType: [],                           //Các phương thức xác thực

        transactionId: '',
        tokenId: '',
        refamt: '',
        authtype: '',

        authData: {},
        minwithdrawamt: '', // Số tiền chuyển tối thiểu

        accountCorebankList: [], // Danh sách tiểu khoản corebank
        currentCorebankAccount: null, // Tiểu khoản coreBank hiện tại
        corebankAccountInfo: {}, // Thông tin tiểu khoản corebank

        unholdAmount: 0,
        unholdAmountValid: false
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }


    onAuthCodeChange = (code, valid) => {
        this._setState({
            authCode: code,
            authCodeValid: valid
        });
    };

    refresh = () => {
        this._setState({
            ...this.initialState,
            accountCorebankList: this.state.accountCorebankList,
            corebankAccountInfo: this.state.corebankAccountInfo
        }, () => {
            this.loadData();
        });
    };


    preCheckInfoInput = () => {
        const {
            currentCorebankAccount, corebankAccountInfo, unholdAmount
        } = this.state;

        //Check tồn tại tiểu khoản giải tỏa
        if (!currentCorebankAccount) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.unhold-corebank.error.001');
            return;
        }

        // Check số tiền có thể giải tỏa tối đa
        if (!unholdAmount) {
            if (unholdAmount === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.unhold-corebank.error.003');
                return;
            }
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.unhold-corebank.error.003');
            return;
        }

        if (_.isEmpty(corebankAccountInfo)) {
            ToastUtil.error("common.fail-to-can-not-make-transaction", 'account.utilities.unhold-corebank.error.fail-to-load-corebank-info');
            return;
        }

        if (unholdAmount > corebankAccountInfo.holdbalance) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'account.utilities.unhold-corebank.error.002');
            return;
        }



        this.checkTransferInfo()
    }

    checkTransferInfo = () => {
        const {
            currentCorebankAccount, unholdAmount
        } = this.state;

        let checkUnholdBody = {
            accountId: currentCorebankAccount.id,
            amt: Number(unholdAmount)
        };


        this._setState({ loadStatus: LoadStatus.LOADING });
        transferService.checkUnholdCorebank(checkUnholdBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, authtype, challengeQuestion, authtypes, verified } = result;

                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    let _dataInfo = this.getDataInfoUnhold()
                    this._setState({
                        authData: _dataInfo
                    }, () => {
                        this._setState({ loadStatus: LoadStatus.LOADED, step: 2, transactionId: transactionId })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };


    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    setStep = (step, isReloadData = false) => {
        this._setState({
            step: step
        });

        if (isReloadData) {
            this.setState({
                ...this.initialState
            }, () => {
                this.loadData()
            })
        }
    };


    // Load danh sách tiểu khoản corebank và thông tin số tiền giải tỏa
    loadData = () => {
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid;
        if (custid) {
            inquiryService.getCoreBankAccountList(custid)
                .then(data => {
                    if (data && data.length > 0) {
                        this.setState({ accountCorebankList: data, currentCorebankAccount: data[0] })
                    } else {
                        this.setState({ accountCorebankList: [], currentCorebankAccount: null })
                    }
                }).catch(err => {
                    ToastUtil.errorApi(err, 'account.utilities.unhold-corebank.error.fail-to-load-corebank-account-list');
                    this.setState({ accountCorebankList: [], currentCorebankAccount: null })
                })
        }
    }

    loadCoreBankAccountInfo = () => {
        const { currentCorebankAccount } = this.state;
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid;
        if (currentCorebankAccount) {
            this.setState({ corebankAccountInfo: {} });
            inquiryService.getCoreBankAccountInfo(currentCorebankAccount.id, custid)
                .then(data => {
                    if (data && data.length > 0) {
                        this.setState({ corebankAccountInfo: data[0] });
                    } else {
                        ToastUtil.error("common.fail-to-can-not-make-transaction", 'account.utilities.unhold-corebank.error.fail-to-load-corebank-info');
                        this.setState({ corebankAccountInfo: {} });
                    }
                }).catch(err => {
                    ToastUtil.errorApi(err, 'account.utilities.unhold-corebank.error.fail-to-load-corebank-info');
                    this.setState({ corebankAccountInfo: {} });
                })
        }
    }


    componentDidMount() {
        this.mounted = true
        this.loadData()
    }

    componentWillUnmount() {
        this.mounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        const { currentCorebankAccount } = this.state;
        const { currentCorebankAccount: prevCurrentCorebankAccount } = prevState;
        const { curCustomer } = this.props;
        const { curCustomer: prevCurCustomer } = prevProps;
        if (!_.isEqual(currentCorebankAccount, prevCurrentCorebankAccount) && currentCorebankAccount) {
            this.loadCoreBankAccountInfo()// Load thông tin corebank
        }

        if (this.isBroker() && !_.isEqual(curCustomer, prevCurCustomer)) {
            this.loadData();
        }
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    transactionCb = () => {
        this._setState({ step: 3, loadStatus: LoadStatus.LOADED });
    };


    processTransfer = () => {
        this._setState({ loadStatus: LoadStatus.LOADING });
        this.TransAuthUnholdRef.current.processAuth();

    };

    getDataInfoUnhold = () => {
        const {
            unholdAmount, currentCorebankAccount, corebankAccountInfo
        } = this.state;
        const { curCustomer, userInfo } = this.props;
        const isBroker = this.isBroker();
        let _info = null;
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid;
        _info = {
            custodycd: isBroker && curCustomer ? curCustomer.custodycd : userInfo.custodycd,
            unholdAmount,
            amt: Number(unholdAmount),
            accountId: currentCorebankAccount.id,
            custid: custid,
            currentCorebankAccount,
            corebankAccountInfo,
            initFuncData: {
                amt: Number(unholdAmount),
                accountId: currentCorebankAccount.id,
                custid: custid,
            },
            authRef: this.TransAuthUnholdRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: transferService.processsUnholdCorebank,
            refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCb() },
            transactionFailCb: (error) => {
                ToastUtil.errorApi(error, 'trade.auth.auth-fail');
                this._setState({
                    loadStatus: LoadStatus.FAIL
                });
            },
            title: "common.confirm-trade",
        }

        return _info || {}
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    onHandleKeyDownConfirm = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.props;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (loadStatus === LoadStatus.LOADING) return;
            this.confirmBtnRef && this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownSuccess = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.successBtnRef.current && this.successBtnRef.current.click();
        }
    }

    genIcon = (keyIcon) => {
        const { defaultTheme } = this.props;
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? <ReloadIconDark onClick={() => { this.loadCoreBankAccountInfo() }} /> : <ReloadIconLight onClick={() => { this.loadCoreBankAccountInfo() }} />
                break;
            default:
                break;
        }
    }

    onUnholdAmountChange = (amount, valid) => {
        this._setState({ unholdAmount: amount, unholdAmountValid: valid });
    };

    render() {
        const {
            isOpen, feeAmount,
            loadStatus, accountSend, accountReceived, filterAccount,
            transferParam, transferType, selectedBenefitAccount, benefitAccounts, feeType,
            description, step, authData, accountCorebankList, currentCorebankAccount, corebankAccountInfo, unholdAmount, unholdAmountValid
        } = this.state;
        const { accounts, userInfo, currentAccount, language, currentCustomer, role, defaultTheme, curCustomer } = this.props;

        const isBroker = this.isBroker();
        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="h-100">
                <div className="cash-transfer h-100">
                    <WizardComponent step={step}></WizardComponent>
                    <div className="row gutters-5">
                        <div className={"cashtran-container " + (transferType === TransferType.EXTERNAL && step === 1 ? 'external' : '')}>
                            {step === 1 && (
                                <div className='block'>
                                    <div className='headB---700-16-24 title'>
                                        <FormattedMessage id="account.utilities.cash-transfer.info-transaction" />
                                    </div>


                                    {/* Tài khoản - môi giới*/}
                                    {isBroker && (
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="stock-transfer.account" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <SelectorAccountCustomer
                                                    curCustomer={curCustomer}
                                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                                    disabled={false}
                                                    className="custom-form-control"
                                                />
                                            </div>
                                        </div>)}

                                    {/* Tài khoản */}
                                    {!isBroker && (
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="stock-transfer.account" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group txt---400-14-20">
                                                    <input className='custom-form-control' value={userInfo.custodycd || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>)}



                                    {/* Tiểu khoản */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.unhold-corebank.sub-account" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group-select">
                                                <select value={currentCorebankAccount ? currentCorebankAccount.id : ''}
                                                    // onChange={setAccountSend} 
                                                    className="custom-form-select txt---400-14-20">
                                                    {(!accountCorebankList || accountCorebankList.length === 0 || !accountCorebankList) && (
                                                        <FormattedMessage key="empty" id="header.choose-account">
                                                            {(txt) => (
                                                                <option key="empty" value="" disabled>{txt}</option>
                                                            )}
                                                        </FormattedMessage>
                                                    )}
                                                    {accountCorebankList && accountCorebankList.map((account) => {
                                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Số tiền tối đa có thể giải tỏa */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.unhold-corebank.max-unhold" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                                <NumberInput2New
                                                    step={stepValues.PRICE}
                                                    min={null}
                                                    max={null}
                                                    value={corebankAccountInfo && corebankAccountInfo.holdbalance ? corebankAccountInfo.holdbalance : 0}
                                                    valid={true}
                                                    onChange={null}
                                                    allowZero={false}
                                                    allowDecimal={false}
                                                    allowNegative={false}
                                                    readOnly={true}
                                                    inputClassName="custom-form-control"
                                                />
                                                {this.genIcon(1)}
                                            </div>
                                        </div>
                                    </div>



                                    {/* Số tiền giải tỏa */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.unhold-corebank.unhold-amount" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group-effect txt---400-14-20">
                                                <NumberInput2New
                                                    ref={this.UnholdNearestControl}
                                                    name="transfer-amount"
                                                    step={stepValues.PRICE}
                                                    min={null}
                                                    max={999999999999}
                                                    value={unholdAmount}
                                                    valid={unholdAmountValid}
                                                    onChange={this.onUnholdAmountChange}
                                                    allowZero={false}
                                                    allowDecimal={false}
                                                    allowNegative={false}
                                                    className="need-focus-eff custom-form-control"
                                                    placeholder={CommonUtils.getPlaceHolderInput(language)}
                                                    onKeyDown={this.onHandleKeyDownConfirm}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* Thao tác */}
                                    <div className="row gutters-5 align-items-center h-row container-btn">

                                        {/* Làm mới */}
                                        <div className="col-6">
                                            <button ref={this.refreshBtnRef} className="btn-refresh txt---500-16-24 btn" onClick={() => this.refresh()} onKeyDown={this.onHandleKeyDownRefresh}>
                                                <FormattedMessage id="common.btn-refresh" />
                                            </button>
                                        </div>
                                        {/* Tiếp tục */}
                                        <div className="col-6">
                                            <button ref={this.confirmBtnRef} className="btn-confirm txt---500-16-24 btn" onClick={() => this.preCheckInfoInput()} onKeyDown={this.onHandleKeyDownConfirm}>
                                                <FormattedMessage id="common.btn-continue" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}


                            {step === 2 && <TranAuthScreenConfirm
                                typeInfo={ModalConfirmType.UNHOLD_COREBANK}
                                dataInfo={authData}
                                toggle={() => { this.setStep(step - 1) }} // backstep
                                onClose={() => { this.setStep(step - 1) }} // backstep
                            />}

                            {step === 3 && (
                                <div className='success-container'>
                                    <div className='icon-container'>
                                        <div className='icon'>
                                            <i className="fa fa-check"></i>
                                        </div>
                                    </div>
                                    <div className='success-title headM---500-20-30'><FormattedMessage id="account.utilities.cash-transfer.transaction-success" /></div>
                                    <div className='row glutter-5'>
                                        <button ref={this.successBtnRef} className="btn-refresh txt---500-16-24 btn" onClick={() => this.setStep(1, true)} onKeyDown={this.onHandleKeyDownSuccess}>
                                            <FormattedMessage id="common.btn-close" />
                                        </button>
                                    </div>


                                </div>
                            )}
                        </div>

                    </div >
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    // let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    // let _accountInfo = _layoutPage && _layoutPage.accountInfo
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        permissionInfo: permissionInfo,
        defaultTheme: defaultTheme,
        curCustomer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Unhold));

