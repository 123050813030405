import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { execTypes, LoadStatus, Role, ToastUtil, LanguageUtils, reportParams, reportTypes, CommonUtils, mDate, Random, SELECTOR_ACCOUNT_SCREEN, OUTPUT_REPORT, RPT_ID, activeOrderExecType } from "../../../../utils";
import config from '../../../../combineConfig';
import RetryableContent from "../../../../components/RetryableContent";
import moment from "moment/moment";
import { fdsInquiryService, inquiryService } from "../../../../services";
import { connect } from "react-redux";
import * as actions from '../../../../store/actions'
import { DatePicker } from "../../../../components/Input";
import TextToolTip from "../../../../components/TextToolTip";
import { ReactComponent as DownloadIconLight } from '../../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../../assets/icons/new/DownloadIcon_Dark.svg'
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './ConditonOrderHistory.scss';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import SymbolSuggestTrans from './../../../../components/SymbolSuggestTrans';

const rowPerPage = 200;

let columnsDefault =
    [
        /* Tiểu khoản */
        {
            dataField: "acctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* SHL */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Ngày */
        {
            dataField: "side",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "status_desc",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Loại GD */
        {
            dataField: "ordertype_desc",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Loại lệnh */
        {
            dataField: "qtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Loại khớp */
        {
            dataField: "price_stop",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Trạng thái */
        {
            dataField: "txtime",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "fromdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "todate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "price",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
        },
        {
            dataField: "activetime",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
        {
            dataField: "quoteamt", //GT đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 12,
        },
        {
            dataField: "activedate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 13,
        },
        {
            dataField: "orderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 14,
        },
        {
            dataField: "pricetype",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 15,
        },
        {
            dataField: "exprice",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
        {
            dataField: "execqtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 17,
        },
        {
            dataField: "order_info",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 18,
        },
    ]

let columnsDefaultFDS =
    [
        /* Tiểu khoản */
        {
            dataField: "afacctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* SHL */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Ngày */
        {
            dataField: "side",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "statusdesc",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Loại GD */
        {
            dataField: "classcd",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Loại lệnh */
        {
            dataField: "orderqtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Loại khớp */
        {
            dataField: "stopprice",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Trạng thái */
        {
            dataField: "txtime",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "todate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "quoteprice",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
        },
        {
            dataField: "activetime",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
        {
            dataField: "quoteamt", //GT đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 12,
        },
        {
            dataField: "activedate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 13,
        },
        {
            dataField: "orderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 14,
        },
        {
            dataField: "child_classcd",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 15,
        },
        {
            dataField: "exec_price",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
        {
            dataField: "exec_qtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 17,
        },
        {
            dataField: "child_gen_status",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 18,
        },
    ]
class ConditonOrderHistory extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        onReloadData: true,
        listAccounts: [],
        listStatus: [{
            value: "ALL"
        }]
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.toDate = currentDate;
        this.state.fromDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.searchBtnRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        if (isDerivative) {
            switch (dataField) {
                case 'afacctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
                case 'side':
                    return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side, lang);
                case 'statusdesc':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.statusdesc);
                case 'classcd':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.classcd);
                case 'orderqtty':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.orderqtty);
                case 'stopprice':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.stopprice);
                case 'txtime':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txtime);
                case 'txdate':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.txdate);
                case 'todate':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.todate);
                case 'quoteprice':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(e.row.data.quoteprice, 'PRICE');
                case 'activetime':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.activetime);
                case 'quoteamt':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(Number(e.row.data.quoteamt));
                case 'activedate':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.activedate);
                case 'orderid':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.orderid);
                case 'pricetype':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.pricetype);
                case 'child_classcd':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.child_classcd);
                case 'exec_price':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.exec_price);
                case 'exec_qtty':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.exec_qtty);
                case 'child_gen_status':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.child_gen_status);
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'acctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol);
                case 'side':
                    return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side, lang);
                case 'status_desc':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.status_desc);
                case 'ordertype_desc':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.ordertype_desc);
                case 'qtty':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.qtty);
                case 'price_stop':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.price_stop);
                case 'txtime':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txtime);
                case 'fromdate':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.fromdate);
                case 'todate':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.todate);
                case 'price':
                    return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.price);
                case 'activetime':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.activetime);
                case 'quoteamt':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(Number(e.row.data.quoteamt));
                case 'activedate':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.activedate);
                case 'orderid':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.orderid);
                case 'pricetype':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.pricetype);
                case 'exprice':
                    return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.exprice);
                case 'execqtty':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.execqtty);
                case 'order_info':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.order_info);
                default:
                    return null;
            }
        }
    }

    returnCaption = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        if (isDerivative) {
            switch (dataField) {
                case 'afacctno':
                    return 'condition-order-history.sub-accounts'
                case 'symbol':
                    return 'condition-order-history.symbol-code'
                case 'side':
                    return 'condition-order-history.purchase'
                case 'statusdesc':
                    return 'condition-order-history.status'
                case 'classcd':
                    return 'condition-order-history.command-type'
                case 'orderqtty':
                    return 'condition-order-history.weight-set'
                case 'stopprice':
                    return 'condition-order-history.stop-price'
                case 'txtime':
                    return 'condition-order-history.booking-time'
                case 'txdate':
                    return 'condition-order-history.effective-date'
                case 'todate':
                    return 'condition-order-history.end-date'
                case 'quoteprice':
                    return 'condition-order-history.set-price'
                case 'activetime':
                    return 'condition-order-history.time'
                case 'quoteamt':
                    return 'condition-order-history.value-set'
                case 'activedate':
                    return 'condition-order-history.day-of-destruction'
                case 'orderid':
                    return 'condition-order-history.order-child'
                case 'child_classcd':
                    return 'condition-order-history.subcommand-type'
                case 'exec_price':
                    return 'condition-order-history.price-match'
                case 'exec_qtty':
                    return 'condition-order-history.matching-weight'
                case 'child_gen_status':
                    return 'condition-order-history.status-details'
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'acctno':
                    return 'condition-order-history.sub-accounts'
                case 'symbol':
                    return 'condition-order-history.symbol-code'
                case 'side':
                    return 'condition-order-history.purchase'
                case 'status_desc':
                    return 'condition-order-history.status'
                case 'ordertype_desc':
                    return 'condition-order-history.command-type'
                case 'qtty':
                    return 'condition-order-history.weight-set'
                case 'price_stop':
                    return 'condition-order-history.stop-price'
                case 'txtime':
                    return 'condition-order-history.booking-time'
                case 'fromdate':
                    return 'condition-order-history.effective-date'
                case 'todate':
                    return 'condition-order-history.end-date'
                case 'price':
                    return 'condition-order-history.set-price'
                case 'activetime':
                    return 'condition-order-history.time'
                case 'quoteamt':
                    return 'condition-order-history.value-set'
                case 'activedate':
                    return 'condition-order-history.day-of-destruction'
                case 'orderid':
                    return 'condition-order-history.order-child'
                case 'pricetype':
                    return 'condition-order-history.subcommand-type'
                case 'exprice':
                    return 'condition-order-history.price-match'
                case 'execqtty':
                    return 'condition-order-history.matching-weight'
                case 'order_info':
                    return 'condition-order-history.status-details'
                default:
                    return null;
            }
        }

    }

    search = () => {
        this.setReloadData();
    }

    renderConfirmsts = (row) => {
        if (row === "Không") return "No"
        return "Yes"
    }


    loadOrderStatus = () => {
        const { fromDate, toDate, currentAccount, symbol, orderStatus, listStatus } = this.state;


        if (currentAccount && currentAccount.accounttype === "FDS") {
            this._setState({ loadStatus: LoadStatus.LOADING });
            let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
            fdsInquiryService.getConfirmCondOrderStatusFDS(custid)
                .then((data) => {
                    let _data = data.map((item, index) => {
                        return {
                            value: item.cdval,
                            description: item.cdcontent,
                            en_description: item.en_cdcontent,
                        }
                    })
                    let _listStatus = [{
                        value: "ALL"
                    }, ..._data]

                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                        listStatus: _listStatus,
                        orderStatus: "ALL"
                    })
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                });
        }

        if (currentAccount && currentAccount.accounttype === "SEC" || currentAccount.accounttype === "ALL") {
            this._setState({ loadStatus: LoadStatus.LOADING });
            let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
            if (custid) {
                inquiryService.getConfirmCondOrderStatus(custid)
                    .then((data) => {
                        let _listStatus = [{
                            value: "ALL"
                        }, ...data]

                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            listStatus: _listStatus,
                            orderStatus: "ALL"
                        })
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-open-positions');
                    });
            } else {
                let _listStatus = [{
                    value: "ALL"
                }]

                this._setState({
                    loadStatus: LoadStatus.LOADED,
                    listStatus: _listStatus,
                    orderStatus: "ALL"
                })
            }

        }

    };



    convertData = (dataArr) => {
        const { fromDate, toDate, currentAccount, symbol, orderStatus } = this.state;
        if (currentAccount && currentAccount.accounttype === "FDS") {
            return this.processOrderDataFDS(dataArr)
        }
        if (currentAccount && currentAccount.accounttype === "SEC" || currentAccount.accounttype === "ALL") {
            return this.processOrderData(dataArr)
        }
        return []
    };


    convertConditonDateBase = (date, type) => {
        if (date) {
            if (type == "time_quote") { //"2023-01-14T10:44:19.000Z"
                let result1 = date.split(".")[0];
                let result2 = result1.split("T")[1];
                return result2
            } else if (type == "fromdate" || type == "todate") { //"2023-01-13T00:00:00.000Z"
                let result1 = date.split("T")[0];
                let result2 = result1.split("-");
                let year = result2[0]
                let month = result2[1]
                let day = result2[2]
                return day + "/" + month + "/" + year
            }
            return "-"
        }
        return "-"
    }


    processOrderDataFDS = (records) => {
        const { language } = this.props
        let newRecords = _.cloneDeep(records)
        if (newRecords.length > 0) {
            _.forEach(newRecords, (record) => {
                record["quoteamt"] = record["nvalue"] ? (record["orderqtty"] * record["quoteprice"]) * record["nvalue"] : record["orderqtty"] * record["quoteprice"]
                record.sideCode = record['side'] == 'B' ? activeOrderExecType.BUY : activeOrderExecType.SELL
                record.side = CommonUtils.convertSideByLanguage(record['sideCode'], language)
                record.txdate = this.convertConditonDateBase(record.txdate, "fromdate")
                record.todate = this.convertConditonDateBase(record.todate, "todate")
            })
            return newRecords
        } else {
            return []
        }
    };

    processOrderData = (records) => {
        const { language } = this.props
        let newRecords = _.cloneDeep(records)
        if (newRecords.length > 0) {
            _.forEach(newRecords, (record) => {
                record["quoteamt"] = record["qtty"] * record["price"]
                record.sideCode = record['side'] == 'B' ? activeOrderExecType.BUY : activeOrderExecType.SELL
                record.side = CommonUtils.convertSideByLanguage(record['sideCode'], language)
                record.fromdate = this.convertConditonDateBase(record.fromdate, "fromdate")
                record.todate = this.convertConditonDateBase(record.todate, "todate")
            })
            return newRecords
        } else {
            return []
        }
    };


    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount, symbol, orderStatus } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const lang = this.props.language;
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }

        try {
            let self = this
            let _obj = {
                accountId: currentAccount.id,
                fromDate: fromDate,
                toDate: toDate,
                symbol: symbol || "ALL",
                status: orderStatus,
                page: obj.page,
                limitRow: obj.limitRow,
            }
            if (currentAccount && currentAccount.accounttype === "SEC") {
                return inquiryService.getConditionOrderHistory(_obj.accountId, _obj.fromDate, _obj.toDate, _obj.symbol, _obj.status, _obj.page, _obj.limitRow)
                    .then((data) => {
                        if (data && data.d) {
                            data.d = this.convertData(data.d)
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'ORDER_HISTORY');
                        return []
                    });
            }
            if (currentAccount && currentAccount.accounttype === "FDS") {
                return fdsInquiryService.getConditionOrderHistoryFDS(_obj.accountId, _obj.fromDate, _obj.toDate, _obj.symbol, _obj.status, _obj.page, _obj.limitRow)
                    .then((data) => {
                        if (data && data.d) {
                            data.d = this.convertData(data.d)
                            data = data.d
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'ORDER_HISTORY');
                        return []
                    });
            }

            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                if (custid) {
                    return inquiryService.getConditionOrderHistoryAllBase(custid, _obj.fromDate, _obj.toDate, _obj.symbol, _obj.status, _obj.page, _obj.limitRow)
                        .then((data) => {
                            if (data && data.d) {
                                data.d = this.convertData(data.d)
                                data = data.d
                            }
                            return data
                        })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'ORDER_HISTORY');
                            return []
                        });
                }
                return []
            }

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    genReport = (exptype) => {
        let { fromDate, toDate, symbol, execType, orderStatus, currentAccount } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL;
        execType = execType ? execType : reportParams.ALL;
        orderStatus = orderStatus ? orderStatus : reportParams.ALL;

        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];
            let accountId = currentAccount.id
            if (currentAccount.accounttype === "ALL") {
                accountId = "ALL"
            }
            rptId = RPT_ID.ORDERHIST;
            inpArr = [custid, accountId, fromDate, toDate, symbol, execType, orderStatus, OUTPUT_REPORT.OFFSET, OUTPUT_REPORT.LIMIT, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    // run only once when component is mounted
    loadInitData = (check) => {
        // Lấy danh sách trạng thái để tìm kiếm
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;
        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found },
            () => this.loadOrderStatus()
        );
    };

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData();
        this.setReloadData();
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language, currentAccountId } = this.props;
        const { language: prevlanguage, currentAccountId: prevCurrentAccountId } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };


    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found },
            () => this.loadOrderStatus()
        )
    };


    //Set tráng thái
    setStatusConfirm = (event) => {
        this._setState({ orderStatus: event.target.value })
    };

    formatFDS = (text) => {
        let check = String(text).search("FDS")
        if (!check) {
            return String(text).replace("FDS", '')
        }
        return text
    }

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    render() {
        const { language, accountInfo } = this.props;
        const { currentAccount, records, listAccounts, onReloadData, listStatus, orderStatus } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        //console.log("binh_listStatus", listStatus)
        return (
            <RetryableContent className="order-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="order-history-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group-n txt---400-14-20">
                                        {this.props.userInfo && this.props.userInfo.custodycd}
                                    </div>
                            }
                            <SymbolSuggestTrans
                                handleInputChange={this.onSymbolChanged}
                                currentSymbol={this.state.symbol}
                                symbolToDisplay={this.state.symbol}
                                suggestId="securities-transaction-report "
                                isRedirect={false}
                                isDisplayError={false}
                                placeholder={LanguageUtils.getMessageByKey("securities-transaction-report.all", this.props.lang)}
                            />
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id} ({account.entypename})</option> :
                                            (<option key={account.id} value={account.id}>{account.entypename} - {CommonUtils.formatFDS(account.id)}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <select value={orderStatus ? orderStatus : ''} onChange={this.setStatusConfirm} className="custom-form-control txt---400-14-20">
                                    {listStatus && listStatus.map((item) => {
                                        return (item.value === "ALL" ?
                                            <FormattedMessage key="empty" id="common.ALL">
                                                {(txt) => (
                                                    <option key="empty" value={item.value}>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                            :
                                            (<option value={item.value}>{this.props.language === "vi" ? item.description : item.en_description}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="symbol" // key của row 
                            columnsDefault={isDerivative ? columnsDefaultFDS : columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="order-history-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ...accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    // let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    // let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // let accountInfo = _layoutPage && _layoutPage["accountInfo"]
    // let { accounts, currentAccountId, curCustomer } = { ...accountInfo }
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        lang: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: accountInfo,
        userInfo: state.user.userInfo,
        defaultTheme: defaultTheme
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConditonOrderHistory));
