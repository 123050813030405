import { LoadStatus } from 'constants/config';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomInput } from 'reactstrap';
import ReloadIconDark from '../../../../assets/icons/ReloadIcon_Dark.svg'
import ReloadIconLight from '../../../../assets/icons/ReloadIcon_Light.svg'
import { FormattedPercentage, FormattedPrice } from "../../../../components/Formating";
import { NumberInput2New } from "../../../../components/Input";
import { CommonUtils, IFlexType, stepValues, TransferTypes } from "../../../../utils";
class IFlexDetail extends Component {

    transferTypes = TransferTypes;


    state = {
        hide: false,
        descriptionInput: '',
        benefitAccountNumber: ''
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
    }

    UNSAFE_componentWillMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this), false);
        this.mounted = false
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 576);
        if (currentHideNav !== this.state.hideNav) {
            this._setState({ hide: currentHideNav });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { flexType, accountSend } = this.props
        if (prevProps.transferType !== flexType
            || prevProps.accountSend !== accountSend
        ) {
        }

        if (prevProps.flexType !== this.props.flexType) {
            this.props.onResetDataInForm()
        }
    }

    render() {
        const {
            accountSend, setAccountSend, transferAmount, onTransferAmountChange,
            transferAmountValid, accounts, refresh, language, preCheckInfoInput, basis, loadSummaryAccount,
            flexType, onTypeChange, baldefovd4td, changeTypeDeposit, typeDeposit, listTypeDeposit, toggleModalDetail,
            onToggleOpenModalRules, onCheckAgree, checkAgree, isOnAuto, isRegistedAuto, checkToggleiFlex,
            changeAutoTypeDeposit, autotypeDeposit, listAutoTypeDeposit, defaultTheme, returnStateAuto, reloadSummaryAccount
        } = this.props;
        let checkAuto = isOnAuto === false && flexType === IFlexType.AUTO ? true : false
        return (
            <div className="row gutters-5">
                <div className="col-12  col-lg-5">
                    <div className='block'>
                        <div className='headB---700-16-24 title'>
                            <div className={flexType === IFlexType.UN_AUTO ? "active" : "not-active"} onClick={() => onTypeChange(IFlexType.UN_AUTO)}>
                                <FormattedMessage id="iflex.sign-up-every-time" />
                            </div>
                            <div className={flexType === IFlexType.AUTO ? "active" : "not-active"} onClick={() => onTypeChange(IFlexType.AUTO)}>
                                <FormattedMessage id="iflex.automatic-registration" />
                            </div>
                        </div>
                        {/* Tiểu khoản */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.sub-account" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <select value={accountSend ? accountSend.id : ''} onChange={setAccountSend} className="custom-form-control txt---400-14-20">
                                            {(!accounts || accounts.length === 0 || !accountSend) && (
                                                <FormattedMessage key="empty" id="header.choose-account">
                                                    {(txt) => (
                                                        <option key="empty" value="" disabled>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                            {accounts && accounts.map((account) => {
                                                return (<option key={account.id} value={account.id}>{account.entypename} - {account.id}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Số dư khả dụng */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.available-balances" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPrice value={baldefovd4td} />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Thời hạn */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.duration" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <select value={typeDeposit ? typeDeposit.valuecd : ''} onChange={changeTypeDeposit} className="custom-form-control txt---400-14-20">
                                            {(!listTypeDeposit || listTypeDeposit.length === 0 || !typeDeposit) && (
                                                <FormattedMessage key="empty" id="header.choose-deposit">
                                                    {(txt) => (
                                                        <option key="empty" value="" disabled>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                            {listTypeDeposit && listTypeDeposit.map((deposit) => {
                                                return (<option key={deposit.valuecd} value={deposit.valuecd}>{deposit.en_display}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Lãi suất */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.interest-rate" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPercentage value={typeDeposit ? typeDeposit.intrate : 0} />
                                        %
                                        <FormattedMessage id="iflex.year" />
                                        <div className='text-detail txt---400-14-20' onClick={toggleModalDetail}>
                                            <FormattedMessage id="iflex.detail" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Số tiền tối thiểu */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.minimum-amount" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPrice value={typeDeposit ? typeDeposit.minamt : 0} />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Số tiền tối đa */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.the-maximum-amount" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPrice value={typeDeposit ? typeDeposit.maxamt : 0} />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Số tiền cho vay */}
                        {flexType === IFlexType.UN_AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.loan-amount" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group-effect txt---400-14-20">
                                        <NumberInput2New
                                            name="transfer-amount"
                                            step={stepValues.PRICE}
                                            min={null}
                                            max={999999999999}
                                            value={transferAmount}
                                            valid={transferAmountValid}
                                            onChange={onTransferAmountChange}
                                            allowZero={false}
                                            allowDecimal={false}
                                            allowNegative={false}
                                            className="need-focus-eff"
                                            placeholder={CommonUtils.getPlaceHolderInput(language)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Tên tài khoản */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.account-name" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        {accountSend ? accountSend.name : ''}
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Số tài khoản */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.account-number" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        {accountSend ? accountSend.custodycd : ''}
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Trạng thái */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.status" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        {
                                            isRegistedAuto ?
                                                <FormattedMessage id="iflex.registered" />
                                                :
                                                <FormattedMessage id="iflex.un-registered" />

                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Bật/Tắt IFlex */}
                        {flexType === IFlexType.AUTO && isRegistedAuto &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.enable-disable-iflex" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <CustomInput
                                        type="switch"
                                        id="switch-theme-input"
                                        // onChange={checkToggleiFlex}
                                        checked={isOnAuto}
                                        disabled={isRegistedAuto}
                                    />
                                </div>
                            </div>
                        }

                        {/* Thời hạn */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.duration" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <select disabled={isRegistedAuto && !isOnAuto} value={autotypeDeposit ? autotypeDeposit.actype : ''} onChange={changeAutoTypeDeposit} className="custom-form-control">
                                            {(!listAutoTypeDeposit || listAutoTypeDeposit.length === 0 || !autotypeDeposit) && (
                                                <FormattedMessage key="empty" id="header.choose-deposit">
                                                    {(txt) => (
                                                        <option key="empty" value="" disabled>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                            {listAutoTypeDeposit && listAutoTypeDeposit.map((deposit) => {
                                                return (<option key={deposit.actype} value={deposit.actype}>{deposit.typename}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Lãi suất */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.interest-rate" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPercentage value={autotypeDeposit ? autotypeDeposit.intrate : 0} />
                                        %
                                        <FormattedMessage id="iflex.year" />
                                        <div className='text-detail txt---400-14-20' onClick={toggleModalDetail}>
                                            <FormattedMessage id="iflex.detail" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Số tiền tối thiểu */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.minimum-amount" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.minamt : 0} />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Số tiền tối đa */}
                        {flexType === IFlexType.AUTO &&
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="iflex.the-maximum-amount" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <FormattedPrice value={autotypeDeposit ? autotypeDeposit.maxamt : 0} />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Đồng ý xác nhận */}
                        <div className="row gutters-5 align-items-center">
                            <div className="col-12">
                                <span>
                                    <input
                                        type="checkbox"
                                        onChange={onCheckAgree}
                                        checked={checkAgree}
                                    ></input>
                                </span>
                                <span className='txt---400-14-20' style={{ marginLeft: "4px" }}>
                                    <FormattedMessage id="iflex.common.001" />
                                </span>
                                <span className='txt---400-14-20' style={{ cursor: "pointer", textDecorationLine: "underline", margin: "0px 5px" }} onClick={onToggleOpenModalRules}>
                                    <FormattedMessage id="iflex.common.002" />
                                </span>
                                <span className='txt---400-14-20'>
                                    <FormattedMessage id="iflex.common.003" />
                                </span>
                            </div>
                        </div>

                        {/* Thao tác */}
                        {flexType === IFlexType.AUTO && isRegistedAuto ?
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {/* Sửa */}
                                <div className="col-5">
                                    <button disabled={isRegistedAuto && !isOnAuto} className="btn-refresh txt---500-16-24 btn" onClick={() => preCheckInfoInput()} >
                                        <FormattedMessage id="iflex.btn-edit" />
                                    </button>
                                </div>
                                {/* Bật/Tắt */}
                                <div className="col-7">
                                    {/* <button className="btn-confirm txt---500-16-24 btn" onClick={() => returnStateAuto(() => checkToggleiFlex())} > */}
                                    <button className="btn-confirm txt---500-16-24 btn" onClick={() => returnStateAuto()} >
                                        {isOnAuto ? <FormattedMessage id="iflex.btn-off" />
                                            :
                                            <FormattedMessage id="iflex.btn-on" />}
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {/* Làm mới */}
                                <div className="col-5">
                                    <button className="btn-refresh txt---500-16-24 btn" onClick={() => refresh()} >
                                        <FormattedMessage id="common.btn-refresh" />
                                    </button>
                                </div>
                                {/* Tiếp tục */}
                                <div className="col-7">
                                    <button disabled={!isOnAuto && flexType === IFlexType.AUTO && isRegistedAuto} className="btn-confirm txt---500-16-24 btn" onClick={() => preCheckInfoInput()} >
                                        <FormattedMessage id="common.btn-continue" />
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-12  col-lg-7 ">
                    <div className="col-12 block-right">
                        <div className='headB---700-16-24 title'>
                            <FormattedMessage id="account.utilities.cash-transfer.money-balance" />
                        </div>
                        <div className='container-block row gutters-5'>
                            <div className="block-item col-12 col-lg-6">
                                <div className='border-block'>
                                    {/* Tiền tại VIX */}
                                    <div className="row-title">
                                        <div className="txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.money-at-VIX" />
                                        </div>

                                        <div className='txt---400-14-20' >
                                            <FormattedPrice value={basis ? basis.balance - basis.tdbalance : 0} />
                                        </div>
                                    </div>
                                    {/* Tiền không kỳ hạn */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.unlimited-money" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.cibalance} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                    {/* Tiền cổ tức chờ về */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.dividends-waiting-to-come-back" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.careceiving} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                    {/* Lãi tiền gửi chưa thanh toán */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.unpaid-deposit-interest" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.interestbalance} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                    {/* Tiền bán chờ về */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.money-from-the-sale-to-return" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.receivingamt} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="block-item col-12 col-lg-6">
                                <div className='border-block'>
                                    {/* Tiền tại @BIDV */}
                                    <div className="row-title">
                                        <div className="txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.money-at-BIDV" />
                                        </div>

                                        <div className='d-flex'>
                                            <div className=' txt---400-14-20' style={{ marginRight: "5px" }}>
                                                <FormattedPrice value={basis ? basis.bankavlbal : 0} />
                                            </div>
                                            <div onClick={(e) => reloadSummaryAccount(e)} style={{ cursor: "pointer" }}>
                                                <img className={"effect-rotate-360" + (this.props.loadStatusReload === LoadStatus.LOADING ? " effect-loading " : "") + (this.props.time !== 0 ? " effect-loaded" : "")} src={defaultTheme === "dark" ? ReloadIconDark : ReloadIconLight} alt="icon-reload" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Số dư khả dụng */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.available-balances" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.bankavlbal} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                    {/* Tiền dư phong tỏa */}
                                    <div className="row-item">
                                        <div className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.blocked-balances" />
                                        </div>
                                        {
                                            basis ?
                                                <div className='txt---400-14-20'>
                                                    <FormattedPrice value={basis.holdbalance} />
                                                </div>
                                                :
                                                <div className='txt---400-14-20'>-</div>
                                        }
                                    </div>
                                </div>
                                <div className='border-block' style={{ marginTop: "8px" }}>
                                    {/* iFlex */}
                                    <div className="row-item-flex">
                                        <div className="txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.i-flex" />
                                        </div>
                                        <div className='txt---400-14-20' >
                                            <FormattedPrice value={basis ? basis.tdbalance : 0} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

}

export default IFlexDetail;
