import React, { Component, Fragment } from 'react';
import _ from 'lodash'
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

import { ReactComponent as DownloadIconLight } from '../../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../../assets/icons/new/DownloadIcon_Dark.svg'
import config from '../../../../combineConfig';
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import TextToolTip from "../../../../components/TextToolTip";
import { fdsInquiryService, inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, execTypes, LoadStatus, mDate, OUTPUT_REPORT, Random, reportParams, reportTypes, Role, RPT_ID, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../../../utils";
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import './MatchHistoryNew.scss';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';

const rowPerPage = 200;

let columnsDefault =
    [
        /* Tiểu khoản */
        {
            dataField: "afacctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* SHL */
        {
            dataField: "orderID",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Ngày */
        {
            dataField: "txDate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Loại GD */
        {
            dataField: "side",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Loại lệnh */
        {
            dataField: "pricetype",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Loại khớp */
        {
            dataField: "matchtype_value",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Thông tin giao dịch chứng khoán */
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 7,
            listColumnGroup: [
                /* KL khớp*/
                {
                    dataField: "matchQtty",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 8,
                },
                /* Giá khớp */
                {
                    dataField: "matchPrice",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 9,
                },
                /* GT khớp */
                {
                    dataField: "matchAmt",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 10,
                }
            ],
        },
        /* Trạng thái */
        {
            dataField: "orstatusvalue",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
        /* Phí */
        {
            dataField: "feeAmt",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        /* Thuế TNCN */
        {
            dataField: "sellTaxamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        /* Thuế TNCN (cổ tức) */
        {
            dataField: "sepittax",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 14,
        },
        // /* Xác nhận */
        // {
        //     dataField: "confirmsts",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "90",
        //     width: '100',
        //     visible: true,
        //     alignment: "center",
        //     visibleIndex: 15,
        // },
        /* Kênh đặt lệnh */
        {
            dataField: "via",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
    ]

let columnsDefaultFDS =
    [
        /* Tiểu khoản */
        {
            dataField: "acctno",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* SHL */
        {
            dataField: "orderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '160',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Ngày */
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* Loại GD */
        {
            dataField: "side_desc",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        /* Loại lệnh */
        {
            dataField: "subtypecd",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Loại khớp */
        {
            dataField: "norp_desc",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Thông tin giao dịch chứng khoán */
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 7,
            listColumnGroup: [
                /* KL khớp*/
                {
                    dataField: "matchqtty",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 8,
                },
                /* Giá khớp */
                {
                    dataField: "matchprice",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 9,
                },
                /* GT khớp */
                {
                    dataField: "matchamt",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    minWidth: "90",
                    width: '100',
                    alignment: "right",
                    visible: true,
                    visibleIndex: 10,
                }
            ],
        },
        /* Trạng thái */
        {
            dataField: "exectype",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
        /* Phí */
        {
            dataField: "feeamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        /* Thuế TNCN */
        {
            dataField: "taxamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        /* Xác nhận */
        {
            dataField: "confirmsts",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 14,
        },
        /* Kênh đặt lệnh */
        {
            dataField: "via",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 15,
        },
    ]
class MatchHistoryNew extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        page: 1,

        listAccounts: [],
        onReloadData: true,
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.searchBtnRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    returnCaption = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        if (!isDerivative) {
            switch (dataField) {
                case 'afacctno':
                    return "match-history.sub-account";
                case 'orderID':
                    return "match-history.order-number";
                case 'txDate':
                    return "match-history.date";
                case 'symbol':
                    return "match-history.symbol";
                case 'side':
                    return "match-history.BS";
                case 'pricetype':
                    return "match-history.command-type";
                case 'matchtype_value':
                    return "match-history.match-type";
                case 'group':
                    return "match-history.stock-info";
                case 'matchQtty':
                    return "match-history.stock-info-matched-qtty";
                case 'matchPrice':
                    return "match-history.stock-info-matched-price";
                case 'matchAmt':
                    return "match-history.stock-info-matched-value";
                case 'orstatusvalue':
                    return "match-history.order-status";
                case 'feeAmt':
                    return "match-history.fee";
                case 'sellTaxamt':
                    return "match-history.personal-income-tax";
                case 'sepittax':
                    return "match-history.personal-income-tax-stock";
                case 'confirmsts':
                    return "match-history.confirmed";
                case 'via':
                    return "match-history.via";
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'acctno':
                    return "match-history.sub-account";
                case 'orderid':
                    return "match-history.order-number";
                case 'txdate':
                    return "match-history.date";
                case 'symbol':
                    return "match-history.symbol";
                case 'side_desc':
                    return "match-history.BS";
                case 'subtypecd':
                    return "match-history.command-type";
                case 'norp_desc':
                    return "match-history.match-type";
                case 'group':
                    return "match-history.stock-info";
                case 'matchqtty':
                    return "match-history.stock-info-matched-qtty";
                case 'matchprice':
                    return "match-history.stock-info-matched-price";
                case 'matchamt':
                    return "match-history.stock-info-matched-value";
                case 'exectype':
                    return "match-history.order-status";
                case 'feeamt':
                    return "match-history.fee";
                case 'taxamt':
                    return "match-history.personal-income-tax";
                case 'confirmsts':
                    return "match-history.confirmed";
                case 'via':
                    return "match-history.via";
                default:
                    return null;
            }
        }
    }


    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount, page } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const lang = this.props.language;
        const checkVi = lang === "vi" ? true : false
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }

        try {
            let self = this
            let _obj = {
                custid: currentAccount.id,
                fromDate: fromDate,
                page: obj.page,
                limitRow: obj.limitRow,
                toDate: toDate,
            }
            if (currentAccount && currentAccount.accounttype === "SEC") {
                return inquiryService.getMatchingHistory(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                    if (data && data.d) {
                        data = data.d
                        if (!checkVi) {
                            let dataEn = data.map(item => { return { ...item, side: CommonUtils.convertSide(item.side) } });
                            return dataEn
                        }
                    }
                    return data
                })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'MATCHING_HISTORY');
                        return []
                    });
            }
            if (currentAccount && currentAccount.accounttype === "FDS") {
                return fdsInquiryService.getFDSMatchingHistory(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                    if (data && data.d) {
                        data = data.d
                        if (!checkVi) {
                            let dataEn = data.map(item => {
                                return {
                                    ...item,
                                    side_desc: CommonUtils.convertSide(item.side_desc),
                                    norp_desc: CommonUtils.convertCommandTypeFilterMatch(item.norp_desc),
                                    exectype: CommonUtils.convertStatusFilter(item.exectype)
                                }
                            });
                            return dataEn
                        }
                    }
                    return data
                })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'MATCHING_HISTORY');
                        return []
                    });
            }

            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                if (custid) {

                    return inquiryService.getMatchHistoryAllBase(custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                        if (data && data.d) {
                            data = data.d
                            if (!checkVi) {
                                let dataEn = data.map(item => { return { ...item, side: CommonUtils.convertSide(item.side) } });
                                return dataEn
                            }
                        }
                        return data
                    })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'MATCHING_HISTORY');
                            return []
                        });
                }
                return []
            }

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    genReport = (exptype) => {
        let { currentAccount, fromDate, toDate, symbol, execType, orderStatus } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL;
        execType = execType ? execType : reportParams.ALL;
        orderStatus = orderStatus ? orderStatus : reportParams.ALL;

        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];
            let accountId = currentAccount.id
            if (currentAccount.accounttype === "ALL") {
                accountId = "ALL"
            }
            rptId = RPT_ID.ORDERMATCH;
            inpArr = [custid, accountId, fromDate, toDate, symbol, execType, orderStatus, OUTPUT_REPORT.OFFSET, OUTPUT_REPORT.LIMIT, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    // run only once when component is mounted
    loadInitData = (check) => {
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;
        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found });
    };

    formatFDS = (text) => {
        let check = String(text).search("FDS")
        if (!check) {
            return String(text).replace("FDS", '')
        }
        return text
    }


    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        if (!isDerivative) {
            switch (dataField) {
                case 'afacctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno);
                case 'orderID':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.orderID);
                case 'txDate':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txDate);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
                case 'side':
                    return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side, lang);
                case 'pricetype':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.pricetype);
                case 'matchtype_value':
                    return (e) => RenderGridCell.renderGridCellCommon(lang === "vi" ? e.row.data.matchtype_value : e.row.data.en_matchtype_value);
                case 'matchQtty':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchQtty, 'PRICE');
                case 'matchPrice':
                    return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.matchPrice);
                case 'matchAmt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchAmt, 'PRICE');
                case 'orstatusvalue':
                    return (e) => RenderGridCell.renderGridCellCommon(lang === "vi" ? e.row.data.orstatusvalue : e.row.data.en_orstatusvalue);
                case 'feeAmt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeAmt, 'PRICE');
                case 'sellTaxamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sellTaxamt, 'PRICE');
                case 'sepittax':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sepittax, 'PRICE');
                case 'confirmsts':
                    return (e) => this.renderConfirmsts(e.row.data.confirmsts);
                case 'via':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.via);
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'acctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
                case 'orderid':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.orderid);
                case 'txdate':
                    return (e) => RenderGridCell.renderGridCellDate(e.row.data.txdate);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
                case 'side_desc':
                    return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side_desc, lang);
                case 'subtypecd':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.subtypecd);
                case 'norp_desc':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.norp_desc);
                case 'matchqtty':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchqtty, 'PRICE');
                case 'matchprice':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(e.row.data.matchprice);
                case 'matchamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.matchamt, 'PRICE');
                case 'exectype':
                    return (e) => RenderGridCell.renderGridCellContent(this.props.language === "vi" ? e.row.data.exectype : e.row.data.en_exectype);
                case 'feeamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.feeamt, 'PRICE');
                case 'taxamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.taxamt, 'PRICE');
                case 'confirmsts':
                    return (e) => this.renderConfirmsts(e.row.data.confirmsts);
                case 'via':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.via);
                default:
                    return null;
            }
        }
    }

    renderConfirmsts = (row) => {
        const lang = this.props.language;
        return (
            <Fragment>
                <div className='text-near-active'>
                    {row === "Không" ? <FormattedMessage id="match-history.no" /> : <FormattedMessage id="match-history.yes" />}
                </div>
            </Fragment>
        )
    }


    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData();
        this.setReloadData();
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }
    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onExecTypeChanged = (event) => {
        this._setState({ execType: event.target.value });
    };

    onStatusChanged = (event) => {
        this._setState({ orderStatus: event.target.value });
    };

    handlerKeyDown = (event) => {
        var eventKey = event.key;
        if (eventKey === 'Enter') {
            this.handlerSearch();
        }
    };

    handlerSearch = () => {
        this.fromDatePicker.current.close();
        this.toDatePicker.current.close();
    };

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    search = () => {
        this.setReloadData();
    }

    render() {
        const { language, accountInfo } = this.props;
        const { currentAccount, records, listAccounts, onReloadData } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false

        return (
            <RetryableContent className="match-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="match-history-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n txt---400-14-20 padding-top-0">
                                        <SelectorAccountCustomer
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group-n txt---400-14-20">
                                        {this.props.userInfo && this.props.userInfo.custodycd}
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id} ({account.entypename})</option> :
                                            (<option key={account.id} value={account.id}>{account.entypename} - {CommonUtils.formatFDS(account.id)}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-block-table p-table-10">
                        <TableExtremeCustom2
                            keyStoreUpdate="orderID" // key của row 
                            columnsDefault={isDerivative ? columnsDefaultFDS : columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="match-history-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        defaultTheme: defaultTheme
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MatchHistoryNew));
