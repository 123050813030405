import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LoadStatus } from 'constants/config';
import { ReactComponent as ReloadIconDark } from '../../../../assets/icons/ReloadIcon_Dark.svg'
import { ReactComponent as ReloadIconLight } from '../../../../assets/icons/ReloadIcon_Light.svg'

import { NumberInput2New } from "../../../../components/Input";
import { CommonUtils, FeeTypes, SELECTOR_ACCOUNT_SCREEN, stepValues, TransferType, TransferTypes, ModalConfirmType, KeyCodeUtils } from "../../../../utils";
import LanguageUtils from '../../../../utils/LanguageUtils'

import TranAuthScreenConfirm from 'containers/Header/TranAuthScreenConfirm/TranAuthScreenConfirm';
import { Fragment } from 'react';
import TextTooltip from 'components/TextToolTip';
class CashTransferDetail extends Component {

    transferTypes = TransferTypes;
    feeTypes = FeeTypes;


    state = {
        hide: false,
        descriptionInput: '',
        benefitAccountNumber: '',
    }

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
        this.successBtnRef = React.createRef();
    }

    UNSAFE_componentWillMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this), false);
        this.mounted = false
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 576);
        if (currentHideNav !== this.state.hideNav) {
            this._setState({ hide: currentHideNav });
        }
    }

    trySetBenefitAccount = () => {
        const { benefitAccounts, selectedBenefitAccount, onBenefitAccountChange, userInfo } = this.props;
        if (selectedBenefitAccount === null || selectedBenefitAccount === undefined) {

            if (userInfo.defaultBenefitAccount && userInfo.defaultBenefitAccount !== '' && benefitAccounts && benefitAccounts.length > 0) {
                let benefitAccount = null;
                benefitAccounts.forEach(element => {
                    if (element.benefitAccount === userInfo.defaultBenefitAccount) {
                        benefitAccount = element;
                    }
                });
                onBenefitAccountChange(benefitAccount ? benefitAccount : benefitAccounts[0]);
            } else if (!userInfo.defaultBenefitAccount && benefitAccounts && benefitAccounts.length > 0) {
                onBenefitAccountChange(benefitAccounts[0]);
            }
        }
    };

    onBenefitAccountChange = (event) => {
        const accountId = event.target.value;
        const { benefitAccounts, onBenefitAccountChange, transferType } = this.props;
        for (let i = 0; i < benefitAccounts.length; i++) {
            const account = benefitAccounts[i];
            if (account.benefitAccount === accountId && transferType === TransferType.EXTERNAL) {
                onBenefitAccountChange(account);
                break;
            } else if (account.accountNo === accountId && transferType === TransferType.INTERNAL) {
                onBenefitAccountChange(account);
                break;
            }
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        // this.trySetBenefitAccount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.trySetBenefitAccount();
        let { transferType, selectedAccount, selectedBenefitAccount, accountSend, accountReceived } = this.props
        if (prevProps.transferType !== transferType
            || prevProps.accountSend !== accountSend
            || prevProps.selectedBenefitAccount !== selectedBenefitAccount
            || prevProps.accountReceived !== accountReceived
        ) {
            //console.log('laojahackgame======> FILL DESCRIPTION 123')
            this.props.fillDescriptionSample();
        }

        if (prevProps.transferType !== this.props.transferType) {
            this.props.onResetDataInForm()
        }
    }

    onBenefitAccountNumberChange = (e) => {
        this.props.onBenefitAccountNumberChange(e.target.value);
    };

    onBlurTransferAmount = () => {
        this.props.onBlurTransferAmount();
    }

    genIcon = (keyIcon) => {
        const { defaultTheme } = this.props;
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? <ReloadIconDark onClick={() => { this.props.loadTransferParam() }} /> : <ReloadIconLight onClick={() => { this.props.loadTransferParam() }} />
                break;
            default:
                break;
        }
    }

    getGuideLine = () => {
        const { transferParam, intl, minwithdrawamt, onlinestart, onlineend } = this.props;
        let guideLine = { line1: '', line2: '' }
        guideLine.line1 = intl.formatMessage({ id: "account.utilities.cash-transfer.guide-line.desc-2.1.1" }, {
            amount: (Number(minwithdrawamt) && Number(minwithdrawamt) !== 0) ? CommonUtils.formatAccounting(Number(minwithdrawamt)) : (minwithdrawamt === 0 ? minwithdrawamt : ''),
        });
        guideLine.line2 = intl.formatMessage({ id: "account.utilities.cash-transfer.guide-line.desc-6.1" }, {
            fromTime: onlinestart ? onlinestart : '',
            toTime: onlineend ? onlineend : '',
        });
        return guideLine;
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    onHandleKeyDownConfirm = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.props;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (loadStatus === LoadStatus.LOADING) return;
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownSuccess = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.successBtnRef.current && this.successBtnRef.current.click();
        }
    }

    render() {
        const {
            onDescriptionChangeInput,
            feeType, onFeeTypeChange, onBenefitAccountNumberChange,
            transferType, checkTransferInfo, accountSend, setAccountSend, setAccountReceived, accountReceived, filterAccount,
            selectedBenefitAccount, benefitAccounts,
            onTransferTypeChange, transferParam,
            transferAmount, description,
            onTransferAmountChange, transferAmountValid,
            accounts, InternalMobileNearestControl, ExternalMobileNearestControl, refresh, language,
            preCheckInfoInput, defaultTheme, curCustomer, isBroker, userInfo,
            step, setStep, authData
        } = this.props;

        let guideLines = this.getGuideLine();

        return (
            <div className="row gutters-5">
                <div className={"cashtran-container " + (transferType === TransferType.EXTERNAL && step === 1 ? 'external' : '')}>
                    {step === 1 && (
                        <div className='block'>

                            {transferType === TransferType.INTERNAL &&
                                <Fragment>
                                    <div className='headB---700-16-24 title'>
                                        <FormattedMessage id="account.utilities.cash-transfer.info-transaction" />
                                    </div>


                                    {/* Loại giao dịch */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.cash-transfer.transaction-type" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group">
                                                {/*<select value={transferType} onChange={onTransferTypeChange} className="custom-form-control txt---400-14-20">
                                    {this.transferTypes && this.transferTypes.map((transferType) => {
                                        return (
                                            <FormattedMessage key={transferType} id={'account.utilities.cash-transfer.transfer-type.' + transferType}>
                                                {(txt) => (
                                                    <option value={transferType}>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        )
                                    })}
                                </select>*/}
                                                <input className='custom-form-control' value={`${LanguageUtils.getMessageByKey('account.utilities.cash-transfer.transfer-type.' + transferType, this.props.language)}`} readOnly />
                                            </div>
                                        </div>
                                    </div>


                                    {/* Tài khoản - môi giới*/}
                                    {isBroker && (
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="stock-transfer.account" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <SelectorAccountCustomer
                                                    curCustomer={curCustomer}
                                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                                    disabled={false}
                                                    className="custom-form-control"
                                                />
                                            </div>
                                        </div>)}

                                    {/* Tài khoản */}
                                    {!isBroker && (
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="stock-transfer.account" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group txt---400-14-20">
                                                    <input className='custom-form-control' value={userInfo.custodycd || ''} readOnly />
                                                </div>
                                            </div>
                                        </div>)}

                                    {/* Tiểu khoản chuyển    */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                {transferType === TransferType.INTERNAL && <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />}
                                                {transferType === TransferType.EXTERNAL && <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc-ex" />}
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group-select">
                                                <select value={accountSend ? accountSend.id : ''} onChange={setAccountSend} className="custom-form-select txt---400-14-20">
                                                    {(!accounts || accounts.length === 0 || !accountSend) && (
                                                        <FormattedMessage key="empty" id="header.choose-account">
                                                            {(txt) => (
                                                                <option key="empty" value="" disabled>{txt}</option>
                                                            )}
                                                        </FormattedMessage>
                                                    )}
                                                    {accounts && accounts.map((account) => {
                                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Số tiền chuyển tối đa */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.cash-transfer.maximum-transfer-amount" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                                <NumberInput2New
                                                    ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                                    step={stepValues.PRICE}
                                                    min={null}
                                                    max={null}
                                                    value={transferParam ? transferParam.availabletransfer : 0}
                                                    valid={true}
                                                    onChange={null}
                                                    allowZero={false}
                                                    allowDecimal={false}
                                                    allowNegative={false}
                                                    readOnly={true}
                                                    inputClassName="custom-form-control"
                                                />
                                                {this.genIcon(1)}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Nội bộ - Tiểu khoản nhận */}
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="account.utilities.cash-transfer.sub-account-received" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group-select">
                                                <select onChange={setAccountReceived} value={accountReceived ? accountReceived.id : ''} disabled={filterAccount.length <= 1} className="custom-form-select txt---400-14-20">
                                                    {/* {(!filterAccount || filterAccount.length === 0) && (
                                                        <FormattedMessage key="empty" id="header.choose-account">
                                                            {(txt) => (
                                                                <option key="empty" value="" disabled>{txt}</option>
                                                            )}
                                                        </FormattedMessage>
                                                    )} */}
                                                    {filterAccount && filterAccount.length > 1 && (
                                                        <option selected disabled></option>
                                                    )}

                                                    {filterAccount && filterAccount.map((account) => {
                                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                        // if (isBroker) {
                                                        //     return (
                                                        //         <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                        //     )
                                                        // }
                                                        // return (
                                                        //     <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                        // )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            }
                            {/* Số dư tiền mặt */}
                            {/* <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label">
                                    <FormattedMessage id="account.utilities.cash-transfer.cash-balance" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group">
                                    <FormattedPrice value={transferParam ? transferParam.cibalance : 0} />
                                </div>
                            </div>
                        </div> */}
                            {/* Tiền chờ về */}
                            {/* <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label">
                                    <FormattedMessage id="account.utilities.cash-transfer.money-waiting-to-come-back" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group">
                                    <FormattedPrice value={transferParam ? transferParam.receivingamt : 0} />
                                </div>
                            </div>
                        </div> */}
                            {transferType === TransferType.EXTERNAL && (

                                <div className='row external-info-container'>
                                    {/* Ngoài - Người chuyển */}
                                    <div className='col-12 col-lg-6 left-block'>
                                        <div className='headB---700-16-24 title custom-txt-color'>
                                            <FormattedMessage id="account.utilities.cash-transfer.sender" />
                                        </div>
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.transaction-type" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group">
                                                    {/*<select value={transferType} onChange={onTransferTypeChange} className="custom-form-control txt---400-14-20">
                                            {this.transferTypes && this.transferTypes.map((transferType) => {
                                                return (
                                                    <FormattedMessage key={transferType} id={'account.utilities.cash-transfer.transfer-type.' + transferType}>
                                                        {(txt) => (
                                                            <option value={transferType}>{txt}</option>
                                                        )}
                                                    </FormattedMessage>
                                                )
                                            })}
                                        </select>*/}
                                                    <input className='custom-form-control' value={`${LanguageUtils.getMessageByKey('account.utilities.cash-transfer.transfer-type.' + transferType, this.props.language)}`} readOnly />
                                                </div>
                                            </div>
                                        </div>


                                        {/* Tài khoản - môi giới*/}
                                        {isBroker && (
                                            <div className="row gutters-5 align-items-center h-row">
                                                <div className="col-5">
                                                    <label className="label txt---400-14-20">
                                                        <FormattedMessage id="stock-transfer.account" />
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <SelectorAccountCustomer
                                                        curCustomer={curCustomer}
                                                        selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                                        disabled={false}
                                                        className="custom-form-control"
                                                    />
                                                </div>
                                            </div>)}

                                        {/*Tài khoản*/}
                                        {!isBroker &&
                                            <div className="row gutters-5 align-items-center h-row">
                                                <div className="col-5">
                                                    <label className="label txt---400-14-20">
                                                        <FormattedMessage id="stock-transfer.account" />
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <div className="custom-form-group txt---400-14-20">
                                                        <input className='custom-form-control' value={userInfo.custodycd || ''} readOnly />
                                                    </div>
                                                </div>
                                            </div>}

                                        {/* Tiểu khoản chuyển    */}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    {transferType === TransferType.INTERNAL && <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc" />}
                                                    {transferType === TransferType.EXTERNAL && <FormattedMessage id="account.utilities.cash-transfer.sender-sub-acc-ex" />}
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group-select">
                                                    <select value={accountSend ? accountSend.id : ''} onChange={setAccountSend} className="custom-form-select txt---400-14-20">
                                                        {(!accounts || accounts.length === 0 || !accountSend) && (
                                                            <FormattedMessage key="empty" id="header.choose-account">
                                                                {(txt) => (
                                                                    <option key="empty" value="" disabled>{txt}</option>
                                                                )}
                                                            </FormattedMessage>
                                                        )}
                                                        {accounts && accounts.map((account) => {
                                                            return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Số tiền chuyển tối đa */}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.maximum-transfer-amount" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                                    <NumberInput2New
                                                        ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                                        step={stepValues.PRICE}
                                                        min={null}
                                                        max={null}
                                                        value={transferParam ? transferParam.availabletransfer : 0}
                                                        valid={true}
                                                        onChange={null}
                                                        allowZero={false}
                                                        allowDecimal={false}
                                                        allowNegative={false}
                                                        readOnly={true}
                                                        inputClassName="custom-form-control"
                                                    />
                                                    {this.genIcon(1)}
                                                </div>
                                            </div>
                                        </div>


                                        {/* Tiền mặt có thể chuyển */}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.available-cash" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <TextTooltip
                                                    placement='bottom'
                                                    tooltipText={'Tiền đã về'}
                                                    targetID={'cash-transfer-available-1'}
                                                ></TextTooltip>
                                                <div id='cash-transfer-available-1' className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                                    <NumberInput2New
                                                        ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                                        step={stepValues.PRICE}
                                                        min={null}
                                                        max={null}
                                                        value={transferParam ? transferParam.baldefovd_not_adv : 0}
                                                        valid={true}
                                                        onChange={null}
                                                        allowZero={false}
                                                        allowDecimal={false}
                                                        allowNegative={false}
                                                        readOnly={true}
                                                        inputClassName="custom-form-control"
                                                    />
                                                    {this.genIcon(1)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Ngoài - Người thụ hưởng */}
                                    <div className='col-12 col-lg-6 right-block'>
                                        <div className='headB---700-16-24 title custom-txt-color'>
                                            <FormattedMessage id="account.utilities.cash-transfer.receiver" />
                                        </div>

                                        {/* Ngoài - Số tài khoản ngân hàng */}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.number-bank" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group-select">
                                                    <select value={selectedBenefitAccount ? selectedBenefitAccount.autoid : ''} onChange={onBenefitAccountNumberChange} className="custom-form-select txt---400-14-20">
                                                        {/*(!benefitAccounts || benefitAccounts.length === 0 || !selectedBenefitAccount) && (
                                                            <FormattedMessage key="empty" id="header.choose-bank">
                                                                {(txt) => (
                                                                    <option key="empty" value="" disabled>{txt}</option>
                                                                )}
                                                            </FormattedMessage>
                                                        )*/}
                                                        <option key="empty" value="" disabled={selectedBenefitAccount && selectedBenefitAccount.benefitaccount}>--------</option>
                                                        {benefitAccounts && benefitAccounts.map((account) => {
                                                            return (<option key={account.autoid} value={account.autoid}>{account.benefitaccount}</option>)
                                                            // if (isBroker) {
                                                            //     return (
                                                            //         <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                            //     )
                                                            // }
                                                            // return (
                                                            //     <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                            // )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        {/*Tên người thụ hưởng*/}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.benefit-name" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group txt---400-14-20">
                                                    <input className='custom-form-control' value={selectedBenefitAccount ? (selectedBenefitAccount.benefitname || '') : ''} readOnly />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Ngoài - Ngân hàng thụ hưởng */}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.bank-beneficiary" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group txt---400-14-20">
                                                    <input className='custom-form-control' value={selectedBenefitAccount ? selectedBenefitAccount.benefitbankname : ''} readOnly />
                                                </div>
                                            </div>
                                        </div>


                                        {/*Chi nhánh*/}
                                        <div className="row gutters-5 align-items-center h-row">
                                            <div className="col-5">
                                                <label className="label txt---400-14-20">
                                                    <FormattedMessage id="account.utilities.cash-transfer.benefit-bankbranch" />
                                                </label>
                                            </div>
                                            <div className="col-7">
                                                <div className="custom-form-group txt---400-14-20">
                                                    <input className='custom-form-control' value={selectedBenefitAccount ? (selectedBenefitAccount.benefitbankbranch || '') : ''} readOnly />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            )}

                            {/* Ngoài - Số tài khoản ngân hàng */}
                            {/*transferType === TransferType.EXTERNAL &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.number-bank" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group">
                                            <select value={selectedBenefitAccount ? selectedBenefitAccount.benefitaccount : ''} onChange={onBenefitAccountNumberChange} className="custom-form-control txt---400-14-20">
                                                {(!benefitAccounts || benefitAccounts.length === 0 || !selectedBenefitAccount) && (
                                                    <FormattedMessage key="empty" id="header.choose-bank">
                                                        {(txt) => (
                                                            <option key="empty" value="" disabled>{txt}</option>
                                                        )}
                                                    </FormattedMessage>
                                                )}
                                                {benefitAccounts && benefitAccounts.map((account) => {
                                                    return (<option key={account.benefitaccount} value={account.benefitaccount}>{account.benefitaccount}</option>)
                                                    // if (isBroker) {
                                                    //     return (
                                                    //         <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                    //     )
                                                    // }
                                                    // return (
                                                    //     <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                                    // )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                            */}
                            {/* Ngoài - Ngân hàng thụ hưởng */}
                            {/*transferType === TransferType.EXTERNAL &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.bank-beneficiary" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group txt---400-14-20">
                                            <FormattedPrice value={selectedBenefitAccount ? selectedBenefitAccount.benefitbankname : ''} />
                                        </div>
                                    </div>
                                </div>
                            */}
                            {transferType === TransferType.EXTERNAL &&
                                <div className='headB---700-16-24 title custom-txt-color'>
                                    <FormattedMessage id="account.utilities.cash-transfer.transaction-detail" />
                                </div>
                            }

                            {/* Ngoài - Loại phí */}
                            {transferType === TransferType.EXTERNAL &&
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className={"col-2"}>
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.cash-transfer.fee-type" />
                                        </label>
                                    </div>
                                    <div className={"col-10 right-block-external"}>
                                        <div className="custom-form-group">
                                            {/*<select value={feeType} onChange={onFeeTypeChange} className="custom-form-control txt---400-14-20">
                                                {this.feeTypes && this.feeTypes.map((feeType) => {
                                                    return (
                                                        <FormattedMessage key={feeType} id={'account.utilities.cash-transfer.feetype.' + feeType}>
                                                            {(txt) => (
                                                                <option value={feeType}>{txt}</option>
                                                            )}
                                                        </FormattedMessage>
                                                    )
                                                })}
                                            </select>*/}
                                            <input className='custom-form-control' value={`${LanguageUtils.getMessageByKey('account.utilities.cash-transfer.feetype.inFee', this.props.language)}`} readOnly />
                                        </div>
                                    </div>
                                </div>
                            }


                            {/* Số tiền chuyển */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className={transferType === TransferType.INTERNAL ? "col-5" : "col-2"}>
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="account.utilities.cash-transfer.transfer-amount" />
                                    </label>
                                </div>
                                <div className={transferType === TransferType.INTERNAL ? "col-7" : "col-10 right-block-external"}>
                                    <div className="custom-form-group-effect txt---400-14-20">
                                        <NumberInput2New
                                            ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                            name="transfer-amount"
                                            step={stepValues.PRICE}
                                            min={null}
                                            max={999999999999}
                                            value={transferAmount}
                                            valid={transferAmountValid}
                                            onChange={onTransferAmountChange}
                                            allowZero={false}
                                            allowDecimal={false}
                                            allowNegative={false}
                                            className="need-focus-eff custom-form-control"
                                            onBlur={this.onBlurTransferAmount}
                                            placeholder={CommonUtils.getPlaceHolderInput(language)}
                                            onKeyDown={this.onHandleKeyDownConfirm}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Nội dung */}
                            <div className="row gutters-5 align-items-center">
                                <div className={transferType === TransferType.INTERNAL ? "col-5" : "col-2"}>
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="account.utilities.cash-transfer.content" />
                                    </label>
                                </div>
                                <div className={transferType === TransferType.INTERNAL ? "col-7" : "col-10 right-block-external"}>
                                    <div className="custom-form-group-effect txt---400-14-20 custom-form-group-textarea" >
                                        {/* <input
                                            cols="30"
                                            rows={1}
                                            // rows={transferType === TransferType.INTERNAL ? 2 : 4}
                                            className="custom-form-control descArea "
                                            value={description}
                                            onChange={onDescriptionChangeInput}
                                            maxLength={70}
                                        // placeholder="Nhập"
                                        /> */}
                                        <textarea rows="4" cols="50"
                                            className="custom-form-control descArea "
                                            autoComplete="off"
                                            value={description}
                                            onChange={onDescriptionChangeInput}
                                            maxLength={70}
                                        />
                                    </div>
                                </div>
                            </div>


                            {transferType === TransferType.EXTERNAL &&
                                <div className='guide-line'>
                                    <label className="label txt---400-14-20">
                                        {/*<p><FormattedMessage id="account.utilities.cash-transfer.guide-line.desc-2.1" /></p>*/}
                                        {/*<p><FormattedMessage id="account.utilities.cash-transfer.guide-line.desc-6" /></p>*/}
                                        <p>{guideLines.line1}</p>
                                        <p>{guideLines.line2}</p>
                                    </label>
                                </div>
                            }


                            {/* Thao tác */}
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {(!isBroker || transferType === TransferType.INTERNAL) && (
                                    <Fragment>
                                        {/* Làm mới */}
                                        <div className="col-6">
                                            <button ref={this.refreshBtnRef} className="btn-refresh txt---500-16-24 btn" onClick={() => refresh()} onKeyDown={this.onHandleKeyDownRefresh}>
                                                <FormattedMessage id="common.btn-refresh" />
                                            </button>
                                        </div>
                                        {/* Tiếp tục */}
                                        <div className="col-6">
                                            <button ref={this.confirmBtnRef} className="btn-confirm txt---500-16-24 btn" onClick={() => preCheckInfoInput()} onKeyDown={this.onHandleKeyDownConfirm}>
                                                <FormattedMessage id="common.btn-continue" />
                                            </button>
                                        </div>
                                    </Fragment>
                                )}

                                {isBroker && transferType === TransferType.EXTERNAL && (
                                    <Fragment>
                                        {/* Làm mới */}
                                        <div className="col-6">
                                            <button className="btn-refresh txt---500-16-24 btn" disabled >
                                                <FormattedMessage id="common.btn-refresh" />
                                            </button>
                                        </div>
                                        {/* Tiếp tục */}
                                        <div className="col-6">
                                            <button className="btn-confirm txt---500-16-24 btn" disabled >
                                                <FormattedMessage id="common.btn-continue" />
                                            </button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    )}


                    {step === 2 && <TranAuthScreenConfirm
                        typeInfo={transferType === TransferType.INTERNAL ? ModalConfirmType.CASH_TRANSFER_INTERNAL : ModalConfirmType.CASH_TRANSFER_EXTERNAL}
                        dataInfo={authData}
                        toggle={() => { setStep(step - 1) }} // backstep
                        onClose={() => { setStep(step - 1) }} // backstep
                    />}

                    {step === 3 && (
                        <div className='success-container'>
                            <div className='icon-container'>
                                <div className='icon'>
                                    <i className="fa fa-check"></i>
                                </div>
                            </div>
                            <div className='success-title headM---500-20-30'><FormattedMessage id="account.utilities.cash-transfer.transaction-success" /></div>
                            <div className='row glutter-5'>
                                <button ref={this.successBtnRef} className="btn-refresh txt---500-16-24 btn" onClick={() => setStep(1, true)} onKeyDown={this.onHandleKeyDownSuccess}>
                                    <FormattedMessage id="common.btn-close" />
                                </button>
                            </div>


                        </div>
                    )}
                </div>

            </div >
        );
    }

}

export default injectIntl(CashTransferDetail);
