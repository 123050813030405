import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadStatus } from "../../../../utils";
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';

let columnsDefault =
    [
        /* Rút */
        {
            dataField: "action",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false,
        },
        /* Tiểu khoản */
        {
            dataField: "afacctno",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Mã iFlex */
        {
            dataField: "acctno",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Số dư gốc */
        {
            dataField: "balance",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Lãi suất tất toán */
        {
            dataField: "flintrate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        /* Lãi suất */
        {
            dataField: "intrate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        /* Ngày tạo */
        {
            dataField: "opndate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Ngày đáo hạn */
        {
            dataField: "todate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        /* Lãi tạm tính */
        {
            dataField: "laips",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        /* Lãi TT trước hạn */
        {
            dataField: "intavlamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        /* Lãi đã nhận */
        {
            dataField: "intpaid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        /* Trạng thái */
        {
            dataField: "desc_status",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '180',
            visible: true,
            alignment: "center",
            visibleIndex: 10,
        },
    ]

class IFlexBalanceDetail extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };


    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    state = {
        ...this.initialState
    };

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'action':
                return (e) => this.renderDetail(e.row.data);
            case 'afacctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno);
            case 'acctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
            case 'balance':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.balance);
            case 'flintrate':
                return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.flintrate);
            case 'intrate':
                return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.intrate);
            case 'todate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.todate);
            case 'opndate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.opndate);
            case 'laips':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.laips);
            case 'intavlamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intavlamt);
            case 'intpaid':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intpaid);
            case 'desc_status':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.desc_status);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'action':
                return "iflex.table.withdraw-money";
            case 'afacctno':
                return "iflex.table.sub-accounts";
            case 'acctno':
                return "iflex.table.iFlex-code";
            case 'balance':
                return "iflex.table.principal-balance";
            case 'flintrate':
                return "iflex.table.closing-interest-rate";
            case 'intrate':
                return "iflex.table.interest-rate";
            case 'todate':
                return "iflex.table.date-due";
            case 'opndate':
                return "iflex.table.created-at";
            case 'laips':
                return "iflex.table.temporary-interest";
            case 'intavlamt':
                return "iflex.table.TT-interest-before-maturity";
            case 'intpaid':
                return "iflex.table.interest-received";
            case 'desc_status':
                return "iflex.table.status";
            default:
                return null;
        }
    }

    onDetail = (record) => {
        const headerCustom = {
            title: record && record.fullName,
            backScreen: "menu-sidebar.title-7.1"
        }

        let data = {
            curCustomer: record.custodycd
        }

        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: "menu-sidebar.title-7.1-extend",
            accountInfo: this.props.accountInfo,
            useCustomHeader: true,
            headerCustom,
            data: data
        })
    }


    renderDetail = (record) => {
        return (
            <button className="txt---400-12-18 btn-withdraw"
                onClick={() => this.props.toggleModalDepositWithDraw(record)}
            >
                <FormattedMessage id="iflex.table.withdraw-money" />
            </button>
        )
    }

    render() {
        const { apiLoadAll, onReloadData } = this.props

        return (

            <div className="row gutters-5" style={{ marginBottom: "10px", marginTop: "5px" }}>
                <div className='col-12'>
                    <div className='block-table p-table-10'>
                        <div className='headB---700-16-24 title'>
                            <FormattedMessage id="iflex.title-detail" />
                        </div>
                        <TableExtremeCustom2
                            keyStoreUpdate="custodycd" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="customer-account-lookup-paging" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            apiLoadAll={apiLoadAll} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>

            </div>
        )
    };
};

export default IFlexBalanceDetail;
