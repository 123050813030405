import React, { Component } from 'react';
// import CustomTableNew from 'components/Table/ReactTable/CustomTableNew';
import _ from 'lodash'
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

import config from '../../../../combineConfig';
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import { fdsInquiryService, inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, CommonWidgetUtils, execTypes, KeyCodeUtils, LoadStatus, mDate, Random, reportParams, reportTypes, Role, ToastUtil, LanguageUtils } from "../../../../utils";

import './RightOffRegisterHistory.scss';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

const rowPerPage = 200;

let columnsDefault =
    [
        /* Thời gian đăng ký */
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Loại giao dịch */
        {
            dataField: "exectype",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Tỷ lệ */
        {
            dataField: "rightoffrate",
            dataType: "string",
            allowReordering: true,
            minWidth: "50",
            width: '50',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        /* Giá mua */
        {
            dataField: "exprice",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        /* Ngày chốt quyền */
        {
            dataField: "reportdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        /* Số CK đã mua */
        {
            dataField: "qtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        /* Số tiền đã nộp */
        {
            dataField: "execamt",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "150",
            width: '12.5%',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
    ]

/* Trạng thái */
// {
//     dataField: "status",
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "150",
//     width: '12.5%',
//     visible: true,
//     alignment: "center",
//     visibleIndex: 7,
// },
class RightOffRegisterHistory extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,

        numOfPage: 1,
        gotoPage: null,

        nextIndex: 0, //nextIndex > 0 có thể next trang tiếp theo
        prevIndex: 0,
        isDisabled: false,
        listAccounts: [],
        onReloadData: true,
        currentAccount: {},                         // Thông tin tài khoản
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();

        this.fromDatePicker = React.createRef();
        this.toDatePicker = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'txdate':
                return "account.report.history.right-off-register-history.tx-date";
            case 'exectype':
                return "account.report.history.right-off-register-history.exectype";
            case 'symbol':
                return "account.report.history.right-off-register-history.symbol";
            case 'rightoffrate':
                return "account.report.history.right-off-register-history.rate";
            case 'exprice':
                return "account.report.history.right-off-register-history.price";
            case 'reportdate':
                return "account.report.history.right-off-register-history.end-date";
            case 'qtty':
                return "account.report.history.right-off-register-history.qtty";
            case 'execamt':
                return "account.report.history.right-off-register-history.execamt";
            // case 'status':
            //     return "account.report.history.right-off-register-history.status";
            default:
                return null;
        }
    }

    renderSwitch = (dataField) => {
        const { language } = this.props;
        const { currentAccount } = this.state;
        switch (dataField) {
            case 'txdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.txdate);
            case 'exectype':
                return (e) => RenderGridCell.renderGridCellCommonByLanguage(language, e.row.data.description, e.row.data.description_en);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
            case 'rightoffrate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rightoffrate);
            case 'exprice':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.exprice);
            case 'reportdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.reportdate);
            case 'qtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.qtty);
            case 'execamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.execamt);
            // case 'status':
            //     return (e) => RenderGridCell.renderGridCellCommon(e.row.data.status);
            default:
                return null;
        }
    }

    search = () => {
        this.setReloadData();
    }

    processOrderData = (data) => {
        let dataArr = data.d || [];
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let exectype_vi = LanguageUtils.getMessageByKey("trade.order.cancel", 'vi');
                let exectype_en = LanguageUtils.getMessageByKey("trade.order.cancel", 'en');
            }
            //console.log("dataArr---: ", dataArr)
            return { ...data, d: dataArr }
        } else {
            return data;
        }
    }

    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount } = this.state;
        const { userInfo } = this.props;
        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }
        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            let self = this
            let _obj = {
                custid: currentAccount ? currentAccount.id : '',
                fromDate: fromDate,
                page: obj.page,
                limitRow: obj.limitRow,
                toDate: toDate,
            }
            let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
            if (custid) {
                return inquiryService.getRightOffStatement(custid, fromDate, toDate)
                    .then((data) => {
                        data = this.processOrderData(data)
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-rightoff-register-history');
                        return []
                    });
            }

            if (currentAccount && currentAccount.accounttype === "FDS") {
                return fdsInquiryService.getOrderHistory(currentAccount.id, fromDate, toDate, rowPerPage)
                    .then((data) => {
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-rightoff-register-history');
                        return []
                    });
            }

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    genReport = (exptype) => {
        const { userInfo, currentCustomer } = this.props;
        let { fromDate, toDate, symbol, execType, orderStatus, currentAccount } = this.state;
        let custodycd = null;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL;
        execType = execType ? execType : reportParams.ALL;
        orderStatus = orderStatus ? orderStatus : reportParams.ALL;
        if (userInfo.role === Role.CUSTOMER) {
            custodycd = userInfo.custodycd;
        } if (userInfo.role === Role.BROKER && currentCustomer) {
            custodycd = currentCustomer.custodycd;
        };
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];

            rptId = 'orderHist';
            inpArr = [currentAccount.id, fromDate, toDate, symbol, execType, orderStatus, reportParams.NULL, reportParams.NULL, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        //document.addEventListener('keydown', this.handlerKeyDown);
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        this._setState({ currentAccount: filterAccount }, () => this.setReloadData())
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { socketCIAction, socketSEAction, socketODAction, language } = this.props;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction, language: prevlanguage
        } = prevProps;

        if (socketCIAction[currentAccount && currentAccount.id] !== prevSocketCIAction[currentAccount && currentAccount.id] || socketSEAction[currentAccount && currentAccount.id] !== prevSocketSEAction[currentAccount && currentAccount.id] || socketODAction[currentAccount && currentAccount.id] !== prevSocketODAction[currentAccount && currentAccount.id]) {
            this.setReloadData()
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.setReloadData()
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onExecTypeChanged = (event) => {
        this._setState({ execType: event.target.value });
    };

    onStatusChanged = (event) => {
        this._setState({ orderStatus: event.target.value });
    };

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.searchBtnRef.current && this.searchBtnRef.current.click();
        }
    };

    handlerSearch = () => {
        this.fromDatePicker.current.close();
        this.toDatePicker.current.close();
        this.loadData(0);
    };

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    render() {
        const { language, accounts } = this.props;
        const { currentAccount, onReloadData } = this.state;
        return (
            <RetryableContent className="right-off-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="right-off-history-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {/*<div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!accounts || accounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {accounts && accounts.map((account) => {
                                        return (<option key={account.id} value={account.id}>{account.entypename} - {CommonUtils.formatFDS(account.id)}</option>)
                                    })}
                                </select>
                            </div> */}
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    ref={this.fromDatePicker}
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    ref={this.toDatePicker}
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        {/* <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    <DownloadIcon />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="symbol" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="right-off-register-history-paging" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            apiLoadAll={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    // let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }

    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,
        curCustomer: curCustomer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),

    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RightOffRegisterHistory));
