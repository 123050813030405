import React, { Component } from 'react';
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CommonUtils, CommonWidgetUtils, LoadStatus, ModalConfirmType, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil, TransferStockType } from "../../../../utils";

import StockTransferDetail from './StockTransferDetail';

import './StockTransfer.scss';

class StockTransfer extends Component {

    constructor(props) {
        super(props);
        this.ExternalMobileNearestControl = React.createRef();
        this.InternalMobileNearestControl = React.createRef();
        this.TransAuthRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        transferType: TransferStockType.INTERNAL,                         //Loại giao dịch
        initFuncData: null,                         //Data truyển lên API

        //Tham số Precheck
        transactionId: '',
        tokenId: '',
        refamt: '',
        authtype: '',
        listAuthType: [],                               //Các phương thức xác thực

        accountSend: {},                                //Số tiểu khoản gửi
        accountReceived: {},                            //Số tiểu khoản nhận
        filterAccount: [],
        isOpen: false,                                  //Đóng mở Modal
        symbolTransferInfo: [],                         //Các mã chứng khoán
        selectedSymbol: null,                           //Mã chứng khoán đang được chọn
        availableTranfer: 0,                            //KLCK tối đa
        totalMass: 0,
        quantityTransfer: null,                         //KLCK chuyển
        quantityTransferValid: false,

        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,
        records: [],
        onReloadData: true,
        loadingAvl: false // Trạng thái reloading chứng khoán có thể chuyển
    };

    state = {
        ...this.initialState
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    //Load thông tin tiểu khoản
    loadBenefitAccounts() {
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.filter(item => item.id !== currentAccountId)
        let filterAccount1 = accounts.find(item => item.id === currentAccountId)
        this._setState({ filterAccount: filterAccount, accountSend: filterAccount1, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null });
    }

    //Load danh sách mã chứng khoán
    loadAvailableBalance = (refresh = false) => {
        const { accountSend, selectedSymbol } = this.state;
        if (refresh) {
            this._setState({ loadingAvl: true });
        }
        if (accountSend) {
            inquiryService.getAvailableBalanceForStockTransfer(accountSend.id, '').then((data) => {
                if (data.length > 0) {
                    const findSymbol = data.find(item => item.symbol == selectedSymbol);
                    //console.log("findSymbol--- 123: ", findSymbol, data[0])
                    this._setState({
                        symbolTransferInfo: data,
                        availableTranfer: refresh && findSymbol ? findSymbol.avl : 0,
                        totalMass: refresh && findSymbol ? findSymbol.trade : 0,
                        selectedSymbol: refresh && findSymbol ? selectedSymbol : null,
                        //selectedSymbol: null,
                        loadingAvl: false
                    }, () => {
                    });
                }
                else {
                    this._setState({
                        availableTranfer: 0,
                        totalMass: 0,
                        symbolTransferInfo: [],
                        selectedSymbol: null
                    });
                }

            })
                .catch((error) => {
                    //console.log("aaaa-1")
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        }
    }


    onResetDataInForm = () => {
        this._setState({ transferAmount: null, transferAmountValid: false });
    };

    resetForm = () => {
        this._setState({
            transferType: this.state.transferType,
            accountSend: this.state.accountSend,
            accountReceived: this.state.accountReceived,
            selectedSymbol: this.state.selectedSymbol,
            symbolTransferInfo: this.state.symbolTransferInfo,
            quantityTransfer: null,
            quantityTransferValid: false,
        }
        );
    };

    refresh = () => {
        this._setState({
            ...this.initialState,
            transferType: this.state.transferType,
            accountSend: this.state.accountSend,
            accountReceived: this.state.accountReceived,
            filterAccount: this.state.filterAccount
        }, () => {
            this.setReloadData();
        }
        );
    };



    backStep = () => {
        if (this.state.step === 2) {
            this._setState({ step: this.state.step - 1 });
            this.toggle()
        }
    };

    preCheckInfoInput = () => {
        const {
            quantityTransfer, accountSend, accountReceived, availableTranfer, selectedSymbol
        } = this.state;

        //Check tồn tại tiểu khoản nhận
        if (!accountReceived) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.002');
            return;
        }

        //Check chưa điền mã ck
        if (!selectedSymbol) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.006');
            return;
        }

        //Check tồn tại tiểu khoản nhan
        if (!accountSend) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.002');
            return;
        }

        //Check khối lượng chuyển
        if (!quantityTransfer) {
            if (quantityTransfer === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.004');
                return;
            }
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.003');
            return;
        }

        if (quantityTransfer > availableTranfer) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'stock-transfer.error.005');
            return;
        }

        this.checkTransferInfo()
    }

    checkTransferInfo = () => {
        const {
            accountSend, accountReceived
        } = this.state;

        let checkTransferInfoBody = '';
        checkTransferInfoBody = {
            "receiveAccount": accountReceived.id,
        };
        this._setState({ loadStatus: LoadStatus.LOADING });
        transferService.checkStockTransferInternal(accountSend.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, feeamt, vatamt, authtypes, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, transactionId: transactionId }, () => {
                        let _dataInfo = this.getDataInfoStockTransferInternal()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.STOCK_TRANSFER_INTERNAL,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };


    loadInitData = () => {
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts && accounts.filter(item => item.id !== currentAccountId)
        let filterAccount1 = accounts && accounts.find(item => item.id === currentAccountId)
        this._setState({
            filterAccount: filterAccount, accountSend: filterAccount1, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null
        });
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
		
	componentWillUnmount() {
        this.mounted = false
    }
    
    componentDidMount() {
        this.mounted = true
        this.loadInitData()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketSEAction, language, currentAccountId } = this.props;
        const { accountSend, accountReceived } = this.state;
        const { accountSend: prevAccountSend } = prevState;
        const { socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction, language: prevLanguage, currentAccountId: prevCurrentAccountId } = prevProps;

        if (socketCIAction[accountSend && accountSend.id] !== prevSocketCIAction[accountSend && accountSend.id]
            || socketSEAction[accountSend && accountSend.id] !== prevSocketSEAction[accountSend && accountSend.id]
            || socketSEAction[accountReceived && accountReceived.id] !== prevSocketSEAction[accountReceived && accountReceived.id]
            || socketCIAction[accountReceived && accountReceived.id] !== prevSocketCIAction[accountReceived && accountReceived.id]
        ) {
            // this.loadAvailableBalance(true)
            console.log('laojahackgame====> LOAD HERE 1')
            this.setReloadData()
        }

        if (accountSend !== prevAccountSend
        ) {
            console.log('laojahackgame====> LOAD HERE 2', { accountSend, prevAccountSend })
            // this.loadAvailableBalance()
            this.setReloadData()

        }

        if (language !== prevLanguage) {
            this.setReloadData()
        }

        if ((currentAccountId !== prevCurrentAccountId)) {
            this.loadInitData();
        }

    }

    setAccountSendByChangeHeader = (currentAccountId) => {
        const { accounts } = this.props;
        let filterAccount = accounts && accounts.filter(item => item.id !== currentAccountId)
        let filterAccount1 = accounts && accounts.find(item => item.id === currentAccountId)
        this._setState({ filterAccount: filterAccount, accountSend: filterAccount1, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null, });
    }

    //Set tiểu khoản chuyển
    setAccountSend = (event) => {
        const { accounts } = this.props;
        let filterAccount = accounts.filter(item => item.id !== event.target.value)
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this.props.setAccountIdScreen(event.target.value, SELECTOR_ACCOUNT_SCREEN.LAYOUT);
        this._setState({ accountSend: foundAccount, filterAccount: filterAccount, accountReceived: (filterAccount && filterAccount.length == 1) ? filterAccount[0] : null, quantityTransfer: null })
    };

    //Set tiểu khoản nhận
    setAccountReceived = (event) => {
        const { accounts } = this.props;
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountReceived: foundAccount, quantityTransfer: null })
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    //Chọn mã chứng khoán
    onChangeSymbolTransfer = (symbol) => {
        const { symbolTransferInfo } = this.state;
        const selectedSymbol = symbolTransferInfo.filter(item => item.symbol == symbol);
        this._setState({ totalMass: selectedSymbol[0].trade, availableTranfer: selectedSymbol[0].avl, selectedSymbol: symbol });
    }

    //Nhập số lượng mã chứng khoán
    onChangeQttySymbolTransfer = (qtty, valid) => {
        this._setState({ quantityTransfer: qtty, quantityTransferValid: valid });
    }

    //Xử lý chuyển CK nội bộ
    handleProcessStockTransferInternal = () => {
        const {
            accountReceived, accountSend, transactionId, selectedSymbol, quantityTransfer
        } = this.state;


        let transferBody = {
            "receiveAccount": accountReceived && accountReceived.id,
            "symbol": selectedSymbol,
            "qtty": quantityTransfer && quantityTransfer,
            "transactionId": transactionId,
            "accountId": accountSend && accountSend.id,
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            loadStatusScreenConfirm: LoadStatus.LOADING,
        })
        transferService.processStockTransferInternal(transferBody)
            .then((data) => {
                this._setState({ loadStatus: LoadStatus.LOADED })
                ToastUtil.success('account.utilities.cash-transfer.success', 'stock-transfer.success.001');
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    isOpenScreenConfirm: false,
                    loadStatusScreenConfirm: LoadStatus.LOADED,
                })
                this.resetForm()
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    loadStatusScreenConfirm: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'common.fail-to-can-not-make-transaction');
            });
    }

    genInfoWithTypeCash = () => {
        const { accountReceived,
            transactionId, quantityTransfer, selectedSymbol, accountSend

        } = this.state;

        let processAuthFunc = null;
        let transferBody = null;

        processAuthFunc = transferService.processStockTransferInternal;
        transferBody = {
            "receiveAccount": accountReceived && accountReceived.id,
            "symbol": selectedSymbol,
            "qtty": quantityTransfer && quantityTransfer,
            "accountId": accountSend && accountSend.id,
            // "transactionId": transactionId, // đã truyền trong phần xác thực
        };

        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    // processOrderData = (dataArr) => {
    //     let instruments = CommonUtils.getInstruments();
    //     if (dataArr.length > 0) {
    //         for (let i = 0; i < dataArr.length; i++) {
    //             let record = dataArr[i];
    //             const instrument = record.symbol ? instruments[this.getSymbolOfSymbolSpecial(record.symbol)] : {};
    //             //console.log("dataArr--- loccoc: ", instrument, record.symbol);
    //             record.closePrice = instrument['CP'] || 0
    //             if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
    //                 record.closePrice = record['basicPrice'] || 0
    //             }
    //             record['basicPrice'] = record.closePrice
    //             record.basicPriceAmt = record.basicPrice * record.totalpnl;
    //             record.pnlamt = (record.basicPriceAmt - record.costpriceamt); // Lãi lỗ
    //         }
    //         return dataArr
    //     } else {
    //         return []
    //     }
    // };

    getSymbolOfSymbolSpecial = (symbolSpecial) => {
        const config = '_WFT'
        if (symbolSpecial.endsWith(config)) {
            return symbolSpecial.replace(config, "")
        }
        return symbolSpecial
    }

    processTransfer = () => {
        this.TransAuthRef.current.processAuth();
    };

    transactionCb = () => {
        this._setState({ loadStatus: LoadStatus.LOADED })
        ToastUtil.success('account.utilities.cash-transfer.success', 'stock-transfer.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            loadStatusScreenConfirm: LoadStatus.LOADED,
        })
        this.resetForm()
    };

    //Xử lý data truyền vào redux
    getDataInfoStockTransferInternal = () => {
        const {
            loadStatus, accountSend, accountReceived, transferType,
            selectedSymbol, quantityTransfer
        } = this.state;
        const { userInfo, curCustomer } = this.props
        let _info = null;
        let username = userInfo.username;
        if (this.isBroker()) {
            username = curCustomer ? curCustomer.custodycd : '';
        }

        let { transferBody } = this.genInfoWithTypeCash()
        _info = {
            quantityTransfer: quantityTransfer,         //KLCK chuyeenr
            selectedSymbol: selectedSymbol,             //Mã chứng khoán
            accountSend: accountSend,                   //Tiểu khoản chuyển
            accountReceived: accountReceived,           //Tiểu khoản nhận
            transferType: transferType,                 //Loại giao dịch
            username: username,
            loadStatus: loadStatus,
            initFuncData: transferBody,
            // onSubmit: () => { this.handleProcessStockTransferInternal() },
            authRef: this.TransAuthRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: transferService.processStockTransferInternal,
            transactionCb: () => { this.transactionCb() },
            title: "common.confirm-trade"
        }
        return _info || {}
    }

    apiLoadAll = async (obj) => {
        try {
            const { accountSend, selectedSymbol } = this.state;
            if (accountSend) {
                return inquiryService.getAvailableBalanceForStockTransfer(accountSend.id, '').then((data) => {
                    if (data.length > 0) {
                        // return this.processOrderData(data);

                        if (data.length > 0) {
                            const findSymbol = data.find(item => item.symbol == selectedSymbol);
                            //console.log("findSymbol--- 123: ", findSymbol, data[0])
                            this._setState({
                                symbolTransferInfo: data,
                                availableTranfer: findSymbol ? findSymbol.avl : 0,
                                totalMass: findSymbol ? findSymbol.trade : 0,
                                selectedSymbol: findSymbol ? selectedSymbol : null,
                                //selectedSymbol: null,
                                loadingAvl: false
                            }, () => {
                            });
                        }
                        else {
                            this._setState({
                                availableTranfer: 0,
                                totalMass: 0,
                                symbolTransferInfo: [],
                                selectedSymbol: null
                            });
                        }

                        return data;
                    }
                    else {
                        return []
                    }
                })
                    .catch((error) => {
                        //console.log("aaaa-2")
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                        return []
                    });
            } else {
                return []
            }
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    fillSymbolData = (e) => {
        let record = e.data || {};
        this._setState({
            availableTranfer: record.avl || 0,
            totalMass: record.trade || 0,
            selectedSymbol: record.symbol || '',
        })

    }

    render() {
        const {
            loadStatus, accountSend, accountReceived, filterAccount,
            transferType, authStatus, symbolTransferInfo, selectedSymbol, availableTranfer,
            quantityTransfer, records, onReloadData, totalMass, loadingAvl
        } = this.state;

        const { accounts, userInfo, language, curCustomer } = this.props;
        const combinedStatus = authStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus
        const isBroker = this.isBroker();
        return (
            <RetryableContent status={combinedStatus} disableRetry={true} className="h-100">
                <div className="stock-transfer h-100">
                    <StockTransferDetail
                        language={language}
                        transferType={transferType}
                        accounts={accounts}
                        isBroker={isBroker}
                        accountSend={accountSend}
                        accountReceived={accountReceived}
                        setAccountSend={this.setAccountSend}
                        setAccountReceived={this.setAccountReceived}
                        filterAccount={filterAccount}
                        refresh={this.refresh}
                        symbolTransferInfo={symbolTransferInfo}
                        selectedSymbol={selectedSymbol}
                        availableTranfer={availableTranfer}
                        onChangeSymbolTransfer={this.onChangeSymbolTransfer}
                        quantityTransfer={quantityTransfer}
                        onChangeQttySymbolTransfer={this.onChangeQttySymbolTransfer}
                        preCheckInfoInput={this.preCheckInfoInput}
                        records={records}
                        username={userInfo.username}
                        curCustomer={curCustomer}
                        apiLoadAll={this.apiLoadAll}
                        onReloadData={onReloadData}
                        totalMass={totalMass}
                        fillSymbolData={this.fillSymbolData}
                        defaultTheme={this.props.defaultTheme}
                        loadAvailableBalance={this.loadAvailableBalance}
                        loadingAvl={loadingAvl}
                        loadStatus={loadStatus}
                    />
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    // let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    // let _accountInfo = _layoutPage && _layoutPage.accountInfo
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS" && !item.typename.includes("TPRL"));
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        curCustomer: curCustomer,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockTransfer);