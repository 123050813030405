import React, { Component } from 'react';
import { Role } from 'constants/config';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { ReactComponent as DownloadIconLight } from '../../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../../assets/icons/new/DownloadIcon_Dark.svg'
import config from '../../../../combineConfig';
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import TextToolTip from "../../../../components/TextToolTip";
import { fdsInquiryService, inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, KeyCodeUtils, LoadStatus, mDate, reportTypes, RPT_ID, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../../../utils";

import './CashTransactionReportNew.scss';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';

const totalDefault =
    [
        {
            column: "busdate",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
        },
        {
            column: "creditamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "debitamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]

const totalDefaultFDS =
    [
        {
            column: "busdate",
            summaryType: "min",
            displayFormat: "total",
            valueFormat: ",##0.##",
        },
        {
            column: "credit",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0.##",
        },
        {
            column: "debit",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0.##",
        },
    ]

let columnsDefault =
    [
        /* Ngày*/
        {
            dataField: "busdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // minWidth: "170",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Tăng */
        {
            dataField: "creditamt",
            dataType: "string",
            allowReordering: true,
            // minWidth: "170",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Giảm */
        {
            dataField: "debitamt",
            dataType: "string",
            allowReordering: true,
            // minWidth: "170",
            width: '200',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* số dư */
        {
            dataField: "available",
            dataType: "string",
            allowReordering: true,
            // minWidth: "160",
            width: '200',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Nội dung */
        {
            dataField: "txdesc",
            dataType: "string",
            allowReordering: true,
            // minWidth: "300",
            // width: '350',
            visible: true,
            alignment: "left",
            visibleIndex: 4,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
    ]

let columnsDefaultDerivative =
    [
        /* Tiểu khoản */
        {
            dataField: "txdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // minWidth: "170",
            // width: '250',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* SHL */
        {
            dataField: "credit",
            dataType: "string",
            allowReordering: true,
            // minWidth: "170",
            // width: '250',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Ngày */
        {
            dataField: "debit",
            dataType: "string",
            allowReordering: true,
            // minWidth: "170",
            // width: '250',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Mã CK */
        {
            dataField: "sodusau",
            dataType: "string",
            allowReordering: true,
            // minWidth: "160",
            // width: '250',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Mã CK */
        {
            dataField: "trdesc",
            dataType: "string",
            allowReordering: true,
            // minWidth: "300",
            // width: '350',
            visible: true,
            alignment: "left",
            visibleIndex: 4,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
    ]
class CashTransactionReportNew extends Component {

    state = {
        fromDate: null,
        toDate: null,
        records: [],
        loadStatus: LoadStatus.NONE,
        currentAccount: {},
        recordsDerivative: [],
        checkDerivative: false,
        onReloadData: true,
        isCoreBank: true
    };

    minDate = null;

    constructor(props) {
        super(props);
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);
        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        // this.searchBtnRef = React.createRef();
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        if (!isDerivative) {
            switch (dataField) {
                case 'busdate':
                    return (e) => (e.row.data.odrnum === 0 || e.row.data.odrnum === 2) && e.row.data.txdesc !== null ? this.renderTitleNotDerivative(e.row.data) : RenderGridCell.renderGridCellDate(e.row.data.busdate);
                case 'creditamt':
                    return (e) => (e.row.data.odrnum === 0 || e.row.data.odrnum === 2) ? null : RenderGridCell.renderGridCellPrice(e.row.data.creditamt, 'PRICE');
                case 'debitamt':
                    return (e) => (e.row.data.odrnum === 0 || e.row.data.odrnum === 2) ? null : RenderGridCell.renderGridCellPrice(e.row.data.debitamt, 'PRICE');
                case 'available':
                    return (e) => (e.row.data.odrnum === 0 || e.row.data.odrnum === 2) ? RenderGridCell.renderGridTotalCashTranReport(e.row.data.available) : RenderGridCell.renderGridCellPrice(e.row.data.available, 'PRICE');
                case 'txdesc':
                    return (e) => (e.row.data.odrnum === 0 || e.row.data.odrnum === 2) ? null : RenderGridCell.renderGridCellContent(e.row.data.txdesc);
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'txdate':
                    return (e) => e.row.data.txdate === null ? this.renderTitle(e.row.data) : RenderGridCell.renderGridCellCommon(e.row.data.txdate);
                case 'credit':
                    return (e) => e.row.data.txdate === null ? null : RenderGridCell.renderGridCellPrice(e.row.data.credit, 'PRICE');
                case 'debit':
                    return (e) => e.row.data.txdate === null ? null : RenderGridCell.renderGridCellPrice(e.row.data.debit, 'PRICE');
                case 'sodusau':
                    return (e) => e.row.data.txdate === null ? RenderGridCell.renderGridTotalCashTranReport(e.row.data.sodusau) : RenderGridCell.renderGridCellPrice(e.row.data.sodusau, 'PRICE');
                case 'trdesc':
                    return (e) => e.row.data.txdate === null ? null : RenderGridCell.renderGridCellContent(this.props.language === "vi" ? e.row.data.trdesc : e.row.data.trdesc);
                default:
                    return null;
            }
        }
    }

    returnCaption = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        if (!isDerivative) {

            switch (dataField) {
                case 'busdate':
                    return "history-cash-transaction-report.date";
                case 'creditamt':
                    return "history-cash-transaction-report.remittanceDetail-Inc";
                case 'debitamt':
                    return "history-cash-transaction-report.remittanceDetail-Decr";
                case 'available':
                    return "history-cash-transaction-report.bal";
                case 'txdesc':
                    return "history-cash-transaction-report.desc";
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'txdate':
                    return "history-cash-transaction-report.date";
                case 'credit':
                    return "history-cash-transaction-report.remittanceDetail-Inc";
                case 'debit':
                    return "history-cash-transaction-report.remittanceDetail-Decr";
                case 'sodusau':
                    return "history-cash-transaction-report.bal";
                case 'trdesc':
                    return "history-cash-transaction-report.desc";
                default:
                    return null;
            }
        }
    }

    renderTitle = (row) => {
        return (
            <div className="text-center txt-bold"><FormattedMessage id={row.trdesc === "BEGIN_CODE" ? "history-cash-transaction-report.begin" : "history-cash-transaction-report.end"} /></div>
        )
    }

    renderTitleNotDerivative = (row) => {
        return (
            <div className="text-center txt-bold"><FormattedMessage id={row.odrnum === 0 ? "history-cash-transaction-report.begin" : "history-cash-transaction-report.end"} /></div>
        )
    }

    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount } = this.state;
        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }
        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            let self = this
            let _obj = {
                custid: currentAccount.id,
                fromDate: fromDate,
                page: obj.page,
                limitRow: obj.limitRow,
                toDate: toDate,
            }
            if (currentAccount && currentAccount.accounttype === "SEC") {
                return inquiryService.getOrderHistory(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                    if (data && data.d) {
                        data = data.d
                    }
                    return data
                })
            }
            if (currentAccount && currentAccount.accounttype === "FDS") {
                return fdsInquiryService.getOrderHistory(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                    if (data && data.d) {
                        data = data.d
                    }
                    return data
                })
            }

            if (currentAccount && currentAccount.accounttype === "ALL") {
                let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
                return inquiryService.getOrderHistoryAllBase(custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                    if (data && data.d) {
                        data = data.d
                    }
                    return data
                })
            }

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    loadData = () => {
        const { fromDate, toDate, currentAccount } = this.state;
        let isCoreBank = currentAccount && currentAccount.corebank === 'Y';

        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const momentMinDate = moment(this.minDate);

        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            if (currentAccount.accounttype === "SEC") {
                this._setState({ loadStatus: LoadStatus.LOADING, checkDerivative: false });
                inquiryService.getCashStatementHistory(custid, currentAccount.id, fromDate, toDate)
                    .then((data) => {
                        this._setState({ records: data, loadStatus: LoadStatus.LOADED });
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-cash-transaction-report');
                    });
            } else {
                this._setState({ loadStatus: LoadStatus.LOADING, checkDerivative: true });
                fdsInquiryService.getCashStatementHist(currentAccount.id, fromDate, toDate)
                    .then((data) => {
                        this._setState({ recordsDerivative: data, loadStatus: LoadStatus.LOADED });
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-cash-transaction-report');
                    });
            }
        } else {
            this._setState({ records: [] });
        }
    };

    genReport = (exptype) => {
        let { fromDate, toDate, currentAccount } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];

            rptId = RPT_ID.GET_CASH_STATEMENT;
            inpArr = [custid, currentAccount.id, fromDate, toDate];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };



    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    loadInitData = () => {

        const { accounts, currentAccountId } = this.props;
        let found = accounts.find(item => item.id === currentAccountId)
        this._setState({ currentAccount: found, isCoreBank: found && found.corebank === 'Y' ? true : false },
            () => {
                // this.setReloadData();
            }
        );

        document.addEventListener('keydown', this.handlerKeyDown);
    }
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData();
        this.setReloadData("NOT_CALL_API")
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }



    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
            // this.setReloadData();
        }
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    search = () => {
        const { currentAccount } = this.state
        this._setState({
            isCoreBank: currentAccount && currentAccount.corebank === 'Y' ? true : false
        }, () => this.setReloadData()
        )
    }

    apiLoadAll = async (obj) => {
        try {
            const { fromDate, toDate, currentAccount } = this.state;

            let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

            if (!checkDateResult) {
                return [];
            }

            // const momentFromDate = moment(fromDate);
            // const momentToDate = moment(toDate);
            // const momentMinDate = moment(this.minDate);

            // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            //     return [];
            // }

            // if (momentToDate.isBefore(momentFromDate)) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            //     return [];
            // }

            // if (!this.checkDate()) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
            //     return [];
            // }
            let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid

            if (currentAccount.accounttype === "SEC") {
                //ALL Cơ sở
                return inquiryService.getCashStatementHistory(custid, currentAccount.id, fromDate, toDate)
                    .then((data) => {
                        // if (data && data.length) {
                        //     data = data.filter(item => item.autoid !== 0 && item.busdate !== "null")
                        // }
                        if (data && data.length > 2) {
                            data[0].autoid = "top"
                            data[data.length - 1].autoid = "bottom"
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-cash-transaction-report');
                        return []
                    });
            }
            else {
                return fdsInquiryService.getCashStatementHist(currentAccount.id, fromDate, toDate)
                    .then((data) => {
                        if (data && data.length) {
                            data = data.filter(item => item.trdesc !== null || item.txdate !== null)
                        }
                        if (data && data.length > 2) {
                            data[0].loc = "top"
                            data[data.length - 1].loc = "bottom"
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-cash-transaction-report');
                        return []
                    });
            }
            return []
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    customStyleTopAndBottomSticky = (e) => {
        // //console.log('Haki1.:customStyleTopAndBottomSticky', e)
        // let dataSource = e.component.getDataSource()
        // let itemsLength = []
        // if (dataSource) {
        //     itemsLength = (dataSource._items).length
        // }
        //console.log("e.rowType===", e.data)
        if (e.rowType === 'data') {
            if (e.data && e.data.autoid == "top") {
                e.rowElement.className = e.rowElement.className + ' sticky-0';
            }
            else if (e.data && e.data.autoid == "bottom") {
                e.rowElement.className = e.rowElement.className + ' sticky-1';
            }
            else if (e.data && e.data.trdesc == "END_CODE") {
                e.rowElement.className = e.rowElement.className + ' sticky-0';
            }
            else if (e.data && e.data.trdesc == "BEGIN_CODE") {
                e.rowElement.className = e.rowElement.className + ' sticky-1';
            }
            // else {
            //     //console.log('TopAndBottomSticky_2', itemsLength, e.rowIndex)
            //     if (e.rowIndex === (itemsLength - 1)) {
            //         e.rowElement.className = e.rowElement.className + ' last-row';
            //     }
            // }
        }
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.searchBtnRef.current && this.searchBtnRef.current.click();
        }
    };

    render() {
        const { accounts, accountInfo } = this.props;
        const { currentAccount, checkDerivative, onReloadData, isCoreBank } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false;
        let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        fullName = CommonUtils.getDisplayName(fullName);
        return (
            <RetryableContent className="cash-transaction-report report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="cash-transaction-report-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!accounts || accounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {accounts && accounts.map((account) => {
                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                    })}
                                </select>
                            </div>
                            {isBroker && <div className="custom-form-group-n fullname">
                                <div className='align-self-center txt---400-14-20'>
                                    {fullName}
                                </div>
                            </div>}
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown} ref={this.searchBtnRef}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        {!isCoreBank &&
                            <div className="export-container text-right">
                                <div className="dropdown">
                                    <TextToolTip
                                        tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                        targetID='toolTipExportBtn'
                                    ></TextToolTip>
                                    <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                        {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                        <FormattedMessage id='common.export-to-pdf'>
                                            {(txt) => (
                                                <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                            )}
                                        </FormattedMessage>
                                        <FormattedMessage id='common.export-to-excel'>
                                            {(txt) => (
                                                <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                            )}
                                        </FormattedMessage>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="afacctno" // key của row 
                            columnsDefault={isDerivative ? columnsDefaultDerivative : columnsDefault} // ds cột
                            totalDefault={isDerivative ? totalDefaultFDS : totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="cash-transaction-all" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                            isCustomTopAndBottomSticky={true}
                            customStyleTopAndBottomSticky={this.customStyleTopAndBottomSticky}
                            showFilter={false}
                        />
                    </div>
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const userInfo = state.user.userInfo;
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        userInfo: userInfo,
        defaultTheme: defaultTheme,
        isDeskTop: state.app.isDeskTop,
        customerFullName: state.user.customerFullName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashTransactionReportNew));