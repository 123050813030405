import React, { Component } from 'react';

import { LoadStatus } from "../../../../utils";

import CustomRowItem from './../../../../components/CustomRowItem/CustomRowItem';
import DraggableModal from './../../../../components/Modal/DraggableModal';

import "./RightOffDetailModal.scss"
class RightOffDetailModal extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    state = {
        ...this.initialState
    };

    render() {
        const {
            isOpen, toggle, listDetailDeposit, onClose, dataView
        } = this.props;
        let titleId = "Quyền mua"
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={onClose}
                className='right-off-detail-modal'
                titleId={titleId}
                titleIdCheck={true}
            >
                <div className="body">
                    <div className="info-detail-symol">
                        <div className="row gutters-5">
                            <div className="col-12 col-sm-6 col-style">
                                <CustomRowItem
                                    showLabel="Mã CK"
                                    showValue={dataView.symbol}
                                />
                                <CustomRowItem
                                    showLabel="Ngày đăng ký cuối cùng"
                                    showValue={dataView.reportdate}
                                    isDate={true}
                                />
                                <CustomRowItem
                                    showLabel="Tỉ lệ CP sở hữu/Quyền"
                                    showValue={dataView.exrate}
                                />
                                <CustomRowItem
                                    showLabel="Tỉ lệ Quyền/CP được mua"
                                    showValue={dataView.rightoffrate}
                                />
                                <CustomRowItem
                                    showLabel="Ngày bắt đầu chuyển nhượng"
                                    showValue={dataView.frdatetransfer}
                                    isDate={true}
                                />
                                <CustomRowItem
                                    showLabel="Ngày bắt đầu đăng ký mua"
                                    showValue={dataView.begindate}
                                    isDate={true}
                                />
                                <CustomRowItem
                                    showLabel="Số dư sở hữu tại ngày chốt"
                                    showValue={dataView.trade}
                                    isPrice={true}
                                />
                                <CustomRowItem
                                    showLabel="Khối lượng CK được mua ban đầu"
                                    showValue={dataView.maxqtty}
                                    isCustomLabel={true}
                                    isCustomValue={true}
                                    classCustomLabel="col-7 p-0"
                                    classCustomValue="col-5 p-0"
                                    isQtty={true}
                                />
                                <CustomRowItem
                                    showLabel="Khối lượng CK đã đăng ký mua"
                                    showValue={dataView.suqtty}
                                    isQtty={true}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-style">
                                <CustomRowItem
                                    showLabel="Mã quyền VSD"
                                    showValue={dataView.camastid}
                                />
                                <CustomRowItem
                                    showLabel="Loại chứng khoán"
                                    showValue={dataView.sectypedesc}
                                />
                                <CustomRowItem
                                    showLabel="Giá đặt mua"
                                    showValue={dataView.exprice}
                                    isPrice={true}
                                />
                                <CustomRowItem
                                    showLabel="Mệnh giá"
                                    showValue={dataView.parvalue}
                                    isPrice={true}
                                />
                                <CustomRowItem
                                    showLabel="Ngày kết thúc chuyển nhượng"
                                    showValue={dataView.todatetransfer}
                                    isDate={true}
                                />
                                <CustomRowItem
                                    showLabel="Ngày kết thúc đăng ký mua"
                                    showValue={dataView.duedate}
                                    isDate={true}
                                />
                                <CustomRowItem
                                    showLabel="KL quyền nhận chuyển nhượng"
                                    showValue={dataView.inbalance}
                                    isQtty={true}
                                />
                                <CustomRowItem
                                    showLabel="KL quyền đã chuyển nhượng"
                                    showValue={dataView.outbalance}
                                    isQtty={true}
                                />
                                <CustomRowItem
                                    showLabel="Khối lượng còn được mua"
                                    showValue={dataView.avlqtty}
                                    isQtty={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        )
    };
};

export default RightOffDetailModal;