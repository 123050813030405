import React, { Component } from 'react';
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";

import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";

import * as actions from '../../../../store/actions'
import { LanguageUtils, CommonUtils, LoadStatus, mDate, Role, ToastUtil, KeyCodeUtils, } from "../../../../utils";
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';

import { inquiryService } from 'services';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';

import './ConfirmOrderBroker.scss';

const rowPerPage = 200;

const columnsDefault =
    [
        {
            dataField: "custodycd", // Tài khoản
            dataType: "string",
            allowReordering: true,
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "fullname", // Tên
            dataType: "string",
            allowReordering: true,
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "cnt", // số lượng lệnh chưa xác nhận
            dataType: "number",
            allowReordering: true,
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "mobilesms",     /* Số điện thoại */
            dataType: "string",
            allowReordering: true,
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "refullname",  /* Tên MG */
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            // minWidth: "100",
            // width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
    ]

const totalDefaultAll =
    [

    ]

class ConfirmOrderBroker extends Component {
    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        loadStatus: LoadStatus.NONE,
        listAccounts: [],
        listCareBy: [
            {
                tlid: "ALL",
                tlname: "ALL"
            }
        ],
        brokerAccountSelected: 'ALL',
        onReloadData: true,
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = moment(currentDate).subtract(60, 'days').toDate();
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'custodycd':
                return "confirm-order-online.custodycd";
            case 'fullname':
                return "confirm-order-online.fullname";
            case 'cnt':
                return "confirm-order-online.cnt";
            case 'mobilesms':
                return "confirm-order-online.mobilesms";
            case 'refullname':
                return "confirm-order-online.refullname";
            default:
                return null;
        }
    }

    renderSwitch = (dataField) => {
        switch (dataField) {
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'fullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.fullname);
            case 'cnt':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.cnt);
            case 'mobilesms':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.mobilesms);
            case 'refullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.refullname);
            default:
                return null;
        }
    }

    search = () => {
        const { brokerAccountSelected } = this.state
        if (brokerAccountSelected) {
            this.setReloadData();
        }
    }

    processOrderData = (data) => { // convert data phái sinh giống cơ sở
        let dataArr = data.d || [];
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                element.count = 1
            }
            //console.log("dataArr---: ", dataArr)
            return { ...data, d: dataArr }
        } else {
            return data;
        }
    };


    apiPaging = async (obj) => {
        const { fromDate, toDate, brokerAccountSelected } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const lang = this.props.language;
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        try {
            const custid = brokerAccountSelected

            const { page, limitRow } = obj

            return inquiryService.getConfirmOrdersBroker({ custid, fromDate, toDate, page, limitRow }).then((data) => {
                if (data && data.d) {
                    // data = data.d
                    data = this.processOrderData(data)
                }
                return data
            })
                .catch((error) => {
                    ToastUtil.errorApi(error);
                    return []
                });
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    // run only once when component is mounted
    loadInitData = (check) => {
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;
        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : newRow, listAccounts: found },
            () => { this.setReloadData(); });
    };

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    getCareByInGroup = async () => {
        await inquiryService.getCareByInGroup().then(data => {
            if (data && data.length !== 0) {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }, ...data]
                })
            } else {
                this._setState({
                    listCareBy: [{
                        tlid: "ALL",
                        tlname: "ALL"
                    }]
                })
                ToastUtil.error('confirm-order-online.fail-to-load-list-broker');
            }
        }).catch(error => {
            ToastUtil.errorApi(error, 'confirm-order-online.fail-to-load-list-broker');
        })
    }

    setBrokerAccountSelected = (e) => {
        this._setState({
            brokerAccountSelected: e.target.value
        })
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData();
        this.getCareByInGroup()
        this.setReloadData("NOT_CALL_API")
    }

    componentWillUnmount() {
        this.mounted = false
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData();
            this.loadInitData(true)
        }

        if (this.isBroker() === true && Array.isArray(this.props.curCustomer) !== true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
            this.setReloadData();
        }

    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };


    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    defaultColumn = {
        headerClassName: "text-center",
    }

    handlerKeyDownSearch = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    render() {
        const { language } = this.props;
        const { onReloadData, listCareBy, brokerAccountSelected } = this.state;
        return (
            <RetryableContent className="confirm-order-broker report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="confirm-order-broker-content">

                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label'><FormattedMessage id="confirm-order-online.broker-account-filter" /></div>
                                <select value={brokerAccountSelected ? brokerAccountSelected : ''} onChange={this.setBrokerAccountSelected} className="custom-form-control" autoFocus={true}>
                                    {listCareBy && listCareBy.map((broker) => {
                                        return (
                                            <option key={broker.tlid} value={broker.tlid}>{broker.tlid} - {broker.tlname !== "ALL" ? broker.tlname : LanguageUtils.getMessageByKey("common.ALL", language)}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDownSearch}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className='h-100 p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="custodycd" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefaultAll} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="confirm-order-broker-table" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            // apiLoadAll={this.apiLoadAll} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAllByPagingRes={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                            // onSelectionChanged={this.onSelectionChanged}
                            isCustomTopAndBottomSticky={false}
                            // addActionToSelection={this.addBtnConfirm}

                            // one column total
                            isOneColumnTotal={true}
                            columnNameCount={columnsDefault[0].dataField}
                            idLabelForColumnTotal="confirm-order-online.total"

                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        lang: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        language: state.app.language,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfirmOrderBroker));

