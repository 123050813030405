import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import './ModalActionMethodCondition.scss';
import DraggableModal from '../../../../components/Modal/DraggableModal';
import { ACTION_METHOD_CONDITION } from './../../../../utils/constants';
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";

const listActionMethodCondition = [
    {
        title: "action-method-condition.final_matched_price",
        value: ACTION_METHOD_CONDITION.FINAL_MATCH_PRICE,
    },
    {
        title: "action-method-condition.top_wait_watch",
        value: ACTION_METHOD_CONDITION.TOP_WAIT_MATCH,
    },
]

class ModalActionMethodCondition extends Component {

    initialState = {
        actionSelect: ACTION_METHOD_CONDITION.FINAL_MATCH_PRICE
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { actionMethodConditon } = this.props;
        this._setState({ actionSelect: actionMethodConditon })
    }

	componentWillUnmount() {
        this.mounted = false
    }

    handleCancel = () => {
        const { onClose } = this.props;
        onClose()
    }

    handleConfirm = () => {
        const { actionSelect } = this.state
        const { setActionMethodCondition, onClose } = this.props;
        setActionMethodCondition(actionSelect)
        onClose()
    }

    onChangeActionMethod = (e) => {
        this._setState({ actionSelect: e.target.value })
    }

    render() {
        const { actionSelect } = this.state
        const { isOpen, onClose, actionMethod } = this.props;

        let _className = 'modal-action-method'
        let titleId = 'action-method-condition.title'

        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
                toggle={onClose}
            >
                <div className="body">
                    <div className="modal-property row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="action-method-condition.title" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-select-option">
                                <select
                                    onChange={(e) => this.onChangeActionMethod(e)}
                                    className="custom-control-select-option"
                                >
                                    {listActionMethodCondition && listActionMethodCondition.map((item, key) => {
                                        return (
                                            <FormattedMessage key={key} id={item.title}>
                                                {(txt) => (
                                                    <option key={key} value={item.value} selected={actionSelect == item.value ? true : false}>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                            // <option key={key} value={item.value} selected={actionSelect == item.value ? true : false}>
                                            //     <FormattedMessage id={item.title} />
                                            //     {/* <FormattedMessage key="empty" id={item.title}>
                                            //         {(txt) => (
                                            //             <option key="empty" value="" disabled>{txt}</option>
                                            //         )}
                                            //     </FormattedMessage> */}
                                            // </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-property row gutters-5 align-items-center h-row container-btn">
                        <div className="col-5">
                            <button className="btn-refresh txt---500-16-24 btn" onClick={() => this.handleCancel()} >
                                <FormattedMessage id="common.btn-cancel-new" />
                            </button>
                        </div>
                        <div className="col-7">
                            <button
                                className="btn-confirm txt---500-16-24 btn" onClick={() => this.handleConfirm()} >
                                <FormattedMessage id="common.btn-confirm" />
                            </button>
                        </div>
                    </div>
                </div>

            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {

    return {
        actionMethodConditon: state.user.userInfo.actionMethodConditon
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActionMethodCondition: (data) => dispatch(actions.setActionMethodCondition(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActionMethodCondition);