import React, { Component } from 'react';
import { FormattedPercentage, FormattedPrice } from 'components/Formating';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';

import { LoadStatus } from "../../../../utils";

import "./ModalDetail.scss"
class ModalDetail extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    state = {
        ...this.initialState
    };

    render() {
        const {
            isOpen, toggle, listDetailDeposit
        } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="detail-modal"
            >
                <div className="custom-header">
                    <div className=" headB---700-20-30">
                        <FormattedMessage id="iflex.term-details" />
                    </div>
                    <div className="btn-icon-fm" onClick={toggle}>
                        <i className="fal fa-times"></i>
                    </div>
                </div>
                <ModalBody className="custom-body">
                    {/* Kỳ hạn */}
                    <div className="row gutters-5 align-items-center h-row">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="iflex.period" />
                        </label>
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="iflex.amount-of-money" />
                        </label>
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="iflex.interest-rate-year" />
                        </label>
                    </div>
                    {listDetailDeposit.map((item, index) => (
                        <div key={index} className="row gutters-5 align-items-center h-row">
                            <label className="label d-flex color-body">
                                <div>{item.frterm && item.frterm !== 0 ? item.frterm : ''}</div>&nbsp;
                                <div>{item.comp && item.comp !== 0 ? item.comp : ''}</div>&nbsp;
                                <div>{item.toterm}</div>&nbsp;
                                <div>{item.termcd}</div>
                            </label>
                            <label className="label color-body d-flex">
                                <FormattedMessage id="iflex.from" />&nbsp;
                                <FormattedPrice value={item.framt} />&nbsp;
                                - &nbsp;
                                {
                                    item.toamt === 0 &&
                                    <div>
                                        <FormattedPrice value={Math.floor(0)} />
                                    </div>

                                }
                                {
                                    item.toamt === 1000000000 &&
                                    <div>
                                        <FormattedPrice value={Math.floor(1)} />&nbsp;
                                        <FormattedMessage id="iflex.bil" />
                                    </div>

                                }
                                {
                                    item.toamt > 1000000000 &&
                                    <div>
                                        <FormattedPrice value={Math.floor(item.toamt / 1000000000)} />&nbsp;
                                        <FormattedMessage id="iflex.bil" />
                                    </div>

                                }
                                {
                                    item.toamt === 1000000 &&
                                    <div>
                                        <FormattedPrice value={1} />&nbsp;
                                        <FormattedMessage id="iflex.mil" />
                                    </div>

                                }
                                {
                                    (1000000 < item.toamt && item.toamt < 1000000000) &&
                                    <div>
                                        <FormattedPrice value={Math.floor(item.toamt / 1000000)} />&nbsp;
                                        <FormattedMessage id="iflex.mil" />
                                    </div>
                                }
                            </label>
                            <label className="label color-body">
                                <FormattedPercentage value={item.intrate} />
                                %
                            </label>
                        </div>
                    ))}
                    <div className="row gutters-5 align-items-center container-btn">
                        {/* Đóng */}
                        <div className="col-12">
                            <button className="btn-refresh txt---500-16-24 btn"
                                onClick={toggle}
                            >
                                <FormattedMessage id="common.btn-close" />
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal >

        )
    };
};

export default ModalDetail;