import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService, userService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CashAdvanceType, CommonUtils, LoadStatus, ModalConfirmType, Role, ToastUtil } from "../../../../utils";
import CashInAdvanceDetail from './CashInAdvanceDetail';
import CashModal from '../CashTransferNew/CashModal';

import './CashInAdvance.scss';

const globalVar = window._env_
class CashInAdvance extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        cashAdvanceType: CashAdvanceType.ADVANCE,                         //Loại giao dịch
        initFuncData: null,                         //Data truyển lên API

        accountSend: null,                                //Số tiểu khoản gửi
        isOpen: false,                                  //Đóng mở Modal

        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,

        maxadvanceamt: 0,                               //Số tiền ứng trước tối đa
        maxreceiveamt: 0,                               // Số tiền nhận tối đa
        feeAmount: 0,                                   //Phí ứng
        totalAmount: 0,                                 //Tổng tiền ứng
        advanceAmount: null,                            //Số tiền ứng
        advanceAmountValid: false,                       //Check số tiền ứng có giá trị không
        checkAgree: false,
        isOpenModalRules: false,
        pendingPayment: [],
        onReloadData: true,
        accounts: [],
        pdf_url: '',
        page: null,
        warningDescMessage: ''
    };

    BASE_URL = globalVar.app.ROUTER_BASE_NAME != null ? '/' + globalVar.app.ROUTER_BASE_NAME : '/';

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
		
	componentWillUnmount() {
        this.mounted = false
    }  

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    // Lấy thông tin điều khoản
    loadTermPdf = () => {
        userService.getClientConfig()
            .then((data) => {
                this._setState({ pdf_url: data.configData.uttb });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    onToggleOpenModalRules = () => {
        const { isOpenModalRules } = this.state;
        this._setState({ isOpenModalRules: !isOpenModalRules });
    }

    //Load thông tin tiểu khoản
    loadBenefitAccounts() {
        const { accounts } = this.props;
        this._setState({ accountSend: accounts[0] });
    }

    onAdvanceAmountChange = (amount, valid) => {
        this._setState({ advanceAmount: amount, advanceAmountValid: valid });
    };

    onCheckAgree = () => {
        const { checkAgree } = this.state;
        this._setState({ checkAgree: !checkAgree });
    };

    onAdvanceAmountBlur = (event) => {
        const { advanceAmount, accountSend } = this.state;
        if (accountSend && advanceAmount) {
            this.getAdvanceFee(advanceAmount, accountSend);
        }
        if (!advanceAmount) {
            this._setState({ feeAmount: 0 });
        }
    };

    getAdvanceFee = (advanceAmount, accountSend, cb = null) => {
        if (parseFloat(advanceAmount) && parseFloat(advanceAmount) !== 0 && accountSend) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getAllocateAdvance(accountSend.id, Number(advanceAmount))
                .then((result) => {
                    const allocatedRecords = _.filter(result, (element) => {
                        return element.type === 'allocate';
                    });
                    const allocatedFee = _.find(result, (element) => {
                        return element.type === 'fee';
                    });
                    let totalAllocatedAmount = 0;
                    allocatedRecords.forEach((record) => {
                        totalAllocatedAmount += record.amt;
                    });
                    let totalActualReceiving = totalAllocatedAmount + allocatedFee.amt;
                    this._setState({
                        feeAmount: allocatedFee.amt,
                        // advanceAmount: totalAllocatedAmount,
                        totalAmount: totalActualReceiving,
                        loadStatus: LoadStatus.LOADED
                    }, () => {
                        cb && cb();
                    })
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.bad-request');
                });
        } else {
            this._setState({
                feeAmount: 0,
                // advanceAmount: totalAllocatedAmount,
                totalAmount: 0
            }, () => {
                cb && cb();
            })
        }
    }

    refresh = () => {
        this._setState({
            cashAdvanceType: this.state.cashAdvanceType,
            accountSend: this.state.accountSend,
            accounts: this.state.accounts,
            advanceAmount: null,
            advanceAmountValid: false,
            feeAmount: 0,
            checkAgree: false
        }, () => {
            this.loadAllData()
        }
        );
    };

    refreshOnSignal=()=>{
        this._setState({
            cashAdvanceType: this.state.cashAdvanceType,
            accountSend: this.state.accountSend,
            accounts: this.state.accounts,
            advanceAmount: this.state.advanceAmount,
            advanceAmountValid: this.state.advanceAmountValid,
            feeAmount: this.state.feeAmount,
            checkAgree: this.state.checkAgree
        }, () => {
            this.loadAllData()
        }
        );
    }


    preCheckInfoInput = () => {
        const {
            advanceAmount, checkAgree, maxreceiveamt
        } = this.state;


        //Check khối lượng chuyển
        if (!advanceAmount) {
            if (advanceAmount === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'cash-in-advance.error.004');
                return;
            }
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'cash-in-advance.error.003');
            return;
        }

        //Check khối lượng chuyển
        if (advanceAmount > maxreceiveamt) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'cash-in-advance.error.005');
            return;
        }

        // //Check khối lượng chuyển
        if (!checkAgree) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'cash-in-advance.error.006');
            return;
        }
        this.checkTransferInfo(true)
    }

    //Đóng mở Popup xác nhận
    toggleConfirm = (warning_desc = '') => {
        this._setState({
            isOpen: !this.state.isOpen,
            warningDescMessage: warning_desc
        });
    }

    checkTransferInfo = (isCheckWarning) => {
        const {
            accountSend, advanceAmount
        } = this.state;

        let checkTransferInfoBody = '';
        checkTransferInfoBody = {
            "advanceAmt": advanceAmount
        };
        this._setState({ loadStatus: LoadStatus.LOADING });
        let precheckCb = () => {
            transferService.checkAdvanced(accountSend.id, checkTransferInfoBody)
                .then((result) => {
                    if (Object.keys(result).length > 0) {
                        const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, fee, vat, challengeQuestion, verified, warning_code, warning_desc } = result;
                        if (warning_code && warning_code != 0 && isCheckWarning) { //Check cảnh báo
                            this.toggleConfirm(warning_desc);
                            this._setState({ loadStatus: LoadStatus.LOADED });
                            return;
                        }
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })

                        this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, transactionId: transactionId }, () => {
                            let _dataInfo = this.getDataInfoCashTransfer()
                            this.props.updateConfirmCheckAuth({
                                typeInfo: ModalConfirmType.CASH_IN_ADVANCE,
                                dataInfo: _dataInfo,
                            })

                            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                isOpenScreenConfirm: true,
                                isCheckAuth2: true,
                            })
                        });

                    } else {
                        ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                    }

                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.bad-request');
                });
        }

        this.getAdvanceFee(advanceAmount, accountSend, precheckCb);
    };

    loadAllData = () => {
        this.loadCashInAdvanceInfo()
        this.loadPendingPayment()
        this.setReloadData()
        this.loadTermPdf()
    }


    componentDidMount() {
        this.mounted = true
        fetch(this.BASE_URL + `html/rule-advance-vi.html`)
            .then(result => {
                return result.text()
            })
            .then(html => {
                this._setState({ page: html });
            });
        this.loadAllCashInAdvance()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction } = this.props;
        const { accountSend } = this.state;
        const { accountSend: prevAccountSend } = prevState;
        const { socketCIAction: prevSocketCIAction } = prevProps;

        if (socketCIAction[accountSend && accountSend.id] !== prevSocketCIAction[accountSend && accountSend.id]) {
            this.refreshOnSignal()
        }

        if (accountSend !== prevAccountSend) {
            this.loadAllData()
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadAllCashInAdvance()
        }
    }

    //Set tiểu khoản chuyển
    setAccountSend = (event) => {
        const { accounts } = this.state;
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountSend: foundAccount })
        this.onResetDataInForm();
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    genInfoWithTypeCash = () => {
        const { accountSend, advanceAmount, feeAmount, totalAmount } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        let sotienungtruoc = 0
        if (Number(advanceAmount) + Number(feeAmount) < Number(totalAmount)) {
            sotienungtruoc = advanceAmount
        } else {
            sotienungtruoc = Number(totalAmount) - Number(feeAmount)
        }
        processAuthFunc = transferService.processCashAdvance;
        transferBody = {
            "advanceAmt": sotienungtruoc,
            "accountId": accountSend && accountSend.id,
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    loadAllCashInAdvance = async () => {
        const { accounts, currentAccountId } = this.props;
        const isBroker = this.isBroker();
        let filterAccount = accounts && accounts.find(item => item.id === currentAccountId)
        this._setState({ accounts: accounts, accountSend: isBroker ? accounts[0] : filterAccount, advanceAmount: null,  advanceAmountValid: false, feeAmount: 0, checkAgree: false }, () => {
            this.loadAllData()
        })
    }

    // Gọi ở đây để lấy ra trường đã đăng ký ứng trước tự động hay chưa
    loadCashInAdvanceInfo = () => {
        const { accountSend } = this.state;
        this._setState({ loadStatus: LoadStatus.LOADING });
        if (accountSend) {
            inquiryService.getInfoForAdvance(accountSend.id)
                .then((data) => {
                    let dataToSet = data[0] || {};
                    const { receivingamt, advamt_manual } = dataToSet
                    this._setState({ maxadvanceamt: receivingamt || 0, loadStatus: LoadStatus.LOADED, maxreceiveamt: advamt_manual || 0 });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.LOADED });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        } else {
            this._setState({ loadStatus: LoadStatus.LOADED });
        }
    }

    // Lấy thông tin tiền bán chờ về
    loadPendingPayment = () => {
        const { accountSend } = this.state;
        this._setState({ loadStatus: LoadStatus.LOADING });

        if (accountSend) {
            inquiryService.getPendingPayment(accountSend.id)
                .then((data) => {
                    this._setState({ pendingPayment: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    this._setState({ loadStatus: LoadStatus.FAIL });
                });
        } else {
            this._setState({ loadStatus: LoadStatus.LOADED });
        }
    }

    apiLoadAll = async (obj) => {
        try {
            const { accountSend } = this.state;
            if (accountSend) {
                return inquiryService.getPendingPayment(accountSend.id)
                    .then((data) => {
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    //Xử lý data truyền vào redux
    getDataInfoCashTransfer = () => {
        const { userInfo } = this.props;
        const {
            loadStatus, accountSend,
            cashAdvanceType, feeAmount, totalAmount, advanceAmount
        } = this.state;
        let _info = null

        let { processAuthFunc, transferBody } = this.genInfoWithTypeCash()

        _info = {
            cashAdvanceType: cashAdvanceType,                       //Loại giao dịch
            accountSend: accountSend,                               //Tiểu khoản
            advanceAmount: advanceAmount,                           //Số tiền ứng trước
            feeAmount: feeAmount,                                   //Phí ứng
            totalAmount: totalAmount,                               //Tổng tiền ứng
            loadStatus: loadStatus,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            custodycd: userInfo.custodycd || '',
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            // refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCb() },
            setLoadStatus: (status) => { this.setLoadStatus(status) },
            title: "common.confirm-trade",
        }
        return _info || {}
    }

    transactionCb = () => {
        this._setState({ checkAgree: false, isOpen: false, feeAmount: 0 });
        this.onResetDataInForm()
        ToastUtil.success('account.utilities.cash-transfer.success', 'cash-in-advance.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };


    onResetDataInForm = () => {
        this._setState({ advanceAmount: null, advanceAmountValid: false, feeAmount: 0 });
    };



    render() {
        //console.log("state init --- : ", this.state)
        const {
            pdf_url, page,
            loadStatus, accountSend, accountReceived, isOpenModalRules,
            authStatus, availableTranfer, checkAgree, onReloadData, accounts,
            advanceAmountValid, advanceAmount, cashAdvanceType, maxadvanceamt, pendingPayment, maxreceiveamt,
            feeAmount, isOpen
        } = this.state;
        const { language, userInfo } = this.props;
        const combinedStatus = authStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus

        const isBroker = this.isBroker();
        const checkEmptyAccount = accounts.length === 0 ? true : false
        return (
            <RetryableContent status={combinedStatus} disableRetry={true} className="h-100">
                <div className="cash-in-advance h-100">
                    <CashInAdvanceDetail
                        language={language}
                        accounts={accounts}
                        isBroker={isBroker}
                        accountSend={accountSend}
                        accountReceived={accountReceived}
                        setAccountSend={this.setAccountSend}
                        refresh={this.refresh}
                        availableTranfer={availableTranfer}
                        preCheckInfoInput={this.preCheckInfoInput}
                        onAdvanceAmountChange={this.onAdvanceAmountChange}
                        onAdvanceAmountBlur={this.onAdvanceAmountBlur}
                        advanceAmountValid={advanceAmountValid}
                        advanceAmount={advanceAmount}
                        cashAdvanceType={cashAdvanceType}
                        maxadvanceamt={maxadvanceamt}
                        maxreceiveamt={maxreceiveamt}
                        onCheckAgree={this.onCheckAgree}
                        checkAgree={checkAgree}
                        isOpenModalRules={isOpenModalRules}
                        onToggleOpenModalRules={this.onToggleOpenModalRules}
                        pendingPayment={pendingPayment}
                        apiLoadAll={this.apiLoadAll}
                        onReloadData={onReloadData}
                        pdf_url={pdf_url}
                        checkEmptyAccount={checkEmptyAccount}
                        page={page}
                        userInfo={userInfo}
                        feeAmount={feeAmount}
                        getAdvanceFee={this.getAdvanceFee}
                        curCustomer={this.props.curCustomer}
                        loadStatus={loadStatus}
                    />
                    {
                        isOpen &&
                        <CashModal
                            isOpen={isOpen}
                            toggle={this.toggleConfirm}
                            checkTransferInfo={this.checkTransferInfo}
                            warningDescMessage={this.state.warningDescMessage}
                        ></CashModal>
                    }
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    let _accountInfo = _layoutPage && _layoutPage.accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        socketCIAction: state.socketRealtime.socketCIAction,
        curCustomer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CashInAdvance);