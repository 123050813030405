import config from '../combineConfig'

const globalVar = window._env_
export const ProtradeVersion = process.env.VERSION;
export const needToShowReleaseNote = false;
export const usingUATApi = true;
export const usingStagingApi = false;
export const usingUATPriceService = false;
export const usingUATOnlineTrading = true;
export const NPSPeriod = '2018-Q4';
export const enableBond = true;
export const useWatchlistV2 = true;
export const enableCCQ = true;

export const DchartUrl = 'https://fss.com.vn/';

export const Api = {
	FINFO: config.BASE_URL,
	SCREENER: '',
	TRADE: config.BASE_URL,
	TRADE_BO: usingUATApi ? '' : usingStagingApi ? '' : '',
	TRADE_REPORT: '',
	AUTH: usingUATApi ? '' : '',
	NOTI: '',
	NOTI_V2: '',
	PERMISSION: usingUATApi ? '' : '',
	WATCHLIST: (usingUATApi ? '' : '') + (useWatchlistV2 ? '/v2' : ''),
	CAREORDER: '',
	I18N: '',
	PRODUCT: '',
	TRANSACTION: ''
};

export const app = {
	IS_CHECK_TRADE_QTTY: config.app.IS_CHECK_TRADE_QTTY,
};

export const uatPriceServiceUrl = globalVar.api.API_MARKET_URL;
// export const uatPriceServiceUrl = 'https://online.bvsc.com.vn'; // Hardcode dữ liệu PRO BVSC

export const ProductPages = {
	CUSTOMER_PROFILE: usingUATApi ? 'https://fss.com.vn' : 'https://fss.com.vn',
	OTP: usingUATApi
		? `https://fss.com.vn/js/otp.js?t=${new Date().getTime()}`
		: `https://fss.com.vn/js/otp.js?t=${new Date().getTime()}`
};

export const GoogleAppScripts = {
	OTCService: usingUATApi
		? 'https://script.google.com/macros/s/AKfycbzwKfkMCCMZ4piNFsot0EY0MI2RDNk6_oXTsewL2-6fjqtuPQ/exec'
		: 'https://script.google.com/macros/s/AKfycbyf2vEs1QEZuAtD5CePUgzqPW_LtMH6faC0Kok8g7jGMC9EDg/exec',
	NPSService:
		'https://script.google.com/macros/s/AKfycbzLnnXW_tOWwMoXGNMph_Bkf_lZmoGUJrSGEqW-CEHcFpvLajhJ/exec',
	ScreenerService:
		'https://script.google.com/macros/s/AKfycbzGFRbqjFguMY3riSZBPUKmXmtHBDfpiyUWNJDz5cOkw7SLBHL5/exec'
};

export const UserId = {
	ForgotPassword: usingUATApi ? '' : '',
	ChangePassword: usingUATApi ? '' : ''
};

export const BROKER_PAGE_URL = '';

export const PriceService = {
	ALL_ODDLOT_STOCK: '/datafeed/oddLotInstruments',
	SECINFO_URL: '/datafeed/instruments',
	// MARKET_INFO_URL: '/priceservice/market/snapshot/q=',
	// MARKET_INFO_URL: '/datafeed/indexsnaps',
	PREV_TRADING_DATE_URL: '/datafeed/prevTradingDate',
	// MARKET_HISTORY_URL: '/priceservice/market/history/q=',
	MARKET_HISTORY_URL: '/datafeed/chartinday',
	GET_TIME_MARKET_HISTORY: '/datafeed/indexs/getTime',
	CEIL_FLOOR_COUNT_URL: '/priceservice/ceilingfloorcount/snapshot/',
	PUT_THROUGH_ORDERS_URL: '/priceservice/ptorder/history/',
	AD_ORDERS_URL: '/priceservice/adorder/history/',
	DERIVATIVE_URL: '/priceservice/derivative/snapshot/q=',
	DERIVATIVE_TRANSACTIONS_URL: '/priceservice/derivative/transactions/q=',
	ALL_TRANSLOG: '/datafeed/alltranslogs',
};

export const FloorCodes = {
	HNX: '02',
	HOSE: '10',
	UPCOM: '04',
	VN30: '30',
	DERIVATIVE_INDEX: '03',
	HNX30: 'HNX30',
	VNXALLSHARE: '13',
	DERIVATIVE_VN30: 'DER01',
	DERIVATIVE: 'XHNF',

	'02': 'HNX',
	'2': 'HNX',
	'10': 'HOSE',
	'04': 'UPCOM',
	'4': 'UPCOM',
	'11': 'VN30',
	'12': 'HNX30',
	'13': 'VNXALLSHARE',

	getFullList() {
		return ['10', '11', '12', '13', '02', '03'];
	}
};
export const ListIndexNotDrawChart = ['DER'];
export const ListIndexHOSE = 'HOSE,30';
export const ListIndexHNX = 'HNX,HNX30,UPCOM';
export const ListIndex = 'HOSE,30,HNX,HNX30,UPCOM,DER'
// export const IndexName = {
// 	'02': 'HNX-INDEX',
// 	'2': 'HNX-INDEX',
// 	'10': 'VN-INDEX',
// 	'03': 'UPCOM',
// 	'3': 'UPCOM',
// 	'11': 'VN30-INDEX',
// 	'12': 'HNX30-INDEX',
// 	'13': 'VNXAllShare'
// };

export const IndexName = {
	'HNX': 'HNX-INDEX',
	'HOSE': 'VN-INDEX',
	'UPCOM': 'UPCOM',
	'30': 'VN30-INDEX',
	'HNX30': 'HNX30-INDEX',
	'ALL': 'VNXAllShare'
};

export const SortTypes = {
	NONE: 'none', // no sort
	ASC: 'asc', // sort ascending
	DESC: 'desc', // sort descending

	changeType: {
		// switch sort type when continuously clicking a column
		none: 'desc',
		desc: 'asc',
		asc: 'desc'
	}
};

export const OrderTypes = {
	HOSE: {
		ato: ['LO', 'ATO', 'ATC'],
		continuous: ['LO', 'MP', 'ATC'],
		intermission: ['LO', 'MP', 'ATC'],
		atc: ['LO', 'ATC'],
		putThrough: ['LO', 'ATO'],
		closed: ['LO', 'ATO', 'ATC']
	},
	HNX: {
		continuous: ['LO', 'ATC', 'MOK', 'MAK', 'MTL'],
		intermission: ['LO', 'ATC', 'MOK', 'MAK', 'MTL'],
		atc: ['LO', 'ATC'],
		putThrough: ['PLO'],
		closed: ['LO', 'ATC', 'MOK', 'MAK', 'MTL', 'PLO']
	},
	UPCOM: {
		continuous: ['LO'],
		intermission: ['LO'],
		closed: ['LO']
	},
	CONDITIONAL: {
		stop: ['LIMIT']
	},
	all: ['LO', 'ATC', 'ATO', 'MP', 'MOK', 'MAK', 'MTL', 'PLO']
};

export const Event = {
	TOGGLE_MENU_DETAIL: 'TOGGLE_MENU_DETAIL',
	ON_INIT_DAY: 'ON_INIT_DAY',
	ADD_DEFAULT_ITEM_FOR_TOUR: 'ADD_DEFAULT_ITEM_FOR_TOUR',
	SAVE_PORTFOLIO_FOR_TOUR: 'SAVE_PORTFOLIO_FOR_TOUR',
	// PRICE_SERVICE_ACTIVE: 'PRICE_SERVICE_ACTIVE',
	// PRICE_SERVICE_PAUSE: 'PRICE_SERVICE_PAUSE',
	SHOW_POPUP_10_BUOC_GIA: 'SHOW_POPUP_10_BUOC_GIA',
	PRICE_SERVICE_READY: 'PRICE_SERVICE_READY',
	LOGGED_IN: 'LOGGED_IN',
	LOGGED_IN_SSO: 'LOGGED_IN_SSO',
	LOG_IN_FAILED: 'LOG_IN_FAILED',
	LOGGED_OUT: 'LOGGED_OUT',
	RECEIVE_STOCKS_LIST: 'RECEIVE_STOCKS_LIST',
	UPDATE_STOCK_REALTIME: 'UPDATE_STOCK_REALTIME',
	UPDATE_BID_ASK_REALTIME: 'UPDATE_BID_ASK_REALTIME',
	UPDATE_TRADE_REALTIME: 'UPDATE_TRADE_REALTIME',
	STOCKS_LIST_RENDERED: 'STOCKS_LIST_RENDERED',
	OPEN_RECOMMEND_POPUP: 'OPEN_RECOMMEND_POPUP',
	CLOSE_RECOMMEND_POPUP: 'CLOSE_RECOMMEND_POPUP',
	OPEN_LOGIN_POPUP: 'OPEN_LOGIN_POPUP',
	OPEN_STEP2AUTH_POPUP: 'OPEN_STEP2AUTH_POPUP',
	OPEN_STEP2AUTH_POPUP_HIDE_CLOSE_BTN: 'OPEN_STEP2AUTH_POPUP_HIDE_CLOSE_BTN',
	OPEN_TOT_POPUP: 'OPEN_TOT_POPUP',
	REGISTER_TOT_SUCCESS: 'REGISTER_TOT_SUCCESS',
	OPEN_CLEAR_OTHER_DEVICE_POPUP: 'OPEN_CLEAR_OTHER_DEVICE_POPUP',
	REFRESH_TOKEN_DONE: 'REFRESH_TOKEN_DONE',
	STEP2_AUTHENTICATED_FOR_PLACE_ORDER: 'STEP2_AUTHENTICATED_FOR_PLACE_ORDER',
	STEP2_AUTHENTICATED_FOR_TRQM: 'STEP2_AUTHENTICATED_FOR_TRQM',
	TOT_AUTHENTICATED_FOR_EDIT_ORDER: 'TOT_AUTHENTICATED_FOR_EDIT_ORDER',
	TOT_AUTHENTICATED_FOR_CANCEL_ORDER: 'TOT_AUTHENTICATED_FOR_CANCEL_ORDER',
	MARKET_INFO_LOADED: 'MARKET_INFO_LOADED',
	// UPDATE_LATEST_MARKET_INFO: 'UPDATE_LATEST_MARKET_INFO',
	UPDATE_LATEST_CEIL_FLOOR_COUNT: 'UPDATE_LATEST_CEIL_FLOOR_COUNT',
	LOAD_WATCHLIST: 'LOAD_WATCHLIST',
	REORDER_SYMBOL_FROM_WATCHLIST: 'REORDER_SYMBOL_FROM_WATCHLIST',
	ADD_SYMBOL_TO_BOARD: 'ADD_SYMBOL_TO_BOARD',
	ADD_SYMBOL_SUCCESS: 'ADD_SYMBOL_SUCCESS',
	REMOVE_SYMBOL_SUCCESS: 'REMOVE_SYMBOL_SUCCESS',
	HIGHLIGHT_SYMBOL_ON_BOARD: 'HIGHLIGHT_SYMBOL_ON_BOARD',
	LOAD_COVERED_WARRANT_BOARD: 'LOAD_COVERED_WARRANT_BOARD',
	REMOVE_SYMBOL_FROM_WATCHLIST: 'REMOVE_SYMBOL_FROM_WATCHLIST',
	RENAME_WATCHLIST: 'RENAME_WATCHLIST',
	REMOVE_SYMBOL_FROM_BOARD: 'REMOVE_SYMBOL_FROM_BOARD',
	CHANGE_TAB: 'CHANGE_TAB',
	CHANGE_THEME: 'CHANGE_THEME',
	FILL_PLACE_ORDER: 'FILL_PLACE_ORDER',
	FILL_PRICE_TO_PLACE_ORDER: 'FILL_PRICE_TO_PLACE_ORDER',
	FILL_PRICE_TO_PLACE_DERIVATIVE_ORDER:
		'FILL_PRICE_TO_PLACE_DERIVATIVE_ORDER',
	FILL_PLACE_DERIVATIVE_ORDER: 'FILL_PLACE_DERIVATIVE_ORDER',
	FILL_SYMBOL_TO_PLACE_ORDER: 'FILL_SYMBOL_TO_PLACE_ORDER',
	FILL_SYMBOL_TO_PLACE_DERIVATIVE_ORDER:
		'FILL_SYMBOL_TO_PLACE_DERIVATIVE_ORDER',
	CHANGE_ACTIVE_ACCOUNT: 'CHANGE_ACTIVE_ACCOUNT',
	CHANGE_TO_PLACEORDER_ACCOUNT_INFO_TAB:
		'CHANGE_TO_PLACEORDER_ACCOUNT_INFO_TAB',
	CHANGE_TO_PLACEORDER_SHORT_PORTFOLIO_INFO_TAB:
		'CHANGE_TO_PLACEORDER_SHORT_PORTFOLIO_INFO_TAB',
	CHANGE_TO_PLACEORDER_ORDERBOOK_INFO_TAB:
		'CHANGE_TO_PLACEORDER_ORDERBOOK_INFO_TAB',
	TOGGLE_PLACE_ORDER_FORM: 'TOGGLE_PLACE_ORDER_FORM',
	HIDE_PLACE_ORDER_FORM: 'HIDE_PLACE_ORDER_FORM',
	NOTI_ORDER: 'NOTI_ORDER',
	NOTI_COUNTER: 'NOTI_COUNTER',
	NOTI_CROSS_ORDER: 'NOTI_CROSS_ORDER',
	NOTI_CAREORDER: 'NOTI_CAREORDER',
	NOTI_DERIVATIVE_ORDER: 'NOTI_DERIVATIVE_ORDER',
	FIRE_NOTIFICATION: 'FIRE_NOTIFICATION',
	SHOW_SECINFO_POPUP: 'SHOW_SECINFO_POPUP',
	CLOSE_SECINFO_POPUP: 'CLOSE_SECINFO_POPUP',
	ADD_SYMBOLS_TO_WATCH_LIST: 'ADD_SYMBOLS_TO_WATCH_LIST',
	LOAD_PUT_THROUGH_ORDERS: 'LOAD_PUT_THROUGH_ORDERS',
	LOAD_AD_ORDERS: 'LOAD_AD_ORDERS',
	FILL_SELL_PORTFOLIO_SYMBOL: 'FILL_SELL_PORTFOLIO_SYMBOL',
	FILL_EDIT_ORDER: 'FILL_EDIT_ORDER',
	OPEN_AD_POPUP: 'OPEN_AD_POPUP',
	CLOSE_AD_POPUP: 'CLOSE_AD_POPUP',
	OPEN_FEEDBACK_STOCKSCREENER: 'OPEN_FEEDBACK_STOCKSCREENER',
	CLOSE_FEEDBACK_STOCKSCREENER: 'CLOSE_FEEDBACK_STOCKSCREENER',
	TOGGLE_INDEX_CHARTS: 'TOGGLE_INDEX_CHARTS',
	TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
	CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
	CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
	CHANGE_BOARD_TYPE: 'CHANGE_BOARD_TYPE',
	CLOSE_MULTISELL_POPUP: 'CLOSE_MULTISELL_POPUP',
	PLACE_MULTISELL_SUCCESS: 'PLACE_MULTISELL_SUCCESS',
	PLACE_MULTISELL_FAIL: 'PLACE_MULTISELL_FAIL',
	RELOAD_PORTFOLIO: 'RELOAD_PORTFOLIO',
	OPEN_SELL_CONFIRMATION_POPUP: 'OPEN_SELL_CONFIRMATION_POPUP',
	CLOSE_SELL_CONFIRMATION_POPUP: 'CLOSE_SELL_CONFIRMATION_POPUP',
	RELOAD_DERIVATIVE_ORDER_CONFIRMATION:
		'RELOAD_DERIVATIVE_ORDER_CONFIRMATION',
	SET_LOAN_LIMIT_DONE: 'SET_LOAN_LIMIT_DONE',
	LOAD_PORTFOLIO_FILTER: 'LOAD_PORTFOLIO_FILTER',
	HIGHT_LIGHT_PROVISION: 'HIGHT_LIGHT_PROVISION',
	CLOSE_FILTERS_MODAL: 'CLOSE_FILTERS_MODAL',
	RELOAD_TERM_DEPOSIT_WITHDRAW_HISTORY: 'RELOAD_TERM_DEPOSIT_WITHDRAW_HISTORY',
	RECEIVE_PUSH_DATA: 'RECEIVE_PUSH_DATA',
	ON_SOCKET_CI_ACTION: 'ON_SOCKET_CI_ACTION',
	ON_SOCKET_SE_ACTION: 'ON_SOCKET_SE_ACTION',
	ON_SOCKET_OD_ACTION: 'ON_SOCKET_OD_ACTION',
	RECEIVE_PUSH_DATA: 'RECEIVE_PUSH_DATA',
	LOGOUT_BY_OTHER: 'LOGOUT_BY_OTHER',
	CHANGE_CUSTOMER_INFO_EVENTTYPE: 'CHANGE_CUSTOMER_INFO_EVENTTYPE',
	CHANGE_PASSWORD_INFO_EVENTTYPE: 'CHANGE_PASSWORD_INFO_EVENTTYPE',
	CHANGE_ACCOUNT_INFO_EVENTTYPE: 'CHANGE_ACCOUNT_INFO_EVENTTYPE',
	SEARCH_IN_ALL_TAB: "SEARCH_IN_ALL_TAB",
	UPDATE_OL_STOCK_PARTIAL_REALTIME: 'UPDATE_OL_STOCK_PARTIAL_REALTIME',
	SET_DERIVATIVE_STOCKS_LIST: 'SET_DERIVATIVE_STOCKS_LIST',
	ON_CHANGE_SYMBOL_TRADE_BROKER: 'ON_CHANGE_SYMBOL_TRADE_BROKER',
	SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL_: 'SET_SYMBOL_DETAIL_PRICE_CHART_SHOW_FULL_',
	RELOAD_BLB_ORDER_LIST: 'RELOAD_BLB_ORDER_LIST',
	UPDATE_LIST_MARKET_INDEX: 'UPDATE_LIST_MARKET_INDEX',
	UPDATE_LATEST_MARKET_INFO_MARKET_DETAIL: 'UPDATE_LATEST_MARKET_INFO_MARKET_DETAIL',
};

export const serverUtcOffset = 420;

export const MIN_SCREEN_WIDTH_TO_SHOW_PLACEORDER = 1000;

export const PagePermissions = {
	DAT_LENH: ['TRADE'],
	SO_LENH: ['VIEW', 'TRADE', 'TRANSFER'],
	SO_LENH_GOC: ['VIEW', 'TRADE'],
	DANH_MUC: ['VIEW', 'TRADE', 'TRANSFER'],
	TAI_SAN: ['VIEW', 'TRADE', 'TRANSFER'],
	CHUYEN_TIEN: ['TRANSFER'],
	BAO_CAO_LAI_LO: ['VIEW'],
	HO_TRO_LAI_SUAT: ['TRANSFER'],
	UNG_TRUOC_TIEN_BAN: ['TRANSFER'],
	XAC_NHAN_PHIEU_LENH: ['TRANSFER'],
	DANG_KY_QUYEN_MUA: ['TRANSFER'],
	TRA_CUU_QUYEN: ['VIEW', 'TRADE', 'TRANSFER'],
	LICH_SU_MUA_BAN: ['VIEW', 'TRADE'],
	SAO_KE_TRUC_TUYEN: ['VIEW', 'TRADE', 'TRANSFER'],
	SAN_PHAM_DICH_VU: []
};

export const PortfolioDefault = usingUATApi
	? {
		CPGiaTri: {
			id: 26
		},
		CPTangTruong: {
			id: 24
		},
		CPSinhLoi: {
			id: 25
		}
	}
	: {
		CPGiaTri: {
			id: 14
		},
		CPTangTruong: {
			id: 16
		},
		CPSinhLoi: {
			id: 18
		}
	};

export const ScrennerAverageProfit = usingUATApi
	? {
		'24': 25,
		'25': 68,
		'26': 72
	}
	: {
		'16': 25,
		'18': 68,
		'14': 72
	};

export const Role = { CUSTOMER: 'C', BROKER: 'B' };

export const STOCKTYPE = { BOND: '1', ETF: '3', CW: '4', DERIVATIVE: '4', TPDN: '12' };
export const orderActions = { BUY: 'NB', SELL: 'NS', MS: 'MS', DEFAULT: 'DEFAULT' };
export const orderTypesKey = { limit: 'limit', market: 'market', stop: 'stop' };

export const subOrderTypes = ['ATO', 'ATC', 'MAK', 'MOK', 'MTL', 'PLO', 'MP'];
export const fullSubOrderTypes = { ATO: 'ATO', ATC: 'ATC', MAK: 'MAK', MOK: 'MOK', MTL: 'MTL', PLO: 'PLO', MP: 'MP', LO: 'LO' };

export const LoadStatus = { NONE: 'NONE', LOADING: 'LOADING', LOADED: 'LOADED', FAIL: 'FAIL' };
export const EXCHANGE = { HOSE: 'HOSE', HNX: 'HNX', UPCOM: 'UPCOM', DERIVATIVE: 'XHNF' };

export const modules = {
	ORDINPUT: 'ORDINPUT', COND_ORDER: 'COND_ORDER', GROUP_ORDER: 'GROUP_ORDER',
	CASHTRANS: 'CASHTRANS', REPURCHARSE: 'REPURCHARSE', ADWINPUT: 'ADWINPUT',
	ISSUEINPUT: 'ISSUEINPUT', MORTGAGE: 'MORTGAGE', BONDSTOSHARES: 'BONDSTOSHARES',
	TERMDEPOSIT: 'TERMDEPOSIT', STOCKTRANS: 'STOCKTRANS'
};
export class KeyCodeUtils {

	static UP = 38;

	static DOWN = 40;

	static TAB = 9;

	static ENTER = 13;

	static E = 69;

	static ESCAPE = 27;

	static isNavigation(e) {
		return (e >= 33 && e <= 40) || e === 9 || e === 8 || e === 46 || e === 14 || e === 13;
	}

	static isNumeric(e) {
		//e === 231 fix lỗi khi sử dụng VIE của win 10
		return (e >= 48 && e <= 57) || (e >= 96 && e <= 105) || (e === 231);
	}
	static isAlphabetic(e) {
		return (e >= 65 && e <= 90);
	}
	static isDecimal(e) {
		return e === 190 || e === 188 || e === 108 || e === 110;
	}

	static isDash(e) {
		return e === 109 || e === 189;
	}
}
export const lifeTime = 700;

export const marketWatchList = {
	liquidity: { id: 'liquidity' }
}

/**
 * sortField: ten truong dung de sort
 * colGroupClass: className cua col group
 * headerClass: className cua header
 * visible: config ẩn hiện các cột
 * group: xem thuộc cột nào với các cột merge
 * toggle: các cột toggle,index: nhóm toggle
 */

//mac dinh
export const defaultColumnConfig = {
	'symbol': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'show-on-mobile col-symbol',
		headerClass: 'show-on-mobile sortable',
		sortField: 'code',
		titleId: "priceboard.header.symbol"
	},
	'tc': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'show-on-mobile col-price',
		headerClass: 'cell-highlight sortable',
		sortField: 'basicPrice',
		titleId: "priceboard.header.tc"
	},
	'ceiling': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'show-on-mobile col-price',
		headerClass: 'cell-highlight sortable',
		sortField: 'ceilingPrice',
		titleId: "priceboard.header.ceil"
	},
	'floor': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'show-on-mobile col-price',
		headerClass: 'cell-highlight sortable',
		sortField: 'floorPrice',
		titleId: "priceboard.header.floor"
	},
	'bidSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.bidSide"
	},
	'bidPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "bidPrice03",
		titleId: "priceboard.header.prc3",
		group: 'bidSide',
	},
	'bidQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "bidQtty03",
		titleId: "priceboard.header.vol3",
		group: 'bidSide',
	},
	'bidPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "bidPrice02",
		titleId: "priceboard.header.prc2",
		group: 'bidSide'
	},
	'bidQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "bidQtty02",
		titleId: "priceboard.header.vol2",
		group: 'bidSide'
	},
	'bidPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "bidPrice01",
		titleId: "priceboard.header.prc1",
		group: 'bidSide'
	},
	'bidQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "bidQtty01",
		titleId: "priceboard.header.vol1",
		group: 'bidSide'
	},

	'match': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.match",
	},
	'matchPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "cell-highlight sortable",
		sortField: "matchPrice",
		titleId: "priceboard.header.price",
		group: "match"
	},
	'matchQtty': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol col-vol-sm',
		headerClass: "cell-highlight sortable",
		sortField: "matchQtty",
		titleId: "priceboard.header.matchVol",
		group: "match"
	},
	//giao diện phức tạp cần case
	'priceDiff': {
		group: "match",
		colGroupClass: 'col-diff',
		rowSpan: 1,
		colSpan: 1,
		visible: true,
		titleId: "priceboard.header.priceDiff",
		toggle: {
			"%": {
				visible: true,
				titleId: "priceboard.header.%"
			},
			"+/-": {
				visible: true,
				titleId: "priceboard.header.+/-"
			}
		}
	},
	'askSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.askSide"
	},
	'askPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "offerPrice01",
		titleId: "priceboard.header.prc1",
		group: 'askSide'
	},
	'askQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "offerQtty01",
		titleId: "priceboard.header.vol1",
		group: 'askSide'
	},
	'askPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "offerPrice02",
		titleId: "priceboard.header.prc2",
		group: 'askSide'
	},
	'askQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "offerQtty02",
		titleId: "priceboard.header.vol2",
		group: 'askSide'
	},
	'askPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "sortable",
		sortField: "offerPrice03",
		titleId: "priceboard.header.prc3",
		group: 'askSide'
	},
	'askQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "offerQtty03",
		titleId: "priceboard.header.vol3",
		group: 'askSide'
	},
	//giao dien phuc tap can case
	'accumulated': {
		colGroupClass: "col-vol col-vol-custom",
		rowSpan: 2,
		colSpan: 2,
		visible: true,
		titleId: "priceboard.header.accumulated",
		toggle: {
			"accumulatedVal": {
				visible: true,
				titleId: 'priceboard.header.totalVal',
			},
			"accumulatedVol": {
				visible: true,
				titleId: 'priceboard.header.totalVol',
			}
		}
	},
	'prices': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.prices",
	},
	'high': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "cell-highlight sortable",
		sortField: "highestPrice",
		titleId: "priceboard.header.highestPrice",
		group: 'prices'
	},
	'average': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "cell-highlight sortable",
		sortField: "avgPrice",
		titleId: "priceboard.header.averagePrice",
		group: 'prices'
	},
	'low': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-price',
		headerClass: "cell-highlight sortable",
		sortField: "lowestPrice",
		titleId: "priceboard.header.lowestPrice",
		group: 'prices'
	},
	'remain': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.remain",
	},
	'bid': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "bv4",
		titleId: "priceboard.header.bid",
		group: 'remain',
	},
	'ask': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-vol',
		headerClass: "sortable",
		sortField: "sv4",
		titleId: "priceboard.header.ask",
		group: 'remain',
	},
	//giao dien phuc tap can case de render
	'foreign': {
		titleId: 'priceboard.header.foreign',
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		toggle: {
			"buyForeignQtty": {
				visible: true,
				titleId: "priceboard.header.bought",
			},
			"sellForeignQtty": {
				visible: true,
				titleId: "priceboard.header.sold",
			},
			"currentRoom": {
				visible: true,
				titleId: "priceboard.header.room"
			}
		}
	}
};
//phai sinh
export const defaultDeriativeColumnConfig = {
	'symbol': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-2',
		headerClass: 'sortable',
		sortField: 'code',
		titleId: "priceboard.header.derSymbol"
	},
	'lastTradingDate': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-small-1',
		headerClass: 'sortable',
		sortField: 'code',
		titleId: "priceboard.header.lastTradingDate"
	},
	'tc': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'basicPrice',
		titleId: "priceboard.header.tc"
	},
	'ceiling': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'ceilingPrice',
		titleId: "priceboard.header.ceil"
	},
	'floor': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'floorPrice',
		titleId: "priceboard.header.floor"
	},
	'openInterest': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'sortable',
		sortField: 'openInterest',
		titleId: "priceboard.header.openInterest"
	},
	'derBidSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derBidSide"
	},
	'bidPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derBidSide',
	},
	'bidQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derBidSide',
	},
	'bidPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derBidSide'
	},
	'bidQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derBidSide'
	},
	'bidPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derBidSide'
	},
	'bidQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derBidSide'
	},

	'match': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.match",
	},
	'matchPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchPrice",
		titleId: "priceboard.header.price",
		group: "match"
	},
	'matchQtty': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchQtty",
		titleId: "priceboard.header.matchVol",
		group: "match"
	},
	'changeRaw': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "changeRaw",
		titleId: "priceboard.header.+/-",
		group: "match"
	},
	'derAskSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derAskSide"
	},
	'askPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derAskSide'
	},
	'askQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derAskSide'
	},
	'askPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derAskSide'
	},
	'askQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derAskSide'
	},
	'askPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derAskSide'
	},
	'askQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derAskSide'
	},
	'accumulatedVol': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "accumulatedVol",
		titleId: "priceboard.header.totalVol",
	},
	'diffirence': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		titleId: "priceboard.header.diffirence"
	},
	'prices': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.prices",
	},
	'high': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "highestPrice",
		titleId: "priceboard.header.highestPrice",
		group: 'prices'
	},
	'openPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "avgPrice",
		titleId: "priceboard.header.openPrice",
		group: 'prices'
	},
	'low': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "lowestPrice",
		titleId: "priceboard.header.lowestPrice",
		group: 'prices'
	},

	"foreign": {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.foreign",
	},
	"buyForeignQtty": {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "buyForeignQtty",
		titleId: "priceboard.header.bought",
		group: 'foreign'
	},
	"sellForeignQtty": {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "sellForeignQtty",
		titleId: "priceboard.header.sold",
		group: 'foreign'
	},
};
//chung quyen
export const defaultCWColumnConfig = {
	'symbol': {
		visible: true,
		rowSpan: 2,
		colSpan: 3,
		colGroupClass: 'col-base-xsmall-2',
		headerClass: 'sortable',
		sortField: 'code',
		titleId: "priceboard.header.symbol"
	},
	'IssuerName': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-symbol',
		headerClass: 'sortable',
		sortField: 'IssuerName',
		titleId: "priceboard.header.issuer"
	},
	'lastTradingDate': {
		visible: true,
		rowSpan: 2,
		colSpan: 3,
		colGroupClass: 'col-base-xsmall-symbol',
		headerClass: 'sortable',
		sortField: 'code',
		titleId: "priceboard.header.lastTradingDate"
	},
	'tc': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'basicPrice',
		titleId: "priceboard.header.tc"
	},
	'ceiling': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'ceilingPrice',
		titleId: "priceboard.header.ceil"
	},
	'floor': {
		visible: true,
		rowSpan: 2,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'floorPrice',
		titleId: "priceboard.header.floor"
	},
	'derBidSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derBidSide"
	},
	'bidPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derBidSide',
	},
	'bidQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derBidSide',
	},
	'bidPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derBidSide'
	},
	'bidQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derBidSide'
	},
	'bidPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derBidSide'
	},
	'bidQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derBidSide'
	},
	'match': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.match",
	},
	'matchPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchPrice",
		titleId: "priceboard.header.price",
		group: "match"
	},
	'matchQtty': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchQtty",
		titleId: "priceboard.header.matchVol",
		group: "match"
	},
	//giao diện phức tạp cần case
	'priceDiff': {
		group: "match",
		colGroupClass: 'col-base-xsmall-1',
		rowSpan: 1,
		colSpan: 2,
		visible: true,
		titleId: "priceboard.header.priceDiff",
		toggle: {
			"%": {
				visible: true,
				titleId: "priceboard.header.%"
			},
			"+/-": {
				visible: true,
				titleId: "priceboard.header.+/-"
			}
		}
	},
	'derAskSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derAskSide"
	},
	'askPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derAskSide'
	},
	'askQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derAskSide'
	},
	'askPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derAskSide'
	},
	'askQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derAskSide'
	},
	'askPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derAskSide'
	},
	'askQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derAskSide'
	},
	//giao dien phuc tap can case
	'accumulated': {
		colGroupClass: "col-vol col-vol-custom",
		rowSpan: 2,
		colSpan: 3,
		visible: true,
		titleId: "priceboard.header.accumulated",
		toggle: {
			"accumulatedVal": {
				visible: true,
				titleId: 'priceboard.header.totalVal',
			},
			"accumulatedVol": {
				visible: true,
				titleId: 'priceboard.header.totalVol',
			}
		}
	},
	'prices': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.prices",
	},
	'high': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "highestPrice",
		titleId: "priceboard.header.highestPrice",
		group: 'prices'
	},
	'average': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "avgPrice",
		titleId: "priceboard.header.averagePrice",
		group: 'prices'
	},
	'low': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "lowestPrice",
		titleId: "priceboard.header.lowestPrice",
		group: 'prices'
	},
	"foreign": {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		titleId: "priceboard.header.foreign",
		toggle: {
			"buyForeignQtty": {
				visible: true,
				titleId: 'priceboard.header.bought',
			},
			"sellForeignQtty": {
				visible: true,
				titleId: 'priceboard.header.sold',
			}
		}
	},
	"underlying": {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.underlying"
	},
	'underlyingSymbol': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-small-1',
		titleId: "priceboard.header.underlyingSymbol",
		group: 'underlying'
	},
	'underlyingPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 2,
		colGroupClass: 'col-base-small-1',
		titleId: "priceboard.header.underlyingPrice",
		group: 'underlying'
	},
};
//TPDN
export const defaultTPDNColumnConfig = {
	'symbol': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-2',
		headerClass: 'sortable',
		sortField: 'code',
		titleId: "priceboard.header.symbol"
	},
	'tc': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'basicPrice',
		titleId: "priceboard.header.tc"
	},
	'ceiling': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'ceilingPrice',
		titleId: "priceboard.header.ceil"
	},
	'floor': {
		visible: true,
		rowSpan: 2,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: 'cell-highlight sortable',
		sortField: 'floorPrice',
		titleId: "priceboard.header.floor"
	},
	'derBidSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derBidSide"
	},
	'bidPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derBidSide',
	},
	'bidQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derBidSide',
	},
	'bidPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derBidSide'
	},
	'bidQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derBidSide'
	},
	'bidPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derBidSide'
	},
	'bidQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "bidQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derBidSide'
	},
	'match': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.match",
	},
	'matchPrice': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchPrice",
		titleId: "priceboard.header.price",
		group: "match"
	},
	'matchQtty': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "matchQtty",
		titleId: "priceboard.header.matchVol",
		group: "match"
	},
	//giao diện phức tạp cần case
	'priceDiff': {
		group: "match",
		colGroupClass: 'col-base-xsmall-1',
		rowSpan: 1,
		colSpan: 1,
		visible: true,
		titleId: "priceboard.header.priceDiff",
		toggle: {
			"%": {
				visible: true,
				titleId: "priceboard.header.%"
			},
			"+/-": {
				visible: true,
				titleId: "priceboard.header.+/-"
			}
		}
	},
	'derAskSide': {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.derAskSide"
	},
	'askPrice1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice01",
		titleId: "priceboard.header.prc1",
		group: 'derAskSide'
	},
	'askQtty1': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty01",
		titleId: "priceboard.header.vol1",
		group: 'derAskSide'
	},
	'askPrice2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice02",
		titleId: "priceboard.header.prc2",
		group: 'derAskSide'
	},
	'askQtty2': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty02",
		titleId: "priceboard.header.vol2",
		group: 'derAskSide'
	},
	'askPrice3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerPrice03",
		titleId: "priceboard.header.prc3",
		group: 'derAskSide'
	},
	'askQtty3': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "offerQtty03",
		titleId: "priceboard.header.vol3",
		group: 'derAskSide'
	},
	//giao dien phuc tap can case
	'accumulated': {
		colGroupClass: "col-base-xsmall-2",
		rowSpan: 2,
		colSpan: 1,
		visible: true,
		titleId: "priceboard.header.accumulated",
		toggle: {
			"accumulatedVal": {
				visible: true,
				titleId: 'priceboard.header.totalVal',
			},
			"accumulatedVol": {
				visible: true,
				titleId: 'priceboard.header.totalVol',
			}
		}
	},
	'prices': {
		visible: true,
		rowSpan: 1,
		headerClass: "cell-highlight",
		titleId: "priceboard.header.prices",
	},
	'high': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "highestPrice",
		titleId: "priceboard.header.highestPrice",
		group: 'prices'
	},
	'average': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "avgPrice",
		titleId: "priceboard.header.averagePrice",
		group: 'prices'
	},
	'low': {
		visible: true,
		rowSpan: 1,
		colSpan: 1,
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "cell-highlight sortable",
		sortField: "lowestPrice",
		titleId: "priceboard.header.lowestPrice",
		group: 'prices'
	},
	"foreign": {
		visible: true,
		rowSpan: 1,
		titleId: "priceboard.header.foreign"
	},
	"buyForeignQtty": {
		visible: true,
		titleId: 'priceboard.header.bought',
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "buyForeignQtty",
		rowSpan: 1,
		colSpan: 1,
		group: 'foreign'
	},
	"sellForeignQtty": {
		visible: true,
		titleId: 'priceboard.header.sold',
		colGroupClass: 'col-base-xsmall-1',
		headerClass: "sortable",
		sortField: "sellForeignQtty",
		rowSpan: 1,
		colSpan: 1,
		group: 'foreign'
	},
};
