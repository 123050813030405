import React, { Component } from 'react';
import * as Promise from 'bluebird';
import { FormattedPrice } from "components/Formating";
import { push } from "connected-react-router";
import { chartColors } from 'containers/QuickOrder/PortfolioQuickOrder/PortfolioQuickOrder';
import OpenPositionsNew from 'containers/Trade/OpenPositions/OpenPositionsNew';
import Highcharts from 'highcharts';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { fdsInquiryService, inquiryService } from "services";
import * as actions from "store/actions";
import { CommonUtils, COUNT_DOWN_RELOAD, KeyCodeUtils, LoadStatus, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil, TYPE_ACCOUNT, PREFIX_CUSTODYCD } from "utils";
import ReloadIconDark from '../../assets/icons/ReloadIcon_Dark.svg'
import ReloadIconLight from '../../assets/icons/ReloadIcon_Light.svg'
import './AssetTotal.scss';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';


class AssetTotal extends Component {
    initialState = {
        currentAccount: {},
        listAccounts: [],
        loadStatus: LoadStatus.NONE,
        records: [],
        dataRawRecords: [],
        recordsFDS: [],
        dataRawRecordsFDS: [],
        // Tổng hợp tài sản
        assetOfAccount: {},
        fdsAssetOfAccount: {},
        //Tổng CK khả dụng ----- ko bảo gồm chờ về và bán
        sumCostMarketAvailable: 0,
        time: 0
    };
    orderListInDay = [];
    orderListInDayFDS = [];

    instrumentsMap = {};
    chart = null
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.customerAccountRef = React.createRef();
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    loadData = async () => {
        const { currentAccount, listAccounts } = this.state
        const { curCustomer, userInfo } = this.props;
        const isDerivativeAccount = currentAccount.accounttype === 'FDS'

        const { id } = { ...currentAccount }
        const isBroker = this.isBroker()
        let custid = null
        custid = isBroker ? curCustomer ? curCustomer.custid : null : userInfo ? userInfo.custid : null;

        this._setState({ loadStatus: LoadStatus.LOADING });

        // Api Tổng hợp tài sản
        const getSummaryAccountFunc = this.isBroker() ? inquiryService.getSummaryAccount : inquiryService.getSummaryAccount;
        const getSummaryAccountFDSFunc = this.isBroker() ? fdsInquiryService.getSummaryAccount : fdsInquiryService.getSummaryAccount;
        if (currentAccount && currentAccount.accounttype === 'ALL') {
            //ALL Cơ sở
            inquiryService.getSummaryAllAccount(custid)
                .then((res) => {
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                        assetOfAccount: res,
                    })
                })
                .catch(error => {
                    //console.log('ha_check_error', error);
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                })
        } else {
            // single
            if (id) {
                if (isDerivativeAccount) {
                    try {
                        const responses = await Promise.all([
                            getSummaryAccountFDSFunc(id),
                        ])
                        let [dataSummary] = responses
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            fdsAssetOfAccount: dataSummary[0],
                        })
                    } catch (error) {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                    }
                } else {
                    await Promise.all([
                        getSummaryAccountFunc(id),
                    ]).then(responses => {
                        let [dataSummary] = responses
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            assetOfAccount: dataSummary,
                        })
                    }).catch(error => {
                        //console.log('ha_check_error', error);
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                    })
                }
            }
        }
    };

    handleChangeAccountId = (event) => {
        const { listAccounts } = this.state;
        let foundAccount = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: foundAccount })
    }

    initMaps = () => {
        this.instrumentsMap = {};
    };

    getValidSumValue = (val) => {
        if (val == null || Number.isNaN(val)) {
            return 0;
        }
        return val;
    };

    loadInitData = () => {
        // Lấy danh sách trạng thái để tìm kiếm
        // const { accounts,  } = this.props;

        const { accounts, language, currentAccountId, curCustomer } = this.props;
        const isBroker = this.isBroker()

        //console.log('ha_check_accounts', accounts, curCustomer);
        if (isBroker && accounts && accounts.length === 0) {
            this._setState({ currentAccount: {}, listAccounts: [] },
                () => {
                    this.loadData()
                }
            );
            return
        }


        let newRow = (language === 'en') ? {
            id: "All",
            entypename: "Basis",
            accounttype: "ALL",
            custodycd: accounts[0].custodycd
        } :
            {
                id: "Tất cả",
                entypename: "Cơ sở",
                accounttype: "ALL",
                custodycd: accounts[0].custodycd
            }
        let newList = [newRow, ...accounts]
        let filterAccount = newList.find(item => item.id === currentAccountId)
        this._setState({ currentAccount: filterAccount, listAccounts: newList },
            () => {
                this.loadData()
            }
        );
    };

    convertDataAll = (isFDS, data) => {
        let arr = []
        // //console.log('convertDataAll().:data=====', data)
        if (isFDS) {
            //console.log('convertDataAll().:data=====', data)
            // record.proportion = this.getValidSumValue((((record['marvalue'] * record['nvalue']) * 100) / _sumCostMarket)) // Tỷ trọng
            arr = _(data)
                .groupBy('symbol')
                .map((e, id) => {
                    return ({
                        symbol: id,
                        total: _.sumBy(e, item => Number(item.total)),
                        valueColor: _.sumBy(e, item => Number(item.totalplamt)), // pnlrate hoặc pnlamt tùy nghiệp vụ chốt
                        proportion: _.sumBy(e, item => Number(item.proportion)),
                        //
                        // price_secured:  _.sumBy(e, item => Number(item.price_secured)),
                        // capvalue:  _.sumBy(e, item => Number(item.capvalue)),
                        // qtty:  _.sumBy(e, item => Number(item.qtty)),
                        // nvalue:  _.sumBy(e, item => Number(item.nvalue)),
                        // vrplamt:  _.sumBy(e, item => Number(item.vrplamt)),
                    })
                })
                .value()
        }
        else {
            arr = _(data)
                .groupBy('symbol')
                .map((e, id) => {
                    let instrument = this.instrumentsMap[id] || {}
                    let closePrice = instrument['CP'] || 0
                    if (CommonUtils.checkSeqApi(instrument.s, e[0].s)) {
                        closePrice = e[0]['basicPrice'] || 0
                    }
                    return ({
                        symbol: id,
                        total: _.sumBy(e, item => Number(item.total)),
                        valueColor: _.sumBy(e, item => Number(item.pnlamt)), // pnlrate hoặc pnlamt tùy nghiệp vụ chốt
                        proportion: _.sumBy(e, item => Number(item.proportion)),
                        //
                        sumPortfolio: _.sumBy(e, item => (Number(item.receivingT0) + Number(item.receivingT1) + Number(item.receivingT2)) * Number(closePrice)),
                        sumPortfolio_WTF: _.sumBy(e, item => (item.symbol.endsWith('_WFT') ? Number(closePrice) * Number(item.trade) : 0)),
                        // sumPortfolio_not_WTF: _.sumBy(e, item => (!item.symbol.endsWith('_WFT') ? Number(closePrice) * Number(item.trade) : 0)),
                        basicPriceAmt: _.sumBy(e, item => Number(item.basicPriceAmt)),
                    })
                })
                .value()
        }
        //console.log('convertDataAll().:arr=====', arr, data)
        this.setChartData(arr)
        return arr
    }

    setDataFromChild = (isFDS, data) => {
        if (isFDS) {
            this.state.recordsFDS = []
            this.state.dataRawRecordsFDS = []
            data = this.convertDataAll(isFDS, data)
            this._setState({ recordsFDS: data, dataRawRecordsFDS: data })

        } else {
            this.state.records = []
            this.state.dataRawRecords = []
            let _data = this.convertDataAll(isFDS, data)
            // this.records = data
            this._setState({ records: _data, dataRawRecords: data })
        }

    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        if (this.chart) this.chart.destroy()
        this.chart = undefined
        if (this.counter) {
            this.clearTimer();
        }
        this.mounted = false
    }

    componentDidMount = () => {
        this.mounted = true
        this.initChart();
        this.loadInitData()
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            socketCIAction, socketSEAction, socketDTAction, socketDOAction, accounts
        } = this.props;

        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketDTAction: prevSocketDTAction,
            socketDOAction: prevSocketDOAction,
        } = prevProps;

        const { currentAccount } = this.state

        if ((!_.isEmpty(prevState.currentAccount) && currentAccount && !_.isEmpty(currentAccount) && JSON.stringify(currentAccount) !== JSON.stringify(prevState.currentAccount)) && currentAccount !== '') {
            this.loadData();
        }

        if (currentAccount && currentAccount.id && currentAccount.accounttype !== "ALL" && (socketCIAction[currentAccount.id] !== prevSocketCIAction[currentAccount.id] || socketSEAction[currentAccount.id] !== prevSocketSEAction[currentAccount.id])) {
            this.loadData();
        }

        if (currentAccount.accounttype === "ALL") {
            let data = accounts.filter(i => i.accounttype !== "FDS")
            Object.keys(data).forEach((item, index) => {
                let account = item
                if (account && account.id && (socketCIAction[account.id] !== prevSocketCIAction[account.id] || socketSEAction[account.id] !== prevSocketSEAction[account.id])) {
                    this.loadData();
                }
            })
            let dataFDS = accounts.filter(i => i.accounttype === "FDS")
            Object.keys(dataFDS).forEach((item, index) => {
                let account = item
                if (account && account.id && (socketDTAction[account.id] !== prevSocketDTAction[account.id]) || (socketDOAction[account.id] !== prevSocketDOAction[account.id])) {
                    this.loadData();
                }
            })

        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }
    }

    initChart = () => {
        const { defaultTheme } = this.props
        const color = defaultTheme === 'dark' ? 'white' : 'black'

        if (this.chart) return this.chart;
        this.chart = new Highcharts.chart('asset-chart-container-pie', {
            chart: {
                type: 'pie',
                events: {
                    render: function () {
                        for (let i = 0; i < this.series[0].data.length; i++) {
                            this.series[0].data[i].legendSymbol.attr({
                                width: 16,
                                height: 16,
                                x: 0,
                                y: 4,
                                rx: 0,
                                ry: 0,
                            });
                        }
                    }
                },
                // margin: [0, 0, 0, 0],
                // spacingTop: 0,
                // spacingBottom: 0,
                // spacingLeft: 0,
                // spacingRight: 0,
                backgroundColor: null,
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                type: 'pie',
                reflow: true,
                style: {
                    fontFamily: `"Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
                }
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    '<b>{point.percentage:.2f} %</b><br/>'
            },
            plotOptions: {
                pie: {
                    size: '100%',
                    showInLegend: true,
                    innerSize: '40%',
                    allowPointSelect: true,
                    cursor: 'pointer',
                    center: ["50%", "50%"],
                    dataLabels: {
                        enabled: true,
                        distance: '-30%'
                    },
                },
                series: {
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false;
                            },
                        }
                    }
                }
            },

            legend: {
                labelFormat: '{name}: {percentage:.2f} %',
                itemMarginBottom: 10,
                itemDistance: 10,
                floating: false,
                margin: 0,
                itemStyle: {
                    color: color
                },
                itemHoverStyle: {

                    color: '#1A7ECB'
                },

                maxHeight: 150,
                navigation: {
                    style: {
                        color: color
                    }
                }


                // symbolRadius: 0,
                // symbolPadding: 10

            },
            series: [{
                data: [],
            }],
        })
        return this.chart;
    };

    getSymbolOfSymbolSpecial = (symbolSpecial) => {
        const config = '_WFT'
        if (symbolSpecial.endsWith(config)) {
            return symbolSpecial.replace(config, "")
        }
        return symbolSpecial
    }

    setChartData = (records) => {
        //console.log("setChartData==records=", JSON.stringify(records))
        if (!this.chart) return;
        let summaryData = []
        let maxIncrease = 0
        let minReduce = 0
        for (let i = 0; i < records.length; i++) {
            let valueChart = {
                name: '',
                y: '',
                color: '',
                p: ''
            }
            let valueColor = Number(records[i].valueColor)
            valueChart = {
                name: records[i]["symbol"],
                y: records[i]["proportion"],
                valueColor: valueColor,
                color: '',
            }
            if (records[i]["proportion"] < 10) {
                valueChart.dataLabels = { distance: 10 }
            }
            if (valueColor < 0) {
                if (valueColor < minReduce) minReduce = valueColor
                valueChart.color = chartColors.reduce
            } else if (valueColor > 0) {
                if (valueColor > maxIncrease) maxIncrease = valueColor
                valueChart.color = chartColors.increase
            } else {
                valueChart.color = chartColors.yellow
            }
            summaryData.push(valueChart)
        }
        summaryData = summaryData.map(e => {
            if (e.valueColor == maxIncrease) {
                e.color = chartColors.increaseBest
            }
            if (e.valueColor == minReduce) {
                e.color = chartColors.reduceBest
            }
            return e
        })
        //console.log("setChartData.:summaryData", JSON.stringify(summaryData))
        this.chart.series[0].setData(summaryData);
        this.chart.reflow()
    };

    reloadSummaryAccount = (e) => {
        e.stopPropagation();
        let { time, currentAccount } = this.state;
        if (time === 0) {
            inquiryService.getCorebankBalance(currentAccount.id)
                .then((data) => {
                    this.loadSummaryAccount(true)
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
                });

        }
    }

    reloadBIDV = (e, accountId) => {
        e.stopPropagation();
        let { time } = this.state;
        if (time === 0) {
            inquiryService.getCorebankBalance(accountId)
                .then((data) => {
                    this.loadData(true)
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
                });
        }
    }

    timer = () => {
        this.counter = setInterval(() => {
            let { time } = this.state;
            time = time - 1;
            if (time === 0) {
                this.clearTimer();
            }
            this._setState({
                time: time
            })
        }, 1000);
    }

    clearTimer = () => {
        if (this.counter) {
            clearInterval(this.counter)
        }
    }

    loadSummaryAccount = (isReload = false) => {
        const { currentAccount } = this.state
        this._setState({ loadStatus: LoadStatus.LOADING });
        if (isReload) {
            this._setState({ loadStatusReload: LoadStatus.LOADING })
        }
        inquiryService.getSummaryAccount(currentAccount.id)
            .then((data) => {
                this._setState({
                    assetOfAccount: data,
                    loadStatus: LoadStatus.LOADED,
                });
                if (isReload) {
                    this._setState({ loadStatusReload: LoadStatus.LOADED, time: COUNT_DOWN_RELOAD },
                        () => this.timer())
                }
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                if (isReload) {
                    this._setState({ loadStatusReload: LoadStatus.LOADED })
                }
                ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
            });
    }

    onRowDataChange = (symbolId, obj) => {
        let { currentAccount } = this.state
        let recordUpdate = []
        recordUpdate.push(obj)
        let isFDS = currentAccount.accounttype === 'FDS'
        if (isFDS) {
            let { dataRawRecordsFDS } = this.state
            let _records = _.unionBy(recordUpdate, dataRawRecordsFDS, 'symbol');
            //console.log('onRowDataChange===2=', JSON.stringify(_records))
            let recordsFDS = this.convertDataAll(isFDS, _records)
            //console.log('onRowDataChange===3=', JSON.stringify(_records))
            this._setState({ records: recordsFDS, dataRawRecordsFDS: _records })
        } else {
            let { dataRawRecords } = this.state
            let _records = _.unionBy(recordUpdate, dataRawRecords, 'symbol');
            //console.log('onRowDataChange===2=', JSON.stringify(_records))
            let records = this.convertDataAll(isFDS, _records)
            //console.log('onRowDataChange===3=', JSON.stringify(_records))
            this._setState({ records: records, dataRawRecords: _records })
        }
    };

    performSearch = () => {
        const { custodycd } = this.state;
        if (custodycd != null && custodycd.length > 0) {
            //console.log("ha_check_performSearch", custodycd)
            this.props.setCustodycdCustomerScreen(custodycd, SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL);
        }
    }

    handleKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        let { custodycd } = this.state;
        let str = "000000"
        if (keyCode === KeyCodeUtils.ENTER || keyCode === KeyCodeUtils.TAB) {
            if (custodycd.indexOf(PREFIX_CUSTODYCD) !== 0) {
                if (custodycd.length <= 6) {
                    this._setState({
                        custodycd: PREFIX_CUSTODYCD + str.slice(0, -(custodycd.length)) + custodycd
                    })
                    str = "000000"
                }
                else this._setState({ custodycd: PREFIX_CUSTODYCD + custodycd.slice(0, -(custodycd.length - 6)) })
            }
            else {
                this._setState({
                    custodycd: PREFIX_CUSTODYCD + str.slice(0, -(custodycd.length - 4)) + custodycd.slice(4)
                })
                str = "000000"
            }
        }
    };

    onCustodycdChanged = (event) => {
        const custodycd = event.target.value;
        this._setState({
            custodycd: custodycd != null ? custodycd.toUpperCase() : ''
        }, () => {
            if (this.state.custodycd.length >= 10) {
                this.performSearch();
            }
        });
    };

    renderNormalAccount = () => {

        const mergeAssetOfAccount = (assetOfAccount) => {
            if (!_.isArray(assetOfAccount)) {
                return {}
            }
            let allFieldSummary = Object.keys(assetOfAccount[0] ? assetOfAccount[0] : {})
            const newObj = {}
            for (const key of allFieldSummary) {
                newObj[key] = 0;
            }

            assetOfAccount.forEach(element => {
                for (const key of allFieldSummary) {
                    try {
                        if (key === "acctno") {
                            newObj[element["acctno"]] = element["bankavlbal"] + element["holdbalance"]
                        } else if (key === "corebank") {
                            newObj["corebank-" + element["acctno"]] = element["corebank"]
                        } else {
                            newObj[key] += element[key];
                        }

                    } catch (error) {
                        newObj[key] = element[key];
                    }
                }
            });
            return newObj
        }

        const { assetOfAccount, currentAccount, loadStatusReload, time, records, listAccounts } = this.state
        const { defaultTheme } = this.props

        const isALL = currentAccount.accounttype === 'ALL'
        const basic = isALL ? mergeAssetOfAccount(assetOfAccount) : assetOfAccount
        const isCoreBank = currentAccount.corebank === 'Y'
        let listNormalAccount = listAccounts.filter(item => item.accounttype === 'SEC')
        //CK chờ về
        //Chờ về T0, T1, T2 trong danh mục đầu tư * giá thị trường

        // CK khớp
        // Lệnh trong ngày đã khớp * giá thị trường


        //CT Chứng khoán = CK khả dụng + (CK khớp chờ về +  WFT)
        // const AllSecurities = sumCostMarketAvailable + SymbolMatchBuyAndWait
        let AllSecurities = _.sumBy(records, function (o) { return o.basicPriceAmt; });
        let sumArraySymbolPortfolio = _.sumBy(this.records, o => o.sumPortfolio);
        let sumWFT = _.sumBy(records, o => o.sumPortfolio_WTF);
        // CK khớp chờ về + WFT
        let SymbolMatchBuyAndWait = sumArraySymbolPortfolio + sumWFT;
        // CK khả dụng
        let sumCostMarketAvailable = AllSecurities - SymbolMatchBuyAndWait;
        //CT nợ
        const LoansTotal = basic.t0debtamt + basic.marginamt + basic.advancedamt + basic.cidepofeeacr + basic.secureamt

        //TS ròng Tiền VIX + iFlex + CK - Nợ
        const realAsset = basic.balance + AllSecurities - LoansTotal

        // Tính tiền tại VIX
        const bscAsset = basic ? (basic.balance || 0) - (basic.tdbalance || 0) - (basic.tdintamt || 0) - (basic.receivingamt || 0) : 0
        //console.log('renderNormalAccount.:====', AllSecurities, sumCostMarketAvailable, SymbolMatchBuyAndWait, records)
        return (
            <div className="asset-total-asset bg-asset">
                <div className="title">
                    <FormattedMessage id="asset-total.title" />
                </div>

                <div className="asset-wrap-block">
                    <div className="asset-block-left">

                        {/* Tiền tại VIX */}
                        <div className="money-in-company d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.money-in-company" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={bscAsset} />
                                </div>
                            </div>

                            <div className="asset-body">

                                {/* Tiền không kỳ hạn */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.unlimited-money" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.cibalance} />
                                    </div>
                                </div>

                                {/* Tiền cổ tức chờ về */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.dividends-waiting" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.careceiving} />
                                    </div>
                                </div>
                                {/* Lãi tiền gửi chưa thanh toán */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.unpaid-deposit-interest" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.interestbalanceweb} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tiền bán chờ về */}

                        <div className="cash-waiting d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.sell-wait" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={basic.receivingamt} />

                                </div>
                            </div>

                            <div className="asset-body">

                                {/* Tiền chờ về T1 */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.sell-wait-T1" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.receivingt1} />
                                    </div>
                                </div>

                                {/* Tiền chờ về T2 */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.sell-wait-T2" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.receivingt2} />
                                    </div>
                                </div>


                                {/* Tiền chờ về T3 */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.sell-wait-T3" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.receivingt3} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* iFlex */}
                        {/*<div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.iflex" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={this.getValidSumValue(basic.tdbalance + basic.tdintamt)} />
                                </div>
                            </div>

                            <div className="asset-body">
                                // Số dư gốc 
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.principal-balance" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.tdbalance} />
                                    </div>
                                </div>
                                // Lãi tạm tính 
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.temporary-interest" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.tdintamt} />
                                    </div>
                                </div>
                            </div>
                        </div>*/}


                        {/* CK */}
                        <div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.securities" />

                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={AllSecurities} />
                                </div>
                            </div>

                            <div className="asset-body">
                                {/* Chứng khoán thực có */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.securities-avaiable" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={sumCostMarketAvailable} />
                                    </div>
                                </div>
                                {/* CK mua khớp + quyền chờ về */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.securities-match-right-off-wait" />

                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={SymbolMatchBuyAndWait} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  Sức mua cơ bản */}
                        {!isALL && <div className="margin d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.basic-purchasing-power" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={basic.pp0} />
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="asset-block-right">

                        {/*  Phải trả */}

                        <div className="loan d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.must-pay" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={Number.isNaN(LoansTotal) ? '-' : LoansTotal} />
                                </div>
                            </div>

                            <div className="asset-body">
                                {/* Nợ bảo lãnh */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.guarantee-debt" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.t0debtamt} />
                                    </div>
                                </div>
                                {/* Nợ đã phát vay margin */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.margin-debt-borrowed" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.marginamt} />
                                    </div>
                                </div>
                                {/* Nợ ứng trước */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.advance-debt" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.advancedamt} />
                                    </div>
                                </div>

                                {/* Nợ tiền mua CK trong ngày */}
                                {/* <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.debt-securities-in-day" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.secureamt} />
                                    </div>
                                </div> */}

                                {/* Nợ cầm cố */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.mortgage-debt" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.dfdebtamt} />
                                    </div>
                                </div>

                                {/* Nợ cầm cố tiết kiệm */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.mortgage-debt-save-inday" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.tddebtamt} />
                                    </div>
                                </div>

                                {/* Nợ phí lưu ký */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.deposit-fee" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={basic.cidepofeeacr} />

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  Tài sản ròng (NAV) */}

                        {!isALL && <div className="nav-asset d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.net-assets" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={Number.isNaN(realAsset) ? '-' : realAsset} />
                                </div>
                            </div>
                        </div>
                        }
                        {/*   Tiền tại @BIDV - Tất cả */}
                        {isALL && <div className="cash-in-bank d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.in-bank" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    {basic ? <FormattedPrice value={basic.bankavlbal + basic.holdbalance} /> : "-"}
                                </div>
                            </div>
                            <div className="asset-body">
                                {/* Tiểu khoản */}
                                {listNormalAccount && listNormalAccount.map((account, index) => {
                                    return (
                                        <div className="asset-body-property">
                                            <div className="sub-block-title">
                                                {account.entypename}.{account.id}
                                            </div>

                                            <div className='d-flex'>
                                                <div className="sub-block-value txt---500-14-20">
                                                    <FormattedPrice value={basic[account.id]} />
                                                </div>
                                                {<span id={account.id} style={{ paddingLeft: "5px" }} onClick={(e) => this.reloadBIDV(e, account.id)}>
                                                    <img className={"effect-rotate-360" + (loadStatusReload === LoadStatus.LOADING ? " effect-loading " : "") + (time !== 0 ? " effect-loaded" : "")} src={defaultTheme === "dark" ? ReloadIconDark : ReloadIconLight} alt="icon-reload" />
                                                </span>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        }
                        {/*   Tiền tại @BIDV - Cơ sở */}
                        {!isALL && <div className="cash-in-bank d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.in-bank" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={basic.bankavlbal + basic.holdbalance} />
                                </div>
                                {isCoreBank && <span style={{ paddingLeft: "5px" }} onClick={(e) => this.reloadSummaryAccount(e)}>
                                    <img className={"effect-rotate-360" + (loadStatusReload === LoadStatus.LOADING ? " effect-loading " : "") + (time !== 0 ? " effect-loaded" : "")} src={defaultTheme === "dark" ? ReloadIconDark : ReloadIconLight} alt="icon-reload" />
                                </span>}
                            </div>
                            <div className="asset-body">
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.available-balance-at-bank" />
                                    </div>
                                    <div className='d-flex'>
                                        <div className="sub-block-value txt---500-14-20">
                                            <FormattedPrice value={basic.bankavlbal} />
                                        </div>
                                    </div>
                                </div>
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.blocked-balance" />
                                    </div>
                                    <div className='d-flex'>
                                        <div className="sub-block-value txt---500-14-20">
                                            <FormattedPrice value={basic.emkamt} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {/*  Tổng NAV */}
                        {isALL && <div className="margin d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.total-nav" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={Number.isNaN(realAsset) ? '-' : realAsset} />
                                </div>
                            </div>
                        </div>
                        }
                        {/*   Trạng thái tiểu khoản */}

                        {!isALL && (<div className="sub-account-info d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.status-sub-account" />
                                </div>

                                <div className="block-value txt---500-14-20">
                                    {/* chưa tính */}
                                </div>
                            </div>

                            <div className="asset-body">
                                {/* Tỷ lệ ký quỹ */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.margin-rate" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        {basic.marginrate}
                                    </div>
                                </div>

                                {/* Hạn mức vay tối đa */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.limit-loans" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        {basic && basic.mrcrlimitmax ? <FormattedPrice value={basic.mrcrlimitmax} /> : '-'}
                                    </div>
                                </div>
                                {/* Trạng thái tiểu khoản*/}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.status-sub-account" />
                                    </div>

                                    <div className="sub-block-value txt---500-14-20">
                                        {basic.status}
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>)
    }

    renderFDSAccount = () => {
        const { fdsAssetOfAccount, recordsFDS } = this.state

        let lailovithehientai = 0;
        let lailovithedadong = 0;
        if (recordsFDS && recordsFDS.length > 0) {
            _.forEach(recordsFDS, (e) => {
                const instrument = e.symbol ? this.instrumentsMap[this.getSymbolOfSymbolSpecial(e.symbol)] : {};
                let closeprice = e.price_secured ? e.price_secured : 0;
                if (instrument && (instrument["CP"] ? instrument["CP"] : 0) > 0) {
                    closeprice = instrument["CP"] ? instrument["CP"] : 0;
                }

                let pnlValue = (Math.abs((closeprice * (e.qtty ? e.qtty : 0))) - Math.abs((e.capvalue ? e.capvalue : 0))) * (e.nvalue ? e.nvalue : 0);

                if (e.qtty != 0) {
                    pnlValue = pnlValue * (e.qtty / (Math.abs(e.qtty)));
                }
                lailovithehientai += pnlValue;
                lailovithedadong += (e.vrplamt ? e.vrplamt : 0);
            })
        }

        const netAssetValueFDS = (fdsAssetOfAccount.vimcash ? fdsAssetOfAccount.vimcash : 0) +
            (fdsAssetOfAccount.vcashonhand ? fdsAssetOfAccount.vcashonhand : 0) +
            (fdsAssetOfAccount.wsecured ? fdsAssetOfAccount.wsecured : 0) +
            (fdsAssetOfAccount.wtransfer ? fdsAssetOfAccount.wtransfer : 0) +
            lailovithehientai +
            lailovithedadong -
            (fdsAssetOfAccount.mstpay ? fdsAssetOfAccount.mstpay : 0);
        // //console.log('Haki22.:netAssetValueFDS=', netAssetValueFDS, fdsAssetOfAccount.vimcash, fdsAssetOfAccount.vcashonhand, fdsAssetOfAccount.wsecured, fdsAssetOfAccount.wtransfer, lailovithehientai, lailovithedadong, fdsAssetOfAccount.mstpay)
        return (
            <div className="asset-total-asset bg-asset" >
                <div className="title">
                    <FormattedMessage id="asset-total.title" />
                </div>
                <div className="asset-wrap-block">
                    <div className="asset-block-left">
                        {/* Giá trị tài sản hợp lệ */}
                        <div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.valid-property-value" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.eca} />
                                </div>
                            </div>

                            <div className="asset-body">
                                {/* Tiền tại CTCK */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.money-at-securities-company" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vcashonhand} />
                                    </div>
                                </div>
                                {/* Tiền chờ ký quỹ */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.money-waiting-for-deposit" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.wsecured} />
                                    </div>
                                </div>
                                {/* Tiền tại CCP */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.money-at-CCP" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vimcash} />
                                    </div>
                                </div>
                                {/* Tiền ký quỹ chờ rút */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.deposit-waiting-for-withdrawal" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.wtransfer} />
                                    </div>
                                </div>
                                {/* CK ký quỹ */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.deposit-securities" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vimnoncash} />
                                    </div>
                                </div>
                                {/* Tỷ lệ tiền mặt trong tài sản KQ */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.cash-to-asset-ratio" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.prcash} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Tổng nghĩa vụ KQ duy trì */}
                        <div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.total-obligation-to-maintain-performance" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.rma} />
                                </div>
                            </div>

                            <div className="asset-body">
                                {/* Nghĩa vụ KQ ban đầu */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.obligations-of-the-initial-performance" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vrimamt} />
                                    </div>
                                </div>
                                {/* Lãi / Lỗ trong ngày */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.profit-loss-of-the-day" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vrvmamt * (-1)} />
                                    </div>
                                </div>
                                {/* Nghĩa vụ KQ chuyển giao */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.obligations-to-deliver-results" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vrdmamt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tỷ lệ thực tế TKKQ */}
                        <div className="margin d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.actual-rate-of-tkkq" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice shortForm={true} value={fdsAssetOfAccount.prsafe} isNotThousand={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="asset-block-right">
                        {/* Tổng giá trị phí */}
                        <div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.total-value-of-fees" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.totalfee} />
                                </div>
                            </div>
                            <div className="asset-body">
                                {/* Phí vị thế cộng dồn */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.cumulative-position-fees" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vposfeeacr} />
                                    </div>
                                </div>
                                {/* Phí giao dịch trong ngày */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.daily-transaction-fee" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vtradefeeacr} />
                                    </div>
                                </div>
                                {/* Thuế TNCN */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.pit" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.incometax} />
                                    </div>
                                </div>
                                {/* Phí SMS */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.sms-fee" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.smsmatchfeeacr} />
                                    </div>
                                </div>
                                {/* Phí giao dịch trả sở */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.payment-transaction-fee" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vecafeeamt} />
                                    </div>
                                </div>
                                {/* Phí ký quỹ cộng dồn */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.cumulative-deposit-fee" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.vecafeeacr} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Tổng nợ */}
                        <div className="securities d-flex flex-column asset-border">
                            <div className="asset-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.total-liabilities" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.totaldebt} />
                                </div>
                            </div>
                            <div className="asset-body">
                                {/* Nợ thấu chi */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.overdraft-debt-asset" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.voverdraftamt} />
                                    </div>
                                </div>
                                {/* Lãi thấu chi */}
                                <div className="asset-body-property">
                                    <div className="sub-block-title">
                                        <FormattedMessage id="asset-total.overdraft-interest-asset" />
                                    </div>
                                    <div className="sub-block-value txt---500-14-20">
                                        <FormattedPrice value={fdsAssetOfAccount.odintacr} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Sức mua cơ bản */}
                        <div className="margin d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.basic-purchasing-power" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.pp_long} />
                                </div>
                            </div>
                        </div>
                        {/* Sức bán cơ bản */}
                        <div className="margin d-flex flex-column asset-border">
                            <div className="asset-header only-header">
                                <div className="block-title">
                                    <FormattedMessage id="asset-total.basic-selling-power" />
                                </div>
                                <div className="block-value txt---500-14-20">
                                    <FormattedPrice value={fdsAssetOfAccount.pp_short} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    render() {
        const { currentAccount, listAccounts, custodycd } = this.state
        //console.log("currentAccount---: ", currentAccount)
        const { accountInfo } = this.props
        const typeAccount = currentAccount.accounttype === 'FDS' ? TYPE_ACCOUNT.FDS : TYPE_ACCOUNT.BASE
        const isSEC = currentAccount.accounttype === 'SEC'
        const isALL = currentAccount.accounttype === 'ALL'
        const isNotSelectAccount = _.isEmpty(currentAccount)

        // for OpenPositionsNew
        let convertCurrentAccount = {}

        if (_.isEmpty(currentAccount)) {
            convertCurrentAccount = this.props.accountInfo
        } else {
            convertCurrentAccount = { ...currentAccount }
            convertCurrentAccount.currentAccountId = currentAccount.id
            convertCurrentAccount.typeAccount = typeAccount
            convertCurrentAccount.accounttype = currentAccount.accounttype
        }
        const { curCustomer } = { ...accountInfo }
        const { role, accounts } = this.props;
        const disableCustomerSelector = !this.isBroker();
        const isBroker = this.isBroker();
        return (
            <div className="asset-total ">

                <div className="asset-total-investment-portfolio bg-asset  ">

                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group-n txt---400-14-20">
                                        {this.props.userInfo && this.props.userInfo.custodycd}
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id} ({account.entypename})</option> :
                                            (<option key={account.id} value={account.id}>{account.entypename} - {CommonUtils.formatFDS(account.id)}</option>)
                                        )
                                    })}
                                </select>
                            </div>

                        </div>
                    </div>

                    <div className="title">
                        <FormattedMessage id="asset-total.portfolio" />
                    </div>

                    {/* <OpenPositions /> */}
                    <OpenPositionsNew
                        isAssetTotal={true}
                        currentAccount={convertCurrentAccount}
                        listAccounts={accounts}
                        setDataFromChild={this.setDataFromChild}
                        onRowDataChange={this.onRowDataChange}
                        role={role}
                    />
                </div>

                <div className="asset-block">
                    {/* 
                    isSEC || isALL || isNotSelectAccount ---- Cơ sở || Tất cả tiểu khoản cơ sở || Chưa chọn tiểu khoản
                    */}
                    {(isSEC || isALL || isNotSelectAccount) && this.renderNormalAccount()}
                    {typeAccount === TYPE_ACCOUNT.FDS && this.renderFDSAccount()}
                    <div className="asset-total-rate bg-asset">
                        <div className="title">
                            <FormattedMessage id="asset-total.proportion" />
                        </div>
                        <div className="asset-chart">
                            <div id="asset-chart-container-pie" className={"asset-chart-container-pie"}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    // let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    // let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    // let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    // let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo;
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    // const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        curCustomer: curCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: accounts,
        isDeskTop: state.app.isDeskTop,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketDTAction: state.socketRealtime.socketDTAction,
        socketDOAction: state.socketRealtime.socketDOAction,
        currentAccountId: currentAccountId,
        accountInfo: _accountInfo,
        defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        setTabForOpenPossitionsAction: (listActionName) => dispatch(actions.setTabForOpenPossitionsAction(listActionName)),
        setCustodycdCustomerScreen: (custodycd, typeScreen) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen)),

    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AssetTotal));
