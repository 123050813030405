import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';


import config from '../../../../combineConfig';
import { NumberFormatCustom } from "../../../../components/Formating";
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, userService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, KeyCodeUtils, LoadStatus, reportParams, reportTypes, ToastUtil } from "../../../../utils";

import './CatalogLookup.scss';
import { Role } from 'constants/config';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import SymbolDetailAction from 'components/SymbolDetailAction/SymbolDetailAction';

{/*let columnsDefault =
    [
        {
            dataField: "basketname",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "mrratioloan",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "mrpriceloan",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "mrratiorate",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        {
            dataField: "mrpricerate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        {
            dataField: "remainmrmaxqtty",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
    ]
*/}



let columnsDefault =
    [
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "issfullname", // Tên tổ chức phát hành
            dataType: "string",
            allowReordering: true,
            minWidth: "300",
            width: '300',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "left",
            visibleIndex: 1,
        },
        {
            dataField: "mrratiorate",
            dataType: "string",
            allowReordering: true,
            minWidth: "250",
            width: '250',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "mratiomargin",
            dataType: "string",
            allowReordering: true,
            minWidth: "170",
            width: '200',
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        }
    ]
class CatalogLookup extends Component {

    constructor(props) {
        super(props);
        this.searchBtnRef = React.createRef();
        this.state = {
            symbol: null,
            records: [],
            loadStatus: LoadStatus.NONE,
            currentAccount: {},
            listSymbol: [],
            onReloadData: true,
            link: ''
        };
    }

    getListSymbol = () => {
        const { curCustomer, userInfo } = this.props
        let custid = null;
        const isBroker = this.isBroker();
        if (!isBroker) {
            custid = userInfo ? userInfo.custid : null;
        } else {
            custid = curCustomer ? curCustomer.custid : null;
        };
        if (custid) {
            inquiryService.getSecbasketCFSymbol(custid)
                .then((data) => {
                    const _listSymbol = []
                    data.forEach(item => {
                        _listSymbol.push(item.symbol)
                    });
                    this._setState({
                        // symbol: data[0].symbol,
                        listSymbol: _listSymbol
                    },
                        () => this.setReloadData()
                    )
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-catalog-lookup');
                });
        }
    }

    apiLoadAll = async (obj) => {
        try {
            const { symbol, listSymbol } = this.state;
            const { curCustomer, userInfo } = this.props
            let custid = null;
            const isBroker = this.isBroker();
            const isCheckSymbol = listSymbol.includes(symbol)
            if (!isBroker) {
                custid = userInfo ? userInfo.custid : null;
            } else {
                custid = curCustomer ? curCustomer.custid : null;
            };
            if (!isCheckSymbol) return []
            if (symbol && custid) {
                return inquiryService.getSecbasket(symbol, custid)
                    .then((data) => {
                        return data
                    })
                    .catch((error) => {
                        return []
                        ToastUtil.errorApi(error, 'common.fail-to-load-catalog-lookup');
                    });
            } else {
                return []
            }
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'basketname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.basketname)
            case 'symbol':
                return (e) => <SymbolDetailAction symbol={e.row.data.symbol} />
            case 'mrratioloan':
                return (e) => this.renderGridCellConvert(e.row.data.mrratioloan);
            case 'mrpriceloan':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.mrpriceloan, 'PRICE');
            case 'mrratiorate':
                return (e) => this.renderGridCellConvert(e.row.data.mrratiorate);
            case 'mrpricerate':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.mrpricerate, 'PRICE');
            case 'remainmrmaxqtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.remainmrmaxqtty, 'PRICE');
            case 'issfullname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.issfullname)
            case 'mratiomargin':
                return (e) => this.renderGridCellConvert(e.row.data.mratiomargin);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'basketname':
                return "catalog-lookup.stock-basket";
            case 'symbol':
                return "catalog-lookup.stock-code";
            case 'mrratioloan':
                return "catalog-lookup.borrowing-rate";
            case 'mrratiorate':
                return "catalog-lookup.mrratiorate";
            case 'mrpriceloan':
                return "catalog-lookup.loan-price";
            case 'pnlamrratioratemt':
                return "catalog-lookup.asset-calculation-rate";
            case 'mrpricerate':
                return "catalog-lookup.property-price";
            case 'remainmrmaxqtty':
                return "catalog-lookup.the-remaining-room";
            case 'issfullname':
                return "catalog-lookup.issuer-name";
            case 'mratiomargin':
                return "catalog-lookup.margin-rate";
            default:
                return null;
        }
    }

    renderGridCellConvert = (item) => {
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    <NumberFormatCustom value={item} decimalScale={2} />%
                </div>
            </Fragment>
        )
    }

    genReport = (exptype) => {
        const { currentAccount } = this.state;
        let { fromDate, toDate, symbol } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];

            rptId = 'catalogLookup';
            inpArr = [currentAccount.id, symbol, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        // const { accounts, currentAccountId } = this.props;
        // let found = accounts.find(item => item.id === currentAccountId)
        // this._setState({ currentAccount: found },
        //     () => {
        //         this.getListSymbol()
        //     }
        // );
        // this.setReloadData("NOT_CALL_API")
        // document.addEventListener('keydown', this.handlerKeyDown);
        this.loadMarginLookupLink()
    }

    loadMarginLookupLink = () => {
        userService.getClientConfig()
            .then((data) => {
                this._setState({ link: data.configData.deposit || '' });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { language } = this.props;
        const { language: prevlanguage } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
        }
        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.getListSymbol()
        }
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    search = () => {
        const { symbol, listSymbol } = this.state
        const isCheckSymbol = listSymbol.includes(symbol)
        if (!symbol || !isCheckSymbol) ToastUtil.error('trade.advance-condition-order.invalid-symbol');
        this.setReloadData();
    }

    render() {
        const { link } = this.state;
        return (
            <RetryableContent className="catalog-lookup report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className='node-container txt---400-14-20'>
                    <p><FormattedMessage id="catalog-lookup.note" /> <a href={link} target='_blank'><FormattedMessage id="catalog-lookup.here" /></a></p>
                </div>
            </RetryableContent>
        )
    }

    // render() {
    //     const { accountInfo } = this.props;
    //     const isBroker = this.isBroker();
    //     const { curCustomer } = { ...accountInfo }
    //     const { onReloadData } = this.state
    //     return (
    //         <RetryableContent className="catalog-lookup report-content" status={this.state.loadStatus} disableRetry={true}>
    //             <div className="catalog-lookup-content">
    //                 <div className="action-container">
    //                     <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
    //                         {
    //                             isBroker ?
    //                                 <div className="custom-form-group-n">
    //                                     <SelectorAccountCustomer
    //                                         curCustomer={curCustomer}
    //                                         selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
    //                                         disabled={false}
    //                                         className="custom-form-control"
    //                                     />
    //                                 </div>
    //                                 :
    //                                 <div className="custom-form-group-n">
    //                                     {this.props.userInfo && this.props.userInfo.custodycd}
    //                                 </div>
    //                         }
    //                         <SymbolSuggestTrans
    //                             isCatalog={true}
    //                             listSymbol={this.state.listSymbol}
    //                             handleInputChange={this.onSymbolChanged}
    //                             currentSymbol={this.state.symbol}
    //                             symbolToDisplay={this.state.symbol}
    //                             suggestId="catalog-lookup"
    //                             isRedirect={false}
    //                             isDisplayError={false}
    //                             placeholder={LanguageUtils.getMessageByKey("securities-transaction-report.search", this.props.lang)}
    //                             isOrder={true}
    //                             isFilterSymbol={true}
    //                         />
    //                         <div>
    //                             <button className="txt---400-14-20 btn-search" onClick={this.search}>
    //                                 <FormattedMessage id="common.search" />
    //                             </button>
    //                         </div>
    //                     </div>
    //                     <div className="export-container text-right">
    //                         <div className="dropdown">
    //                             <TextToolTip
    //                                 tooltipText={<FormattedMessage id="common.export-tooltip" />}
    //                                 targetID='toolTipExportBtn'
    //                             ></TextToolTip>
    //                             <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
    //                                 <DownloadIcon />
    //                             </button>
    //                             <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
    //                                 <FormattedMessage id='common.export-to-pdf'>
    //                                     {(txt) => (
    //                                         <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
    //                                     )}
    //                                 </FormattedMessage>
    //                                 <FormattedMessage id='common.export-to-excel'>
    //                                     {(txt) => (
    //                                         <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
    //                                     )}
    //                                 </FormattedMessage>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className='h-block-table p-table-10'>
    //                     <TableExtremeCustom2
    //                         keyStoreUpdate="symbol" // key của row 
    //                         columnsDefault={columnsDefault} // ds cột
    //                         totalDefault={[]} // ds sum (nếu có)
    //                         renderSwitch={this.renderSwitch} // func render dữ liệu
    //                         returnCaption={this.returnCaption} // func render column name
    //                         isUseSelection={false} // true/false: hiện/ẩn chọn row
    //                         isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
    //                         addClass="catalog-lookup-all" // class cha
    //                         isPager={false} // hiển thị giao diện phân trang
    //                         //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
    //                         onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
    //                         defaultPageSizes={500}  //Truyền 
    //                         apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
    //                         isCustomTopAndBottomSticky={false}
    //                         showFilter={false}
    //                     />
    //                 </div>
    //             </div>
    //         </RetryableContent>

    //     );
    // }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    const userInfo = state.user.userInfo;
    return {
        isDeskTop: state.app.isDeskTop,
        symbolWithIndex: state.symbol.allQuotesWithIndex,
        lang: state.app.language,
        language: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        userInfo: userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CatalogLookup));