import React, { Component } from 'react';
// import CustomTableNew from 'components/Table/ReactTable/CustomTableNew';
import _ from 'lodash'
import moment from "moment/moment";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from "react-redux";
import config from '../../../../combineConfig';
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, CommonWidgetUtils, execTypes, KeyCodeUtils, LoadStatus, mDate, Random, reportParams, reportTypes, Role, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../../../utils";

import './RightOffPerform.scss';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';

const rowPerPage = 200;

let columnsDefault =
    [
        /* Tiểu khoản */
        {
            dataField: "accounttype",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Sự kiện */
        {
            dataField: "catype",
            dataType: "string",
            allowReordering: true,
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        /* Ngày chốt */
        {
            dataField: "reportdate",
            dataType: "string",
            width: '150',
            allowReordering: true,
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        /* SL CK sở hữu */
        {
            dataField: "slcksh",
            dataType: "string",
            allowReordering: true,
            //minWidth: "120",
            //width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        /* Tỷ lệ */
        {
            dataField: "tyle",
            dataType: "string",
            allowReordering: true,
            //minWidth: "120",
            //width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        /* SL CK trả về */
        {
            dataField: "slckcv",
            dataType: "string",
            allowReordering: true,
            //minWidth: "120",
            // width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        /* Số tiền trả về */
        {
            dataField: "stcv",
            dataType: "string",
            allowReordering: true,
            //minWidth: "120",
            //width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        /* Ngày thực hiện dự kiến */
        {
            dataField: "actiondate",
            dataType: "string",
            allowReordering: true,
            // minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 8,
        },
        /* Trạng thái */
        {
            dataField: "castatus",
            dataType: "string",
            allowReordering: true,
            // minWidth: "120",
            // width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
        },

    ]


const totalGroupDefault =
    [
        {
            column: "stcv",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
            showInGroupFooter: true
        },

    ]

class RightOffPerform extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);
    state = {
        loadStatus: LoadStatus.NONE,
        listRightEvent: [
            {
                id: "All"
            }
        ],
        fromDate: null,
        toDate: null,
        symbol: null,
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],


        numOfPage: 1,
        gotoPage: null,

        nextIndex: 0, //nextIndex > 0 có thể next trang tiếp theo
        prevIndex: 0,
        isDisabled: false,
        listAccounts: [],
        onReloadData: true,
        currentAccount: {},                         // Thông tin tài khoản
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "ORSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();

        this.fromDatePicker = React.createRef();
        this.toDatePicker = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }


    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'accounttype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.accounttype);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellSymbol(e.row.data.symbol);
            case 'catype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.catype);
            case 'acctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.acctno);
            case 'reportdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.reportdate);
            case 'slcksh':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.slcksh);
            case 'tyle':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tyle);
            case 'slckcv':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.slckcv);
            case 'stcv':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.stcv);
            case 'actiondate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.actiondate);
            case 'castatus':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.castatus);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'accounttype':
                return "customer-management.exercise-right-table.account-type";
            case 'symbol':
                return "customer-management.exercise-right-table.symbol-code";
            case 'catype':
                return "customer-management.exercise-right-table.event";
            case 'acctno':
                return "customer-management.margin-warning-table.sub-account-number";
            case 'reportdate':
                return "customer-management.exercise-right-table.closing-date";
            case 'slcksh':
                return "customer-management.exercise-right-table.quantity-symbol-owns";
            case 'tyle':
                return "customer-management.exercise-right-table.ratio";
            case 'slckcv':
                return "customer-management.exercise-right-table.quantity-symbol-returns";
            case 'stcv':
                return "customer-management.exercise-right-table.amount-returned";
            case 'actiondate':
                return "customer-management.exercise-right-table.estimated-actual-date";
            case 'castatus':
                return "customer-management.exercise-right-table.status";
            default:
                return null;
        }
    }

    search = () => {
        this.setReloadData();
    }

    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount } = this.state;
        const { userInfo, curCustomer } = this.props

        let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

        if (!checkDateResult) {
            return [];
        }
        // const momentFromDate = moment(fromDate);
        // const momentToDate = moment(toDate);
        // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
        //     return [];
        // }

        // if (momentToDate.isBefore(momentFromDate)) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
        //     return [];
        // }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return [];
        // }

        try {
            const isBroker = this.isBroker()
            let custid = isBroker ? this.props.curCustomer.custid : this.props.userInfo.custid;
            if (currentAccount && custid) {
                let _obj = {
                    page: obj.page,
                    limitRow: obj.limitRow,
                    fromDate: moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
                    toDate: moment(toDate).format(config.app.SERVER_DATE_FORMAT),
                    id: currentAccount.id
                }
                if (currentAccount.accounttype === 'ALL') {
                    return inquiryService.getRightoffHistAllAccount(_obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow, custid)
                        .then((data) => {
                            return data.d
                        })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                            return []
                        });
                } else {
                    return inquiryService.getRightoffAccountInfoCustomer(_obj.fromDate, _obj.toDate, _obj.id, _obj.page, _obj.limitRow)
                        .then((data) => {
                            return data.d
                        })
                        .catch((error) => {
                            ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                            return []
                        });
                }
            }else {
                ToastUtil.error('common.no-account');
            }

        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    loadData = () => {
        const { fromDate, toDate, currentAccount } = this.state;
        const { userInfo, curCustomer } = this.props
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const momentMinDate = moment(this.minDate);
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }

        const isBroker = this.isBroker()
        let custid = null
        custid = isBroker ? curCustomer ? curCustomer.custid : null : userInfo ? userInfo.custid : null;

        if (currentAccount && currentAccount.accounttype === "SEC") {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getHistoryRightoffAccountInfo(fromDate, toDate, custid)
                .then((data) => {
                    this._setState({ records: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                });
        }

        // if (currentAccount && currentAccount.accounttype === "FDS") {
        //     this._setState({ loadStatus: LoadStatus.LOADING });
        //     fdsInquiryService.getOrderHistory(currentAccount.id, fromDate, toDate, rowPerPage)
        //         .then((data) => {
        //             this._setState({ records: data.d, nextIndex: data.nextIndex, loadStatus: LoadStatus.LOADED });
        //         })
        //         .catch((error) => {
        //             this._setState({ loadStatus: LoadStatus.FAIL });
        //             ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
        //         });
        // }
    };

    genReport = (exptype) => {
        const { currentAccount, userInfo, currentCustomer } = this.props;
        let { fromDate, toDate, symbol, execType, orderStatus } = this.state;
        let custodycd = null;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        symbol = symbol ? symbol : reportParams.ALL;
        execType = execType ? execType : reportParams.ALL;
        orderStatus = orderStatus ? orderStatus : reportParams.ALL;
        if (userInfo.role === Role.CUSTOMER) {
            custodycd = userInfo.custodycd;
        } if (userInfo.role === Role.BROKER && currentCustomer) {
            custodycd = currentCustomer.custodycd;
        };
        if (currentAccount) {
            let rptId = '';
            let inpArr = [];

            rptId = 'orderHist';
            inpArr = [currentAccount.id, fromDate, toDate, symbol, execType, orderStatus, reportParams.NULL, reportParams.NULL, reportParams.OUT, reportParams.OUT];

            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    loadInitData = (isFistLoad) => {
        // Lấy danh sách trạng thái để tìm kiếm
        // const { accounts,  } = this.props;

        const { accounts, language, currentAccountId, curCustomer } = this.props;
        const isBroker = this.isBroker()

        // console.log('ha_check_accounts', accounts, curCustomer);
        if (isBroker && accounts && accounts.length === 0) {
            this._setState({ currentAccount: {}, listAccounts: [] },
                () => {
                    this.setReloadData("NOT_CALL_API")
                }
            );
            return
        }


        let newRow = (language === 'en') ? {
            id: "All",
            entypename: "Basis",
            accounttype: "ALL",
            custodycd: accounts[0] && accounts[0].custodycd
        } :
            {
                id: "Tất cả",
                entypename: "Cơ sở",
                accounttype: "ALL",
                custodycd: accounts[0] && accounts[0].custodycd
            }
        let newList = [newRow, ...accounts]
        if (isFistLoad) { // Mặc định tất cả khi mới vào màn hoặc môi giới mới nhập tài khoản careby hợp lệ
            this._setState({ currentAccount: newRow, listAccounts: newList },
                // this._setState({ currentAccount: filterAccount, listAccounts: newList },
                () => {
                    this.setReloadData()
                }
            );
        } else {
            let filterAccount = newList.find(item => item.id === currentAccountId);
            this._setState({ currentAccount: filterAccount, listAccounts: newList },
                () => {
                    this.setReloadData()
                }
            );
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        // document.addEventListener('keydown', this.handlerKeyDown);
        // const { accounts, currentAccountId } = this.props;
        // let filterAccount = accounts.find(item => item.id === currentAccountId)
        // this._setState({ currentAccount: filterAccount }, () => this.setReloadData());
        this.loadInitData(true);
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        // document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    // handlerKeyDown = (event) => {
    //     const keyCode = event.which || event.keyCode;
    //     if (keyCode === KeyCodeUtils.ENTER) {
    //         event.preventDefault();
    //         if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
    //         this.searchBtnRef.current.click();
    //     }
    // };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        // if (currentAccount !== prevCurrentAccount) {
        //     this.loadData();
        // }

        const { accounts, currentAccountId, language } = this.props

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            // let found = accounts.find(item => item.id === currentAccountId)
            let newRow = (language === 'en') ? {
                id: "All",
                entypename: "Basis",
                accounttype: "ALL",
                custodycd: accounts[0] && accounts[0].custodycd
            } :
                {
                    id: "Tất cả",
                    entypename: "Cơ sở",
                    accounttype: "ALL",
                    custodycd: accounts[0] && accounts[0].custodycd
                }
            this._setState({ currentAccount: newRow, listAccounts: [newRow, ...accounts] },
                // () => {
                //     this.setReloadData()
                // }
            );
        }
    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onExecTypeChanged = (event) => {
        this._setState({ execType: event.target.value });
    };

    onStatusChanged = (event) => {
        this._setState({ orderStatus: event.target.value });
    };

    //Set tiểu khoản chuyển

    setCurrentAccount = (event) => {
        // const { accounts } = this.props;
        // let found = accounts.find(item => item.id === event.target.value)
        // this._setState({ currentAccount: found })

        const { listAccounts } = this.state;
        let _value = event.target.value
        let found = listAccounts.find(item => item.id === _value)
        this._setState({ currentAccount: found });
    };


    onChangeRightEvent = (event) => {

    };

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.searchBtnRef.current && this.searchBtnRef.current.click();
        }
    };

    render() {
        const { language, accounts, curCustomer } = this.props;
        const { currentAccount, records, listRightEvent, onReloadData, listAccounts } = this.state;

        const isBroker = this.isBroker();

        return (
            <RetryableContent className="right-off-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="block right-off-history-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>

                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        // saveAccountInfo={true} // Lưu tài khoản với môi giới
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }

                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    ref={this.fromDatePicker}
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    ref={this.toDatePicker}
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search txt---400-14-20" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        {/* <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    <DownloadIcon />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="orderid" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            totalGroupDefault={totalGroupDefault}
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="right-off-register-history-paging" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    // let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage
    let _accountInfo = _layoutPage && _layoutPage[_currentLayoutPageActive]["accountInfo"]
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");

    return {
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: _accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        isDeskTop: state.app.isDeskTop,
        curCustomer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RightOffPerform));
