import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import './WizardComponent.scss';


class WizardComponent extends Component {

    static defaultProps = {
        step: 1,
        legends: []
    };

    state = {
        shorterLegend: [],
        activedLegends: []

    };

    getWizardClassName = () => {
        const { step, multiStep } = this.props;
        if (!multiStep && step <= 3) {
            return 'wizard step-' + step + '-active';
        } else {
            return 'wizard';
        }
    };

    // Lấy đoạn giữa của legend để gán vào mảng
    getShorterLegend = () => {
        const { legends } = this.props;
        if (legends.length > 3) {
            this._setState({ shorterLegend: legends.slice(1, legends.length - 1) }, () => {
                this.updateActivedStep();
            });
        } else {
            this._setState({ shorterLegend: legends.length > 1 ? legends[1] : [] })
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { multiStep } = this.props;
        if (multiStep) {
            this.getShorterLegend();
        }
    }

    // Chỉ được gọi khi shorterLegend.leng > 0
    updateActivedStep = () => {
        const { shorterLegend } = this.state;
        const { step } = this.props;
        let newActivedLegends = [];
        let nextStepIndex = step - 2;
        if (nextStepIndex >= 0 && shorterLegend.length > 0) {
            for (var i = 0; i <= nextStepIndex; i++) {
                newActivedLegends.push(shorterLegend[i]);
                this._setState({ activedLegends: newActivedLegends });
            }
        } else {
            this._setState({ activedLegends: [] });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { step, multiStep } = this.props;
        const { step: prevStep } = prevProps;
        const { shorterLegend } = this.state;
        let newActivedLegends = [];
        // Tạo một array đã kích hoạt để so sánh và style cho các nút đã actived
        if (step !== prevStep && multiStep) {
            this.updateActivedStep();
        }

    }

    onLegendClick = (stepClicked) => {
        const { onClick, clickable } = this.props;
        if (clickable === true && onClick) {
            onClick(stepClicked);
        }
    }

    render() {
        const { legends, multiStep, step, clickable } = this.props;
        const { shorterLegend, activedLegends } = this.state;

        let activeMsgId = "";
        if (multiStep && shorterLegend.length > 0 && step > 1) {
            activeMsgId = shorterLegend[step - 2];
        }

        return (
            <div className={this.getWizardClassName()}>
                <div className="row no-gutters">
                    {!multiStep &&
                        (
                            <Fragment>
                                <div className="col-auto">
                                    <div className="step step-1">
                                        <div className="step-icon">
                                            {step > 1 ? <i className="fas fa-check" style={{ fontWeight: '300' }} /> : 1}
                                            {/* <CustomIcon><Step1 /></CustomIcon> */}
                                        </div>
                                        <div className='step-title txt---400-16-24'>Thông tin</div>
                                    </div>
                                </div>
                                <div className='line line-1-2'></div>
                                <div className="col-auto">
                                    <div className="step step-2">
                                        <div className="step-icon">
                                            {step > 2 ? <i className="fas fa-check" style={{ fontWeight: '300' }} /> : 2}
                                            {/* <CustomIcon><Step2 /></CustomIcon> */}
                                        </div>
                                        <div className='step-title txt---400-16-24'>Xác nhận</div>
                                    </div>
                                </div>
                                <div className='line line-2-3'></div>
                                <div className="col-auto">
                                    <div className="step step-3">
                                        <div className="step-icon">
                                            3
                                            {/*<i className="fas fa-check" />*/}
                                            {/* <CustomIcon><Step3 /></CustomIcon> */}
                                        </div>
                                        <div className='step-title txt---400-16-24'>Kết thúc</div>
                                    </div>
                                </div>

                            </Fragment>


                        )}

                    {multiStep && shorterLegend.length > 0 && (<div className="col-auto">
                        <div
                            className={"step step-first " + (step === 1 ? "active" : "") + (step > 1 ? " actived" : "") + (clickable === true ? ' clickable' : '')}
                            onClick={this.onLegendClick.bind(null, 1)}
                        >
                            <div className="step-icon">
                                <i className="fas fa-keyboard" />
                                {/* <CustomIcon><Step1 /></CustomIcon> */}
                            </div>
                            <div className="step-legend">
                                {legends.length > 0 && (
                                    <FormattedMessage id={legends[0]} />
                                )}
                            </div>
                        </div>
                    </div>)}

                    {multiStep && shorterLegend.length > 0 &&
                        shorterLegend.map(element => {
                            let active = (activeMsgId !== "" && activeMsgId === element);
                            let actived = activedLegends.includes(element);
                            let activing = actived && active;
                            let stepLegend = legends.findIndex(item => item === element) + 1;
                            return (
                                <div className="col">
                                    <div
                                        className={"step step-middle " + (activing ? 'active' : "") + ((actived && !active) ? ' actived' : "") + (clickable === true ? ' clickable' : '')}
                                        onClick={this.onLegendClick.bind(null, stepLegend)}
                                    >
                                        <div className="step-icon">
                                            <i className="fas fa-lock-alt" />
                                            {/* <CustomIcon><Step2 /></CustomIcon> */}

                                        </div>
                                        <div className="step-legend">
                                            {legends.length > 1 && (
                                                <FormattedMessage id={element} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    {multiStep && legends.length > 3 && (

                        <div className="col-auto">
                            <div className={"step step-last " + (step === legends.length ? "active" : "")}>
                                <div className="step-icon">
                                    <i className="fas fa-check" />
                                    {/* <CustomIcon><Step3 /></CustomIcon> */}
                                </div>
                                <div className="step-legend">
                                    <FormattedMessage id={legends[legends.length - 1]} />
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        );
    }

}

export default WizardComponent;