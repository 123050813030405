import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { NumberInput2New } from "../../../../components/Input";
import { CommonUtils, FeeTypes, KeyCodeUtils, SELECTOR_ACCOUNT_SCREEN, stepValues, TransferStockTypes } from "../../../../utils";
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import SymbolDetailAction from 'components/SymbolDetailAction/SymbolDetailAction';

import { ReactComponent as ReloadIconDark } from '../../../../assets/icons/ReloadIcon_Dark.svg'
import { ReactComponent as ReloadIconLight } from '../../../../assets/icons/ReloadIcon_Light.svg'
import { LoadStatus } from 'constants/config';

let columnsDefault =
    [
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '30%',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Khối lượng sở hữu */
        {
            dataField: "trade",
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '30%',
            visible: true,
            alignment: "right",
            visibleIndex: 1,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
        /* Khối lượng có thể chuyển */
        {
            dataField: "avl",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '30%',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
        },
    ]
class StockTransferDetail extends Component {

    transferTypes = TransferStockTypes;
    feeTypes = FeeTypes;


    state = {
        hide: false,
    }

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	

    UNSAFE_componentWillMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this), false);
        this.mounted = false
    }

    resize() {
        let currentHideNav = (window.innerWidth <= 576);
        if (currentHideNav !== this.state.hideNav) {
            this._setState({ hide: currentHideNav });
        }
    }

    componentDidMount() {
        // this.trySetBenefitAccount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // this.trySetBenefitAccount();
        let { transferType, accountSend, accountReceived } = this.props
        if (prevProps.transferType !== transferType
            || prevProps.accountSend !== accountSend
            || prevProps.accountReceived !== accountReceived
        ) {
            // this.fillDescriptionSample();
        }
    }

    onBlurTransferAmount = () => {
        this.props.onBlurTransferAmount();
    }

    onChangeSymbolTransfer = (event) => {
        const symbol = event.target.value;
        this.props.onChangeSymbolTransfer(symbol);
    }

    onChangeQttySymbolTransfer = (qtty, valid) => {
        this.props.onChangeQttySymbolTransfer(qtty, valid);
    }

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'symbol':
                return (e) => <SymbolDetailAction symbol={e.row.data.symbol} />
            case 'trade':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.trade);
            // case 'basicPriceAmt':
            //     return (e) => RenderGridCell.renderGridCellPrice(e.row.data.basicPriceAmt);
            case 'avl':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.avl, 'PRICE');
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'symbol':
                return "stock-transfer.table.symbol-code";
            case 'trade':
                return "stock-transfer.table.owntrade";
            // case 'basicPriceAmt':
            //     return "stock-transfer.table.basic-price-amt";
            case 'avl':
                return "stock-transfer.table.avl-transfer";
            default:
                return null;
        }
    }

    genIcon = (keyIcon) => {
        const { defaultTheme } = this.props;
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? <ReloadIconDark onClick={() => { this.props.loadAvailableBalance(true) }} /> : <ReloadIconLight onClick={() => { this.props.loadAvailableBalance(true) }} />
                break;
            default:
                break;
        }
    }

    onHandleKeyDownSubmit = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.props;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (loadStatus === LoadStatus.LOADING)  return;
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    render() {
        const {
            preCheckInfoInput, username, onReloadData, apiLoadAll, totalMass,
            transferType, accountSend, setAccountSend, setAccountReceived, accountReceived, filterAccount,
            onTransferTypeChange, accounts, refresh, language, transferAmountValid,
            selectedSymbol, symbolTransferInfo, availableTranfer, quantityTransfer, isBroker, curCustomer, fillSymbolData, loadingAvl
        } = this.props;

        return (
            <div className="row gutters-5 h-100 auto-scroll">
                <div className="col-12 col-lg-6">
                    <div className='block'>
                        <div className='headB---700-16-24 title'>
                            <FormattedMessage id="stock-transfer.info-transaction" />
                        </div>
                        {/* Loại giao dịch */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.transaction-type" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group">
                                    <select value={transferType} onChange={onTransferTypeChange} className="custom-form-control txt---400-14-20">
                                        {this.transferTypes && this.transferTypes.map((transferType) => {
                                            return (
                                                <FormattedMessage key={transferType} id={'stock-transfer.transfer-type.' + transferType}>
                                                    {(txt) => (
                                                        <option value={transferType}>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tài khoản */}
                        {isBroker ?
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-6">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="stock-transfer.account" />
                                    </label>
                                </div>
                                <div className="col-6">
                                    <div className="custom-form-group txt---400-14-20">
                                        <SelectorAccountCustomer
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.LAYOUT}
                                            disabled={false}
                                        // className="custom-form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-6">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="stock-transfer.account" />
                                    </label>
                                </div>
                                <div className="col-6">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input className='custom-form-control' value={username || ''} readOnly />
                                    </div>
                                </div>
                            </div>
                        }
                        {/* Tiểu khoản chuyển    */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.sub-account-transfer" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group-select">
                                    <select value={accountSend ? accountSend.id : ''} onChange={setAccountSend} className="custom-form-select txt---400-14-20">
                                        {(!accounts || accounts.length === 0 || !accountSend) && (
                                            <FormattedMessage key="empty" id="header.choose-account">
                                                {(txt) => (
                                                    <option key="empty" value="" disabled>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        )}
                                        {accounts && accounts.map((account) => {
                                            return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tiểu khoản nhận */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.sub-account-received" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group-select">
                                    <select value={accountReceived ? accountReceived.id : ''} onChange={setAccountReceived} disabled={filterAccount.length <= 1} className="custom-form-select txt---400-14-20">
                                        {/* {(!filterAccount || filterAccount.length === 0) && (
                                            <FormattedMessage key="empty" id="header.choose-account">
                                                {(txt) => (
                                                    <option key="empty" value="" disabled>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        )} */}

                                        {filterAccount && filterAccount.length > 1 && (
                                            <option selected disabled></option>
                                        )}
                                        {filterAccount && filterAccount.map((account) => {
                                            return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Mã chứng khoán */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.stock-code" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group-select">
                                    <select value={selectedSymbol ? selectedSymbol : ''} onChange={this.onChangeSymbolTransfer} className="custom-form-select txt---400-14-20">
                                        {(!symbolTransferInfo || symbolTransferInfo.length === 0) && (
                                            <FormattedMessage key="empty" id="header.choose-symbol">
                                                {(txt) => (
                                                    <option key="empty" value="" disabled>{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        )}

                                        {symbolTransferInfo && symbolTransferInfo.length >= 1 && (
                                            <option selected disabled></option>
                                        )}

                                        {symbolTransferInfo && symbolTransferInfo.map((symbolTransfer, index) => {
                                            return (<option key={index} value={symbolTransfer.symbol}>{symbolTransfer.symbol}</option>)
                                            // if (isBroker) {
                                            //     return (
                                            //         <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                            //     )
                                            // }
                                            // return (
                                            //     <option key={account.id} value={account.id}>{account.afacctnoext}</option>
                                            // )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/* Tổng khối lượng */}
                        {/*<div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.total-mass" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group text-secondary-1 txt---400-14-20">
                                    <NumberInput2New
                                        ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={totalMass ? totalMass : 0}
                                        valid={true}
                                        onChange={null}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        readOnly={true}
                                        inputClassName="custom-form-control"
                                    />
                                </div>
                            </div>
                        </div>*/}
                        {/* Khối lượng có thể chuyển */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.max-KLCK" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                    <NumberInput2New
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={availableTranfer ? availableTranfer : 0}
                                        valid={true}
                                        onChange={null}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        readOnly={true}
                                        inputClassName={"custom-form-control " + (loadingAvl ? 'loading' : '')}
                                    />
                                    {this.genIcon(1)}
                                </div>
                            </div>
                        </div>

                        {/* Khối lượng chuyển */}
                        <div className="row gutters-5 align-items-center h-row">
                            <div className="col-6">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="stock-transfer.KLCK-moves" />
                                </label>
                            </div>
                            <div className="col-6">
                                <div className="custom-form-group-effect txt---400-14-20">
                                    <NumberInput2New
                                        // ref={transferType === TransferType.INTERNAL ? InternalMobileNearestControl : ExternalMobileNearestControl}
                                        name="transfer-amount"
                                        step={stepValues.PRICE}
                                        min={null}
                                        max={999999999999}
                                        value={quantityTransfer}
                                        valid={transferAmountValid}
                                        onChange={this.onChangeQttySymbolTransfer}
                                        allowZero={false}
                                        allowDecimal={false}
                                        allowNegative={false}
                                        className="need-focus-eff"
                                        // onBlur={this.onBlurTransferAmount}
                                        placeholder={CommonUtils.getPlaceHolderInput(language)}
                                        inputClassName="custom-form-control"
                                        onKeyDown={this.onHandleKeyDownSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Thao tác */}
                        <div className="row gutters-5 align-items-center h-row container-btn">
                            {/* Làm mới */}
                            <div className="col-6">
                                <button className="btn-refresh txt---500-16-24 btn" ref={this.refreshBtnRef} onClick={() => refresh()} onKeyDown={this.onHandleKeyDownRefresh}>
                                    <FormattedMessage id="common.btn-refresh" />
                                </button>
                            </div>
                            {/* Tiếp tục */}
                            <div className="col-6">
                                <button className="btn-confirm txt---500-16-24 btn" onClick={() => preCheckInfoInput()} ref={this.confirmBtnRef} onKeyDown={this.onHandleKeyDownSubmit}>
                                    <FormattedMessage id="common.btn-continue" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="col-12 block-right">
                        <div className='headB---700-16-24 title p-title'>
                            <FormattedMessage id="stock-transfer.stock-portfolio" />
                        </div>
                        <div className='h-table p-table-10'>
                            <TableExtremeCustom2
                                keyStoreUpdate="symbol" // key của row 
                                columnsDefault={columnsDefault} // ds cột
                                totalDefault={[]} // ds sum (nếu có)
                                renderSwitch={this.renderSwitch} // func render dữ liệu
                                returnCaption={this.returnCaption} // func render column name
                                isUseSelection={false} // true/false: hiện/ẩn chọn row
                                isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                                addClass="stock-transfer-all" // class cha
                                isPager={false} // hiển thị giao diện phân trang
                                //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                                onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                                defaultPageSizes={100}  //Truyền 
                                apiLoadAll={apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                                isCustomTopAndBottomSticky={false}
                                showFilter={true}
                                onRowDblClick={fillSymbolData}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }

}

export default StockTransferDetail;
