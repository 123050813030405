import React, { Component } from 'react';
import { Role } from 'constants/config';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ReactComponent as DownloadIconLight } from '../../../../assets/icons/new/DownloadIcon.svg'
import { ReactComponent as DownloadIconDark } from '../../../../assets/icons/new/DownloadIcon_Dark.svg'
import config from '../../../../combineConfig';
import { FormattedPrice } from "../../../../components/Formating";
import { DatePicker } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import SymbolSuggestTrans from '../../../../components/SymbolSuggestTrans';
import TextToolTip from "../../../../components/TextToolTip";
import { inquiryService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, KeyCodeUtils, LanguageUtils, LoadStatus, mDate, reportTypes, RPT_ID, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from "../../../../utils";

import './SecuritiesTransactionReportNew.scss';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';

const totalDefault =
    [
        {
            column: "cramt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "dramt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]


let columnsDefault =
    [
        /* Ngày */
        {
            dataField: "busdate",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            //minWidth: "40",
            width: '120',
            //maxWidth: "70",
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        /* Mã CK */
        {
            dataField: "symbol",
            dataType: "string",
            allowReordering: true,
            //minWidth: "50",
            width: '120',
            //maxWidth: "70",
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        /* Diễn giải */
        {
            dataField: "txdesc",
            dataType: "string",
            allowReordering: true,
            //minWidth: "300",
            //width: '400',
            //maxWidth: "400",
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        /* số dư đầu kỳ */
        {
            dataField: "tradepre",
            dataType: "string",
            allowReordering: true,
            //minWidth: "80",
            width: '120',
            //maxWidth: "80",
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        /* Phát sinh tăng */
        {
            dataField: "cramt",
            dataType: "string",
            allowReordering: true,
            //minWidth: "80",
            width: '120',
            //maxWidth: "80",
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        /* Phát sinh giảm */
        {
            dataField: "dramt",
            dataType: "string",
            allowReordering: true,
            //minWidth: "80",
            width: '120',
            //maxWidth: "80",
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        /* số dư cuối */
        {
            dataField: "running_amt",
            dataType: "string",
            allowReordering: true,
            //minWidth: "80",
            width: '120',
            //maxWidth: "80",
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
    ]
class SecuritiesTransactionReportNew extends Component {

    state = {
        fromDate: null,
        toDate: null,
        symbol: null,
        records: [],
        loadStatus: LoadStatus.NONE,
        currentAccount: {},
        onReloadData: true,
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    minDate = null;

    constructor(props) {
        super(props);
        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate;
        this.state.toDate = currentDate;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    checkDate = () => {
        const { fromDate, toDate } = this.state;
        let arrayDate = moment(fromDate).format('YYYY-MM-DD').split("-");
        arrayDate[1] -= 1; // thang trong array = thang hien tai - 1
        let rangeDate = moment(arrayDate).add(6, 'M');
        return moment(toDate).isBefore(moment(rangeDate));
    }

    

    loadData = () => {
        const { fromDate, toDate, symbol, currentAccount } = this.state;

        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const momentMinDate = moment(this.minDate);
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }

        if (currentAccount) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getSecuritiesStatementHist(currentAccount.id, fromDate, toDate, symbol)
                .then((data) => {
                    this._setState({ records: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                });
        }
    };

    apiLoadAll = async (obj) => {
        try {
            const { fromDate, toDate, currentAccount, symbol } = this.state;
            let isCoreBank = currentAccount && currentAccount.corebank === 'Y';

            let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

            if (!checkDateResult) {
                return [];
            }

            // const momentFromDate = moment(fromDate);
            // const momentToDate = moment(toDate);
            // const momentMinDate = moment(this.minDate);

            // if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            //     return [];
            // }

            // if (momentToDate.isBefore(momentFromDate)) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            //     return [];
            // }

            // if (!this.checkDate()) {
            //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
            //     return [];
            // }


            if (currentAccount) {
                return inquiryService.getSecuritiesStatementHist(currentAccount.id, fromDate, toDate, symbol)
                    .then((data) => {
                        if (data && data.length > 0) {
                            // let filterData = data.filter((e) => e.busdate !== null)
                            // return filterData
                            return data;
                        } else {
                            return []
                        }
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                        return []
                    });
            }
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    search = () => {
        this.setReloadData();
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    genReport = (exptype) => {
        let { fromDate, toDate, currentAccount, symbol } = this.state;
        fromDate = moment(fromDate).format(config.app.SERVER_DATE_FORMAT);
        toDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        if (currentAccount) {
            let symbolParam = symbol && symbol !== '' ? symbol : 'ALL';
            let rptId = '';
            let inpArr = [];
            rptId = RPT_ID.GET_SECURITIES_STATEMENT;
            //'23/10/2023','23/10/2023','ALL','072C000035','0001','ALL'
            inpArr = [custid, fromDate, toDate, symbolParam, currentAccount.custodycd, '0001', currentAccount.id]; //0001 hardcode anh Bình
            //inpArr = [currentAccount.id, fromDate, toDate, symbolParam];
            for (let i = 0; i < inpArr.length; i++) {
                let str = CommonUtils.stringifyInputExcel(inpArr[i]);
                inpArr[i] = str;
            };
            inquiryService.genReport({
                rptId: rptId,
                rptParams: _.join(inpArr, ','),
                exptype: exptype
            }).then(data => {
                ToastUtil.success('common.request-gen-report-success');
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-gen-report')
            })
        } else {
            ToastUtil.error('common.no-account');
        }
    };

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    renderTitleDesc = (row) => {
        const type = row.data.type;
        //type = 1: Số dư đầu kỳ,
        //type = 2: Chi tiết các thông tin Mã ck,
        //type = 3: Tổng cộng,
        return (

            <div className="txt-bold">
                {type == 1 && (
                    <>
                        <div className="text-left">
                            <FormattedMessage id="securities-transaction-report.begin-balance" />:
                            {/* <span> {row.data.symbol}: </span> */}
                            {/* <span> <FormattedPrice value={row.data.tradepre} /> </span> */}
                        </div>
                    </>
                )}

                {type == 3 && (
                    <>
                        <div className="text-center sum-row">
                            <FormattedMessage id="securities-transaction-report.sum" />
                            <span> {row.data.symbol}: </span>
                        </div>

                    </>
                )}

            </div>


        )
    }

    renderBeginBalance = (row) => {
        //type = 1: Số dư đầu kỳ,
        //type = 2: Chi tiết các thông tin Mã ck,
        //type = 3: Tổng cộng,
        const type = row.data.type;
        return (
            <>
                {type == 1 && (
                    <div className="text-right txt-bold">
                        <>
                            <span> <FormattedPrice value={row.data.tradepre} /> </span>
                        </>
                    </div>
                )}
            </>

        )
    }

    renderCellSymbolCustom = (row) => {
        //type = 1: Số dư đầu kỳ,
        //type = 2: Chi tiết các thông tin Mã ck,
        //type = 3: Tổng cộng,
        const type = row.data.type;
        return (
            <>
                {type == 1 && (
                    <div className="text-center txt-bold">
                        <>
                            <span>{row.data.symbol}</span>
                        </>
                    </div>
                )}
                {type == 2 && (
                    <div className="text-center">
                        <>
                            <span>{row.data.symbol}</span>
                        </>
                    </div>
                )}
            </>

        )
    }

    renderPriceCellCustom = (type, item) => {
        //type = 1: Số dư đầu kỳ,
        //type = 2: Chi tiết các thông tin Mã ck,
        //type = 3: Tổng cộng,
        return (
            <>
                {type == 3 && (
                    <div className="text-right txt-bold sum-row">
                        <>
                            <span> <FormattedPrice value={item} /> </span>
                        </>
                    </div>
                )}
                {type == 2 && (
                    <div className="text-right">
                        <>
                            <span> <FormattedPrice value={item} /> </span>
                        </>
                    </div>
                )}
            </>

        )
    }
    renderSwitch = (dataField) => {
        const lang = this.props.language;
        //type = 1: Số dư đầu kỳ,
        //type = 2: Chi tiết các thông tin Mã ck,
        //type = 3: Tổng cộng,
        switch (dataField) {
            case 'busdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.busdate)
            case 'symbol':
                return (e) => this.renderCellSymbolCustom(e.row);
            case 'txdesc':
                return (e) => e.row.data.type == 2 ? RenderGridCell.renderGridCellContent(e.row.data.txdesc) : this.renderTitleDesc(e.row);
            case 'tradepre':
                return (e) => this.renderBeginBalance(e.row);
            case 'cramt':
                return (e) => this.renderPriceCellCustom(e.row.data.type, e.row.data.cramt);
            case 'dramt':
                return (e) => this.renderPriceCellCustom(e.row.data.type, e.row.data.dramt);
            case 'running_amt':
                return (e) => e.row.data.type == 2 ? RenderGridCell.renderGridCellPrice(e.row.data.running_amt, 'PRICE') : null;
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'busdate':
                return "securities-transaction-report.date";
            case 'symbol':
                return "securities-transaction-report.symbol";
            case 'cramt':
                return "securities-transaction-report.remittanceDetail-Decr";
            case 'dramt':
                return "securities-transaction-report.remittanceDetail-Inc";
            case 'txdesc':
                return "securities-transaction-report.desc";
            case 'tradepre':
                return "securities-transaction-report.begin-balance";
            case 'running_amt':
                return "securities-transaction-report.running-amt";
            default:
                return null;
        }
    }

    loadInitData = () => {
        const { accounts, currentAccountId } = this.props;
        let found = accounts.find(item => item.id === currentAccountId)
        this._setState({ currentAccount: found });
        //document.addEventListener('keydown', this.handlerKeyDown);
    }

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData()
        this.setReloadData("NOT_CALL_API")
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        //document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        // const changed = currentAccount !== prevCurrentAccount;
        // if (currentAccount) {
        //     if (changed) {
        //         this.loadData();
        //     }
        // } else {
        //     if (this.state.accountId) {
        //         this._setState({ records: [], loadStatus: LoadStatus.NONE });
        //     }
        // }
        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData()
        }
    }

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    getTotals = (data, key) => {
        let total = 0;
        data.forEach(item => {
            if (item["tran_symbol"] !== null)
                total += item[key];
        });
        return total;
    };

    render() {
        const { accounts, accountInfo } = this.props;
        const { currentAccount, onReloadData } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        fullName = CommonUtils.getDisplayName(fullName);
        return (
            <RetryableContent className="securities-transaction-report report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="securities-transaction-report-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!accounts || accounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {accounts && accounts.map((account) => {
                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                    })}
                                </select>
                            </div>
                            {isBroker && <div className="custom-form-group-n fullname">
                                <div className='align-self-center txt---400-14-20'>
                                    {fullName}
                                </div>
                            </div>}
                            <SymbolSuggestTrans
                                handleInputChange={this.onSymbolChanged}
                                currentSymbol={this.state.symbol}
                                symbolToDisplay={this.state.symbol}
                                suggestId="securities-transaction-report "
                                isRedirect={false}
                                isDisplayError={false}
                                placeholder={LanguageUtils.getMessageByKey("securities-transaction-report.all", this.props.lang)}
                            />
                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.fromDate}
                                    onChange={this.onFromDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                <DatePicker
                                    className="custom-form-control text-left txt---400-14-20"
                                    value={this.state.toDate}
                                    onChange={this.onToDateChanged}
                                    minDate={this.minDate}
                                />

                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDown}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        <div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="busdate" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={[]} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="securities-transaction-all" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            //apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                            isCustomTopAndBottomSticky={false}
                            showFilter={false}
                            legacyMode={true}
                        />
                    </div>
                </div>
            </RetryableContent>

        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        isDeskTop: state.app.isDeskTop,
        lang: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        userInfo: state.user.userInfo,
        defaultTheme: defaultTheme,
        customerFullName: state.user.customerFullName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data }))
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SecuritiesTransactionReportNew));
