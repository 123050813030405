import React, { Component } from 'react';
import { FormattedPrice, FormattedDate } from 'components/Formating';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';

import { LoadStatus, } from "../../utils";

import './ExtendDebtManagement.scss';
import RetryableContent from "../../components/RetryableContent";

class ExtendDebtManagement extends Component {


    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    state = {
        ...this.initialState
    };


    render() {
        const { row, typename, isOpen, onPreCheck, toggle, currentAccount, onFromDateChanged, fromDate, dateConverter, loadStatusExtend } = this.props;
        return (
                <Modal
                    isOpen={isOpen}
                    centered={true}
                    scrollable={true}
                    className="extend-debt-management"
                >
                    <div className="custom-header">
                        <div className=" headB---700-16-24">
                            <FormattedMessage id="debt-management.extend-title" />
                        </div>
                        <div className="btn-icon-fm" onClick={toggle}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <ModalBody className="custom-body">
                        <RetryableContent status={loadStatusExtend} disableRetry={true} className="check-debt-management-report">
                        <div style={{ overflow: "auto", paddingRight: "16px" }}>
                            {/* Mã lịch vay */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.loan-schedule-code" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{row.autoid}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Tài khoản */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.custodycd" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{currentAccount.custodycd}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Tiểu khoản */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.sub" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{currentAccount.cdcontent}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Ngày giải ngân */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.disbursement-date" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{row.rlsdate}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Ngày đáo hạn */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.date-due" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span><FormattedDate value={row.overduedate_val} /></span>
                                    </div>
                                </div>
                            </div>

                            {/* Ngày bắt đầu gia hạn */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.renewal-start-date" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{row.overduedate}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Ngày đến hạn mới */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.new-due-date" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        {/* <DatePicker
                                        className="custom-form-control"
                                        value={fromDate}
                                        onChange={onFromDateChanged}
                                    /> */}
                                        <span>{fromDate}</span>
                                    </div>
                                </div>
                            </div>


                            {/* Số lần đã gia hạn */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.renewed-number-of-times" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{row.extimes}</span>
                                    </div>
                                </div>
                            </div>


                            {/* Số ngày gia hạn */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.number-of-extension-days" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{dateConverter(row.overduedate, row.nextoverduedate)}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Gốc còn lại */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.remaining-original" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <FormattedPrice value={row.prinamt} />
                                    </div>
                                </div>
                            </div>

                            {/* Lãi tạm tính */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.unpaid-interest" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <FormattedPrice value={row.intamt} />
                                    </div>
                                </div>
                            </div>

                            {/* Số dư tiền mặt */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.cash-balance" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <FormattedPrice value={row.baldefovd} />
                                    </div>
                                </div>
                            </div>

                            {/* Phí gia hạn */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.renewal-fee" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <FormattedPrice value={row.feeamt} />
                                    </div>
                                </div>
                            </div>

                            {/* Lãi suất khoản vay */}
                            {/* <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.loan-interest-rate" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <span>{row.interestrate}%</span>
                                    </div>
                                </div>
                            </div> */}

                            {/* Tổng phải trả */}
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id="debt-management.extend.total-pay" />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group">
                                        <FormattedPrice value={row.sumpaid} />
                                    </div>
                                </div>
                            </div>

                            {/* Lãi suất sau gia hạn */}
                            {/* <div className="row gutters-5 align-items-center h-row">
                            <div className="col-5">
                                <label className="label txt---400-14-20">
                                    <FormattedMessage id="debt-management.extend.interest-rate-after-extension" />
                                </label>
                            </div>
                            <div className="col-7">
                                <div className="custom-form-group">
                                    {row.interestrateadd}%
                                    <FormattedMessage id="debt-management.year" />
                                </div>
                            </div>
                        </div> */}

                            <div className="row gutters-5 align-items-center h-row container-btn">
                                {/* Làm mới */}
                                <div className="col-6">
                                    <button className="btn-refresh txt---500-16-24 btn"
                                        onClick={toggle}
                                    >
                                        <FormattedMessage id="common.btn-back" />
                                    </button>
                                </div>
                                {/* Tiếp tục */}
                                <div className="col-6">
                                    <button className="btn-confirm txt---500-16-24 btn"
                                        onClick={() => onPreCheck()}
                                    >
                                        <FormattedMessage id="common.btn-continue"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        </RetryableContent> 
                    </ModalBody>             
                </Modal >    
        );
    }

}



export default ExtendDebtManagement;