import React, { Component } from 'react';
import { push } from "connected-react-router";
import RightOffRegisterHistory from 'containers/Account/Utilities/RightOffRegister/RightOffRegisterHistory';
import CancelRightList from 'containers/Account/Utilities/RightOffRegister/CancelRightList';
import { connect } from 'react-redux';

import * as actions from "../../../../store/actions";

import ListRightOff from './ListRightOff';
// import RightOffPerform from './RightOffPerform';

import './RightOffRegister.scss';
import CustomMenuTab from 'components/CustomMenuTab/CustomMenuTab';

const TAB_SCREEN = {
    HIS_RIGHT_OFF: 'HIS_RIGHT_OFF',
    PERFORM_RIGHT_OFF: 'PERFORM_RIGHT_OFF',
    CANCEL_RIGHT_LIST: 'CANCEL_RIGHT_LIST'
}
class RightOffRegister extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        tabActive: TAB_SCREEN.HIS_RIGHT_OFF
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	
		
	componentWillUnmount() {
        this.mounted = false
    }

    onChangeTabActive = (tab) => {
        this._setState({ tabActive: tab })
    }

    render() {
        const { tabActive } = this.state
        return (
            <div className="right-off-register">
                <ListRightOff />
                <div className="right-off-look-up">
                    <div className='block block-custom-tab'>
                        <div className="header-block-custom-tab">
                            <CustomMenuTab listTab={[
                                {
                                    title: "account.report.history.right-off-register-history.registration-history",
                                    value: TAB_SCREEN.HIS_RIGHT_OFF,
                                },
                                // {
                                //     title: "account.report.history.right-off-register-history.exercise-right",
                                //     value: TAB_SCREEN.PERFORM_RIGHT_OFF,
                                // },
                                {
                                    title: "account.report.history.right-off-register-history.cancel-right-register",
                                    value: TAB_SCREEN.CANCEL_RIGHT_LIST,
                                },
                            ]}
                                addClassTitle={"headB---700-16-24 "}
                                onChangeTab={this.onChangeTabActive}
                                activeTab={tabActive}
                            />
                        </div>
                        <div className="body-block-custom-tab ">
                            {
                                tabActive === TAB_SCREEN.HIS_RIGHT_OFF && <RightOffRegisterHistory />
                            }
                            {/* {
                                tabActive === TAB_SCREEN.PERFORM_RIGHT_OFF && <RightOffPerform />
                            } */}
                            {
                                tabActive === TAB_SCREEN.CANCEL_RIGHT_LIST && <CancelRightList />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");

    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightOffRegister);

