import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import RetryableContent from "../../components/RetryableContent";
import { inquiryService, transferService } from "../../services";
import * as actions from '../../store/actions'
import ExtendDebtManagement from './ExtendDebtManagement';
import _ from 'lodash';

import './DebtManagement.scss';

import { CommonUtils, KeyCodeUtils, LoadStatus, ModalConfirmType, SELECTOR_ACCOUNT_SCREEN, ToastUtil } from " ../../utils";
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import { Role } from 'constants/config';

let columnsDefault =
    [
        {
            dataField: "action",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "autoid",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "releaseamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "prinamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "intamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        {
            dataField: "iprinamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "190",
            width: '200',
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        {
            dataField: "rlsdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        {
            dataField: "overduedate_val",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "feerate",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        // {
        //     dataField: "interestrate",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "90",
        //     width: '100',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 9,
        // },
        {
            dataField: "extimes",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "center",
            visibleIndex: 10,
        },
        {
            dataField: "extenddays",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "center",
            visibleIndex: 11,
        },
    ]


const totalDefault =
    [
        {
            column: "releaseamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "prinamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "intamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "iprinamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }

    ]

let columnsDefaultBroker =
    [
        {
            dataField: "autoid",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "releaseamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "120",
            width: '140',
            visible: true,
            alignment: "right",
            visibleIndex: 1,
        },
        {
            dataField: "prinamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "right",
            visibleIndex: 2,
        },
        {
            dataField: "intamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "iprinamt",
            dataType: "string",
            allowReordering: true,
            minWidth: "190",
            width: '200',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        {
            dataField: "rlsdate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        {
            dataField: "overduedate_val",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        {
            dataField: "feerate",
            dataType: "string",
            allowReordering: true,
            minWidth: "90",
            width: '140',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        // {
        //     dataField: "interestrate",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "90",
        //     width: '100',
        //     visible: true,
        //     alignment: "right",
        //     visibleIndex: 8,
        // },
        {
            dataField: "extimes",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "center",
            visibleIndex: 9,
        },
        {
            dataField: "extenddays",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '170',
            visible: true,
            alignment: "center",
            visibleIndex: 10,
        },
    ]

class DebtManagement extends Component {

    constructor(props) {
        super(props);
        this.searchBtnRef = React.createRef();
        this.TransAuthExternalRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();
    }

    state = {
        records: [],
        transactionId: '',
        fromDate: "",
        loadStatus: LoadStatus.NONE,
        currentAccount: {},
        isOpenPay: false,
        isOpenExtend: false,
        transferAmount: null,                       //Số tiền chuyển
        transferAmountValid: false,                 //Kiểm tra giá trị số tiền chuyển
        onReloadData: true,
        loadStatusExtend: LoadStatus.NONE,
    };


    onTransferAmountChange = (amount, valid) => {
        this._setState({ transferAmount: amount, transferAmountValid: valid });
    };


    minDate = null;


    togglePay = (row) => {
        const { isOpenPay } = this.state;
        if (row) {
            this._setState({ isOpenPay: !isOpenPay, row: row, transferAmount: null, transferAmountValid: false });
        } else {
            this._setState({ isOpenPay: !isOpenPay, transferAmount: null, transferAmountValid: false });
        }
    }

    toggleExtend = (row) => {
        const { isOpenExtend } = this.state;
        if (row) {
            this._setState({ isOpenExtend: !isOpenExtend, row: row, fromDate: row.nextoverduedate });
        } else {
            this._setState({ isOpenExtend: !isOpenExtend });
        }
    }

    renderSwitch = (dataField) => {
        const lang = this.props.language;
        switch (dataField) {
            case 'autoid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.autoid);
            case 'releaseamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.releaseamt, 'PRICE');
            case 'prinamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.prinamt, 'PRICE');
            case 'intamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.intamt, 'PRICE');
            case 'iprinamt':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.iprinamt, 'PRICE');
            case 'rlsdate':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rlsdate);
            case 'overduedate_val':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.overduedate_val);
            case 'feerate':
                return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.feerate);
            // case 'interestrate':
            //     return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.interestrate);
            case 'extimes':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.extimes);
            case 'extenddays':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.extenddays);
            case 'action':
                return (e) => this.renderAction(e.row.data);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'autoid':
                return "debt-management.table.loan-code";
            case 'releaseamt':
                return "debt-management.table.initial-debt";
            case 'prinamt':
                return "debt-management.table.remaining-original-debt";
            case 'intamt':
                return "debt-management.table.unpaid-interest-balance";
            case 'iprinamt':
                return "debt-management.table.total-current-outstanding-balance";
            case 'rlsdate':
                return "debt-management.table.borrowing-date";
            case 'overduedate_val':
                return "debt-management.table.date-of-maturity";
            case 'feerate':
                return "debt-management.table.feerate";
            // case 'interestrate':
            //     return "debt-management.table.interest-rate";
            case 'extimes':
                return "debt-management.table.number-of-renewals";
            case 'extenddays':
                return "debt-management.table.number-of-extension-days";
            case 'action':
                return "";
            default:
                return null;
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    transactionCbPay = () => {
        this._setState({ row: null, isOpenPay: false });
        ToastUtil.success('account.utilities.cash-transfer.success', 'iflex.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    genInfoProcessPay = () => {
        const { row, transferAmount, currentAccount } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        processAuthFunc = transferService.processRightOffPaymentLoan;
        transferBody = {
            autoid: row.autoid,
            payamount: transferAmount,
            fee: 0,
            accountId: currentAccount.id
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    apiLoadAll = async (obj) => {
        const { fromDate, toDate, symbol, currentAccount } = this.state;
        try {
            if (currentAccount) {
                return inquiryService.getMarginInfo(currentAccount.id)
                    .then((data) => {
                        if (data && data.length > 0) {
                            data.forEach(item => {
                                item['iprinamt'] = Number(item['intamt']) + Number(item['prinamt']); // gán để tính tổng
                            });
                        }
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-debt-management');
                        return []
                    });
            } else {
                return []
            }
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    renderAction = (record) => {
        let isBroker = this.isBroker()
        return (
            <div className="d-flex text-active" style={{ gap: "4px", justifyContent: "center" }} >
                {
                    !isBroker &&
                    <button
                        className={"btn-pay txt---400-12-18 " + (record.extendstatus !== "Y" ? "text-custom" : "bg-pay text-custom")}
                        onClick={() => this.toggleExtend(record)}
                        style={{ cursor: record.extendstatus !== "Y" ? "not-allowed" : "pointer" }}
                        disabled={record.extendstatus !== "Y"}>
                        <FormattedMessage id="debt-management.table.extend" />
                    </button>
                }
                {/* {
                    !isBroker && <button className="btn-extend txt---400-12-18" onClick={() => this.togglePay(record)}>
                        <FormattedMessage id="debt-management.table.pay" />
                    </button>
                } */}
            </div>
        )
    }

    genInfoProcessExtend = () => {
        const { row, transferAmount, currentAccount, fromDate } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        processAuthFunc = transferService.processRightOffPayLoan;
        transferBody = {
            autoid: row.autoid,
            accountId: currentAccount.id,
            todate: moment(fromDate, "DD/MM/YYYY").format("DD/MM/YYYY"),
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    refresh = () => {
        this.loadData()
    };

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    getDataInfoPay = () => {
        const { loadStatus, row, currentAccount, transferAmount } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoProcessPay()
        _info = {
            currentAccount: currentAccount,
            row: row,
            transferAmount: transferAmount,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            loadStatus: loadStatus,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            backStep: () => { this.backStepPay() },
            refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbPay() },
            setLoadStatus: (status) => { this.setLoadStatus(status) },
            title: "common.confirm-trade",
        }
        return _info || {}
    }

    backStepPay = () => {
        //close and NOT reset data
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
        })
        this._setState({
            isOpenPay: true,
        })
    }

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    transactionCbExtend = () => {
        this._setState({ loadStatus: LoadStatus.LOADED })
        ToastUtil.success('account.utilities.cash-transfer.success', 'debt-management.sucess-extend');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            loadStatusScreenConfirm: LoadStatus.LOADED,
        });
        this.toggleExtend();
        this.setReloadData();
    };

    getDataInfoExtend = () => {
        const { loadStatus, row, currentAccount, transferAmount, fromDate } = this.state;
        let _info = null
        let { transferBody, processAuthFunc } = this.genInfoProcessExtend()
        let _fromDate = moment(fromDate, "DD/MM/YYYY").format("DD/MM/YYYY")
        let _extenddays = this.dateConverter(row.overduedate, _fromDate)
        _info = {
            extenddays: _extenddays,
            currentAccount: currentAccount,
            row: row,
            transferAmount: transferAmount,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            loadStatus: loadStatus,
            fromDate: _fromDate,
            //onSubmit: () => { this.handleProcessExtend() },
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCbExtend() },
            title: "common.confirm-trade",
            titleErrorApi: "debt-management.extend.extend-faild"
        }
        return _info || {}
    }

    handleProcessExtend = () => {
        const { row, currentAccount, fromDate, transactionId } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        transferBody = {
            autoid: row.autoid,
            todate: moment(fromDate, "DD/MM/YYYY").format("DD/MM/YYYY"),
            //"transactionId": transactionId,
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)


        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            loadStatusScreenConfirm: LoadStatus.LOADING,
        })
        transferService.processRightOffPayLoan(currentAccount.id, transferBody)
            .then((data) => {
                ToastUtil.success('account.utilities.cash-transfer.success', 'debt-management.sucess-extend');
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    loadStatusScreenConfirm: LoadStatus.LOADED,
                    isOpenScreenConfirm: false
                })
                this.toggleExtend()
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("ScreenConfirm", {
                    loadStatusScreenConfirm: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'common.fail-to-can-not-make-transaction');
            });
    }

    checkPay = () => {
        const { row, transferAmount, currentAccount } = this.state;

        //Check số tiền chuyển
        if (!transferAmount) {
            if (transferAmount === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'debt-management.error.002');
                return;
            }
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'debt-management.error.001');
            return;
        }
        if (transferAmount > row.avlbal) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'debt-management.error.003');
            return;
        }

        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        precheckCashtransferFunct = transferService.checkLoanPaymentDeal;
        checkTransferInfoBody = {
            "autoid": row.autoid,
            "payamount": transferAmount,
            "fee": row.feeamt,
        };

        this._setState({ loadStatus: LoadStatus.LOADING });
        precheckCashtransferFunct(currentAccount.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    this._setState({ isOpenPay: false, loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                        let _dataInfo = this.getDataInfoPay(row)
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.DEBT_MANAGEMENT_PAY,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    checkExtend = () => {
        const { row, currentAccount, fromDate } = this.state;
        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        precheckCashtransferFunct = transferService.checkLoanExtendDeal;
        let isValidDate = fromDate.toString() === 'Invalid Date'
        if (isValidDate) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'DEBT_MANAGEMENT');
            return;
        }
        checkTransferInfoBody = {
            "autoid": row.autoid,
            "todate": moment(fromDate, "DD/MM/YYYY").format("DD/MM/YYYY")
        };
        this._setState({ loadStatusExtend: LoadStatus.LOADING });
        precheckCashtransferFunct(currentAccount.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    this._setState({ loadStatusExtend: LoadStatus.LOADED, step: 2, transactionId: transactionId }, () => {
                        let _dataInfo = this.getDataInfoExtend()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.DEBT_MANAGEMENT_EXTEND,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatusExtend: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    loadData = () => {
        const { currentAccount } = this.state;
        if (currentAccount) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getMarginInfo(currentAccount.id)
                .then((data) => {
                    // this._setState({ records: [], loadStatus: LoadStatus.LOADED });
                    this._setState({ records: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-debt-management');
                });
        }
    };


    // Does not care about Data
    tryLoadData = (prevState) => {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const changed = currentAccount !== prevCurrentAccount;
        if (currentAccount) {
            if (changed) {
                this.loadData();
            }
        } else {
            if (this.state.accountId) {
                this._setState({ records: [], loadStatus: LoadStatus.NONE });
            }
        }
    };

    onFromDateChanged = (dates) => {
        if (dates) {
            this._setState({ fromDate: dates[0] });
        }
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;
        let isBroker = this.isBroker()
        let found = accounts.find(item => item.id === currentAccountId)
        this._setState({ currentAccount: found },
            () => {
                this.setReloadData()
                // if (isBroker) {
                //     this.setReloadData("NOT_CALL_API")
                // } else {
                //     this.setReloadData()
                // }
            }
        );

        document.addEventListener('keydown', this.handlerKeyDown);
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectAccountCustomerRef);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const changed = currentAccount !== prevCurrentAccount;
        const { language, accounts, currentAccountId, socketCIAction, socketSEAction, socketDTAction, socketDOAction, } = this.props;

        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            language: prevlanguage
        } = prevProps;


        if ((!_.isEmpty(prevState.currentAccount) && currentAccount && !_.isEmpty(currentAccount) && JSON.stringify(currentAccount) !== JSON.stringify(prevState.currentAccount)) && currentAccount !== '') {
            this.setReloadData();
        }

        if (currentAccount && currentAccount.id && (socketCIAction[currentAccount.id] !== prevSocketCIAction[currentAccount.id] || socketSEAction[currentAccount.id] !== prevSocketSEAction[currentAccount.id])) {
            this.setReloadData();
        }

        if (changed) {
            this.setReloadData()
        }

        if (language !== prevlanguage) {
            this.setReloadData()
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            let found = accounts.find(item => item.id === currentAccountId)
            this._setState({ currentAccount: found },
                () => {
                    this.setReloadData()
                }
            );
        }
    }


    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };


    dateConverter = (startDate, timeEnd) => {
        let start = moment(startDate, 'DD/MM/YYYY');
        let end = moment(timeEnd, 'DD/MM/YYYY');
        let daysDiff = end.diff(start, 'days');
        return daysDiff
    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    render() {
        const { accounts, accountInfo } = this.props;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const { currentAccount, isOpenExtend, isOpenPay, row, transferAmount, transferAmountValid, fromDate, onReloadData } = this.state;
        let fullName = isBroker ? (curCustomer.fullName || '') : this.props.customerFullName;
        fullName = CommonUtils.getDisplayName(fullName);
        return (
            <RetryableContent className="debt-management-report report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="debt-management-report-content">
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n txt---400-14-20 padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group txt---400-14-20">
                                        <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control">
                                    {(!accounts || accounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {accounts && accounts.map((account) => {
                                        return (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                    })}
                                </select>
                            </div>

                            {isBroker && <div className="custom-form-group-n fullname">
                                <div className='align-self-center txt---400-14-20'>
                                    {fullName}
                                </div>
                            </div>}
                        </div>
                    </div>

                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="autoid" // key của row 
                            columnsDefault={this.isBroker() ? columnsDefaultBroker : columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={false} // true/false: hiện/ẩn chọn row
                            isLoadPanel={true} // true/false: hiện/ẩn iconLoadPanel
                            addClass="debt-management-all" // class cha
                            isPager={false} // hiển thị giao diện phân trang
                            apiLoadAll={this.apiLoadAll} // sử dụng Api phân trang: không trả ra totalCount
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            defaultPageSizes={100}  //Truyền 
                            showFilter={false}
                        />
                    </div>

                    {
                        isOpenExtend &&
                        <ExtendDebtManagement
                            isOpen={isOpenExtend}
                            toggle={this.toggleExtend}
                            row={row}
                            onPreCheck={this.checkExtend}
                            currentAccount={currentAccount}
                            fromDate={fromDate}
                            dateConverter={this.dateConverter}
                            onFromDateChanged={this.onFromDateChanged}
                            loadStatusExtend={this.state.loadStatusExtend}
                        ></ExtendDebtManagement>
                    }

                    {/* {
                        isOpenPay &&
                        <PayDebtManagement
                            isOpen={isOpenPay}
                            toggle={this.togglePay}
                            row={row}
                            onPreCheck={this.checkPay}
                            currentAccount={currentAccount}
                            dateConverter={this.dateConverter}
                            language={this.props.language}
                            transferAmount={transferAmount}
                            transferAmountValid={transferAmountValid}
                            onTransferAmountChange={this.onTransferAmountChange}
                        ></PayDebtManagement>
                    } */}

                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts.filter(item => item.accounttype !== "FDS");
    const userInfo = state.user.userInfo;
    return {
        language: state.app.language,
        accounts: filterAccount,
        currentAccountId: currentAccountId,
        curCustomer: curCustomer,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        userInfo: userInfo,
        isDeskTop: state.app.isDeskTop,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        customerFullName: state.user.customerFullName,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtManagement));