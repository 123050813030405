import React, { Component } from 'react';
import { push } from "connected-react-router";
import _ from 'lodash';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ReactComponent as View } from '../../../../assets/icons_new/view-dark.svg'
import { FormattedAmount } from "../../../../components/Formating";
import { NumberInput2New } from "../../../../components/Input";
import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService } from "../../../../services";
import * as actions from '../../../../store/actions'
import { CommonUtils, LoadStatus, ModalConfirmType, Role, ToastUtil, stepValues, SELECTOR_ACCOUNT_SCREEN, KeyCodeUtils } from "../../../../utils";
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import CustomScrollbars from './../../../../components/CustomScrollbars';
import { FormattedPercentage, FormattedPrice } from './../../../../components/Formating';
import RightOffDetailModal from './RightOffDetailModal';

import { ReactComponent as ReloadIconDark } from '../../../../assets/icons/ReloadIcon_Dark.svg'
import { ReactComponent as ReloadIconLight } from '../../../../assets/icons/ReloadIcon_Light.svg'

import './ListRightOff.scss';
import { Fragment } from 'react';

class ListRightOff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.registerAmountRef = React.createRef();
        this.transAuthRef = React.createRef();
        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
    }

    initialState = {
        loadStatus: LoadStatus.NONE,
        dataListRightOff: [],                       // Danh sách đăng ký quyền mua

        checkAgree: false,                        // Kiểm tra xem có đồng ý mua hay không
        symbol: "",                                 // Thông tin nhập mã symbol
        currentAccount: {},                         // Thông tin tài khoản

        registerAmountValid: false,
        registerQtty: null,
        registerAmout: 0,
        isOpenRightOffDetailModal: false,
        dataView: {},
        dataFill: {},
        accountSummary: {}
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    refresh = () => {
        this._setState({
            ...this.initialState,
            currentAccount: this.state.currentAccount // refresh không clear thông tin tiểu khoản
        });
        this.loadDataRightOffList();
        this.loadAllData();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        this.loadDataRightOffList() // Không dựa vào đầu vào tiểu khoản
        this._setState({ currentAccount: filterAccount },
            // () => {
            //     this.loadAllData();
            // }
        );
    }

    componentWillUnmount() {
        this.mounted = false
    }

    loadAllData = () => {
        this.loadSummaryAccount() // Load thông tin tiền
    }

    loadDataRightOffList = () => {
        let custid = this.isBroker() ? this.props.curCustomer.custid : this.props.userInfo.custid
        this._setState({ loadStatus: LoadStatus.LOADING });
        if (custid) {
            inquiryService.getRightOffList(custid)
                .then((data) => {
                    this._setState({ dataListRightOff: data, loadStatus: LoadStatus.LOADED });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-security-transaction-history');
                });
        } else {
            this._setState({ loadStatus: LoadStatus.FAIL });
        }
    };

    loadSummaryAccount = () => {
        const { currentAccount } = this.state;
        if (!_.isEmpty(currentAccount)) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getSummaryAccount(currentAccount && currentAccount.id)
                .then((data) => {
                    this._setState({
                        accountSummary: data,
                        loadStatus: LoadStatus.LOADED,
                    });
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                });
        }
    }


    onChangeCheckAgree = () => {
        const { checkAgree } = this.state;
        this._setState({ checkAgree: !checkAgree });
    };


    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found }, () => this.refresh())
    };


    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onChangeRegisterAmount = (amount, valid) => {
        const { dataFill } = this.state
        let _registerAmout = Number(amount * dataFill.exprice) || 0
        this._setState({ registerQtty: amount, registerAmountValid: valid, registerAmout: _registerAmout });
    };


    checkRightOffRegister = () => {
        const { registerQtty, dataFill, registerAmout, checkAgree } = this.state;
        const { currentAccount } = this.state;

        if (!registerQtty) {
            if (registerQtty === 0) {
                ToastUtil.error('account.utilities.right-off-register.title-step2', 'RIGHT_OFF_REGISTER_1');
                return;
            }
            ToastUtil.error('account.utilities.right-off-register.title-step2', 'RIGHT_OFF_REGISTER_2');
            return;
        }

        if (Number(registerQtty) > dataFill.avlqtty) {
            ToastUtil.error('account.utilities.right-off-register.title-step2', 'RIGHT_OFF_REGISTER_3');
            return;
        }
        if (!checkAgree) {
            ToastUtil.error('account.utilities.right-off-register.title-step2', 'RIGHT_OFF_REGISTER_4');
            return;
        }
        const checkRightOffRegisterBody = {
            "camastid": dataFill.camastid,
            "quantity": registerQtty,
            "amount": registerAmout,
        };
        this._setState({ loadStatus: LoadStatus.LOADING });
        transferService.checkRightOffRegister(currentAccount.id, checkRightOffRegisterBody)
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataInfoToggleRightOff()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.RIGHT_OFF,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                }
                else {
                    this._setState({ loadStatus: LoadStatus.LOADED });
                    ToastUtil.error('common.fail-to-precheck-transactions');
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };


    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state

        const { socketCIAction, socketSEAction, socketODAction } = this.props;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction
        } = prevProps;
        if (currentAccount !== prevState.currentAccount) {
            this.loadAllData()
        }

        if (socketCIAction[currentAccount && currentAccount.id] !== prevSocketCIAction[currentAccount && currentAccount.id] || socketSEAction[currentAccount && currentAccount.id] !== prevSocketSEAction[currentAccount && currentAccount.id] || socketODAction[currentAccount && currentAccount.id] !== prevSocketODAction[currentAccount && currentAccount.id]) {
            this.loadSummaryAccount();
            this.loadDataRightOffList();
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadDataRightOffList()
        }

    }

    onViewDetail = (record) => {
        this._setState({ dataView: record, isOpenRightOffDetailModal: true })
    }

    onFillData = (record) => {
        const { accounts } = this.props;
        // let accountFound = accounts.find(item => item.id === record.ciaccount);
        let filterAccount = accounts.find(item => item.id === record.ciaccount)
        this._setState({
            dataFill: record,
            registerAmountValid: false,
            registerQtty: null,
            registerAmout: 0,
            currentAccount: filterAccount ? filterAccount : {}
        }, () => {
            this.loadAllData();
        })

    }

    toogleOpenRightOffDetailModal = () => {
        this._setState({ isOpenRightOffDetailModal: !this.state.isOpenRightOffDetailModal, dataView: {} })
    }

    genInfoProcessToggleRightOff = () => {
        const { registerQtty, dataFill, currentAccount, registerAmout } = this.state;
        let processAuthFunc = null;
        let rightOffRegisterBody = null;
        processAuthFunc = transferService.processRightOffRegister;
        rightOffRegisterBody = {
            "camastId": dataFill && dataFill.camastid,
            "qtty": Number(registerQtty),
            "description": '',
            "accountId": dataFill.ciaccount || '',
            "amount": registerAmout,
        };

        return {
            processAuthFunc,
            rightOffRegisterBody,
        }
    }

    getDataInfoToggleRightOff = () => {
        const { userInfo } = this.props;
        const { dataFill, currentAccount, registerQtty, registerAmout, accountSummary } = this.state;
        let _info = null
        let { processAuthFunc, rightOffRegisterBody } = this.genInfoProcessToggleRightOff()
        _info = {
            custodycd: userInfo.custodycd || '',
            accountSummary: accountSummary,
            registerAmout: registerAmout,
            registerQtty: registerQtty,
            currentAccount: currentAccount,
            dataFill: dataFill,
            initFuncData: rightOffRegisterBody,
            authRef: this.transAuthRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbToggleRightOff() },
            title: "common.confirm-trade",
        }
        return _info || {}
    }

    processTransfer = () => {
        this.transAuthRef.current.processAuth();
    };

    transactionCbToggleRightOff = () => {
        ToastUtil.success('account.utilities.cash-transfer.success', 'account.utilities.right-off-register.success-001');
        this.refresh()
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    defaultColumn = {
        headerClassName: "text-center",
    }


    genIcon = (keyIcon) => {
        const { defaultTheme } = this.props;
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? <ReloadIconDark onClick={() => { this.loadSummaryAccount() }} /> : <ReloadIconLight onClick={() => { this.loadSummaryAccount() }} />
                break;
            default:
                break;
        }
    }

    onHandleKeyDownSubmit = (event) => {
        const keyCode = event.which || event.keyCode;
        const { loadStatus } = this.state;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (loadStatus === LoadStatus.LOADING) return;
            event.preventDefault();
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    render() {
        const { accountSummary, isOpenRightOffDetailModal, dataView, dataListRightOff, currentAccount, checkAgree, registerAmountValid, registerQtty, symbol, loadStatus, dataFill, registerAmout } = this.state
        const { accounts, quotes, userInfo, curCustomer } = this.props;
        let instruments = CommonUtils.getInstruments();
        const columns = [
            {
                Header: props => <FormattedMessage id="account.utilities.right-off-register.symbol" />,
                accessor: "symbol",
                cellClassName: "text-center",
                width: "20%",
                Cell: (props) => {
                    const record = props.row.original.symbol;
                    return (
                        <div onClick={() => this.onFillData(record)}>
                            {record}
                        </div>
                    )
                },
            },
            /* Loại lệnh */
            {
                Header: props => <FormattedMessage id="account.utilities.right-off-register.regis-begin-date" />,
                accessor: "pricefromdatetransfertype",
                cellClassName: "text-center text-near-active",
                width: "20%",
                Cell: (props) => {
                    const record = props.row.original.fromdatetransfer;
                    return (
                        <div onClick={() => this.onFillData(record)}>
                            {record}
                        </div>
                    );
                },
            },
            /* Loại khớp */
            {
                Header: props => <FormattedMessage id="account.utilities.right-off-register.regis-end-date" />,
                accessor: "registlastdate",
                cellClassName: "text-center text-near-active",
                width: "20%",
                Cell: (props) => {
                    const record = props.row.original.registlastdate;
                    return (
                        <div onClick={() => this.onFillData(record)}>
                            {record}
                        </div>
                    );
                },
            },
            {
                Header: props => <FormattedMessage id="account.utilities.right-off-register.registered-amount" />,
                accessor: "qtty",
                cellClassName: "text-center text-near-active",
                width: "20%",
                Cell: (props) => {
                    const record = props.row.original.qtty;
                    return (
                        <div onClick={() => this.onFillData(record)}>
                            {record}
                        </div>
                    );
                },
            },
            {
                Header: props => <FormattedMessage id="account.utilities.right-off-register.view" />,
                accessor: "view",
                cellClassName: "text-center text-near-active",
                width: "20%",
                Cell: (props) => {
                    const record = props.row.original;
                    return (
                        <div className="btn-view" onClick={() => this.onViewDetail(record)}>
                            <View />
                        </div>
                    );
                },
            },
        ].map(c => ({ ...this.defaultColumn, ...c }));

        let showSymbol = null
        let infoSymbol = null
        infoSymbol = quotes && _.pickBy(instruments, (value, key) => (key === dataFill.symbol));
        if (infoSymbol) {
            showSymbol = infoSymbol[dataFill.symbol]
        }

        let priceReferenceDeviant = (showSymbol && showSymbol["CH"]) || null
        let percentReferenceDeviant = (showSymbol && showSymbol["CHP"]) || null
        let isDisableConfirm = !(!_.isEmpty(dataFill) && checkAgree && registerQtty)

        let isBroker = this.isBroker();

        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="list-right-off">
                <RightOffDetailModal
                    isOpen={isOpenRightOffDetailModal}
                    toggle={this.toogleOpenRightOffDetailModal}
                    onClose={this.toogleOpenRightOffDetailModal}
                    dataView={dataView}
                />
                <div className="row gutters-5">
                    <div className="col-12 col-lg-7">
                        <div className="block block-list-right-off">
                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id="account.utilities.right-off-register.list-of-rights-to-buy" />
                            </div>
                            {/*<div className="action-container pl-10 p-0 ml-10">
                                <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                                    <div className="custom-form-group-n">
                                        <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                            {(!accounts || accounts.length === 0 || !currentAccount) && (
                                                <FormattedMessage key="empty" id="header.choose-account">
                                                    {(txt) => (
                                                        <option key="empty" value="" disabled>{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            )}
                                            {accounts && accounts.map((account) => {
                                                return (<option key={account.id} value={account.id}>{account.entypename} - {account.id}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <SymbolSuggestTrans
                                        handleInputChange={this.onSymbolChanged}
                                        currentSymbol={symbol}
                                        symbolToDisplay={this.state.symbol}
                                        suggestId="right-off-register"
                                        isRedirect={false}
                                        isDisplayError={false}
                                        placeholder={LanguageUtils.getMessageByKey("account.utilities.right-off-register.all", this.props.lang)}
                                    />
                                </div>
                            </div>*/}
                            {/* <div className="container-table">
                                <CustomTableNew
                                    onChange={(rowDisplay) => {
                                        this.state.recordsDisplay = rowDisplay
                                    }}
                                    columns={columns}
                                    data={dataListRightOff}
                                    defaultColumn={this.defaultColumn} />
                                                                                        </div> */}

                            <div className="container-table p-0" style={{ marginTop: "10px" }}>
                                <CustomScrollbars className="basic-table">
                                    <table className='basic-table table-custom'>
                                        <thead>
                                            <tr>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2"><FormattedMessage id="account.utilities.right-off-register.account-type" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2"><FormattedMessage id="account.utilities.right-off-register.symbol" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2"><FormattedMessage id="account.utilities.right-off-register.regis-begin-date" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2"><FormattedMessage id="account.utilities.right-off-register.regis-end-date" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.ratio" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.price-of-the-right-to-buy" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.registered-amount" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.quantity-left-to-buy" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.number-of-registered" /></th>
                                                <th className="text-active text-center txt---400-12-18" rowSpan="2" ><FormattedMessage id="account.utilities.right-off-register.action" /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataListRightOff.length > 0 && dataListRightOff.map((record, index) => {
                                                if (record.accounttype === 'ALL') {
                                                    return (
                                                        <tr key={index} className='sum-row'>
                                                            <td className="last-column text-left txt---400-12-18" ></td>
                                                            <td className="last-column text-left txt---400-12-18" >{record.symbol} </td>
                                                            <td className="text-left txt---400-12-18" ><FormattedDate value={record.begindate} /></td>
                                                            <td className="text-left txt---400-12-18" ><FormattedDate value={record.duedate} /></td>
                                                            <td className="text-right txt---400-12-18" >{record.rightoffrate}</td>
                                                            <td className="text-right txt---400-12-18" ><FormattedPrice value={record.exprice} /></td>
                                                            <td className="text-right txt---400-12-18" ><FormattedAmount value={record.maxqtty} /></td>
                                                            <td className="text-right txt---400-12-18" ><FormattedAmount value={record.avlqtty} /></td>
                                                            <td className="text-right txt---400-12-18" ><FormattedAmount value={record.suqtty} /></td>
                                                            <td className="text-center btn-view txt---400-12-18">
                                                                {record.allowregist === 'Y' && !isBroker ? (
                                                                    <button onClick={() => this.onFillData(record)} class="btn-confirm txt---400-12-18 bg-secondary-2 btn"><FormattedMessage id="account.utilities.right-off-register.register" /></button>
                                                                ) : (
                                                                    <button class="btn-confirm txt---400-12-18 bg-secondary-2 btn" disabled><FormattedMessage id="account.utilities.right-off-register.register" /></button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="last-column text-left text-near-active txt---400-12-18" >{record.accounttype} </td>
                                                            <td className="last-column text-left text-near-active txt---400-12-18" >{record.symbol} </td>
                                                            <td className="text-left text-near-active txt---400-12-18" ><FormattedDate value={record.begindate} /></td>
                                                            <td className="text-left text-near-active txt---400-12-18" ><FormattedDate value={record.duedate} /></td>
                                                            <td className="text-right text-near-active txt---400-12-18" >{record.rightoffrate}</td>
                                                            <td className="text-right text-near-active txt---400-12-18" ><FormattedPrice value={record.exprice} /></td>
                                                            <td className="text-right text-near-active txt---400-12-18" ><FormattedAmount value={record.maxqtty} /></td>
                                                            <td className="text-right text-near-active txt---400-12-18" ><FormattedAmount value={record.avlqtty} /></td>
                                                            <td className="text-right text-near-active txt---400-12-18" ><FormattedAmount value={record.suqtty} /></td>
                                                            <td className="text-center text-near-active btn-view txt---400-12-18" onClick={() => this.onViewDetail(record)}><View /></td>
                                                        </tr>
                                                    )
                                                }
                                            })}

                                            {dataListRightOff.length === 0 && (
                                                <tr>
                                                    <td colSpan="10" className="last-column text-center txt---400-12-18">
                                                        <FormattedMessage id="common.no-data-to-display" />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </CustomScrollbars>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 ">
                        <div className="block right-off-info-trade">
                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id="account.utilities.right-off-register.registration-information" />
                            </div>

                            <div className='container-block p-10'>
                                {/* Tài khoản*/}
                                {isBroker ?
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="stock-transfer.account" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group txt---400-14-20">
                                                <SelectorAccountCustomer
                                                    curCustomer={curCustomer}
                                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                                    disabled={false}
                                                // className="custom-form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row gutters-5 align-items-center h-row">
                                        <div className="col-5">
                                            <label className="label txt---400-14-20">
                                                <FormattedMessage id="stock-transfer.account" />
                                            </label>
                                        </div>
                                        <div className="col-7">
                                            <div className="custom-form-group txt---400-14-20">
                                                <input className='custom-form-control' value={userInfo.custodycd || ''} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* Tiểu khoản cắt tiền*/}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.account-reduce-amount" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group txt---400-14-20">
                                            <input className='custom-form-control' value={dataFill.ciaccount_af || ''} readOnly />
                                        </div>
                                    </div>
                                </div>


                                {/* Mã chứng khoán */}
                                <div className={"row gutters-5 align-items-center " + (_.isEmpty(dataFill) ? "h-row" : " ")}>
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.stock-code" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        {_.isEmpty(dataFill) && <div className="custom-form-group txt---400-14-20">
                                        </div>}
                                        {!_.isEmpty(dataFill) &&
                                            <div className="custom-form-group-block">
                                                <div className="block-info-symbol">
                                                    <div className="info-symbol">
                                                        <div className="custom-row">
                                                            <div className="label txt---500-14-20 text-active">
                                                                {showSymbol && `${showSymbol["Id"]} | ${showSymbol["EX"]}`}
                                                            </div>
                                                            <div className="value txt---500-14-20 text-active">
                                                                {showSymbol && <FormattedPrice value={showSymbol["CP"]} />}
                                                            </div>
                                                        </div>
                                                        <div className="custom-row">
                                                            <div className="label txt---400-12-18 txt-not-active text-left">{showSymbol && showSymbol["FN"]}</div>
                                                            <div className="value txt---400-12-18 txt-increase">
                                                                <span className={"" + (priceReferenceDeviant < 0 ? "text-red" : "text-green")}>
                                                                    {CommonUtils.roundToTwo(priceReferenceDeviant, 1000)}
                                                                </span>
                                                                <span className={"" + (percentReferenceDeviant < 0 ? "text-red" : "text-green")}>
                                                                    (<FormattedPercentage value={percentReferenceDeviant} />)
                                                                    {showSymbol && "%"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* Số dư khả dụng */}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.available-balances" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group reload-icon text-secondary-1 txt---400-14-20">
                                            <NumberInput2New
                                                step={stepValues.PRICE}
                                                min={null}
                                                max={null}
                                                value={accountSummary.baldefovd_not_adv || 0}
                                                valid={true}
                                                onChange={null}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                readOnly={true}
                                                inputClassName="custom-form-control"
                                            />
                                            {this.genIcon(1)}
                                        </div>
                                    </div>
                                </div>

                                {/* Giá đặt mua */}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.exec-price" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group text-secondary-1 txt---400-14-20">
                                            <NumberInput2New
                                                step={stepValues.PRICE}
                                                min={null}
                                                max={999999999999}
                                                value={dataFill.exprice || 0}
                                                valid={true}
                                                onChange={null}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                readOnly={true}
                                                inputClassName="custom-form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Khối lượng còn được mua */}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.quantity-left-to-buy-2" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group text-secondary-1 txt---400-14-20">
                                            <NumberInput2New
                                                step={stepValues.PRICE}
                                                min={null}
                                                max={999999999999}
                                                value={dataFill.avlqtty || 0}
                                                valid={true}
                                                onChange={null}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                readOnly={true}
                                                inputClassName="custom-form-control"
                                            />
                                        </div>
                                    </div>
                                </div>


                                {/* Giá (x1000) */}
                                {/*<div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.price-1000" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group txt---400-14-20">
                                            {CommonUtils.roundToTwo(dataFill.price, 1000)}
                                        </div>
                                    </div>
                                </div>*/}

                                {/* Số lượng tối đa */}
                                {/* <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.maximum-quantity" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group txt---400-14-20">
                                            {dataFill.rightbuyavailable && <FormattedAmount value={dataFill.rightbuyavailable} />}
                                        </div>
                                    </div>
                                </div>*/}

                                {/* Khối lượng đăng ký */}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.number-of-registrations" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group-effect txt---400-14-20">
                                            <NumberInput2New
                                                ref={this.registerAmountRef}
                                                name="transfer-amount"
                                                step={1}
                                                min={null}
                                                max={999999999999}
                                                value={registerQtty}
                                                valid={registerAmountValid}
                                                onChange={this.onChangeRegisterAmount}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                className="need-focus-eff"
                                                onBlur={this.onBlurTransferAmount}
                                                placeholder={this.props.lang === "vi" ? "Nhập" : "Import"}
                                                onKeyDown={this.onHandleKeyDownSubmit}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Số tiền thanh toán */}
                                <div className="row gutters-5 align-items-center h-row">
                                    <div className="col-5">
                                        <label className="label txt---400-14-20">
                                            <FormattedMessage id="account.utilities.right-off-register.payment-amount" />
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <div className="custom-form-group read-only text-secondary-1 txt---400-14-20">
                                            <FormattedAmount value={registerAmout} />
                                        </div>
                                    </div>
                                </div>

                                <hr className='custom-hr' />

                                <div className="row gutters-5 align-items-center">
                                    <div className="col-12 accept-rule txt---400-14-20">
                                        <span>
                                            <input
                                                type="checkbox"
                                                onChange={this.onChangeCheckAgree}
                                                checked={checkAgree}
                                                onKeyDown={this.onHandleKeyDownSubmit}
                                            ></input>
                                        </span>
                                        <span className='txt---400-14-20 text-near-active'>
                                            <FormattedMessage id="account.utilities.right-off-register.condition" />
                                        </span>
                                    </div>
                                </div>

                                {/* Thao tác */}
                                <div className="row gutters-5 align-items-center h-row container-btn">
                                    {!isBroker && (
                                        <Fragment>
                                            {/* Làm mới */}
                                            <div className="col-6">
                                                <button className="btn-refresh txt---500-16-24 btn" ref={this.refreshBtnRef} onClick={this.refresh} onKeyDown={this.onHandleKeyDownRefresh}>
                                                    <FormattedMessage id="common.btn-refresh" />
                                                </button>
                                            </div>
                                            {/* Tiếp tục */}
                                            <div className="col-6">
                                                <button className="btn-confirm txt---500-16-24 bg-primary btn" ref={this.confirmBtnRef} onClick={this.checkRightOffRegister} onKeyDown={this.onHandleKeyDownSubmit}
                                                // disabled={isDisableConfirm}
                                                >
                                                    <FormattedMessage id="common.btn-continue" />
                                                </button>
                                            </div>

                                        </Fragment>
                                    )}

                                    {isBroker && (
                                        <Fragment>
                                            {/* Làm mới */}
                                            <div className="col-6">
                                                <button className="btn-refresh txt---500-16-24 btn" disabled >
                                                    <FormattedMessage id="common.btn-refresh" />
                                                </button>
                                            </div>
                                            {/* Tiếp tục */}
                                            <div className="col-6">
                                                <button className="btn-confirm txt---500-16-24 bg-primary btn" disabled
                                                // disabled={isDisableConfirm}
                                                >
                                                    <FormattedMessage id="common.btn-continue" />
                                                </button>
                                            </div>

                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RetryableContent >
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        isDeskTop: state.app.isDeskTop,
        lang: state.app.language,
        quotes: state.symbol.quotes,
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,
        defaultTheme: defaultTheme,
        curCustomer: curCustomer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRightOff);