import React, { Component } from 'react';
import { FormattedPrice } from 'components/Formating';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Modal, ModalBody } from 'reactstrap';

import { LoadStatus, } from "../../../../utils";

import './ModalDepositWithdraw.scss';

class ModalDepositWithdraw extends Component {



    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    state = {
        ...this.initialState
    };


    render() {
        const { row, typename, isOpen, checkDepositWithdraw, toggle } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="modal-deposit-withdraw"
            >
                <div className="custom-header">
                    <div className=" headB---700-20-30">
                        <FormattedMessage id="iflex.withdraw-money-iflex" />
                    </div>
                    <div className="btn-icon-fm" onClick={toggle}>
                        <i className="fal fa-times"></i>
                    </div>
                </div>
                <ModalBody className="custom-body">

                    {/* Mã khoản gửi */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.deposit-code" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                {row.acctno}
                            </div>
                        </div>
                    </div>

                    {/* Tiểu khoản */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.sub-accounts" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                {typename}.
                                {row.afacctno}
                            </div>
                        </div>
                    </div>

                    {/* Thời hạn */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.type" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                {row.typename}
                            </div>
                        </div>
                    </div>

                    {/* Số dư gốc */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.table.principal-balance"></FormattedMessage>
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <FormattedPrice value={row.balance} />
                            </div>
                        </div>
                    </div>

                    {/* Ngày tạo khoản iFlex */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.iFlex-account-creation-date" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <FormattedDate value={row.opndate} />
                            </div>
                        </div>
                    </div>

                    {/* Ngày đáo hạn */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.date-due" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <FormattedDate value={row.todate} />
                            </div>
                        </div>
                    </div>

                    {/* Lãi tất toán trước hạn */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.interest-paid-off-before-maturity" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <FormattedPrice value={row.intavlamt} />
                            </div>
                        </div>
                    </div>

                    {/* Số tiền tất toán */}
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-5">
                            <label className="label txt---400-14-20">
                                <FormattedMessage id="iflex.withdraw.amount-of-settlement" />
                            </label>
                        </div>
                        <div className="col-7">
                            <div className="custom-form-group">
                                <FormattedPrice value={row.orgamt} />
                            </div>
                        </div>
                    </div>

                    <div className="row gutters-5 align-items-center h-row container-btn">
                        {/* Làm mới */}
                        <div className="col-6">
                            <button className="btn-refresh txt---500-16-24 btn"
                                onClick={toggle}
                            >
                                <FormattedMessage id="common.btn-back" />
                            </button>
                        </div>
                        {/* Tiếp tục */}
                        <div className="col-6">
                            <button className="btn-confirm txt---500-16-24 btn"
                                onClick={() => checkDepositWithdraw()}
                            >
                                <FormattedMessage id="common.btn-continue"
                                />
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        );
    }

}



export default ModalDepositWithdraw;