import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';
import { LoadStatus } from "../../../../utils";
import { isMobile } from 'react-device-detect';
import './ModalPDFRules.scss';
import PreviewPDF from 'containers/Ekyc/components/PreviewPDF';
import PDFviewer from 'containers/Ekyc/components/PDFviewer';
const globalVar = window._env_

class ModalPDFRules extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    BASE_URL = globalVar.app.ROUTER_BASE_NAME != null ? '/' + globalVar.app.ROUTER_BASE_NAME : '/';


    initialState = {
        loadStatus: LoadStatus.NONE,
        data: null,
        page: null
    };


    state = {
        ...this.initialState
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handlerKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
    }

    render() {
        const {
            isOpen, toggle, pdf_url, onCheckAgree, checked, page, titleId, noFooter 
        } = this.props;

        const {
            data
        } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="rules-modal-cash"
                onClose={toggle}
                size="lg"
            >
                <div className="custom-header">
                    <div className=" headB---700-16-24">
                        <FormattedMessage id={titleId ? titleId : "cash-in-advance.rules"} />
                    </div>
                    <div className="btn-icon-fm" onClick={() => toggle()}>
                        <i className="fal fa-times"></i>
                    </div>
                </div>
                <ModalBody className="custom-body">
                    {/* <div dangerouslySetInnerHTML={{ __html: page }}></div> */}
                    {
                        isMobile ? <div className="preview-pdf">
                            <PreviewPDF src={pdf_url && pdf_url && pdf_url} />
                        </div> : <PDFviewer src={pdf_url && pdf_url && pdf_url} />
                    }
                </ModalBody>
                <div className="line-divide"></div>
                {!noFooter && <div className="custom-footer">
                    <div style={{ gap: "5px", display: "flex" }}>
                        <span>
                            <input
                                type="checkbox"
                                onChange={onCheckAgree}
                                checked={checked}
                            ></input>
                        </span>
                        <span className='txt---500-14-20'>
                            <FormattedMessage id="cash-in-advance.common.005" />
                        </span>
                    </div>
                    <div>
                        <button className="btn-confirm txt---500-16-24 btn" onClick={() => toggle()} >
                            <FormattedMessage id="common.btn-confirm" />
                        </button>
                    </div>
                </div>}
            </Modal >

        )
    };
};

export default ModalPDFRules;