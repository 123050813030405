import React, { Component } from 'react';
import { push } from "connected-react-router";
import { connect } from 'react-redux';

import RetryableContent from "../../../../components/RetryableContent";
import { inquiryService, transferService, userService } from "../../../../services";
import * as actions from "../../../../store/actions";
import { CommonUtils, COUNT_DOWN_RELOAD, IFlexType, LoadStatus, ModalConfirmType, Role, ToastUtil } from "../../../../utils";

import IFlexBalanceDetail from './IFlexBalanceDetail';
import IFlexDetail from "./IFlexDetail";
import ModalDepositWithdraw from './ModalDepositWithdraw';
import ModalDetail from './ModalDetail'
import ModalPDFRules from './ModalPDFRules';

import './IFlex.scss';
import _ from 'lodash';

class IFlex extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        flexType: IFlexType.UN_AUTO,                         //Loại giao dịch
        transferAmount: null,                       //Số tiền chuyển
        transferAmountValid: false,                 //Kiểm tra giá trị số tiền chuyển
        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,

        feeAmount: 0,                               //Phí chuyển tiền
        realAmount: 0,                              //Số tiền thực nhận
        accountSend: {},                            // Số tiểu khoản gửi
        accountReceived: {},                        //Số tiểu khoản nhận

        filterAccount: [],
        isOpen: false,                      //Đóng mở Modal
        isRegisteredCashInAdvance: false,   //Đăng ký ứng tiền tự đỘng
        basis: null,                                  //Số dư tiền
        listTypeDeposit: [],
        listDetailDeposit: [],
        typeDeposit: null,
        baldefovd4td: 0,                        //Số dư khả dụng
        isOpenModalDetail: false,
        checkAgree: false,
        isOpenModalRules: false,
        listAutoTypeDeposit: [],
        autotypeDeposit: null,
        isOnAuto: false,
        isRegistedAuto: false,
        records: [],
        isOpenModalDepositWithdraw: false,
        row: null,
        onReloadData: true,
        time: 0,
        pdf_url: ''
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }	

    reloadSummaryAccount = (e) => {
        let { accountSend } = this.state;
        e.stopPropagation();
        let { time } = this.state;
        if (time === 0) {
            inquiryService.getCorebankBalance(accountSend.id)
                .then((data) => {
                    this.loadSummaryAccount(true)
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-find-benefit-bank');
                });

        }
    }

    clearTimer = () => {
        if (this.counter) {
            clearInterval(this.counter)
        }
    }

    timer = () => {
        this.counter = setInterval(() => {
            let { time } = this.state;
            time = time - 1;
            if (time === 0) {
                this.clearTimer();
            }
            this._setState({
                time: time
            })
        }, 1000);
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }

    changeTypeDeposit = (event) => {
        let { listTypeDeposit } = this.state;
        this._setState({
            typeDeposit: listTypeDeposit.find(item => item.valuecd === event.target.value)
        })
    }

    changeAutoTypeDeposit = (event) => {
        let { listAutoTypeDeposit } = this.state;
        this._setState({
            autotypeDeposit: listAutoTypeDeposit.find(item => item.actype === event.target.value)
        })
    }

    //Lấy danh sách thời hạn từng lần
    loadListTypeIdeposit = async () => {
        let { accountSend } = this.state;
        if (accountSend) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            await inquiryService.getListTypeIdeposit(accountSend.id)
                .then((data) => {
                    this._setState({
                        typeDeposit: data[0],
                        listTypeDeposit: data,
                        loadStatus: LoadStatus.LOADED
                    });
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fails-to-load-list-type-ideposit');
                });
        }
    }

    //Lấy danh sách thời hạn tự động
    //Đang bị lỗi
    loadListAutoTypeIdeposit = () => {
        const { userInfo } = this.props;
        let { accountSend } = this.state;
        if (accountSend) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getListAutoTypeIdeposit(userInfo.custid)
                .then((data) => {
                    this._setState({
                        autotypeDeposit: data[0],
                        listAutoTypeDeposit: data,
                        loadStatus: LoadStatus.LOADED
                    });
                    this.loadDepositAutoInfo()
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fails-to-load-list-type-ideposit');
                });
        }
    }

    //Chi tiết số dư iFlex
    // loadDepositInfo = () => {
    //     let { accountSend } = this.state;
    //     if (accountSend) {
    //         this._setState({ loadStatus: LoadStatus.LOADING });
    //         inquiryService.getDepositInfo(accountSend.id, accountSend.custodycd)
    //             .then((data) => {
    //                 this._setState({ records: data, loadStatus: LoadStatus.LOADED });
    //             })
    //             .catch(error => {
    //                 this._setState({ loadStatus: LoadStatus.FAIL });
    //                 ToastUtil.errorApi(error, 'account.utilities.deposit.fail-to-get-deposit-info');
    //             });
    //     }
    // }

    //Lấy danh sách chi tiết các kỳ hạn
    loadListDetailDeposit = () => {
        let { typeDeposit, autotypeDeposit, flexType } = this.state;
        let type = null
        type = flexType === IFlexType.AUTO ? autotypeDeposit && autotypeDeposit.actype : typeDeposit && typeDeposit.valuecd
        if (type) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getIntRateDeposit(type)
                .then((data) => {
                    this._setState({
                        listDetailDeposit: data,
                        loadStatus: LoadStatus.LOADED
                    });
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fails-to-load-list-type-ideposit');
                });
        }
    }

    //Lấy thông tin đăng ký quản lý tiền mặt tự động
    loadDepositAutoInfo = async () => {
        const { userInfo } = this.props;

        let { listAutoTypeDeposit } = this.state;

        this._setState({ loadStatus: LoadStatus.LOADING });
        await inquiryService.getDepositAutoInfo(userInfo.custid)
            .then((data) => {
                let depositAutoRegisted = null
                let fountAutoDeposit = null
                if (data && data.length > 0) {
                    depositAutoRegisted = data[0];
                }

                if (depositAutoRegisted != null) {
                    let isRegistedAuto = depositAutoRegisted && depositAutoRegisted.actype ? true : false;
                    let isOnAuto = depositAutoRegisted && depositAutoRegisted.isonstatus == 'Y' ? true : false;
                    if (isRegistedAuto) {
                        fountAutoDeposit = listAutoTypeDeposit.find(item => item.actype === depositAutoRegisted.actype)
                    } else {
                        fountAutoDeposit = listAutoTypeDeposit[0]
                    }
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                        isOnAuto: isOnAuto,
                        isRegistedAuto: isRegistedAuto,
                        autotypeDeposit: fountAutoDeposit
                    });
                } else {
                    this._setState({
                        loadStatus: LoadStatus.LOADED,
                        isOnAuto: false,
                        isRegistedAuto: false,
                        autotypeDeposit: listAutoTypeDeposit[0]
                    });
                }
            })
            .catch(error => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fails-to-load-list-type-ideposit');
            });
    }

    //Lấy thông tin số dư khả dụng
    loadBalanceDeposit = () => {
        let { accountSend } = this.state;
        if (accountSend) {
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getBalanceDeposit(accountSend.id)
                .then((data) => {
                    this._setState({
                        baldefovd4td: data[0].baldefovd4td,
                        loadStatus: LoadStatus.LOADED
                    });
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fails-to-load-list-type-ideposit');
                });
        }
    }

    // Lấy thông tin điều khoản
    loadTermPdf = () => {
        userService.getClientConfig()
            .then((data) => {
                this._setState({ pdf_url: data.configData.iflex_term_link });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    toggleModalDetail = () => {
        const { isOpenModalDetail } = this.state
        this._setState({ isOpenModalDetail: !isOpenModalDetail });
    }

    toggleModalRules = () => {
        const { isOpenModalRules } = this.state;
        this._setState({ isOpenModalRules: !isOpenModalRules });
    }

    toggleModalDepositWithDraw = (row) => {
        const { isOpenModalDepositWithdraw } = this.state;
        if (row) {
            this._setState({ isOpenModalDepositWithdraw: !isOpenModalDepositWithdraw, row: row });
        } else {
            this._setState({ isOpenModalDepositWithdraw: !isOpenModalDepositWithdraw });
        }
    }

    onCheckAgree = () => {
        const { checkAgree } = this.state;
        this._setState({ checkAgree: !checkAgree });
    };

    switchIFlex = () => {
        const { isOnAuto } = this.state;
        this._setState({ isOnAuto: !isOnAuto });
    };


    onTypeChange = (event) => {
        this._setState({ flexType: event }, () => {
            // this.refresh();
        });
    };


    onTransferAmountChange = (amount, valid) => {
        this._setState({ transferAmount: amount, transferAmountValid: valid });
    };

    onResetDataInForm = () => {
        this._setState({ transferAmount: null, transferAmountValid: false });
    };


    refresh = () => {
        this._setState({
            ...this.initialState,
            flexType: this.state.flexType,
            accountSend: this.state.accountSend,
        });
        this.loadAllData()
    };

    checkDepositWithdraw = () => {
        const { row } = this.state;
        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        precheckCashtransferFunct = transferService.checkDepositWithdraw;
        checkTransferInfoBody = {
            "tdactype": row.acctno,
            "amt": row.orgamt,
        };

        this._setState({ loadStatus: LoadStatus.LOADING });
        precheckCashtransferFunct(row.afacctno, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                        let _dataInfo = this.getDataInfoDepositWithdraw(row)
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.DEPOSIT_WITHDRAW,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    returnStateAuto = async () => {
        const {
            checkAgree } = this.state;

        if (!checkAgree) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.002');
            return;
        }
        await this.loadDepositAutoInfo()
        await this.loadListTypeIdeposit()
        await this.checkToggleiFlex()
    }


    checkToggleiFlex = async () => {
        const { isOnAuto } = this.state;
        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        precheckCashtransferFunct = transferService.checkToggleiFlex;
        checkTransferInfoBody = {
            "isOn": isOnAuto,
        };

        this._setState({ loadStatus: LoadStatus.LOADING });
        await precheckCashtransferFunct(checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                        let _dataInfo = this.getDataInfoToggleIFlex()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.TOGGLE_IFLEX,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    preCheckInfoInput = () => {
        const {
            transferAmount, accountSend, checkAgree, typeDeposit, flexType, baldefovd4td
        } = this.state;

        if (!accountSend && flexType === IFlexType.UN_AUTO) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.001');
            return;
        }

        if (!transferAmount && flexType === IFlexType.UN_AUTO) {
            if (transferAmount === 0) {
                ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.004');
                return;
            }
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.003');
            return;
        }

        if (transferAmount < typeDeposit.minamt && flexType === IFlexType.UN_AUTO) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.005');
            return;
        }

        if (transferAmount > typeDeposit.maxamt && flexType === IFlexType.UN_AUTO) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.006');
            return;
        }
        //console.log("log---: ", baldefovd4td, transferAmount)

        if (transferAmount > baldefovd4td && flexType === IFlexType.UN_AUTO) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.008');
            return;
        }
        if (!checkAgree) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'iflex.error.002');
            return;
        }

        this.checkTransferInfo()
    }

    checkTransferInfo = () => {
        const {
            typeDeposit, flexType, transferAmount, accountSend, autotypeDeposit
        } = this.state;

        let checkTransferInfoBody = '';
        let precheckCashtransferFunct;
        if (flexType === IFlexType.UN_AUTO) {
            precheckCashtransferFunct = transferService.checkTermDeposit;
            checkTransferInfoBody = {
                "amt": Number(transferAmount),
                "tdactype": typeDeposit && typeDeposit.valuecd,
                "auto": typeDeposit && typeDeposit.autowithdraw,
            };
        } else {
            precheckCashtransferFunct = transferService.checkAutoIFlex;
            checkTransferInfoBody = {
                "tdactype": autotypeDeposit && autotypeDeposit.actype
            };
        }
        this._setState({ loadStatus: LoadStatus.LOADING });
        precheckCashtransferFunct(accountSend.id, checkTransferInfoBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, challengeQuestion, verified } = result;
                    if (flexType === IFlexType.UN_AUTO) {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                            let _dataInfo = this.getDataInfoCashTransfer()
                            this.props.updateConfirmCheckAuth({
                                typeInfo: ModalConfirmType.IFLEX_UN_AUTO,
                                dataInfo: _dataInfo,
                            })

                            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                isOpenScreenConfirm: true,
                                isCheckAuth2: true,
                            })

                        });
                    } else {
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, feeAmount: vatamt + feeamt, isOpen: false }, () => {
                            let _dataInfo = this.getDataInfoCashTransfer()
                            this.props.updateConfirmCheckAuth({
                                typeInfo: ModalConfirmType.IFLEX_AUTO,
                                dataInfo: _dataInfo,
                            })

                            this.props.setIsOpenModalHaveData("ScreenConfirm", {
                                isOpenScreenConfirm: true,
                                isCheckAuth2: true,
                            })

                        });
                    }

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    setStep = (step) => {
        this._setState({
            step: step
        });
    };


    loadAllData = () => {
        this.loadListTypeIdeposit();
        this.loadBalanceDeposit()
        this.loadListDetailDeposit()
        this.loadSummaryAccount()
        this.loadListAutoTypeIdeposit()
        this.loadTermPdf()
        // this.loadDepositInfo()
    }


    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.find(item => item.id === currentAccountId)
        this._setState({ accountSend: filterAccount },
            () => {
                this.loadAllData();
                this.setReloadData()
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketPPAction, accountInfo, language } = this.props;
        const { accountSend, typeDeposit, autotypeDeposit, flexType } = this.state;
        const { accountSend: prevAccountSend, typeDeposit: prevTypeDeposit,
            autotypeDeposit: prevAutoTypeDeposit, flexType: prevFlexType
        } = prevState;
        const { socketCIAction: prevSocketCIAction, socketPPAction: prevSocketPPAction,
            accountInfo: prevAccountInfo, language: prevlanguage } = prevProps;

        if ((socketCIAction[accountSend && accountSend.id] !== prevSocketCIAction[accountSend && accountSend.id] || socketPPAction[accountSend && accountSend.id] !== prevSocketPPAction[accountSend && accountSend.id])) {
            this.loadBalanceDeposit()
            this.loadSummaryAccount()
            this.setReloadData()
        }

        // if (accountSend !== prevAccountSend) {
        //     this.setReloadData()
        //     this.loadAllData();
        // }

        if (!_.isEqual(accountInfo, prevAccountInfo)) {
            const { accounts, currentAccountId } = this.props;
            let filterAccount = accounts.find(item => item.id === currentAccountId)
            this._setState({ accountSend: filterAccount })
        }

        if (typeDeposit !== prevTypeDeposit || flexType !== prevFlexType || autotypeDeposit !== prevAutoTypeDeposit) {
            this.loadListDetailDeposit()
        }

        if (language !== prevlanguage) {
            this.setReloadData()
        }
    }

    //Set tiểu khoản chuyển
    setAccountSend = (event) => {
        const { accounts } = this.props;
        let filterAccount = accounts.filter(item => item.id !== event.target.value)
        let foundAccount = accounts.find(item => item.id === event.target.value)
        this._setState({ accountSend: foundAccount, filterAccount: filterAccount }, () => {
            this.loadAllData();
            this.setReloadData()
        })
        this.onResetDataInForm();
    };

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };

    transactionCb = () => {
        this._setState({ row: null, isOpenModalDepositWithdraw: false, transferAmount: null, transferAmountValid: false });
        this.loadAllData()
        ToastUtil.success('account.utilities.cash-transfer.success', 'iflex.success.002');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    transactionCbToggleIFlex = () => {
        this.switchIFlex()
        this.loadAllData()
        ToastUtil.success('account.utilities.cash-transfer.success', 'iflex.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    transactionCbDepositWithdraw = () => {
        this.toggleModalDepositWithDraw()
        this._setState({ row: null, isOpenModalDepositWithdraw: false });
        //this.loadDepositInfo()
        this.setReloadData()
        ToastUtil.success('account.utilities.cash-transfer.success', 'iflex.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };


    genInfoWithTypeCash = () => {
        const {
            transferAmount, typeDeposit, accountSend, flexType, autotypeDeposit
        } = this.state;

        let processAuthFunc = null;
        let transferBody = null;
        if (flexType === IFlexType.UN_AUTO) {
            processAuthFunc = transferService.processTermDeposit;
            transferBody = {
                "amt": Number(transferAmount),
                "tdactype": typeDeposit && typeDeposit.valuecd,
                "auto": typeDeposit && typeDeposit.autowithdraw,
                "accountId": accountSend.id
            };
        } else {
            processAuthFunc = transferService.processAutoIFlex;
            transferBody = {
                "tdactype": autotypeDeposit && autotypeDeposit.actype
            };
        }
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    genInfoProcessToggleIFlex = () => {
        const { isOnAuto } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        processAuthFunc = transferService.processToggleiFlex;
        transferBody = {
            "isOn": !isOnAuto,
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    genInfoProcessDepositWithdraw = () => {
        const { row } = this.state;
        let processAuthFunc = null;
        let transferBody = null;
        processAuthFunc = transferService.processDepositWithdraw;
        transferBody = {
            "tdactype": row.acctno,
            "amt": row.orgamt,
            "accountId": row.afacctno
        };
        transferBody = CommonUtils.checkDataBodyNotNull(transferBody)

        return {
            processAuthFunc,
            transferBody,
        }
    }

    loadSummaryAccount = (isReload = false) => {
        const { accountSend } = this.state;
        if (accountSend) {
            if (isReload) {
                this._setState({ loadStatusReload: LoadStatus.LOADING })
            } else {
                this._setState({ loadStatus: LoadStatus.LOADING });
            }
            inquiryService.getSummaryAccount(accountSend && accountSend.id)
                .then((data) => {
                    this._setState({
                        basis: data,
                    });
                    if (isReload) {
                        this._setState({ loadStatusReload: LoadStatus.LOADED, time: COUNT_DOWN_RELOAD },
                            () => this.timer())
                    } else {
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                        });
                    }
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL, loadStatusReload: LoadStatus.LOADED });
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-summary');
                });
        }
    }

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };


    getDataInfoDepositWithdraw = () => {
        const { loadStatus, row, accountSend } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoProcessDepositWithdraw()
        _info = {
            typename: accountSend && accountSend.typename,
            row: row,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            loadStatus: loadStatus,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbDepositWithdraw() },
            setLoadStatus: (status) => { this.setLoadStatus(status) },
            title: "common.confirm-trade",
        }
        return _info || {}
    }

    getDataInfoToggleIFlex = () => {
        const {
            loadStatus, accountSend, autotypeDeposit, isOnAuto
        } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoProcessToggleIFlex()
        _info = {
            accountSend: accountSend,
            autotypeDeposit: autotypeDeposit,
            isOnAuto: isOnAuto,
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            loadStatus: loadStatus,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbToggleIFlex() },
            setLoadStatus: (status) => { this.setLoadStatus(status) },
            title: "common.confirm-trade",
        }
        return _info || {}
    }


    getDataInfoCashTransfer = () => {
        const {
            loadStatus, accountSend, transferAmount,
            typeDeposit, flexType, autotypeDeposit, isRegistedAuto
        } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoWithTypeCash()
        if (flexType === IFlexType.UN_AUTO) {
            _info = {
                transferAmount: transferAmount,
                accountSend: accountSend,
                flexType: flexType,
                typeDeposit: typeDeposit,
                initFuncData: transferBody,
                authRef: this.TransAuthExternalRef,
                loadStatus: loadStatus,
                processTransfer: () => { this.processTransfer() },
                processAuthFunc: processAuthFunc,
                refresh: () => { this.refresh() },
                transactionCb: () => { this.transactionCb() },
                setLoadStatus: (status) => { this.setLoadStatus(status) },
                title: "common.confirm-trade",
            }
        }
        else if (flexType === IFlexType.AUTO) {
            _info = {
                accountSend: accountSend,
                autotypeDeposit: autotypeDeposit,
                isRegistedAuto: isRegistedAuto,
                initFuncData: transferBody,
                authRef: this.TransAuthExternalRef,
                loadStatus: loadStatus,
                processTransfer: () => { this.processTransfer() },
                processAuthFunc: processAuthFunc,
                refresh: () => { this.refresh() },
                transactionCb: () => { this.transactionCb() },
                setLoadStatus: (status) => { this.setLoadStatus(status) },
                title: "common.confirm-trade",
            }
        }

        return _info || {}
    }

    apiLoadAll = async (obj) => {
        const { accountSend } = this.state;
        try {
            return inquiryService.getDepositInfo(accountSend.id, accountSend.custodycd)
                .then((data) => {
                    return data
                })
                .catch(error => {
                    ToastUtil.errorApi(error, 'account.utilities.deposit.fail-to-get-deposit-info');
                    return []
                });
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    render() {
        const {
            basis,
            loadStatus, accountSend, accountReceived, filterAccount,
            iFlexType, onReloadData, pdf_url,
            transferAmount, transferAmountValid, description,
            flexType, listTypeDeposit, typeDeposit, baldefovd4td, isOpenModalDetail,
            checkAgree, isOpenModalRules, listAutoTypeDeposit, autotypeDeposit,
            isRegistedAuto, isOnAuto, listDetailDeposit, records, isOpenModalDepositWithdraw, row
        } = this.state;
        const { accounts, userInfo, currentAccount, language, role } = this.props;

        const isBroker = this.isBroker();

        return (
            <RetryableContent status={loadStatus} disableRetry={true}>
                <div className="i-flex">
                    <IFlexDetail
                        language={language}
                        iFlexType={iFlexType}
                        accounts={accounts}
                        isBroker={isBroker}
                        selectedAccount={currentAccount}
                        transferAmount={transferAmount}
                        userInfo={userInfo}
                        onTransferTypeChange={this.onTransferTypeChange}
                        onTransferAmountChange={this.onTransferAmountChange}
                        transferAmountValid={transferAmountValid}

                        role={role}

                        onBlurTransferAmount={this.onBlurTransferAmount}
                        onResetDataInForm={this.onResetDataInForm}

                        accountSend={accountSend}
                        accountReceived={accountReceived}
                        setAccountSend={this.setAccountSend}
                        filterAccount={filterAccount}
                        refresh={this.refresh}
                        preCheckInfoInput={this.preCheckInfoInput}
                        basis={basis}
                        flexType={flexType}
                        onTypeChange={this.onTypeChange}
                        listTypeDeposit={listTypeDeposit}
                        typeDeposit={typeDeposit}
                        changeTypeDeposit={this.changeTypeDeposit}
                        baldefovd4td={baldefovd4td}
                        toggleModalDetail={this.toggleModalDetail}
                        onCheckAgree={this.onCheckAgree}
                        checkAgree={checkAgree}
                        isOpenModalRules={isOpenModalRules}
                        onToggleOpenModalRules={this.toggleModalRules}
                        loadSummaryAccount={this.loadSummaryAccount}
                        autotypeDeposit={autotypeDeposit}
                        listAutoTypeDeposit={listAutoTypeDeposit}
                        changeAutoTypeDeposit={this.changeAutoTypeDeposit}
                        isOnAuto={isOnAuto}
                        isRegistedAuto={isRegistedAuto}
                        switchIFlex={this.switchIFlex}
                        checkToggleiFlex={this.checkToggleiFlex}
                        defaultTheme={this.props.defaultTheme}
                        returnStateAuto={this.returnStateAuto}
                        loadStatusReload={this.state.loadStatusReload}
                        time={this.state.time}
                        reloadSummaryAccount={this.reloadSummaryAccount}
                    />
                    <IFlexBalanceDetail
                        onReloadData={onReloadData}
                        apiLoadAll={this.apiLoadAll}
                        checkDepositWithdraw={this.checkDepositWithdraw}
                        accounts={accounts}
                        toggleModalDepositWithDraw={this.toggleModalDepositWithDraw} />

                    {
                        isOpenModalDetail &&
                        <ModalDetail
                            isOpen={isOpenModalDetail}
                            toggle={this.toggleModalDetail}
                            listDetailDeposit={listDetailDeposit}
                        ></ModalDetail>
                    }
                    {
                        isOpenModalRules &&
                        <ModalPDFRules
                            isOpen={isOpenModalRules}
                            toggle={this.toggleModalRules}
                            onCheckAgree={this.onCheckAgree}
                            checked={checkAgree}
                            pdf_url={pdf_url}
                        ></ModalPDFRules>
                    }
                    {
                        isOpenModalDepositWithdraw &&
                        <ModalDepositWithdraw
                            isOpen={isOpenModalDepositWithdraw}
                            toggle={this.toggleModalDepositWithDraw}
                            row={row}
                            checkDepositWithdraw={this.checkDepositWithdraw}
                            typename={accountSend.typename}
                        ></ModalDepositWithdraw>
                    }
                </div>
            </RetryableContent>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        socketCIActionCounter: state.app.socketCIActionCounter,
        socketSEActionCounter: state.app.socketSEActionCounter,
        socketODActionCounter: state.app.socketODActionCounter,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        defaultTheme: defaultTheme,
        accountInfo: _accountInfo,
        socketPPAction: state.socketRealtime.socketPPAction,
        socketCIAction: state.socketRealtime.socketCIAction,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IFlex);

